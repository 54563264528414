var learningClubsAddEventManager = {
    _containerVue: "#containerVueLearningClubsAddEventId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                clubId: 0,
                currentUser: null,
                members: null
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    const elemContainerElem = vueObj.$els.containerElem;

                    if(elemContainerElem){
                        let clubId = $(elemContainerElem).data('learning-club-id');
                        
                        if(clubId){
                            vueObj.clubId = parseInt(clubId);
                        }
                    }

                    vueObj.getCurrentUser();
                    vueObj.getClubApprovedMembers();
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getClubApprovedMembers: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/approved_members";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.members = response.data.members;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }

};

$(function(){
    if($(learningClubsAddEventManager._containerVue) && $(learningClubsAddEventManager._containerVue).length > 0){
        learningClubsAddEventManager.initVueObj();
    }
});
