var managerIndexTutorStudentNotes = {
    _containerVue: "#containerPageContentIndexTutorStudentNotes",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                tokenCsrf: null,
                newNote: { students: null, description: null },
                preAssociatedUsers: null,
                showFormNewNote: 'no',
                sendingForm: 'no',
            },

            ready: function(){
                this.init();
            },

            events: {                
                'updateDescription': function(msg){
                    this.newNote.description = msg;
                },

                'updatedListAuthorsOrModerators': function(msg){
                    this.newNote.students = msg;
                },
            },

            computed: {
                isVisibleFormNewNote: function(){
                    return this.showFormNewNote && this.showFormNewNote == 'yes';
                },

                isSendingForm: function(){
                    return this.sendingForm && this.sendingForm == 'yes';
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.tokenCsrf){
                        vueObj.tokenCsrf = $(vueObj.$els.tokenCsrf).data('token-csrf');
                    }

                    if($(vueObj.$els.currentUserId)){
                        vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                    }
 
                    if(vueObj.currentUserId && vueObj.currentUserId > 0){
                        vueObj.getCurrentUser();
                    }
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                toggleVisibleFromNewNote: function(){
                    const vueObj = this;

                    if(vueObj.showFormNewNote == 'yes'){
                        vueObj.showFormNewNote = 'no';
                    }else{
                        vueObj.showFormNewNote = 'yes';
                    }
                },

                addNewNote: function(){
                    const vueObj = this;

                    vueObj.sendingForm = 'yes';

                    let url = '/json/tutor_student_notes';

                    let params = new FormData();
                    params.append('description', vueObj.newNote.description);

                    if(vueObj.newNote.students && vueObj.newNote.students.length > 0){
                        params.append('student_id', vueObj.newNote.students[0].user_id);
                    }

                    vueObj.$http({ 
                            url: url, method: 'POST',
                            responseType: 'json', 
                            data: params,
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        if(response.data.status == 'success'){
                            $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                            if(response.data.url_redirect){
                                setTimeout(function(){ window.location.href = response.data.url_redirect }, 3000);
                            }else{
                                vueObj.sendingForm = 'no';
                            }
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            vueObj.sendingForm = 'no';
                        }
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }

};

$(function(){
    if($(managerIndexTutorStudentNotes._containerVue) && $(managerIndexTutorStudentNotes._containerVue).length > 0){
        managerIndexTutorStudentNotes.initVueObj();
    }
});
