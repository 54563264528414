var managerPromotionBannerEvents = {
    _containerVueId: "#containerPromotionEventsBannerPageId",
    _vueManager: null,

    initVue: function(){
        const manager = this;

        manager._vueManager = new Vue({
            el: manager._containerVueId,

            data: {
                events: null
            },

            ready: function(){
                const vueObj = this;

                vueObj.getEvents();
            },

            computed: {
                showEvents: function(){
                    return this.events && this.events.length > 0;
                }
            },

            methods: {
                getEvents: function(){
                    const vueObj = this;
                
                    let params = { is_featured: 'yes', with_tutors: 'yes', with_learning_clubs: 'yes', with_categories: 'yes' };
                        
                    vueObj.$http({ 
                        url: "/json/events", 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        vueObj.events = response.data.events;

                        if(vueObj.events && vueObj.events.length > 0){
                            vueObj.initCarousel();
                        }
                    }, function(response){
                        //error callback
                    });
                },

                initCarousel: function(){
                    const component = this;
        
                    component.$nextTick(() => {
                        let divCarousel = null;
                        let carouselOptions = { items: 1, loop: true, margin:0, autoplay: true, dots: false, nav: false, mouseDrag: true, autoHeight: true, autoplayHoverPause: true };

   
                        divCarousel = component.$els.carouselEventsPromotion;
                        

                        if(divCarousel){
                            const elem = $(divCarousel);
                            
                            if(!elem.hasClass('owl-carousel')){
                                elem.addClass("owl-carousel");
    
                                elem.owlCarousel(carouselOptions);
                                
                                setTimeout(function(){ elem.trigger('refresh.owl.carousel'); }, 1500);
                            }else{
                                elem.trigger('refresh.owl.carousel');
                            }
                        }
                    });
                },
            }
        });
    }
};

$(function(){
    if($(managerPromotionBannerEvents._containerVueId) && $(managerPromotionBannerEvents._containerVueId).length > 0){
        managerPromotionBannerEvents.initVue();
    }
});
