var managerPageMyJourneyPath = {
    _containerVue: "#pageMyJourneyPathId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                currentUserEventsIdsJoined: [],
                currentUserClubsIdsJoined: [],
                currentUserMembershipsActive: [],
                tokenCsrf: null,
                searchParams: { doctoral_journey_groups_ids: null, price_tier: null, location: null },
                events: null,
                workshops: null,
                advancedTrainings: null,
                clubs: null,
                inResidences: null,
                isLoadingEvents: 'yes',
                isLoadingWorkshops: 'yes',
                isLoadingAdvancedTrainings: 'yes',
                isLoadingClubs: 'yes',
                isLoadingInResidences: 'no',
            },

            props: {        
                selectedObjGroups: {
                    type: Array,
                    default:  function(){
                        return []
                    }
                }
            },

            ready: function(){
                this.init();
            },

            computed: {
                isPriceTier3: function(){
                    return this.searchParams.price_tier && this.searchParams.price_tier == 'tier_3';
                },

                showLoadingEvents: function(){
                    return this.isLoadingEvents && this.isLoadingEvents == 'yes';
                },

                showLoadingWorkshops: function(){
                    return this.isLoadingWorkshops && this.isLoadingWorkshops == 'yes';
                },

                showLoadingAdvancedTrainings: function(){
                    return this.isLoadingAdvancedTrainings && this.isLoadingAdvancedTrainings == 'yes';
                },

                showLoadingClubs: function(){
                    return this.isLoadingClubs && this.isLoadingClubs == 'yes';
                },

                showLoadingInResidences: function(){
                    return this.isLoadingInResidences && this.isLoadingInResidences == 'yes';
                },

                showSectionWorkshops: function(){
                    return this.showLoadingWorkshops || this.hasWorkshopsToShow;
                },

                showSectionInResidences: function(){
                    return this.showLoadingInResidences || this.hasInResidencesToShow;
                },

                showSectionAdvancedTrainings: function(){
                    return this.showLoadingAdvancedTrainings || this.hasAdvancedTrainingsToShow;
                },

                showServiceMentoringOneOnOne: function(){
                    return this.searchParams.price_tier && this.searchParams.price_tier == 'tier_2';
                },

                showServiceMentoring: function(){
                    return this.searchParams.price_tier && this.searchParams.price_tier == 'tier_3';
                },

                showServiceMockViva: function(){
                    return this.isPriceTier3 && this.isStageSelectedMockViva;
                },

                showSectionOtherServices: function(){
                    return this.showServiceMentoring || this.showServiceMockViva;
                },

                hasEventsToShow: function(){
                    return !this.showLoadingEvents && this.events && this.events.length > 0;
                },

                hasWorkshopsToShow: function(){
                    return !this.showLoadingWorkshops && this.workshops && this.workshops.length > 0;
                },

                hasAdvancedTrainingsToShow: function(){
                    return !this.showLoadingAdvancedTrainings && this.advancedTrainings && this.advancedTrainings.length > 0;
                },

                hasClubsToShow: function(){
                    return !this.showLoadingClubs && this.clubs && this.clubs.length > 0;
                },

                hasInResidencesToShow: function(){
                    return !this.showLoadingInResidences && this.inResidences && this.inResidences.length > 0;
                },

                clubsAreVisible: function(){
                    return this.searchParams.price_tier && (this.searchParams.price_tier == 'tier_1' || this.searchParams.price_tier == 'tier_2');
                },

                hasSelectedGroups: function(){
                    return this.selectedObjGroups && this.selectedObjGroups.length > 0;
                },

                isStageSelectedMockViva: function(){
                    const manager = this;

                    if(manager.hasSelectedGroups){
                        if(manager.selectedObjGroups.some(group => group.name == 'Viva & Graduation')){
                            return true;
                        }
                    }

                    return false;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerVue).data('current-user-id'));
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data("token-csrf");
                    }

                    vueObj.setSearchParamsAndGetEvents();

                    vueObj.getClubs();

                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }
                },

                setSearchParamsAndGetEvents: function(){
                    const vueObj = this;

                    const urlParams = new URLSearchParams(window.location.search);

                    if(urlParams.get('djg') && urlParams.get('djg').length > 0){
                        vueObj.searchParams.doctoral_journey_groups_ids = urlParams.get('djg').split(',').map(function(id){
                            return parseInt(id);
                        })
                    }

                    vueObj.searchParams.price_tier = urlParams.get('p');
                    vueObj.searchParams.location = urlParams.get('l');

                    vueObj.getEvents('next');
                    vueObj.getEvents('workshops');
                    vueObj.getEvents('advanced_trainings');

                    if(vueObj.isPriceTier3){
                        vueObj.getEvents('in_residences');
                    }
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;
                        vueObj.currentUserMembershipsActive = response.data.memberships_active;
                    }, function(response){
                        //ERROR
                    });
                },

                getEvents: function(eventsType){
                    const vueObj = this;

                    let params = {};

                    switch(eventsType){
                        case 'next':
                            vueObj.isLoadingEvents = 'yes';

                            params = { is_published: 'yes', events_with_date: 'yes', filter: 'from_today_to_one_month', with_learning_clubs: 'yes', with_event_participants: 'yes', with_tutors: 'yes', dynamic_list_groups_ids: vueObj.searchParams.doctoral_journey_groups_ids, price_tier: vueObj.searchParams.price_tier, location: vueObj.searchParams.location };

                            break;

                        case 'workshops':
                            vueObj.isLoadingWorkshops = 'yes';

                            params = { is_published: 'yes', with_learning_clubs: 'yes', with_event_participants: 'yes', with_tutors: 'yes', type: 'workshop', dynamic_list_groups_ids: vueObj.searchParams.doctoral_journey_groups_ids, price_tier: vueObj.searchParams.price_tier };

                            break;

                        case 'advanced_trainings':
                            vueObj.isLoadingAdvancedTrainings = 'yes';

                            params = { is_published: 'yes', with_learning_clubs: 'yes', with_event_participants: 'yes', with_tutors: 'yes', type: 'advanced_training', dynamic_list_groups_ids: vueObj.searchParams.doctoral_journey_groups_ids, price_tier: vueObj.searchParams.price_tier };

                            break;

                        case 'in_residences':
                            vueObj.isLoadingInResidences = 'yes';

                            params = { is_published: 'yes', with_event_participants: 'yes', with_tutors: 'yes', type: 'in_residence' };

                            break;
                    }

                    vueObj.$http({ url: '/json/events', method: 'GET', params: params }).then(function(response){
                        switch(eventsType){
                            case 'next':
                                vueObj.events = response.data.events;
        
                                vueObj.isLoadingEvents = 'no';
        
                                vueObj.$nextTick(() => {
                                    vueObj.manageCarousel($(vueObj.$els.listEvents));
                                });

                                break;

                            case 'workshops':
                                vueObj.workshops = response.data.events;
        
                                vueObj.isLoadingWorkshops = 'no';
        
                                vueObj.$nextTick(() => {
                                    setTimeout(function(){
                                        vueObj.manageCarousel($(vueObj.$els.listWorkshops));
                                    },1600);
                                });

                                break;

                            case 'advanced_trainings':
                                vueObj.advancedTrainings = response.data.events;
        
                                vueObj.isLoadingAdvancedTrainings = 'no';
        
                                vueObj.$nextTick(() => {
                                    setTimeout(function(){
                                        vueObj.manageCarousel($(vueObj.$els.listAdvancedTrainings));
                                    },1700);
                                });

                                break;

                            case 'in_residences':
                                vueObj.inResidences = response.data.events;

                                vueObj.isLoadingInResidences = 'no';

                                vueObj.$nextTick(() => {
                                    setTimeout(function(){
                                        vueObj.manageCarousel($(vueObj.$els.listInResidences));
                                    },1700);
                                });

                                break;
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                getClubs: function(){
                    const vueObj = this;

                    if(vueObj.searchParams.price_tier && (vueObj.searchParams.price_tier == 'tier_1' || vueObj.searchParams.price_tier == 'tier_2')){
                        const params = { with_accepted_members: 'yes', with_upcoming_events: 'yes', dynamic_list_groups_ids: vueObj.searchParams.doctoral_journey_groups_ids };

                        vueObj.$http({ url: '/json/learning_clubs', method: 'GET', params: params }).then(function(response){
                            vueObj.clubs = response.data.learning_clubs;

                            vueObj.isLoadingClubs = 'no';
    
                            vueObj.$nextTick(() => {
                                setTimeout(function(){
                                    vueObj.manageCarousel($(vueObj.$els.listClubs));
                                },1500);
                            });
                        }, function(response){
                            //ERROR
                        });
                    }
                },

                manageCarousel: function(divCarousel){
                    const component = this;
        
                    component.$nextTick(() => {
                        if(divCarousel){
                            const elem = divCarousel;
        
                            if(component.events){
                                if(!elem.hasClass('owl-carousel')){
                                    elem.addClass("owl-carousel");
        
                                    elem.owlCarousel({
                                        items: 3,
                                        loop:false,
                                        margin:0,
                                        autoplay: false,
                                        dots: true,
                                        nav: true,
                                        navText: [
                                            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
                                            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
                                        ],
                                        mouseDrag: true,
                                        autoHeight: true,
                                        responsive:{
                                            0:{
                                                items:1,
                                                center: true,
                                                nav: false,
                                            },
                                            650:{
                                                items: 2
                                            },
                                            1000:{
                                                items:3
                                            }
                                        }
                                    });
                                    
                                    setTimeout(function(){ elem.trigger('refresh.owl.carousel'); }, 1500);
                                }else{
                                    elem.trigger('refresh.owl.carousel');
                                }
                            }else{
                                component.removeCarousel(elem);
                            }
                        }
                    });
                },
        
                removeCarousel: function(divCarousel){
                    if(divCarousel && divCarousel.hasClass('owl-carousel')){
                        divCarousel.trigger('destroy.owl.carousel');
                        divCarousel.removeClass('owl-carousel');
                    }
                },
            }
        });
    }
};

$(function(){
    if($(managerPageMyJourneyPath._containerVue) && $(managerPageMyJourneyPath._containerVue).length > 0){
        managerPageMyJourneyPath.initVueObj();
    }
});
