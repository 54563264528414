var showMasterCourseManager = {
    _containerVueId: "#contentShowMasterCourseId",
    _vueObj: null,

    initVueObjShow: function(){
        const manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVueId,
            data: {
                eventId: null,
                event: null,
                currentUser: null,
                tasks: null,
                activeIndexTask: 0,
                forceUpdateThreads: 'no',
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerList){
                    vueObj.eventId = $(vueObj.$els.containerList).data('event-id');
                    vueObj.getCurrentUser();
                    vueObj.getEvent();
                    vueObj.getTasks();
                }
            },

            events: {
                'updatedIndexActiveOnTaskList': function(index){
                    this.manageActiveIndexChange(index);
                },

                'updatedIndexActiveOnMessageBoard': function(index){
                    this.manageActiveIndexChange(index);
                },

                'updatedIndexActiveOnListParticipants': function(index){
                    this.manageActiveIndexChange(index);
                },

                'forceUpdateMessageBoard': function(){
                    this.forceUpdateThreads = 'yes';

                    this.$nextTick(() => {
                        this.forceUpdateThreads = 'no';
                    });
                }
            },

            methods: {
                getTasks: function(){
                    const vueObj = this;

                    if(vueObj.eventId && vueObj.eventId > 0){
                        vueObj.tasks = null;

                        let url = "/json/events/"+ vueObj.eventId +"/tasks";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.tasks = response.data.tasks;
                            }else{
                                vueObj.tasks = [];
                            }
                        }, function(response){
                            //ERROR
                            vueObj.tasks = [];
                        });
                    }
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getEvent: function(){
                    const vueObj = this;

                    const url = "/events/"+ vueObj.eventId +"/json/detail";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.event = response.data.event;
                    }, function(response){
                        //ERROR
                    });
                },

                manageActiveIndexChange: function(newIndex){
                    const vueObj = this;

                    vueObj.activeIndexTask = newIndex;
                }
            }
        });
    }
};

$(function(){
    if($(showMasterCourseManager._containerVueId) && $(showMasterCourseManager._containerVueId).length > 0){
        showMasterCourseManager.initVueObjShow();
    }
});
