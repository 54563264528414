var headerDropdownManager = {
    _containerVue: "#headerUserDropdownOptionsVue",
    _vueObj: null,

    initVue: function(){
        const manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                braincoins: 0
            },

            ready: function(){
                this.init();                
            },

            events: {
            },

            computed: {
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    vueObj.getBraincoins();
                },

                getBraincoins: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current/braincoins/balance', method: 'GET' }).then(function(response){
                        vueObj.braincoins = response.data.braincoins;

                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }
};

$(function(){
    if($(headerDropdownManager._containerVue) && $(headerDropdownManager._containerVue).length > 0){
        headerDropdownManager.initVue();
    }
});
