var learningClubsFormManager = {
    _containerVueId: '#vueContainerFormLearningClubs',
    _formVueObj: null,

    initVueForm: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            el: manager._containerVueId,

            data: {
                containerFormDivId: manager._containerVueId,
                tokenCsrf: null,
                objectId: null,
                sendingForm: 'no',
                alreadyInit: 'no',
                formData: { name: null, short_description: null, long_description: null, location: null, learning_outcomes: null, membership_requires_approval: false, image: { original: null, web: null, fb_lkn: null, twitter: null }, link_whatsapp: null, club_label: null, is_published: false, request_to_publish: null, annual_fee: null },
                preselectedStudySegments: { researchMethodsAndTools: [], dataCollectionTechiques: [], doctoralJourney: [] },
                studySegments: { researchMethodsAndTools: { id: 0, list: [] }, dataCollectionTechiques: { id: 0, list: [] }, doctoralJourney: { id: 0, list: [] } },
                errors: { general: 'no', name: 'no', short_description: 'no' },
                updateWysiwygFields: { short_description: null, long_description: null, learning_outcomes:null },
                checkboxLocationIsOnline: true,
                checkboxHasLocation: false,
                checkboxesClubLabel: { journey: false, local: false, special_interest: false }
            },

            computed: {
                isUpdateForm: function(){
                    return  this.objectId && this.objectId > 0;
                },

                formType: function(){
                    if(this.isUpdateForm){
                        return 'edit';
                    }

                    return 'new';
                },

                showLoadingForm: function(){
                    return this.sendingForm == 'yes' || this.alreadyInit == 'no';
                },

                showErrorGeneral: function(){
                    return !this.showLoadingForm && this.errors.general && this.errors.general == 'yes';
                },

                showErrorName: function(){
                    return !this.showLoadingForm && this.errors.name && this.errors.name == 'yes';
                },

                showErrorShortDescription: function(){
                    return !this.showLoadingForm && this.errors.short_description && this.errors.short_description == 'yes';
                },

                showInputLocation: function(){
                    return this.checkboxHasLocation;
                },

                requestToPublishIsPending: function(){
                    return this.formData.request_to_publish && this.formData.request_to_publish == 'pending';
                },

                isPublished: function(){
                    return (this.formData.request_to_publish && this.formData.request_to_publish == 'accepted') || this.formData.is_published;
                },

                showSectionPublish: function(){
                    return !this.requestToPublishIsPending && !this.isPublished;
                },

                showSectionPublishPending: function(){
                    return this.requestToPublishIsPending;
                },

                showSectionIsPublish: function(){
                    return this.isPublished;
                },

                showInputAnnualFee: function(){
                    return this.formData.club_label && this.formData.club_label == 'local';
                }
            },

            events: {
                'updateName': function(msg){
                    this.formData.name = msg;
                },

                'updateShortDescription': function(msg){
                    this.formData.short_description = msg;
                },

                'updateLongDescription': function(msg){
                    this.formData.long_description = msg;
                },

                'updateLocation': function(msg){
                    this.formData.location = msg;
                },

                'updateLinkWhatsapp': function(msg){
                    this.formData.link_whatsapp = msg;
                },

                'updateLearningOutcomes': function(msg){
                    this.formData.learning_outcomes = msg;
                },

                'updateResearchMethodsAndTools': function(msg){
                    this.studySegments.researchMethodsAndTools.id = msg.id;
                    this.studySegments.researchMethodsAndTools.list = msg.list;
                },

                'updateDataCollectionTechiques': function(msg){
                    this.studySegments.dataCollectionTechiques.id = msg.id;
                    this.studySegments.dataCollectionTechiques.list = msg.list;
                },

                'updateDoctoralJourney': function(msg){
                    this.studySegments.doctoralJourney.id = msg.id;
                    this.studySegments.doctoralJourney.list = msg.list;
                },

                'updatePhoto': function(msg){
                    this.formData.image.original = msg.original;
                    this.formData.image.web = msg.web;
                    this.formData.image.fb_lkn = msg.fb_lnk;
                    this.formData.image.twitter = msg.twitter;
                }
            },

            ready: function(){
                const vueObj = this;

                const elemContainerForm = vueObj.$els.containerForm;

                if(elemContainerForm){
                    vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                }

                const elemContainerObjectId = vueObj.$els.containerObjectId;
                if(elemContainerObjectId){
                    vueObj.objectId = $(elemContainerObjectId).data('id');
                }

                if(vueObj.isUpdateForm){
                    vueObj.getObjectForEditionAndInitForm();
                }else{
                    vueObj.setFormAsInitialized();
                }
            },

            methods: {
                setFormAsInitialized: function(){
                    const vueObj = this;

                    vueObj.$nextTick(() => {
                        vueObj.alreadyInit = 'yes';
                    });
                },

                resetErrors: function(){
                    const vueObj = this;

                    vueObj.errors.general = 'no';
                    vueObj.errors.name = 'no';
                    vueObj.errors.short_description = 'no';
                },

                stringWithoutHtml: function(stringToCheck){
                    let container = document.createElement('div');
                    let text = document.createTextNode(stringToCheck);
                    container.appendChild(text);

                    return container.innerHTML;
                },

                toggleCheckboxIsOnline: function(){
                    const vueObj = this;

                    vueObj.checkboxHasLocation = !vueObj.checkboxHasLocation;
                    vueObj.checkboxLocationIsOnline = !vueObj.checkboxLocationIsOnline;

                    if(vueObj.checkboxLocationIsOnline){
                        vueObj.formData.location = null;
                    }
                },

                setClubLabel: function(label){
                    const vueObj = this;
                    
                    switch(label){
                        case 'journey':
                            vueObj.checkboxesClubLabel.journey = true;
                            vueObj.checkboxesClubLabel.local = false;
                            vueObj.checkboxesClubLabel.special_interest = false;

                            vueObj.formData.club_label = "journey";
                            break;

                        case 'local':
                            vueObj.checkboxesClubLabel.journey = false;
                            vueObj.checkboxesClubLabel.local = true;
                            vueObj.checkboxesClubLabel.special_interest = false;

                            vueObj.formData.club_label = "local";
                            break;

                        case 'special_interest':
                            vueObj.checkboxesClubLabel.journey = false;
                            vueObj.checkboxesClubLabel.local = false;
                            vueObj.checkboxesClubLabel.special_interest = true;

                            vueObj.formData.club_label = "special_interest";
                            break;

                        default:
                            vueObj.checkboxesClubLabel.journey = false;
                            vueObj.checkboxesClubLabel.local = false;
                            vueObj.checkboxesClubLabel.special_interest = false;

                            vueObj.formData.club_label = null;
                    }
                },

                validateForm: function(){
                    const vueObj = this;

                    let formIsValid = 'yes';

                    vueObj.resetErrors();
                    
                    if(!vueObj.formData.name || vueObj.formData.name.replace(/\s/g, "").length <= 0){
                        vueObj.errors.name = 'yes';
                        formIsValid = "no";
                    }

                    if(vueObj.formData.short_description && vueObj.formData.short_description.replace(/\s/g, "").length > 0){
                        const shortDescriptionWithoutHtml = vueObj.stringWithoutHtml(vueObj.formData.short_description);

                        if(shortDescriptionWithoutHtml.length <= 0){
                            vueObj.errors.short_description = 'yes';
                            formIsValid = "no";
                        }
                    }else{
                        vueObj.errors.short_description = 'yes';
                        formIsValid = "no";
                    }

                    if(formIsValid == 'no'){
                        vueObj.errors.general = 'yes';
                    }

                    return formIsValid;
                },

                sendForm: function(extraParams = null){
                    const vueObj = this;

                    vueObj.sendingForm = 'yes';

                    vueObj.$nextTick(() => {
                        const elemContainerForm = vueObj.$els.containerForm;

                        if(elemContainerForm){
                            elemContainerForm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    });

                    const formIsValid = vueObj.validateForm();

                    if(formIsValid && formIsValid == 'yes'){
                        let url = "/json/learning_clubs";
                        let method = "POST";

                        let params = new FormData();

                        if(vueObj.isUpdateForm){
                            url = "/json/learning_clubs/" + vueObj.objectId;
                            params.append('_method', 'PATCH');
                        }

                        params.append('name', vueObj.formData.name);

                        if(vueObj.formData.short_description && vueObj.formData.short_description.replace(/\s/g, "").length > 0){
                            params.append('short_description', vueObj.formData.short_description);
                        }

                        if(vueObj.formData.long_description && vueObj.formData.long_description.replace(/\s/g, "").length > 0){
                            params.append('long_description', vueObj.formData.long_description);
                        }

                        if(vueObj.formData.location && vueObj.formData.location.replace(/\s/g, "").length > 0){
                            params.append('location', vueObj.formData.location);
                        }

                        if(vueObj.formData.link_whatsapp && vueObj.formData.link_whatsapp.replace(/\s/g, "").length > 0){
                            params.append('link_whatsapp', vueObj.formData.link_whatsapp);
                        }

                        if(vueObj.formData.club_label && vueObj.formData.club_label.replace(/\s/g, "").length > 0){
                            params.append('club_label', vueObj.formData.club_label);
                        }

                        if(vueObj.formData.learning_outcomes && vueObj.formData.learning_outcomes.replace(/\s/g, "").length > 0){
                            params.append('learning_outcomes', vueObj.formData.learning_outcomes);
                        }

                        if(vueObj.formData.club_label && vueObj.formData.club_label == 'local' && vueObj.formData.annual_fee && vueObj.formData.annual_fee > 0){
                            params.append('annual_fee', vueObj.formData.annual_fee);
                        }

                        if(vueObj.formData.membership_requires_approval && vueObj.formData.membership_requires_approval == true){
                            params.append('membership_requires_approval', 'yes');
                        }else{
                            params.append('membership_requires_approval', 'no');
                        }

                        if(vueObj.formData.image.original && vueObj.formData.image.original != null){
                            params.append('image_original', vueObj.formData.image.original);
                        }

                        if(vueObj.formData.image.web && vueObj.formData.image.web != null){
                            params.append('image_web', vueObj.formData.image.web);
                        }

                        if(vueObj.formData.image.fb_lkn && vueObj.formData.image.fb_lkn != null){
                            params.append('image_fb_lkn', vueObj.formData.image.fb_lkn);
                        }

                        if(vueObj.formData.image.twitter && vueObj.formData.image.twitter != null){
                            params.append('image_twitter', vueObj.formData.image.twitter);
                        }

                        params.append('segments', JSON.stringify(vueObj.studySegments));


                        if(extraParams && extraParams.request_to_publish && extraParams.request_to_publish == 'yes' && !vueObj.requestToPublishIsPending && !vueObj.isPublished){
                            params.append('request_to_publish', 'yes');
                            vueObj.formData.request_to_publish = 'pending';
                        }

                        vueObj.$http({ 
                                url: url, method: method,
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                                if(response.data.url_redirect){
                                    setTimeout(function(){ window.location.href = response.data.url_redirect }, 3000);
                                }
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                vueObj.sendingForm = 'no';
                            }
                        }, function(response){
                            //ERROR
                        });
                    }else{
                        vueObj.sendingForm = 'no';

                        vueObj.$nextTick(() => {
                            const elemContainerErrorsText = vueObj.$els.containerTextErrorsForm;
    
                            if(elemContainerErrorsText){
                                elemContainerErrorsText.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                            }
                        });
                    }
                },

                getObjectForEditionAndInitForm: function(){
                    const vueObj = this;

                    let url = "/json/learning_clubs/" + vueObj.objectId;

                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        if(response.data.status == 'success'){
                            vueObj.setObjectDataFromRequest(response.data.learning_club, response.data.segments);
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            setTimeout(function(){ window.location.href = "/" }, 3000);
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                setObjectDataFromRequest: function(club, segments){
                    const vueObj = this;

                    vueObj.setPreselectedSegments(segments);

                    vueObj.setPreselectedImage(club);

                    vueObj.setStatusPublished(club);

                    vueObj.formData.name = club.name;

                    if(club.description_short && club.description_short.length > 0 && club.description_short != 'null'){
                        vueObj.formData.short_description = club.description_short;
                    }

                    if(club.description_long && club.description_long.length > 0 && club.description_long != 'null'){
                        vueObj.formData.long_description = club.description_long;
                    }

                    if(club.location && club.location.length > 0 && club.location != 'null'){
                        vueObj.formData.location = club.location;
                        vueObj.checkboxLocationIsOnline = false;
                        vueObj.checkboxHasLocation = true;
                    }

                    if(club.link_whatsapp && club.link_whatsapp.length > 0 && club.link_whatsapp != 'null'){
                        vueObj.formData.link_whatsapp = club.link_whatsapp;
                    }

                    if(club.label && club.label.length > 0 && club.label != 'null'){
                        vueObj.setClubLabel(club.label);
                    }

                    if(club.learning_outcomes && club.learning_outcomes.length > 0 && club.learning_outcomes != 'null'){
                        vueObj.formData.learning_outcomes = club.learning_outcomes;
                    }

                    if(club.membership_approval_required == 1){
                        vueObj.formData.membership_requires_approval = true;
                    }else{
                        vueObj.formData.membership_requires_approval = false;
                    }

                    if(club.annual_fee && parseFloat(club.annual_fee)){
                        vueObj.formData.annual_fee = parseFloat(club.annual_fee);
                    }

                    vueObj.setFormAsInitialized();
                },

                setPreselectedSegments: function(segments){
                    const vueObj = this;
     
                    if(segments){
                        if('research_methods_and_tools' in segments){
                            $.each(segments.research_methods_and_tools, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.researchMethodsAndTools.push(tempObj);
                            });
                        }

                        if('data_collection_techiques' in segments){
                            $.each(segments.data_collection_techiques, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.dataCollectionTechiques.push(tempObj);
                            });
                        }

                        if('doctoral_journey' in segments){
                            $.each(segments.doctoral_journey, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.doctoralJourney.push(tempObj);
                            });
                        }
                    }
                },

                setPreselectedImage: function(club){
                    const vueObj = this;

                    if(club && club.photo && club.photo.length > 0){
                        vueObj.formData.image.original = vueObj.$options.filters.urlImageFromFolderPath(club, 'original');
                        vueObj.formData.image.web = vueObj.$options.filters.urlImageFromFolderPath(club, 'web');
                        vueObj.formData.image.fb_lkn = vueObj.$options.filters.urlImageFromFolderPath(club, 'fb_lkn');
                        vueObj.formData.image.twitter = vueObj.$options.filters.urlImageFromFolderPath(club, 'twitter');
                    }
                },

                setStatusPublished: function(club){
                    const vueObj = this;

                    if(club.is_published && parseInt(club.is_published) == 1){
                        vueObj.formData.is_published = true;
                    }else{
                        if(club.status_request_publish){
                            vueObj.formData.request_to_publish = club.status_request_publish;
                        }
                    }
                }
            }
        });
    }
};

$(function(){
    if($(learningClubsFormManager._containerVueId) && $(learningClubsFormManager._containerVueId).length > 0){
        learningClubsFormManager.initVueForm();
    }

    $('.accordionFormSharedV1').on({
        'down.zf.accordion': function(){
           const liActive = $(this).find(".accordion-item.is-active");

           const menuListTabs = $(".containerFormLearningClubs .containerGenericFormAccordion");

           if(menuListTabs){
               $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                   if(liActive && $(elem).data("tab") == liActive.data("name")){
                       $(elem).addClass("active");
                   }
               });
           }
        },
        'up.zf.accordion': function(){
            const liActive = $(this).find(".accordion-item.is-active");

            const menuListTabs = $(".containerFormLearningClubs .containerGenericFormAccordion");

            if(menuListTabs){
                $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                    if($(elem).hasClass("active")){
                        $(elem).removeClass("active");
                    }
                });
            }
        }
    });
});
