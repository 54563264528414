$(function(){    
    var messageBoardManager = {
        _containerFormNewThreadId: '#containerFormNewThreadId',
        _vueObjFormNewThread: null,
        _containerFormNewThreadMessageId: '#containerFormNewThreadMessageId',
        _vueObjFormNewThreadMessage: null,
        _vueObjFormNewThread: null,
        _containerListThreadsId: '#containerListThreadsId',
        _vueObjListThreads: null,
        _containerListThreadMessagesId: '#containerListThreadMessagesId',
        _vueObjListThreadMessages: null,
        _containerFormNewSectionId: '#containerFormNewMessageBoardSectionId',
        _vueObjFormNewSection: null,
        _containerListSections: '#containerListMbSectionsId',
        _vueObjListSections: null,

        initVueObjFormNewThread: function(){
            var manager = this;

            manager._vueObjFormNewThread = new Vue({
                // element to mount to
                el: manager._containerFormNewThreadId,
                // initial data
                data: {
                    thread: { title: null, body: null, visible_for: 'all', visible_for_ids: [] },
                    attachments: [],
                    waitingForFormToSend: 'no',
                    showForm: 'yes',
                    containerFormDivId: manager._containerFormNewThreadId
                },

				ready: function(){
                    var vueObj = this;
                },

                // computed property for form validation state
                computed: {
                },
                // methods
                methods: {
                    getContenteditableBody(evt) {
                        var vueObj = this;

                        vueObj.thread.body = evt.target.innerHTML;
                    },

                    manageFileUploads: function(){
                        var vueObj = this;

                        var elem = vueObj.$els.attachments;
                        var files = $(elem)[0].files;

                        if(files && files.length > 0){
                            for(var i = 0; i < files.length; i++){
                                vueObj.attachments.push(files[i]);
                            }
                        }
                    },

                    removeFileFromUploads: function(index){
                        var vueObj = this;

                        vueObj.attachments.splice(index, 1);
                    },

                    readURL: function(file, index) {
                        if(file){
                            var reader = new FileReader();

                            reader.onload = function(e) {
                                //return e.target.result; 
                                $("#img_a_" + index).attr('src', e.target.result);
                            }

                            reader.readAsDataURL(file);
                        }
                    },

                    getFileExt: function(file){
                        if(file.type){
                            return file.type.split('/')[1];
                        }

                        return 'file';
                    },

                    sendForm: function () {
                        var vueObj = this;

                        vueObj.waitingForFormToSend = 'yes';

                        var form = $(vueObj.containerFormDivId).find(".formMbThreadNew");
                        
                        var url = form.attr("action");
                        var method = form.attr("method");
                        var formData = new FormData(form[0]);

                        formData.append('body', vueObj.thread.body);

                        formData.append('list_attachments[]', null);

                        if(vueObj.attachments && vueObj.attachments.length > 0){
                            for(var i=0; i < vueObj.attachments.length; i++){
                                formData.append('list_attachments[]', vueObj.attachments[i]);
                            }
                        }

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");

                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }

                        if(form && !form.hasClass("blur2px")){
                            form.addClass("blur2px");
                        }

                        vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                            vueObj.manageRequestResponse(response.data);
                        }, function(response){
                            vueObj.manageRequestResponse({status: "error", message: ''});
                        });
                    },

                    manageRequestResponse: function(response){
                        var vueObj = this;

                        if(response.mb_thread_id){
                            if(response.event){
                                switch(response.event.type){
                                    case 'training':
                                        var urlToRedirect = '/webinars/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'short_course':
                                        var urlToRedirect = '/advanced_courses/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'residence':
                                        var urlToRedirect = '/residencies/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'workshop':
                                        var urlToRedirect = '/workshops/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'in_residence':
                                        var urlToRedirect = '/in_residences/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'learning_cafe':
                                        var urlToRedirect = '/learning_cafes/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'master_course':
                                        var urlToRedirect = '/master_courses/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'master_course_edition':
                                        var urlToRedirect = '/master_course_editions/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    case 'conference':
                                        var urlToRedirect = '/conferences/' + response.event.id + '/thread/' + response.mb_thread_id;
                                        break;
                                    default:
                                        var urlToRedirect = '/';
                                }

                                window.location.href = urlToRedirect;
                            }else if(response.events_series_id){
                                let urlToRedirect = '/training_session_series/' + response.events_series_id + '/thread/' + response.mb_thread_id;
                                window.location.href = urlToRedirect;
                            }else if(response.learning_club_id){
                                let urlToRedirect = '/clubs/' + response.learning_club_id + '/thread/' + response.mb_thread_id;
                                window.location.href = urlToRedirect;
                            }else if(response.programme_id){
                                let urlToRedirect = '/programmes/' + response.programme_id + '/thread/' + response.mb_thread_id;
                                window.location.href = urlToRedirect;
                            }else{
                                window.location.href = '/message_board/thread/' + response.mb_thread_id;
                            }
                        }else{
                            window.location.reload();
                        }
                    },

                    clearForm: function(){
                        var vueObj = this;

                        vueObj.thread.title = null;
                        vueObj.thread.body = null;
                    },

                    btnClickManageShowForm: function(){
                        var vueObj = this;

                        if(vueObj.showForm == 'yes'){
                            vueObj.showForm = 'no';
                        }else{
                            vueObj.showForm = 'yes';
                        }
                    }
                }
            });
        },

        initVueObjFormNewThreadMessage: function(){
            var manager = this;

            manager._vueObjFormNewThreadMessage = new Vue({
                // element to mount to
                el: manager._containerFormNewThreadMessageId,
                // initial data
                data: {
                    message: { body: null, thread_id: null, message_id: null },
                    attachments: [],
                    waitingForFormToSend: 'no',
                    showPlubisherReal: 'no',
                    containerFormDivId: manager._containerFormNewThreadMessageId,
                    messageToReply: null,
                    threadId: null
                },

				ready: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerOutterForm){
                        vueObj.threadId = $(vueObj.$els.containerOutterForm).data('thread-id');
                    } 
                },

                // computed property for form validation state
                computed: {
                    hasMessageToReply: function(){
                        if(this.messageToReply){
                            return true;
                        }
            
                        return false;
                    }
                },
                // methods
                methods: {
                    getContenteditableBody(evt) {
                        var vueObj = this;

                        vueObj.message.body = evt.target.innerHTML;
                    },

                    manageFileUploads: function(){
                        var vueObj = this;

                        var elem = vueObj.$els.attachments;
                        var files = $(elem)[0].files;

                        if(files && files.length > 0){
                            for(var i = 0; i < files.length; i++){
                                vueObj.attachments.push(files[i]);
                            }
                        }
                    },

                    removeFileFromUploads: function(index){
                        var vueObj = this;

                        vueObj.attachments.splice(index, 1);
                    },

                    readURL: function(file, index) {
                        if(file){
                            var reader = new FileReader();

                            reader.onload = function(e) {
                                //return e.target.result; 
                                $("#img_a_" + index).attr('src', e.target.result);
                            }

                            reader.readAsDataURL(file);
                        }
                    },

                    getFileExt: function(file){
                        if(file.type){
                            return file.type.split('/')[1];
                        }

                        return 'file';
                    },

                    sendForm: function () {
                        var vueObj = this;

                        vueObj.waitingForFormToSend = 'yes';

                        var form = $(vueObj.containerFormDivId).find(".formMbThreadMessageNew");
                        
                        var url = form.attr("action");
                        var method = form.attr("method");
                        var formData = new FormData(form[0]);

                        formData.append('body', vueObj.message.body);
                        
                        formData.append('list_attachments[]', null);

                        if(vueObj.attachments && vueObj.attachments.length > 0){
                            for(var i=0; i < vueObj.attachments.length; i++){
                                formData.append('list_attachments[]', vueObj.attachments[i]);
                            }
                        }
                        
                        if(vueObj.messageToReply){
                            if(vueObj.messageToReply.creator){
                                formData.append('reply_to_thread_id', vueObj.messageToReply.id);
                            }else{
                                formData.append('reply_to_message_id', vueObj.messageToReply.id);
                            }
                        }

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }

                        if(form && !form.hasClass("blur2px")){
                            form.addClass("blur2px");
                        }

                        vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                            vueObj.manageRequestResponse(response.data);
                        }, function(response){
                            vueObj.manageRequestResponse({status: "error", message: ''});
                        });
                    },

                    manageRequestResponse: function(response){
                        var vueObj = this;

                        window.location.reload();
                    },

                    clearForm: function(){
                        var vueObj = this;

                        vueObj.deleteMessageToReply();

                        vueObj.message.body = null;
                        vueObj.message.thread_id = null;
                        vueObj.message.message_id = null;
                    },

                    btnClickManageShowPublisher: function(){
                        var vueObj = this;

                        if(vueObj.showPlubisherReal == 'yes'){
                            vueObj.showPlubisherReal = 'no';
                        }else{
                            vueObj.showPlubisherReal = 'yes';
                            setTimeout(function(){ vueObj.$els.body.focus(); }, 0);
                        }
                    },

                    setMessageToReply: function(message){
                        const vueObj = this;

                        vueObj.messageToReply = message;

                        vueObj.$nextTick(() => {
                            if(vueObj.$els.containerOutterForm){
                                $(vueObj.$els.containerOutterForm)[0].scrollIntoView();
                            }  
                        }); 
                    },

                    deleteMessageToReply: function(){
                        const vueObj = this;
            
                        vueObj.messageToReply = null;
                    }
                }
            });
        },

        initVueObjListThreads: function(){
            var manager = this;

            manager._vueObjListThreads = new Vue({
                // element to mount to
                el: manager._containerListThreadsId,

                // initial data
                data: {
                    baseUrl: null,
                    messageBoardId: null,
                    sectionId: null,
                    eventId: null,
                    currentUserId: null,
                    csrfToken: null,
                    threads: null,
                    tutors: null,
                    participants: null,
                    threadIdsIsVisible: {/*...*/},
                    showDivLoadingThreads: 'yes',
                    showDropdownMoreOptionsForThreadId: 0,
                    showFormEditForThreadId: 0,
                    editThread: { id: null, title: null, visible_for: 'all', visible_for_ids: [], event_id: null },
                    containerDivId: manager._containerListThreadsId
                },

				ready: function(){
                    var vueObj = this;

                    vueObj.init();
                },

                // computed property for form validation state
                computed: {
                },

                filters: {
                    formatDateWithTime: function(date){
                        return moment(date).format('dddd, DD MMMM YYYY HH:mm');
                    }
                },

                // methods
                methods: {
                    init: function(){
                        var vueObj = this;

                        var containerVue = $(vueObj.containerDivId);

                        if(containerVue){
                            vueObj.baseUrl = containerVue.data("url");
                            vueObj.csrfToken = containerVue.data('csrf');

                            if(vueObj.$els.messageBoardId){
                                vueObj.messageBoardId = $(vueObj.$els.messageBoardId).data('id');
                            }

                            if(vueObj.$els.sectionId){
                                vueObj.sectionId = $(vueObj.$els.sectionId).data('id');
                            }

                            if(vueObj.$els.eventId){
                                vueObj.eventId = $(vueObj.$els.eventId).data("id");
                                vueObj.getEventParticipantsAndTutors();
                            }

                            if(vueObj.$els.currentUserId){
                                vueObj.currentUserId = $(vueObj.$els.currentUserId).data("id");
                            }

                            vueObj.getThreads();
                        }
                    },

                    getThreads: function(){
                        var vueObj = this;

                        vueObj.showDivLoadingThreads = 'yes';

                        if(vueObj.messageBoardId){
                            var url = vueObj.baseUrl + "/message_board/"+vueObj.messageBoardId+"/json/threads";
                        }

                        if(vueObj.sectionId){
                            var url = vueObj.baseUrl + "/message_board_section/"+vueObj.sectionId+"/json/threads";
                        }

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.threads = response.data.threads;

                            vueObj.initThreadsVisibleArray();

                            vueObj.showDivLoadingThreads = 'no';
                            
                        }, function(response){
                            //error callback
                        });
                    },

                    getEventParticipantsAndTutors: function(){
                        var vueObj = this;

                        vueObj.getEventParticipants();
                        vueObj.getEventTutors();
                    },

                    getEventParticipants: function(){
                        var vueObj = this;

                        var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/participants";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.participants = response.data.participants;                            
                        }, function(response){
                            //error callback
                        });
                    },

                    getEventTutors: function(){
                        var vueObj = this;

                        var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/tutors";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.tutors = response.data.tutors;                            
                        }, function(response){
                            //error callback
                        });
                    },

                    initThreadsVisibleArray: function(){
                        var vueObj = this;

                        if(vueObj.threads && vueObj.threads.length > 0){
                            $.each(vueObj.threads, function(index, thread){
                                var url = vueObj.baseUrl + "/message_board_thread/"+thread.id+"/json/can_see_thread";

                                vueObj.$http({ 
                                    url: url, 
                                    method: 'GET', 
                                    responseType: 'json'
                                }).then(function(response){
                                    Vue.set(vueObj.threadIdsIsVisible, thread.id, response.data.visible);
                                                                    
                                }, function(response){
                                    //error callback
                                });
                            });
                        }
                        
                    },

                    getUrlToThread: function(id){
                        var vueObj = this;
 
                        var url = vueObj.baseUrl + "/message_board/thread/redirect_to_page?idThread="+id;

                        if(vueObj.eventId){
                            url = url + '&id=' + vueObj.eventId;
                        }

                        return url;
                    },

                    getUrlToCreator: function(userId){
                        var vueObj = this;

                        var url = vueObj.baseUrl + '/users/' + userId;

                        return url;
                    },

                    getStorageImagePath: function(imagePath){
                        return "/storage/"+imagePath;
                    },

                    manageClickMoreOptions: function(threadId){
                        var vueObj = this;

                        if(vueObj.showDropdownMoreOptionsForThreadId != threadId){
                            vueObj.showDropdownMoreOptions(threadId);
                        }else{
                            vueObj.hideDropdownMoreOptions();
                        }
                    },

                    showDropdownMoreOptions: function(threadId){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForThreadId = threadId;
                    },

                    hideDropdownMoreOptions: function(){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForThreadId = 0;
                    },

                    deleteThread: function(threadId){
                        var vueObj = this;

                        vueObj.showDivLoadingThreads = 'yes';
                        vueObj.hideDropdownMoreOptions();

                        var url = vueObj.baseUrl + "/message_board_thread/"+threadId+"/json";

                        vueObj.$http({ 
                            url: url, 
                            method: 'DELETE', 
                            responseType: 'json', 
                            data:{ _token: vueObj.csrfToken } 
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                vueObj.getThreads();
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                vueObj.showDivLoadingThreads = 'no';
                            }                            
                        }, function(response){
                            //error callback
                        });
                    },

                    manageClickEditThread: function(thread){
                        var vueObj = this;

                        if(vueObj.showFormEditForThreadId != thread.id){
                            vueObj.showFormForThread(thread);
                        }else{
                            vueObj.hideFormForThread();
                        }
                    },

                    showFormForThread: function(thread){
                        var vueObj = this;

                        vueObj.hideDropdownMoreOptions();

                        vueObj.editThread.id = thread.id;

                        vueObj.editThread.title = thread.title;

                        if(thread.visible_for == 'all'){
                            vueObj.editThread.visible_for = 'all';

                            vueObj.editThread.visible_for_ids = [];
                        }else{
                            vueObj.editThread.visible_for = 'select_users';

                            vueObj.editThread.visible_for_ids = thread.visible_for.split(",");
                        }

                        vueObj.showFormEditForThreadId = thread.id;

                        setTimeout(function(){ $(vueObj.containerDivId).find(".inputTitle").focus(); }, 0);
                    },

                    hideFormForThread: function(){
                        var vueObj = this;

                        vueObj.showFormEditForThreadId = 0;
                    },

                    currentUserIsTutor: function(){
                        var vueObj = this;

                        var isTutor = false;

                        if(vueObj.currentUserId && vueObj.tutors && vueObj.tutors.length > 0){
                            $.each(vueObj.tutors, function(index, tutor){
                                if(tutor.id == vueObj.currentUserId){
                                    isTutor = true;
                                    return false;
                                }
                            })
                        }

                        return isTutor;
                    },

                    updateThread: function(threadId){
                        var vueObj = this;

                        if(threadId == vueObj.showFormEditForThreadId){
                            vueObj.showDivLoadingThreads = 'yes';

                            var url = vueObj.baseUrl + "/message_board_thread/"+threadId+"/json";

                            if(vueObj.eventId){
                                vueObj.editThread.event_id = vueObj.eventId;
                            }

                            vueObj.$http({ 
                                url: url, 
                                method: 'PATCH', 
                                responseType: 'json', 
                                data:{ _token: vueObj.csrfToken, thread: vueObj.editThread } 
                            }).then(function(response){
                                if(response.data.status == 'success'){
                                    $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                    vueObj.hideFormForThread();
                                    vueObj.getThreads();
                                }else{
                                    $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                    vueObj.showDivLoadingThreads = 'no';
                                }                            
                            }, function(response){
                                //error callback
                            });
                        }
                    }
                }
            });
        },

        initVueObjListThreadMessages: function(){
            var manager = this;

            manager._vueObjListThreadMessages = new Vue({
                // element to mount to
                el: manager._containerListThreadMessagesId,

                // initial data
                data: {
                    baseUrl: null,
                    event: null,
                    eventId: null,
                    thread: null,
                    threadId: null,
                    currentUser: null,
                    csrfToken: null,
                    replies: null,
                    repliesAndThread: null,
                    showDivLoadingMessages: 'yes',
                    showDropdownMoreOptionsForThreadId: 0,
                    showFormEditForMessageThread: 'no',
                    showFormEditForMessageId: 0,
                    editMessage: { id: null, is_thread: 'no', thread_id: null, body: null, attachments: [], previous_attachments: [], attachments_ids_to_remove: [] },
                    containerDivId: manager._containerListThreadMessagesId
                },

				ready: function(){
                    var vueObj = this;

                    var containerVue = $(vueObj.containerDivId);

                    if(containerVue){
                        vueObj.baseUrl = containerVue.data("url");
                        vueObj.threadId = containerVue.data('thread-id');
                        vueObj.csrfToken = containerVue.data('csrf');

                        if(vueObj.$els.eventId){
                            vueObj.eventId = $(vueObj.$els.eventId).data("id");
                        }

                        vueObj.getData();
                    }
                },

                // computed property for form validation state
                computed: {
                },

                filters: {
                    messageDate: function(date){
                        return moment(date).format('DD MMM YYYY');
                    },
                    messageTime: function(date){
                        return moment(date).format('HH:mm');
                    }
                },

                // methods
                methods: {
                    getData: function(){
                        var vueObj = this;

                        vueObj.showDivLoadingMessages = 'yes';

                        if(vueObj.eventId){
                            var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/thread/"+vueObj.threadId;
                        }else{
                            var url = vueObj.baseUrl +"/message_board_thread/"+vueObj.threadId+"/json/replies";
                        }

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){

                            if(response.data.current_user){
                                vueObj.currentUser = response.data.current_user;
                            }

                            if(response.data.event){
                                vueObj.event = response.data.event;
                            }

                            vueObj.thread = response.data.thread;
                            vueObj.replies = response.data.replies;

                            vueObj.initRepliesAndThread();

                            vueObj.showDivLoadingMessages = 'no';
                            
                        }, function(response){
                            //error callback
                        });
                    },

                    initRepliesAndThread: function(){
                        var vueObj = this;

                        if((!vueObj.replies || vueObj.replies.length <= 0) && vueObj.thread){
                            vueObj.repliesAndThread = [];
                            vueObj.repliesAndThread.push(vueObj.thread);
                        }else if(vueObj.replies && vueObj.replies.length > 0 && vueObj.thread){
                            vueObj.repliesAndThread = [vueObj.thread].concat(vueObj.replies.slice());
                        }

                        Vue.nextTick(function () {
                            divExpandManager.check_size_div_text();
                            svgManager.initInlineSVG('.vue-inline-svg');
                        })

                    },

                    getStorageImagePath: function(imagePath){
                        return "/storage/"+imagePath;
                    },

                    getUrlToUser: function(userId){
                        var vueObj = this;

                        var url = vueObj.baseUrl + '/users/' + userId;

                        return url;
                    },

                    deleteMessage: function(messageId){
                        var vueObj = this;

                        vueObj.showDivLoadingMessages = 'yes';

                        var url = vueObj.baseUrl + "/message_board_thread_message/"+messageId+"/json";

                        vueObj.$http({ 
                            url: url, 
                            method: 'DELETE', 
                            responseType: 'json', 
                            data:{ _token: vueObj.csrfToken } 
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                vueObj.getData();
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                vueObj.showDivLoadingMessages = 'no';
                            }                            
                        }, function(response){
                            //error callback
                        });
                    },

                    showFormForMessage: function(message, index){
                        var vueObj = this;

                        if((index == 0 && vueObj.showFormEditForMessageThread != 'yes') || (index != 0 && message.id != vueObj.showFormEditForMessageId)){
                            vueObj.hideFormForMessage();
    
                            if(index == 0){
                                vueObj.showFormEditForMessageThread = 'yes';
                                vueObj.showFormEditForMessageId = 0;
    
                                vueObj.editMessage.is_thread = 'yes';
                                vueObj.editMessage.thread_id = message.id;
                            }else{
                                vueObj.showFormEditForMessageThread = 'no';
                                vueObj.showFormEditForMessageId = message.id;
    
                                vueObj.editMessage.is_thread = 'no';
                                vueObj.editMessage.id = message.id;
                            }

                            if(message.attachments && message.attachments.length > 0){
                                vueObj.editMessage.previous_attachments = message.attachments.slice();
                            }
    
                            Vue.nextTick(function () {
                                svgManager.initInlineSVG('.vue-inline-svg-attachment');
                                
                                setTimeout(function(){ 
                                    var divBodyEditor = $(vueObj.containerDivId).find(".containerFormThreadMessageEdit .divEditor");
    
                                    if(divBodyEditor){
                                        divBodyEditor.focus();
                                    }
                                }, 0);
                            })
                        }
                    },

                    hideFormForMessage: function(){
                        var vueObj = this;

                        vueObj.editMessage.id = null;
                        vueObj.editMessage.is_thread = 'no';
                        vueObj.editMessage.thread_id = null;
                        vueObj.editMessage.body = null;
                        vueObj.editMessage.attachments = [];
                        vueObj.editMessage.previous_attachments = [];
                        vueObj.editMessage.attachments_ids_to_remove = [];

                        vueObj.showFormEditForMessageId = 0;
                        vueObj.showFormEditForMessageThread = 'no';
                    },

                    getContenteditableBody(evt) {
                        var vueObj = this;
 
                        vueObj.editMessage.body = evt.target.innerHTML;
                    },

                    manageFileUploads: function(){
                        var vueObj = this;

                        var elem = $(vueObj.containerDivId).find(".containerFormThreadMessageEdit #uploadFilesMessageEditId");
                        var files = $(elem)[0].files;

                        if(files && files.length > 0){
                            for(var i = 0; i < files.length; i++){
                                vueObj.editMessage.attachments.push(files[i]);
                            }
                        }
                    },

                    removeFileFromUploads: function(index){
                        var vueObj = this;

                        vueObj.editMessage.attachments.splice(index, 1);
                    },

                    removeFilePreviousAttachments: function(attachment, index){
                        var vueObj = this;

                        vueObj.editMessage.attachments_ids_to_remove.push(attachment.id);

                        vueObj.editMessage.previous_attachments.splice(index, 1);
                    },

                    readURL: function(file, index) {
                        if(file){
                            var reader = new FileReader();

                            reader.onload = function(e) {
                                //return e.target.result; 
                                $("#img_a_" + index).attr('src', e.target.result);
                            }

                            reader.readAsDataURL(file);
                        }
                    },

                    getFileExt: function(file){
                        if(file.type){
                            return file.type.split('/')[1];
                        }

                        return 'file';
                    },

                    getAttachmentExtFromName: function(name){
                        var ext = "";

                        var arraySplit = name.split('.');

                        ext = arraySplit[arraySplit.length - 1];

                        return ext;
                    },

                    updateMessage : function(message, index){
                        var vueObj = this;

                        if((index == 0 && message.id == vueObj.editMessage.thread_id) || (index != 0 && message.id == vueObj.editMessage.id)){
                            vueObj.showDivLoadingMessages = 'yes';

                            var url = vueObj.baseUrl + "/message_board_thread_message/"+message.id+"/json";
                            
                            var formData = new FormData();
                            
                            formData.append('_token', vueObj.csrfToken);
                            formData.append('_method', 'PATCH');
                            formData.append('body', vueObj.editMessage.body);
                            formData.append('list_attachments[]', null);
                            formData.append('list_attachments_ids_to_remove[]', vueObj.editMessage.attachments_ids_to_remove);

                            if(vueObj.editMessage.is_thread == 'no'){
                                formData.append('is_thread_message', "no");
                            }else{
                                formData.append('is_thread_message', "yes");
                                formData.append('thread_id', vueObj.editMessage.thread_id);
                            }


                            if(vueObj.editMessage.attachments && vueObj.editMessage.attachments.length > 0){
                                for(var i=0; i < vueObj.editMessage.attachments.length; i++){
                                    formData.append('list_attachments[]', vueObj.editMessage.attachments[i]);
                                }
                            }

                            vueObj.$http({ 
                                url: url, 
                                method: 'POST', 
                                responseType: 'json', 
                                data: formData,
                                contentType: 'multipart/form-data'
                            }).then(function(response){
                                if(response.data.status == 'success'){
                                    $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                    vueObj.hideFormForMessage();
                                    vueObj.getData();
                                }else{
                                    $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                    vueObj.showDivLoadingMessages = 'no';
                                }                            
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    manageClickBtnReply: function(message){
                        $(document).trigger("messageBoardNewReplyToMessage", [message]);
                    },

                    showMessageReplyQuote: function(message){
                        return message.reply_to_message || message.reply_to_thread;
                    },

                    messageReplyQuote: function(message){
                        if(message.reply_to_message){
                            return message.reply_to_message;
                        }
            
                        if(message.reply_to_thread){
                            return message.reply_to_thread;
                        }
            
                        return null;
                    },

                    getClassQuoteForDiv: function(message){
                        if(message.author){
                            return 'divGoToMessageQuote';
                        }
            
                        if(message.creator){
                            return 'divGoToThreadQuote';
                        }
            
                        return '';
                    }
                }

            });
        },

        initVueFormNewSection: function(){
            var manager = this;

            manager._vueObjFormNewSection = new Vue({
                //element to mount to
                el: manager._containerFormNewSectionId,

                //initial data
                data: {
                    mb_section: { title: null, description: null },
                    showForm: 'no',
                    waitingForFormToSend: 'no',
                    containerFormDivId: manager._containerFormNewSectionId
                },

                //methods
                methods: {
                    getContenteditableDescription(evt) {
                        var vueObj = this;

                        vueObj.mb_section.description = evt.target.innerHTML;
                    },

                    sendForm: function(){
                        var vueObj = this;

                        vueObj.waitingForFormToSend = 'yes';

                        var form = $(vueObj.containerFormDivId).find(".formMbSectionNew");
                        var url = form.attr("action");
                        var method = form.attr("method");
                        var formData = new FormData(form[0]);

                        formData.append('description', vueObj.mb_section.description);

                        vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                            vueObj.manageRequestResponse(response.data);
                        }, function(response){
                            vueObj.manageRequestResponse({status: "error", message: ''});
                        });                        
                    },

                    manageRequestResponse: function(response){
                        var vueObj = this;

                        if(response.mb_section_id){
                            if(response.event){
                                switch(response.event.type){
                                    case 'training':
                                        var urlToRedirect = '/webinars/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'short_course':
                                        var urlToRedirect = '/advanced_courses/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'residence':
                                        var urlToRedirect = '/residencies/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'workshop':
                                        var urlToRedirect = '/workshops/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'in_residence':
                                        var urlToRedirect = '/in_residences/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'learning_cafe':
                                        var urlToRedirect = '/learning_cafes/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'master_course':
                                        var urlToRedirect = '/master_courses/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'master_course_edition':
                                        var urlToRedirect = '/master_course_editions/' + response.event.id + '?stb=message_board';
                                        break;
                                    case 'conference':
                                        var urlToRedirect = '/conferences/' + response.event.id + '?stb=message_board';
                                        break;
                                    default:
                                        var urlToRedirect = '/';
                                }

                                window.location.href = urlToRedirect;
                            }else if(response.events_series_id){
                                let urlToRedirect = '/training_session_series/' + response.events_series_id + '?stb=message_board';
                                window.location.href = urlToRedirect;
                            }else{
                                //window.location.href = '/message_board/section/' + response.mb_section_id;
                                window.location.reload();
                            }
                        }else{
                            window.location.reload();
                        }
                    },

                    btnClickManageShowForm: function(){
                        var vueObj = this;

                        if(vueObj.showForm == 'yes'){
                            vueObj.showForm = 'no';
                        }else{
                            vueObj.showForm = 'yes';
                        }
                    }
                }
            });
        },

        initVueObjListSections: function(){
            var manager = this;

            manager._vueObjListSections = new Vue({
                el: manager._containerListSections,

                data: {
                    baseUrl: null,
                    csrfToken: null,
                    messageBoardId: null,
                    currentUserId: null,
                    eventId: null,
                    eventsSerieId: null,
                    learningClubId: null,
                    event: null,
                    showDivLoadingMbSections: 'no',
                    sections: null,
                    tutors: null,
                    participants: null,
                    showDropdownMoreOptionsForSectionId: 0,
                    showDropdownMoreOptionsForThreadId: 0,
                    editSection: { id: null, title: null, description: null },
                    editThread: { id: null, title: null, visible_for: 'all', visible_for_ids: [], event_id: null },
                    showFormEditForSectionId: 0,
                    showFormEditForThreadId: 0,
                    containerDivId: manager._containerListSections,
                    currentUserCanCreateThread: "no",
                    currentUserCanOrderSections: "no",
                },

                filters: {
                    formatDate: function(date){
                        return moment(date).format('dddd, DD MMMM YYYY');
                    },
                    formatDateWithTime: function(date){
                        return moment(date).format('dddd, DD MMMM YYYY HH:mm');
                    }
                },

                ready: function(){
                    var vueObj = this;

                    vueObj.init();
                },

                methods: {
                    getContenteditableDescription(evt) {
                        var vueObj = this;

                        vueObj.editSection.description = evt.target.innerHTML;
                    },

                    init: function(){
                        var vueObj = this;

                        var containerVue = $(vueObj.containerDivId);

                        if(containerVue){
                            vueObj.baseUrl = containerVue.data('url');
                            vueObj.csrfToken = containerVue.data('csrf');

                            if(vueObj.$els.messageBoardId){
                                vueObj.messageBoardId = $(vueObj.$els.messageBoardId).data('id');
                            }

                            if(vueObj.$els.currentUserId){
                                vueObj.currentUserId = $(vueObj.$els.currentUserId).data("id");
                            }

                            if(vueObj.$els.eventId){
                                vueObj.eventId = $(vueObj.$els.eventId).data("id");
                                vueObj.getEventParticipantsAndTutors();
                                vueObj.getEvent();
                            }

                            if(vueObj.$els.eventsSerieId){
                                vueObj.eventsSerieId = $(vueObj.$els.eventsSerieId).data("id");
                            }

                            if(vueObj.$els.learningClubId){
                                vueObj.learningClubId = $(vueObj.$els.learningClubId).data("id");
                            }

                            vueObj.getSections();

                            vueObj.setCurrentUserCanCreateThreads();
                            vueObj.setCurrentUserCanOrderSections();
                        }
                    },

                    getSections: function(){
                        var vueObj = this;

                        vueObj.showDivLoadingMbSections = 'yes';

                        var url = vueObj.baseUrl + "/message_board/"+ vueObj.messageBoardId +"/json/sections";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.sections = response.data.sections;

                            //console.log(vueObj.sections);

                            vueObj.showDivLoadingMbSections = 'no';
                            
                        }, function(response){
                            //error callback
                        });
                    },

                    getEvent: function(){
                        var vueObj = this;

                        var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/detail";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.event = response.data.event;    
                            vueObj.setCurrentUserCanCreateThreads();                        
                            vueObj.setCurrentUserCanOrderSections();                        
                        }, function(response){
                            //error callback
                        });
                    },

                    getEventParticipantsAndTutors: function(){
                        var vueObj = this;

                        vueObj.getEventParticipants();
                        vueObj.getEventTutors();
                    },

                    getEventParticipants: function(){
                        var vueObj = this;

                        var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/participants";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.participants = response.data.participants;                            
                        }, function(response){
                            //error callback
                        });
                    },

                    getEventTutors: function(){
                        var vueObj = this;

                        var url = vueObj.baseUrl + "/events/"+vueObj.eventId+"/json/tutors";

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json'
                        }).then(function(response){
                            vueObj.tutors = response.data.tutors;                            
                        }, function(response){
                            //error callback
                        });
                    },

                    getUrlToSection: function(id){
                        var vueObj = this;
 
                        var url = vueObj.baseUrl + "/message_board/section/redirect_to_page?idSection="+id;

                        if(vueObj.eventId){
                            url = url + '&id=' + vueObj.eventId;
                        }

                        return url;
                    },

                    getUrlToThread: function(id){
                        var vueObj = this;
 
                        var url = vueObj.baseUrl + "/message_board/thread/redirect_to_page?idThread="+id;

                        if(vueObj.eventId){
                            url = url + '&id=' + vueObj.eventId;
                        }

                        return url;
                    },

                    getUrlToCreator: function(userId){
                        var vueObj = this;

                        var url = vueObj.baseUrl + '/users/' + userId;

                        return url;
                    },

                    getStorageImagePath: function(imagePath){
                        return "/storage/"+imagePath;
                    },

                    manageClickMoreOptions: function(id){
                        var vueObj = this;

                        if(vueObj.showDropdownMoreOptionsForSectionId == id){
                            vueObj.hideDropdownMoreOptions();
                        }else{
                            vueObj.showDropdownMoreOptions(id);
                        }
                    },

                    showDropdownMoreOptions: function(sectionId){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForSectionId = sectionId;
                    },

                    hideDropdownMoreOptions: function(){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForSectionId = 0;
                    },

                    manageClickEditSection: function(section){
                        var vueObj = this;

                        if(vueObj.showFormEditForSectionId == section.id){
                            vueObj.hideFormForSection();
                        }else{
                            vueObj.showFormForSection(section);
                        }
                    },

                    showFormForSection: function(section){
                        var vueObj = this;

                        vueObj.hideDropdownMoreOptions();

                        vueObj.editSection.id = section.id;
                        vueObj.editSection.title = section.title;
                        vueObj.editSection.description = section.description;

                        vueObj.showFormEditForSectionId = section.id;
                    },

                    hideFormForSection: function(){
                        var vueObj = this;

                        vueObj.showFormEditForSectionId = 0;
                    },

                    deleteSection: function(id){
                        var vueObj = this;

                        vueObj.showDivLoadingMbSections = 'yes';
                        vueObj.hideDropdownMoreOptions();

                        var url = vueObj.baseUrl + "/message_board_section/"+id+"/json";

                        vueObj.$http({
                            url: url,
                            method: 'DELETE',
                            responseType: 'json',
                            data:{ _token: vueObj.csrfToken }
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger('flashNotyAlert', ["success", response.data.message]);

                                vueObj.getSections();
                            }else{
                                $(document).trigger('flashNotyAlert', ["error", response.data.message]);

                                vueObj.showDivLoadingMbSections = 'no';
                            }
                        }, function(response){
                            //error callback
                        });
                    },

                    updateSection: function(sectionId){
                        var vueObj = this;

                        if(sectionId == vueObj.showFormEditForSectionId){
                            vueObj.showDivLoadingMbSections = 'yes';

                            var url = vueObj.baseUrl + "/message_board_section/"+sectionId+"/json";

                            vueObj.$http({ 
                                url: url, 
                                method: 'PATCH', 
                                responseType: 'json', 
                                data:{ _token: vueObj.csrfToken, section: vueObj.editSection } 
                            }).then(function(response){
                                if(response.data.status == 'success'){
                                    $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                    vueObj.hideFormForSection();
                                    vueObj.getSections();
                                }else{
                                    $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                    vueObj.showDivLoadingMbSections = 'no';
                                }                            
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    getUrlToPageNewThread: function(sectionId){
                        var vueObj = this;
 
                        var url = vueObj.baseUrl + "/message_board/thread/new/redirect_to_page?idSection="+sectionId;

                        return url;
                    },

                    manageClickMoreOptionsThread: function(threadId){
                        var vueObj = this;

                        if(vueObj.showDropdownMoreOptionsForThreadId != threadId){
                            vueObj.showDropdownMoreOptionsThread(threadId);
                        }else{
                            vueObj.hideDropdownMoreOptionsThread();
                        }
                    },

                    showDropdownMoreOptionsThread: function(threadId){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForThreadId = threadId;
                    },

                    hideDropdownMoreOptionsThread: function(){
                        var vueObj = this;

                        vueObj.showDropdownMoreOptionsForThreadId = 0;
                    },

                    deleteThread: function(threadId){
                        var vueObj = this;

                        vueObj.showDivLoadingMbSections = 'yes';
                        vueObj.hideDropdownMoreOptionsThread();

                        var url = vueObj.baseUrl + "/message_board_thread/"+threadId+"/json";

                        vueObj.$http({ 
                            url: url, 
                            method: 'DELETE', 
                            responseType: 'json', 
                            data:{ _token: vueObj.csrfToken } 
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                vueObj.getSections();
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                vueObj.showDivLoadingMbSections = 'no';
                            }                            
                        }, function(response){
                            //error callback
                        });
                    },

                    manageClickEditThread: function(thread){
                        var vueObj = this;

                        if(vueObj.showFormEditForThreadId != thread.id){
                            vueObj.showFormForThread(thread);
                        }else{
                            vueObj.hideFormForThread();
                        }
                    },

                    showFormForThread: function(thread){
                        var vueObj = this;

                        vueObj.hideDropdownMoreOptionsThread();

                        vueObj.editThread.id = thread.id;

                        vueObj.editThread.title = thread.title;

                        if(thread.visible_for == 'all'){
                            vueObj.editThread.visible_for = 'all';

                            vueObj.editThread.visible_for_ids = [];
                        }else{
                            vueObj.editThread.visible_for = 'select_users';

                            vueObj.editThread.visible_for_ids = thread.visible_for.split(",");
                        }

                        vueObj.showFormEditForThreadId = thread.id;

                        setTimeout(function(){ $(vueObj.containerDivId).find(".inputTitle").focus(); }, 0);
                    },

                    hideFormForThread: function(){
                        var vueObj = this;

                        vueObj.showFormEditForThreadId = 0;
                    },

                    currentUserIsTutor: function(){
                        var vueObj = this;

                        var isTutor = false;

                        if(vueObj.currentUserId && vueObj.tutors && vueObj.tutors.length > 0){
                            $.each(vueObj.tutors, function(index, tutor){
                                if(tutor.id == vueObj.currentUserId){
                                    isTutor = true;
                                    return false;
                                }
                            })
                        }

                        return isTutor;
                    },

                    currentUserIsParticipant: function(){
                        var vueObj = this;

                        var isParticipant = false;

                        if(vueObj.currentUserId && vueObj.participants && vueObj.participants.length > 0){
                            $.each(vueObj.participants, function(index, participant){
                                if(participant.user_id == vueObj.currentUserId){
                                    isParticipant = true;
                                    return false;
                                }
                            })
                        }

                        return isParticipant;
                    },

                    updateThread: function(threadId){
                        var vueObj = this;

                        if(threadId == vueObj.showFormEditForThreadId){
                            vueObj.showDivLoadingThreads = 'yes';

                            var url = vueObj.baseUrl + "/message_board_thread/"+threadId+"/json";

                            if(vueObj.eventId){
                                vueObj.editThread.event_id = vueObj.eventId;
                            }

                            vueObj.$http({ 
                                url: url, 
                                method: 'PATCH', 
                                responseType: 'json', 
                                data:{ _token: vueObj.csrfToken, thread: vueObj.editThread } 
                            }).then(function(response){
                                if(response.data.status == 'success'){
                                    $(document).trigger("flashNotyAlert", ["success", response.data.message]);

                                    vueObj.hideFormForThread();
                                    vueObj.getSections();
                                }else{
                                    $(document).trigger("flashNotyAlert", ["error", response.data.message]);

                                    vueObj.showDivLoadingThreads = 'no';
                                }                            
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    threadIsVisibleToUser: function(thread){
                        var vueObj = this;

                        var threadIsVisible = "no";

                        if(thread.visible_for == 'all'){
                            if(vueObj.event){
                                if(vueObj.event.type === 'training' || vueObj.event.type == 'learning_cafe'){
                                    threadIsVisible = "yes";
                                }else if(vueObj.event.owner_id && vueObj.event.owner_id == vueObj.currentUserId){
                                    threadIsVisible = "yes";
                                }else{
                                    if(vueObj.currentUserIsTutor() || vueObj.currentUserIsParticipant()){
                                        threadIsVisible = "yes";
                                    }
                                }
                            }else{
                                threadIsVisible = 'yes';
                            }
                        }else{
                            var usersIdsThatCanSeeThread = thread.visible_for.split(",");

                            $.each(usersIdsThatCanSeeThread, function(index, userId){
                                if(vueObj.currentUserId && vueObj.currentUserId == userId){
                                    threadIsVisible = "yes";
                                }
                            })
                        }

                        return threadIsVisible;
                    },

                    setCurrentUserCanCreateThreads: function(){
                        var vueObj = this;

                        if(vueObj.currentUserId){
                            if(vueObj.event && (vueObj.event.type == 'training' || vueObj.event.type == 'learning_cafe')){
                                vueObj.currentUserCanCreateThread = "yes";
                            }else if(vueObj.event && vueObj.event.owner_id && vueObj.event.owner_id == vueObj.currentUserId){
                                vueObj.currentUserCanCreateThread = "yes";
                            }else{
                                if(vueObj.eventId){
                                    if(vueObj.currentUserIsTutor() || vueObj.currentUserIsParticipant()){
                                        vueObj.currentUserCanCreateThread = "yes";
                                    }else{
                                        vueObj.currentUserCanCreateThread = "no";
                                    }
                                }else{
                                    vueObj.currentUserCanCreateThread = "yes";
                                }
                            }
                        }else{
                            vueObj.currentUserCanCreateThread = "no";
                        }
                    },

                    setCurrentUserCanOrderSections: function(){
                        var vueObj = this;

                        if(vueObj.currentUserId){
                            if(vueObj.event && vueObj.event.owner_id && vueObj.event.owner_id == vueObj.currentUserId){
                                vueObj.currentUserCanOrderSections = "yes";
                            }else{
                                if(vueObj.eventId){
                                    if(vueObj.currentUserIsTutor()){
                                        vueObj.currentUserCanOrderSections = "yes";
                                    }else{
                                        vueObj.currentUserCanOrderSections = "no";
                                    }
                                }else{
                                    vueObj.currentUserCanOrderSections = "no";
                                }
                            }
                        }else{
                            vueObj.currentUserCanCreateThread = "no";
                        }
                    }
                }
            });
        }
    };

    if($(messageBoardManager._containerFormNewThreadId) && $(messageBoardManager._containerFormNewThreadId).length > 0){
        messageBoardManager.initVueObjFormNewThread();
    }

    if($(messageBoardManager._containerFormNewThreadMessageId) && $(messageBoardManager._containerFormNewThreadMessageId).length > 0){
        messageBoardManager.initVueObjFormNewThreadMessage();
    }

    if(messageBoardManager._containerListThreadsId && $(messageBoardManager._containerListThreadsId).length > 0){
        messageBoardManager.initVueObjListThreads();
    }

    if(messageBoardManager._containerListThreadMessagesId && $(messageBoardManager._containerListThreadMessagesId).length > 0){
        messageBoardManager.initVueObjListThreadMessages();
    }

    if(messageBoardManager._containerFormNewSectionId && $(messageBoardManager._containerFormNewSectionId).length > 0){
        messageBoardManager.initVueFormNewSection();
    }

    if(messageBoardManager._containerListSections && $(messageBoardManager._containerListSections).length > 0){
        messageBoardManager.initVueObjListSections();
    }

    $(document).on('click', function(e){
        if(!$(e.target).closest(".divMessageBoardsMoreOptions .divContent ul").length && $(".divMessageBoardsMoreOptions .divContent").hasClass("active") && !$(e.target).closest(".divMessageBoardsMoreOptions .divContent ul li").length && !$(e.target).closest(".divMessageBoardsMoreOptions .divBtn").length){
            if(messageBoardManager._vueObjListThreads){
                messageBoardManager._vueObjListThreads.hideDropdownMoreOptions();
            }

            if(messageBoardManager._vueObjListSections){
                messageBoardManager._vueObjListSections.hideDropdownMoreOptions();
            }
        }
    });

    $(document).on('click', function(e){
        if(!$(e.target).closest(".divMessageBoardsMoreOptions.thread .divContent ul").length && $(".divMessageBoardsMoreOptions.thread .divContent").hasClass("active") && !$(e.target).closest(".divMessageBoardsMoreOptions.thread .divContent ul li").length && !$(e.target).closest(".divMessageBoardsMoreOptions.thread .divBtn").length){
            if(messageBoardManager._vueObjListSections){
                messageBoardManager._vueObjListSections.hideDropdownMoreOptionsThread();
            }
        }
    });

    $(document).on("messageBoardNewReplyToMessage", {
        foo: "bar"
    }, function(event, message) {
        if(message){
            messageBoardManager._vueObjFormNewThreadMessage.setMessageToReply(message);
        }
    });
});
