var showEventManager = {
    _containerVueId: "#containerEventShowVue",
    _vueObj: null,

    initVueObjShow: function(){
        const manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVueId,
            data: {
                eventId: null,
                eventType: null,
                tokenCsrf: null,
                currentUserId: 0,
                currentUser: null,
                dataCrowdfunding: { crowdfunding: null, participant: null, listParticipants: [] },
                tableFormatsVisible: 'formats',
                isLoadingSessions: 'yes',
                sessions: null,
                reloadResourcesStorageSize: 'no',
                reloadResources: 'no',
                currentUserEventsIdsJoined: [],
                currentUserClubsIdsJoined: [],
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    vueObj.eventId = $(vueObj.$els.containerVue).data('event-id');
                    vueObj.eventType = $(vueObj.$els.containerVue).data('event-type');
                    vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                    vueObj.currentUserId = $(vueObj.$els.containerVue).data('current-user-id');

                    vueObj.getCurrentUserInfo();
                    
                    if(vueObj.shouldInitCrowdfunding){
                        vueObj.getCrowdfunding();
                    }

                    if(vueObj.shouldInitSessions){
                        vueObj.getSessions();
                    }
                }

                manager.initOrRemoveCarouselTableFormats();
            },

            computed: {
                isWorkshop(){
                    return this.eventType && this.eventType == 'workshop';
                },

                isConference(){
                    return this.eventType && this.eventType == 'conference';
                },

                shouldInitSessions: function(){
                    return this.isConference;
                },

                shouldInitCrowdfunding: function(){
                    return this.isWorkshop;
                },

                showRegisterCrowdfunding(){
                    return this.dataCrowdfunding.crowdfunding && this.dataCrowdfunding.crowdfunding != null;
                },

                crowdfundingFormIsUpdate(){
                    return this.showRegisterCrowdfunding && this.dataCrowdfunding.participant != null;
                },

                showListParticipants(){
                    return this.dataCrowdfunding.listParticipants && this.dataCrowdfunding.listParticipants.length > 0;
                },

                userIsLogged(){
                    return this.currentUser != null;
                },

                showBtnRegister(){
                    return this.showRegisterCrowdfunding && !this.crowdfundingFormIsUpdate;
                },

                showBtnUpdateContribution(){
                    return this.showRegisterCrowdfunding && this.crowdfundingFormIsUpdate;
                },

                showTabContentRegisterCrowdfunding(){
                    return this.showRegisterCrowdfunding && this.userIsLogged;
                },

                tableIsVisibleFormats: function(){
                    return this.tableFormatsVisible && this.tableFormatsVisible == 'formats';
                },

                tableIsVisibleMonthlySupportPackage: function(){
                    return this.tableFormatsVisible && this.tableFormatsVisible == 'monthly_support_package';
                },

                tableIsVisibleOnDemand: function(){
                    return this.tableFormatsVisible && this.tableFormatsVisible == 'on_demand';
                },

                buttonIsVisibleBack: function(){
                    return this.tableIsVisibleMonthlySupportPackage || this.tableIsVisibleOnDemand;
                },

                textIsVisibleBuyBrainCoins: function(){
                    return this.tableIsVisibleOnDemand;
                },

                showLoadingSessions: function(){
                    return this.isLoadingSessions && this.isLoadingSessions == 'yes';
                },

                showListSessions: function(){
                    return !this.showLoadingSessions && this.sessions && this.sessions.length > 0;
                },

                showNoSessionsFound: function(){
                    return !this.showLoadingSessions && (!this.sessions || this.sessions.length <= 0);
                }
            },

            events: {
                'files_finish_upload': function(msg){
                    const vueObj = this;
                    vueObj.reloadResources = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResources = "no";
                    });
                },
                
                'resource_deleted': function(msg){
                    const vueObj = this;
                    vueObj.reloadResourcesStorageSize = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResourcesStorageSize = "no";
                    });
                }
            },

            methods: {
                getCrowdfunding: function(){
                    const vueObj = this;

                    const url = "/json/events/"+ vueObj.eventId +"/crowdfunding_for_join";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.dataCrowdfunding.crowdfunding = response.data.crowdfunding;

                        if(response.data.participant){
                            vueObj.dataCrowdfunding.participant = response.data.participant;
                        }

                        if(response.data.list_participants){
                            vueObj.dataCrowdfunding.listParticipants = response.data.list_participants;
                        }

                        if(response.data.current_user){
                            vueObj.currentUser = response.data.current_user;
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                goToAndOpenTabRegister: function(){
                    var vueObj = this;
        
                    if(vueObj.$els.tabLinkRegister){
                        var linkTabRegister = $(vueObj.$els.tabLinkRegister);
            
                        if(linkTabRegister.hasClass("linkAccesssMasterCourseEdition")){
                            //manager.goToCourseEdition(linkTabRegister);
                        }else{
                            if(linkTabRegister && linkTabRegister.length > 0){
                                linkTabRegister.trigger("click");

                                vueObj.$nextTick(() => {
                                    vueObj.$els.tabLinkRegister.scrollIntoView();
                                });
                
                                /*$('html, body').animate({
                                    scrollTop: linkTabRegister.offset().top - 100
                                }, 1500);*/
                            }
                        }
                    }
        
                },

                goToAndOpenTabEditions: function(){
                    var vueObj = this;

                    const linkTabEditions = $(vueObj.$els.tabLinkEditions);
        
                    if(linkTabEditions && linkTabEditions.length > 0){
                        linkTabEditions.trigger("click");

                        vueObj.$nextTick(() => {
                            vueObj.$els.tabLinkEditions.scrollIntoView();
                        });
                    }
                },

                showTable: function(type){
                    const vueObj = this;

                    vueObj.setTableType(type);

                    vueObj.$nextTick(() => {
                        manager.updateCarouselTableFormats();
                        vueObj.slideToTopContainerTableFormats();
                    });
                },

                setTableType: function(type){
                    const vueObj = this;

                    vueObj.tableFormatsVisible = type;
                },

                slideToTopContainerTableFormats: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerTableFormats){
                        vueObj.$els.containerTableFormats.scrollIntoView();
                    }
                },

                getSessions: function(){
                    const vueObj = this;

                    vueObj.isLoadingSessions = 'yes';

                    const url = "/json/events/"+ vueObj.eventId +"/collaborative_working_sessions/next_and_past";

                    const params = { with_participants: 'yes' };
        
                    vueObj.$http({ url: url, params: params, method: 'GET' }).then(function(response){                        
                        if((response.data.next && response.data.next.length > 0) && (response.data.past && response.data.past.length > 0)){
                            let tempArray = response.data.next;
                            tempArray.push(...response.data.past);

                            vueObj.sessions = tempArray;
                        }else if(response.data.next && response.data.next.length > 0){
                            vueObj.sessions = response.data.next;
                        }else if(response.data.past && response.data.past.length > 0){
                            vueObj.sessions = response.data.past;
                        }


                        vueObj.isLoadingSessions = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    },

    onWindowResize: function(){
        const manager = this;

        manager.initOrRemoveCarouselTableFormats();
    },

    updateCarouselTableFormats: function(){
        const manager = this;

        const divsTablePrices = $(manager._classTablePrices);

        if(divsTablePrices && divsTablePrices.length > 0){
            $.each(divsTablePrices, function(index, div){
                const divTable = $(div);

                if(divTable.hasClass("owl-carousel")){
                    divTable.trigger('refresh.owl.carousel');
                }else{
                    manager.initOrRemoveCarouselTableFormats();
                }
            });
        }
    },
  
    initOrRemoveCarouselTableFormats: function(){
        const manager = this;

        const divsTablePrices = $(manager._classTablePrices);

        if(divsTablePrices && divsTablePrices.length > 0){
            $.each(divsTablePrices, function(index, div){
                const divTable = $(div);
                const widthToAddCarousel = divTable.data("carousel");

                if(widthToAddCarousel && $(window).width() <= widthToAddCarousel){
                    if(!divTable.hasClass("owl-carousel")){
                        divTable.addClass("owl-carousel");

                        divTable.owlCarousel({
                            items:1,
                            loop:true,
                            margin:0,
                            autoplay: false,
                            dots: true,
                            nav: true,
                            navText: ['<img src="/images/icon_select_arrow.svg" alt="img">','<img src="/images/icon_select_arrow.svg" alt="img">'],
                            mouseDrag: true,
                            autoHeight: true
                        });
                    }
                }else{
                    manager.removeCarouselTableFormats(divTable);
                }
            });
        }
    },
  
    removeCarouselTableFormats: function(divCarousel){
        if(divCarousel && divCarousel.hasClass('owl-carousel')){
            divCarousel.trigger('destroy.owl.carousel');
            divCarousel.removeClass('owl-carousel');
        }
    }
};

$(window).resize(function(){
    showEventManager.onWindowResize();
})

$(function(){
    if($(showEventManager._containerVueId) && $(showEventManager._containerVueId).length > 0){
        showEventManager.initVueObjShow();
    }
});
