var callForCourseAuthorsManager = {
    _containerFormId: "#containerFormCallCourseAuthorsId",
    _vueForm: null,

    initVueForm: function(){
        var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                
                // initial data
                data: {
                    fields: { email: null, cover_letter: null },
                    files: { syllabus: null, cv: null },
                    formIsBeingSent: 'no',
                },

                ready: function(){
                },

                computed: {
                    sendingForm: function(){
                        return this.formIsBeingSent && this.formIsBeingSent == 'yes';
                    }
                },

                // methods
                methods: {
                    manageFileUploads: function(type){
                        const vueObj = this;

                        let elem = null;

                        switch(type){
                            case 'syllabus':
                                elem = vueObj.$els.syllabus;
                                break;
                            case 'cv':
                                elem = vueObj.$els.cv;
                                break;
                        }

                        if(elem){
                            const files = $(elem)[0].files;

                            if(files.length > 0){
                                switch(type){
                                    case 'syllabus':
                                        vueObj.files.syllabus = files[0];
                                        break;
                                    case 'cv':
                                        vueObj.files.cv = files[0];
                                        break;
                                }
                            }
                        }
                    },

                    sendForm: function(){
                        const vueObj = this;

                        const formElem = $(vueObj.$els.containerVue).find("form");

                        const url = formElem.attr("action");
                        const method = formElem.attr("method");
                        const formData = new FormData(formElem[0]);

                        if(formData.get('g-recaptcha-response')){
                            vueObj.formIsBeingSent = "yes";

                            vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                if(response.data.status == 'success'){
                                    $(document).trigger("flashNotyAlert", ["success", response.data.message]);
                                    vueObj.cleanForm();
                                }else{
                                    $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                }

                                vueObj.formIsBeingSent = 'no';

                            }, function(response){
                                $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            });
                        }else{
                            //ReCaptcha needed!
                        }
                    },

                    cleanForm: function(){
                        const vueObj = this;

                        vueObj.fields.email = null;
                        vueObj.fields.cover_letter = null;
                        vueObj.files.syllabus = null;
                        vueObj.files.cv = null;
                    }
                }
            });
    }
};

$(function(){
    if($(callForCourseAuthorsManager._containerFormId) && $(callForCourseAuthorsManager._containerFormId).length > 0){
        callForCourseAuthorsManager.initVueForm();
    }
});
