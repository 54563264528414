var managerUserManageMemberships = {
    _containerVue: "#containerPageUsersManageMembershipsId",
    _vueObj: null,

    initVue: function(){
        const manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,
            data: {
                isLoading: 'yes',
                activeType: 'active',
                membershipsActive: null,
                membershipsExpired: null
            },

            ready: function(){
                this.getCurrentUserMemberships(this.activeType);                
            },

            events: {
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                tabMembershipsActiveIsSelected: function(){
                    return this.activeType && this.activeType == 'active';
                },

                tabMembershipsExpiredIsSelected: function(){
                    return this.activeType && this.activeType == 'expired';
                },

                showContentTabMembershipsActive: function(){
                    return !this.showLoading && this.tabMembershipsActiveIsSelected;
                },

                showContentTabMembershipsExpired: function(){
                    return !this.showLoading && this.tabMembershipsExpiredIsSelected;
                },

                hasMembershipsActive: function(){
                    return this.membershipsActive && this.membershipsActive.length > 0;
                },

                hasMembershipsExpired: function(){
                    return this.membershipsExpired && this.membershipsExpired.length > 0;
                }
            },

            methods: {
                getCurrentUserMemberships: function(type){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    vueObj.$http({ url: '/json/users/current_user_memberships', method: 'GET', data: { membership_status: type }}).then(function(response){
                        vueObj.isLoading = 'no';
              
                        switch(vueObj.activeType){
                            case 'expired':
                                vueObj.membershipsExpired = response.data.memberships;
                                break;

                            default:
                                vueObj.membershipsActive = response.data.memberships;
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                changeSelectedTabMembershipType: function(type){
                    const vueObj = this;

                    if(vueObj.activeType != type){
                        vueObj.activeType = type;
                        vueObj.getCurrentUserMemberships(vueObj.activeType);
                    }
                }
            }
        });
    }
};

$(function(){
    if($(managerUserManageMemberships._containerVue) && $(managerUserManageMemberships._containerVue).length > 0){
        managerUserManageMemberships.initVue();
    }
});
