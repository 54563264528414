Vue.component('event-participant-list-tasks-with-submit', {
    template: `
        <div class="containerEventParticipantListTasksWithSubmit">
            <div class="containerLoading" v-show="showLoadingTasks">
                <img src="/images/loading/pulse.svg">
            </div>

            <ul class="listEventParticipantTasksWithSubmit" v-if="showListTasks">
                <li v-for="(index, task) in tasks">
                    <event-participant-task-detail :participant-task="task"
                                                   :task="task.task"
                                                   :task-nr="index + 1"
                                                   :token-csrf="tokenCsrf">
                    </event-participant-task-detail>
                </li>
            </ul>

            <div class="clearfix"></div>
        </div>
        `,
    props: {
        participantId: {
            type: Number,
            required: true
        },

        tokenCsrf: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoadingTasks: 'no',
            tasks: null,
        };
    },

    ready: function(){
        this.getParticipantTasks();
    },

    computed: {
        showLoadingTasks: function(){
            return this.isLoadingTasks && this.isLoadingTasks == 'yes';
        },

        hasTasks: function(){
            return this.tasks && this.tasks.length > 0;
        },

        showListTasks: function(){
            return !this.showLoadingTasks && this.hasTasks;
        }
    },

    methods: {
        getParticipantTasks: function(){
            const component = this;

            component.isLoadingTasks = 'yes';

            component.$http({ 
                url: "/json/event_participants/"+ component.participantId +"/tasks", 
                method: 'GET', 
                responseType: 'json',
            }).then(function(response){
                component.tasks = response.data.tasks;

                component.isLoadingTasks = 'no';
            }, function(response){
                //error callback
            });
        }
    }
});

Vue.component('event-participant-task-submit-form', {
    template: `
        <div class="containerEventParticipantTaskSubmit form">

            <div class="containerLoading" v-show="showLoading">
                <img src="/images/loading/pulse.svg">
            </div>

            <div v-if="showForm">
                <div class="containerFormV5SectionNumber v1 text">
                    <div class="divTitle"><span class="text">Your comment</span></div>

                    <div class="divLabelAndInput">
                        <div class="divFormInput">
                            <custom-field-text :input-value="formData.text"
                                            input-name="user_comment_{{ participantTaskId }}"
                                            input-id="user_comment_{{ participantTaskId }}"
                                            event-name="updateUserComment"
                                            form-type="new">
                            </custom-field-text>
                        </div>
                    </div>
                </div>

                <div class="divUploadFile">
                    <input type="file" name="file_{{ participantTaskId }}" accept=".doc, .docx, .ppt, .pptx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*" 
                        v-on:change="manageUploadSubmissionFile()"
                        v-el:input-file-submitted>
                    <div class="buttonV7 fill c54">
                        Select file
                        <img class="icon" src="/images/c_white/upload.png" >
                    </div>
                </div>

                <div class="divUploadedFileName" v-if="formData.file">{{ formData.file.name }}</div>

                <div class="containerFormV5SectionNumber" v-if="showErrorFile">
                    <div class="divTextError">You have to upload a file to submit.</div>
                </div>

                <div class="divBtnSubmit">
                    <div class="buttonV7 btn" @click="submitForm()">Submit assigment</div>
                </div>
            </div>

        </div>
        `,
    props: {
        participantTaskId: {
            type: Number,
            required: true
        },

        tokenCsrf: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: 'no',
            formData: { text: null, file: null },
            errors: { file: 'no' },
        };
    },

    ready: function(){
    },

    events: {
        'updateUserComment': function(msg){
            this.formData.text = msg;
        }
    },

    computed: {
        showLoading: function(){
            return this.isLoading && this.isLoading == 'yes';
        },

        showForm: function(){
            return !this.showLoading;
        },

        showErrorFile: function(){
            return !this.errors.file || this.errors.file == 'yes';
        }
    },

    methods: {
        manageUploadSubmissionFile: function(){
            const component = this;

            const elem = component.$els.inputFileSubmitted;

            if(elem){
                const fileList = $(elem)[0].files;

                if(fileList && fileList.length > 0){
                    component.errors.file = 'no';
                    component.formData.file = fileList[0];
                }
            }
        },

        submitForm: function(){
            const component = this;

            component.isLoading = 'yes';

            let canSendForm = 'yes';

            if(!component.formData.file){
                canSendForm = 'no';
                component.errors.file = 'yes';
            }

            if(canSendForm == 'yes'){
                let params = new FormData;

                if(component.formData.text && component.formData.text.length > 0){
                    params.append('text_user', component.formData.text);
                }
                
                params.append('file', component.formData.file);

                component.$http({ 
                    url: "/json/event_participant_tasks/"+ component.participantTaskId +"/submit_task", 
                    method: 'POST',  
                    contentType: 'multipart/form-data', 
                    responseType: 'json', 
                    data: params,
                    headers: { 'X-CSRF-TOKEN': component.tokenCsrf }
                }).then(function(response){
                    if(response.data.status == "success"){
                        $(document).trigger("flashNotyAlert", ["success", "Task submitted successfully!"]);
                        component.$dispatch('updateToParticipantTask', response.data.event_participant_task);
                    }else{
                        component.isLoading = 'no';
                        $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                    }
                }, function(response){
                    //ERROR
                });
            }else{
                component.isLoading = 'no';
            }
        }
    }
});

Vue.component('event-participant-task-submit-details', {
    template: `
        <div class="containerEventParticipantTaskSubmit details">
            <div class="divSubmittedFile" v-if="hasSubmissionFile">
                <p class="title">Your submitted file</p>
                <a :href="participantTask.file_path | urlFromStorage" class="linkS8" target="_blank">
                    <img src="/images/icon_uploaded_file.png" class="icon" alt="file">
                    {{ submissionFileName }}
                </a>
            </div>

            <div class="divCommentText participant" v-if="hasTextParticipant">
                <p class="title">Your comment</p>
                <p>{{ participantTask.text_participant }}</p>
            </div>

            <div class="divCommentText participant" v-if="hasTextFeedback">
                <p class="title">Submission feedback</p>
                <p>{{ participantTask.text_feedback }}</p>
            </div>
        </div>
        `,
    props: {
        participantTask: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
        };
    },

    ready: function(){
    },

    computed: {
        hasSubmissionFile: function(){
            return this.participantTask.file_path && this.participantTask.file_path.length > 0;
        },

        submissionFileName: function(){
            if(this.hasSubmissionFile){
                return this.participantTask.file_path.split("/").pop();
            }        

            return '';
        },

        hasTextParticipant: function(){
            return this.participantTask.text_participant && this.participantTask.text_participant.length > 0;
        },

        hasTextFeedback: function(){
            return this.participantTask.text_feedback && this.participantTask.text_feedback.length > 0
        }
    },

    methods: {
    }
});

Vue.component('event-participant-task-detail', {
    template: `
        <div class="containerEventParticipantTaskDetail">
            <div class="containerHeaderTaskDetail">
                <p class="taskName">Task {{ taskNr }} - {{ task.title }}</p>

                <p class="date" v-if="showDateLimitSubmission"><span>Limit submission: </span>{{ participantTask.date_submit_limit | fomatedDateV2 }} 23:59</p>
                <p class="date" v-if="showDateOpenSubmission"><span>Submission starts: </span>{{ participantTask.date_submit_start | fomatedDateV2 }} 00:00</p>
                <p class="date" v-if="showDateSubmittedOn"><span>Submitted on: </span>{{ participantTask.date_submitted | fomatedDateV5WithBrowserTimezone }}</p>

                <div class="divLabels">
                    <div class="divLabel success" v-if="showLabelSubmitted">Submitted</div>
                    <div class="divLabel warning" v-if="showLabelWaitingSubmission">Awaiting submission</div>
                    <div class="divLabel success" v-if="showLabelFeedbackReceived">Feedback received</div>
                    <div class="divLabel" v-if="showLabelFutureTask">Future task</div>
                    <div class="divLabel error" v-if="showLabelLateForSubmision">Late for submission</div>
                </div>

                <div class="linkC13HoverUnderline btnShowDetails" 
                    :class="{ 'active' : showTaskContentDetails }" 
                    @click="toogleShowTaskContentDetails()">
                    Task details
                    <img src="/images/c54/arrow.png" />
                </div>
            </div>

            <div class="containerBodyTaskDetail" v-show="showTaskContentDetails">
                <div class="divText" v-if="task.description" v-html="task.description"></div>
                <div class="divText" v-if="task.long_description" v-html="task.long_description"></div>

                <div class="divListAttachments" v-if="taskHasAttachments">
                    <p class="title">Attached files</p>
                    <ul>
                        <li v-for="attachment in task.attachments">
                            <a :href="attachment.file | urlFromStorage" target="_blank">
                                <img src="/images/icon_uploaded_file.png" class="icon" alt="file">
                                {{ attachment.name }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="divSubmissionDetails" v-if="showSubmissionData">
                    <p class="title">My submission</p>

                    <event-participant-task-submit-form :participant-task-id="participantTask.id"
                                                        :token-csrf="tokenCsrf"
                                                        v-if="showSubmissionForm">
                    </event-participant-task-submit-form>

                    <event-participant-task-submit-details :participant-task="participantTask"
                                                            v-if="showSubmissionDetails">
                    </event-participant-task-submit-details>
                </div>
            </div>
        </div>
        `,
    props: {
        participantTask: {
            type: Object,
            required: true
        },

        tokenCsrf: {
            type: String,
            required: true
        },

        task: {
            type: Object,
            default: null
        },

        taskNr: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            taskContentIsOpen: 'no',
        };
    },

    ready: function(){
        const component = this;

        if(component.taskIsNextForSubmission){
            component.taskContentIsOpen = 'yes';
        }
    },

    computed: {
        taskIsFuture: function(){
            const dateTodayJs = new Date();
            const dateTodayStartDayString = dateTodayJs.getFullYear() + '-' + String(dateTodayJs.getMonth() + 1).padStart(2, '0') + '-' + dateTodayJs.getDate() + " 00:00:00";

            const dateMomentToday = moment.utc(dateTodayStartDayString);
            const dateMomentTaskSubmitStart = moment.utc(this.participantTask.date_submit_start);
            
            if(dateMomentTaskSubmitStart.isAfter(dateMomentToday)){
                return true;
            }

            return false;
        },

        taskIsPast: function(){
            const dateTodayJs = new Date();
            const dateTodayStartDayString = dateTodayJs.getFullYear() + '-' + String(dateTodayJs.getMonth() + 1).padStart(2, '0') + '-' + dateTodayJs.getDate() + " 00:00:00";

            const dateMomentToday = moment.utc(dateTodayStartDayString);
            const dateMomentTaskSubmitLimit = moment.utc(this.participantTask.date_submit_limit);

            if(dateMomentTaskSubmitLimit.isBefore(dateMomentToday)){
                return true;
            }

            return false;
        },

        taskNotSubmitted: function(){
            return !this.participantTask.date_submitted || this.participantTask.date_submitted.length <= 0;
        },

        taskSubmitted: function(){
            return !this.taskNotSubmitted;
        },

        taskIsLateForSubmission: function(){
            return this.taskIsPast && this.taskNotSubmitted;
        },

        taskIsWaitingFeedback: function(){
            return !this.participantTask.date_feedback_sent || this.participantTask.date_feedback_sent.length <= 0;
        },

        taskHasFeedback: function(){
            return !this.taskIsWaitingFeedback;
        },

        taskIsNextForSubmission: function(){
            return this.participantTask.is_next_for_submission && parseInt(this.participantTask.is_next_for_submission) != 0;
        },

        showLabelSubmitted: function(){
            return this.taskSubmitted && this.taskIsWaitingFeedback;
        },

        showLabelWaitingSubmission: function(){
            return this.taskIsNextForSubmission && this.taskNotSubmitted && !this.taskIsFuture;
        },

        showLabelFutureTask: function(){
            return !this.taskIsNextForSubmission && this.taskIsFuture;
        },

        showLabelFeedbackReceived: function(){
            return this.taskHasFeedback;
        },

        showLabelLateForSubmision: function(){
            return this.taskIsPast && this.taskNotSubmitted;
        },

        showDateLimitSubmission: function(){
            return (this.showLabelWaitingSubmission || this.showLabelLateForSubmision) && !this.showLabelSubmitted;
        },

        showDateOpenSubmission: function(){
            return this.showLabelFutureTask;
        },

        showDateSubmittedOn: function(){
            return this.showLabelSubmitted || this.showLabelFeedbackReceived;
        },

        showTaskContentDetails: function(){
            return this.taskContentIsOpen && this.taskContentIsOpen == 'yes';
        },

        taskAttachments: function(){
            if(this.task && this.task.attachments && this.task.attachments.length > 0){
                return this.task.attachments;
            }

            return [];
        },

        taskHasAttachments: function(){
            return this.taskAttachments && this.taskAttachments.length > 0;
        },

        showSubmissionForm: function(){
            return this.showLabelWaitingSubmission || this.showLabelLateForSubmision;
        },

        showSubmissionDetails: function(){
            return this.showLabelSubmitted || this.showLabelFeedbackReceived;
        },

        showSubmissionData: function(){
            return this.showSubmissionForm || this.showSubmissionDetails;
        },
    },

    events: {
        'updateToParticipantTask': function(updatedEventParticipantTask){
            this.participantTask = updatedEventParticipantTask;
        }
    },

    methods: {
        toogleShowTaskContentDetails: function(){
            const component = this;

            if(!component.taskContentIsOpen || component.taskContentIsOpen == 'yes'){
                component.taskContentIsOpen = 'no';
            }else{
                component.taskContentIsOpen = 'yes';
            }
        }
    }
});

Vue.component('list-assigned-students-with-tasks', {
    template: `
        <div class="containerListAssignedStudentsWithTasks">
            <event-participant-task-assigned-student :student="student"
                                                     :token-csrf="tokenCsrf"
                                                     v-for="student in students">
            </event-participant-task-assigned-student>
        </div>
        `,
    props: {
        students: {
            type: Array,
            default: function(){
                return []
            }
        },

        tokenCsrf: {
            type: String,
            required: true
        }
    },

    data() {
        return {
        };
    },

    ready: function(){
    },

    computed: {
        hasStudents: function(){
            return this.students && this.students.length > 0;
        }
    },

    methods: {
    }
});

Vue.component('event-participant-task-assigned-student', {
    template: `
        <div class="containerEventParticipantTaskAssignedStudent">
            <div class="divHeader">
                <div class="divUserInfo">
                    <user-photo :user="user"></user-photo>

                    <div class="divInfo">
                        <a :href="user | linkToProfile" class="name">{{ user | userFullName }}</a>
                        <div class="linkC13HoverUnderline btnShowDetails" 
                             :class="{ 'active' : showStudentContentDetails }" 
                             @click="toogleShowStudentContentDetails()">
                            View details
                            <img src="/images/c54/arrow.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="divContent" v-show="showStudentContentDetails">
                <div class="divListTasksParticipant">
                    <p class="title">Submitted tasks</p>

                    <ul v-if="studentHasSubmittedTasks">
                        <li v-for="(index, task) in tasks">
                            <p class="name" v-if="task.task">Task {{ parseInt(task.task.order_nr) + 1 }} - {{ task.task.title }}</p>
                            <p class="submittedOn"><span>Submitted on: </span>{{ task.date_submitted | fomatedDateV5WithBrowserTimezone }}</p>
                            <div class="divFile">
                                <span>Submitted file: </span>
                                <a :href="task.file_path | urlFromStorage" class="linkS8" target="_blank">
                                    <img src="/images/icon_uploaded_file.png" class="icon" alt="file">
                                    {{ taskSubmissionFileName(task) }}
                                </a>
                            </div>

                            <p class="textStudent" v-if="task.text_participant && task.text_participant.length > 0"><span>Student comment: </span>{{ task.text_participant }}</p>

                            <p>mEhHehhhe: {{ task.text_feedback }}</p>

                            <div class="divFeeback" v-if="task.text_feedback && task.text_feedback.length > 0">
                                <p class="titleV1">Feedback</p>
                                <p class="textFeedback">{{ task.text_feedback }}</p>
                            </div>

                            <div class="divFormFeedback" v-else>
                                <p class="formTitle">Send feedback</p>
                                
                                <event-participant-task-submit-feedback-form :participant-task-id="task.id"
                                                                             :participant-task-index="index"
                                                                             :token-csrf="tokenCsrf">
                                </event-participant-task-submit-feedback-form>
                            </div>
                        </li>
                    </ul>

                    <p v-else>No tasks found.</p>
                </div>
            </div>
        </div>
        `,
    props: {
        student: {
            type: Object,
            required: true
        },

        tokenCsrf: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            contentStudentIsOpen: 'no',
            isLoadingTasks: 'no',
            tasks: null,
        };
    },

    ready: function(){
        this.getParticipantTasksSubmitted();
    },

    computed: {
        user: function(){
            if(this.student.user){
                return this.student.user;
            }

            return {};
        },

        showStudentContentDetails: function(){
            return this.contentStudentIsOpen && this.contentStudentIsOpen == 'yes';
        },

        studentHasSubmittedTasks: function(){
            return this.tasks && this.tasks.length > 0;
        }
    },

    events: {
        'updateTask': function(msg){
            if(msg.index){
                this.tasks.$set(msg.index, msg.task);
            }else{
                this.tasks.$set(0, msg.task);
            }
        }
    },

    methods: {
        toogleShowStudentContentDetails: function(){
            const component = this;

            if(component.showStudentContentDetails){
                component.contentStudentIsOpen = 'no';
            }else{
                component.contentStudentIsOpen = 'yes';
            }
        },

        getParticipantTasksSubmitted: function(){
            const component = this;

            component.isLoadingTasks = 'yes';

            component.$http({ 
                url: "/json/event_participants/"+ component.student.id +"/tasks", 
                params: { task_status: 'submitted' },
                method: 'GET', 
                responseType: 'json',
            }).then(function(response){
                component.tasks = response.data.tasks;

                //component.isLoadingTasks = 'no';
            }, function(response){
                //error callback
            });
        },

        taskSubmissionFileName: function(task){
            if(task.file_path && task.file_path.length > 0){
                return task.file_path.split("/").pop();
            }        

            return ''; 
        }
    }
});

Vue.component('event-participant-task-submit-feedback-form', {
    template: `
        <div class="containerEventParticipantTaskFeedbackSubmit containerEventParticipantTaskSubmit form">

            <div class="containerLoading" v-show="showLoading">
                <img src="/images/loading/pulse.svg">
            </div>

            <div v-if="showForm">
                <div class="containerFormV5SectionNumber v1 text">
                    <div class="divLabelAndInput">
                        <div class="divFormInput">
                            <custom-field-text :input-value="formData.text"
                                            input-name="text_feedback_{{ participantTaskId }}"
                                            input-id="text_feedback_{{ participantTaskId }}"
                                            event-name="updateTextFeedback"
                                            form-type="new">
                            </custom-field-text>
                        </div>
                    </div>
                </div>

                <div class="containerFormV5SectionNumber" v-if="showErrorTextFeedback">
                    <div class="divTextError">You have to write the feedback to submit.</div>
                </div>

                <div class="divBtnSubmit">
                    <div class="buttonV7 btn" @click="submitForm()">Submit feedback</div>
                </div>
            </div>

        </div>
        `,
    props: {
        participantTaskId: {
            type: Number,
            required: true
        },

        participantTaskIndex: {
            type: Number,
            default: 0
        },

        tokenCsrf: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: 'no',
            formData: { text: null },
            errors: { text: 'no' },
        };
    },

    ready: function(){
    },

    events: {
        'updateTextFeedback': function(msg){
            this.formData.text = msg;
        }
    },

    computed: {
        showLoading: function(){
            return this.isLoading && this.isLoading == 'yes';
        },

        showForm: function(){
            return !this.showLoading;
        },

        showErrorTextFeedback: function(){
            return !this.errors.text || this.errors.text == 'yes';
        }
    },

    methods: {
        submitForm: function(){
            const component = this;

            component.isLoading = 'yes';

            let canSendForm = 'yes';

            if(!component.formData.text || component.formData.text.length <= 0){
                canSendForm = 'no';
                component.errors.text = 'yes';
            }

            if(canSendForm == 'yes'){
                let params = new FormData;

                if(component.formData.text && component.formData.text.length > 0){
                    params.append('text_feedback', component.formData.text);
                }

                component.$http({ 
                    url: "/json/event_participant_tasks/"+ component.participantTaskId +"/submit_feedback", 
                    method: 'POST',  
                    contentType: 'multipart/form-data', 
                    responseType: 'json', 
                    data: params,
                    headers: { 'X-CSRF-TOKEN': component.tokenCsrf }
                }).then(function(response){
                    if(response.data.status == "success"){
                        $(document).trigger("flashNotyAlert", ["success", "Feedback submitted successfully!"]);
                        component.$dispatch('updateTask', { task: response.data.event_participant_task, index: component.participantTaskIndex });
                    }else{
                        component.isLoading = 'no';
                        $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                    }
                }, function(response){
                    //ERROR
                });
            }else{
                component.isLoading = 'no';
            }
        }
    }
});
