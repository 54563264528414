var eventParticipantsListManager = {
    _containerVueListParticipants: "#vueListParticipantsId",
    _vueObjListParticipants: null,

    initVueObjListParticipants: function(){
        const manager = this;

        manager._vueObjListParticipants = new Vue({
            el: manager._containerVueListParticipants,
            data: {
                eventId: null,
                currentUser: null,
                tasks: null,
                participants: null,
                participantsByTask: null,
                activeTaskIndex: -1
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerList){
                    vueObj.eventId = $(vueObj.$els.containerList).data('event-id');
                    vueObj.getTasks();
                    vueObj.getAllParticipants();
                }
            },

            computed: {
                showLoadingParticipants(){
                    if(this.activeTaskIndex < 0){
                        return !this.participants;
                    }else{
                        return !this.participantsByTask;
                    }
                },

                showListAllParticipants(){
                    return this.activeTaskIndex < 0 && this.participants;
                },

                showListParticipantsByTask(){
                    return this.activeTaskIndex >= 0 && this.participantsByTask;
                },

                showMenuTasks(){
                    return this.tasks && this.tasks.length > 0;
                },

                activeTask(){
                    if(this.tasks && this.tasks.length > 0){
                        return this.tasks[this.activeTaskIndex];
                    }

                    return null;
                },

                addClassActiveToAll(){
                    return this.activeTaskIndex < 0;
                },

                addClassActiveToTaskIndex(){
                    return this.activeTaskIndex >= 0;
                },
            },

            methods: {
                getTasks: function(){
                    const vueObj = this;

                    if(vueObj.eventId && vueObj.eventId > 0){
                        vueObj.tasks = null;

                        let url = "/json/events/"+ vueObj.eventId +"/tasks";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.tasks = response.data.tasks;
                            }else{
                                vueObj.tasks = [];
                            }
                        }, function(response){
                            //ERROR
                            vueObj.tasks = [];
                        });
                    }
                },

                getAllParticipants: function(){
                    var vueObj = this;

                    var url = "/events/"+vueObj.eventId+"/json/participants";

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.participants = response.data.participants;                            
                    }, function(response){
                        //error callback
                    });
                },

                getAllParticipantsByActiveTask: function(){
                    const vueObj = this;

                    vueObj.participantsByTask = null;

                    if(vueObj.tasks){
                        let url = "/json/tasks/"+ vueObj.tasks[vueObj.activeTaskIndex].id +"/assignments";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.participantsByTask = response.data.assignments;
                        }, function(response){
                            //ERROR
                        });
                    }
                },

                changeActiveTaskIndex: function(index){
                    const vueObj = this;

                    if(index != vueObj.activeTaskIndex){
                        vueObj.activeTaskIndex = index;

                        if(vueObj.activeTaskIndex >= 0){
                            vueObj.getAllParticipantsByActiveTask();
                        }
                    }
                },

                getFileURLStorage: function(file){
                    let url = "";

                    url = '/storage/' + file;
        
                    return url;
                },

                getUrlUserProfile: function(user){
                    return "/users/" + user.id;
                },

                getFullName(user){
                    let name = user.name;

                    if(user.name_last && user.name_last.length > 0){
                        name = name + " " + user.name_last;
                    }

                    return name;
                }
            }
        });
    }
};

$(function(){
    if($(eventParticipantsListManager._containerVueListParticipants) && $(eventParticipantsListManager._containerVueListParticipants).length > 0){
        eventParticipantsListManager.initVueObjListParticipants();
    }
});
