var investorsIndexManager = {
    _containerVueListId: "#containerPageIndexId",
    _vueObjPageList: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObjPageList = new Vue({
            el: manager._containerVueListId,
            data: {
                cookieNameRememberToken: 'dh_investors_remember_token',
                currentUser: null,
                currentInvestor: null,
                tokenCsrf: null,
                isLoading: 'no',
                investor: { name: null, email: null, password: null, remember_token: null },
                activeSigninOrSignup: 'signin',
                errors: { name: 'no', email: 'no', password: 'no' },
                files: [],
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                };

                vueObj.checkUserLoggedIn();
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                currentUserIsAdmin(){
                    return this.currentUser !== null && parseInt(this.currentUser.is_admin) == 1;
                },

                hasInvestorLoggedIn(){
                    return this.currentInvestor !== null;
                },

                showSignInOrSignUp: function(){
                    return !this.currentUserIsAdmin && !this.hasInvestorLoggedIn;
                },

                isVisibleSignin: function(){
                    return this.showSignInOrSignUp && (!this.activeSigninOrSignup || this.activeSigninOrSignup == 'signin');
                },

                isVisibleSignup: function(){
                    return this.showSignInOrSignUp && this.activeSigninOrSignup == 'signup';
                },

                showErrorName: function(){
                    return this.errors.name && this.errors.name == 'yes';
                },

                showErrorEmail: function(){
                    return this.errors.email && this.errors.email == 'yes';
                },

                showErrorPassword: function(){
                    return this.errors.password && this.errors.password == 'yes';
                },

                nameToShow: function(){
                    if(this.currentUserIsAdmin){
                        return this.currentUser.name;
                    }

                    if(this.hasInvestorLoggedIn){
                        return this.currentInvestor.name;
                    }

                    return "";
                },

                showInvestorsData: function(){
                    return this.currentUser || this.currentInvestor;
                },

                hasFiles: function(){
                    return this.files && this.files.length > 0;
                },
            },

            watch: {
            },

            methods: {
                checkUserLoggedIn: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    if(Cookies.get(vueObj.cookieNameRememberToken)){
                        vueObj.validateRememberToken();
                    }else{
                        vueObj.getCurrentUser();
                    }
                },

                validateRememberToken: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    const token = Cookies.get(vueObj.cookieNameRememberToken);
                    const url = "/json/investor/validate_token";
                    let params = new FormData();
                    params.append('token', token);

                    vueObj.$http({ url: url, method: 'POST', data: params, headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }}).then(function(response){
                        vueObj.isLoading = 'no';
                        vueObj.currentInvestor = response.data.investor;

                        if(vueObj.hasInvestorLoggedIn){
                            vueObj.getData();
                        }
                    });
                },  

                getCurrentUser: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.isLoading = 'no';

                        if(vueObj.currentUserIsAdmin){
                            vueObj.getData();
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                toogleBetweenSignInAndSignUp: function(){
                    const vueObj = this;

                    vueObj.clearErrors();

                    switch(vueObj.activeSigninOrSignup){
                        case 'signin':
                            vueObj.activeSigninOrSignup = 'signup';
                            break;
                        
                        default:
                            vueObj.activeSigninOrSignup = 'signin';
                    }
                },

                clearErrors: function(){
                    const vueObj = this;

                    vueObj.errors.name = 'no';
                    vueObj.errors.email = 'no';
                    vueObj.errors.password = 'no';
                },

                validateForm: function(typeForm){
                    const vueObj = this;

                    let formIsValid = 'yes';

                    vueObj.clearErrors();

                    if(typeForm && typeForm == 'signup'){
                        if(!vueObj.investor.name || vueObj.investor.name.replace(/\s/g, "").length <= 0){
                            vueObj.errors.name = 'yes';
                            formIsValid = "no";
                        }
                    }

                    if(!vueObj.investor.email || vueObj.investor.email.replace(/\s/g, "").length <= 0){
                        vueObj.errors.email = 'yes';
                        formIsValid = "no";
                    }

                    if(!vueObj.investor.password || vueObj.investor.password.replace(/\s/g, "").length <= 0){
                        vueObj.errors.password = 'yes';
                        formIsValid = "no";
                    }

                    return formIsValid;

                },

                sendForm: function(){
                    const vueObj = this;

                    let formType = 'signin';
                    let url = "/json/investor/validate_credentials";

                    if(vueObj.isVisibleSignup){
                        formType = 'signup';
                        url = "/json/investor";
                    }

                    const formIsValid = vueObj.validateForm(formType);

                    if(formIsValid == 'yes'){
                        vueObj.isLoading = 'yes';

                        let params = new FormData();
                        params.append('name', vueObj.investor.name);
                        params.append('email', vueObj.investor.email);
                        params.append('password', vueObj.investor.password);

                        vueObj.$http({ url: url, method: 'POST', data: params, headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }}).then(function(response){
                            switch(response.data.status){
                                case 'error':
                                    if(vueObj.isVisibleSignup){
                                        $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                    }else{
                                        $(document).trigger("flashNotyAlert", ["error", "Wrong email or password."]);
                                    }
                                    vueObj.isLoading = 'no';
                                    break;
                                
                                case 'warning':
                                    $(document).trigger("flashNotyAlert", ["warning", "Email already registered."]);
                                    vueObj.isLoading = 'no';
                                    break;

                                default:
                                    if(vueObj.isVisibleSignup){
                                        $(document).trigger("flashNotyAlert", ["success", "Account successfully created."]);
                                    }else{
                                        vueObj.currentInvestor = response.data.investor;
                                    }

                                    vueObj.setInvestorAndGetData(response.data.investor);
                            }
                        }, function(response){
                            //ERROR
                        });
                    }
                },

                setInvestorAndGetData: function(investor){
                    const vueObj = this;

                    vueObj.currentInvestor = investor;
                    vueObj.setTokenCookie();

                    if(vueObj.investor){
                        vueObj.getData();
                    }
                },

                setTokenCookie: function(){
                    const vueObj = this;

                    if(vueObj.currentInvestor && vueObj.currentInvestor.remember_token){
                        Cookies.set(vueObj.cookieNameRememberToken, vueObj.currentInvestor.remember_token, { expires: 2 });
                    }
                },

                getData: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/investor/files', method: 'GET' }).then(function(response){
                        vueObj.files = response.data.files;

                        vueObj.isLoading = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                manageUploadedFiles: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    const elem = vueObj.$els.investorUploadFiles;

                    if(elem){
                        const files = $(elem)[0].files;

                        const url = "/json/investor/upload_files";

                        let params = new FormData();
                        params.append('files[]', null);

                        if(files && files.length > 0){
                            for(i = 0; i < files.length; i++){
                                params.append('files[]', files[i]);
                            }
                        }

                        vueObj.$http({ url: url, method: 'POST', contentType: 'multipart/form-data', data: params, headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }}).then(function(response){
                            vueObj.getData();
                        });
                    }
                },

                deleteFile: function(index){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    const fileToDelete = vueObj.files[index];
                    
                    if(fileToDelete){
                        const url = "/json/investor/files/" + fileToDelete.id;
                        vueObj.$http({ url: url, method: 'DELETE', 
                                       headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }}).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.files.splice(index, 1);
                                $(document).trigger("flashNotyAlert", ["success", "File deleted."]);
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            }

                            vueObj.isLoading = 'no';
                        });
                    }
                },

                signOut: function(){
                    const vueObj = this;

                    vueObj.currentInvestor = null;
                    Cookies.remove(vueObj.cookieNameRememberToken);
                }
            }
        });
    }
};

$(function(){
    if($(investorsIndexManager._containerVueListId) && $(investorsIndexManager._containerVueListId).length > 0){
        investorsIndexManager.initVueObj();
    }
});
