var eventImageUploadManager = {
    _containerVueId: '#containerVueEventImageUploadId',
    _vueObjEventImageUpload: null,
    _cropperImageWeb: null,
    _cropperImageSocialNetworks: null,

    initVueEventImageUpload: function(){
        var manager = this;

        manager._vueObjEventImageUpload = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                tokenCsrf: null,
                eventId: null,
                imageUrl: null,
                photo: { image_web: $("#eventImageUploadWebId"), image_social: $("#eventImageUploadSocialId") },
                base64data: { web: null, fb_lkn: null, twitter: null },
                sendingForm: 'no'
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoading: function(){
                    return this.sendingForm && this.sendingForm == 'yes';
                },

                showFormUpload: function(){
                    return !this.sendingForm || this.sendingForm == 'no';
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');

                        if($(vueObj.$els.containerVue).data('event-id')){
                            vueObj.eventId = $(vueObj.$els.containerVue).data('event-id');
                        }
                    }
                },

                manageImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventimageuploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.imageUrl = url;

                        if(vueObj.photo.image_web){
                            vueObj.photo.image_web.attr("src",url);
                        }

                        if(vueObj.photo.image_social){
                            vueObj.photo.image_social.attr("src",url);
                        }

                        if(!manager._cropperImageWeb){       
                            manager.initCropper('web');
                        }

                        if(!manager._cropperImageSocialNetworks){       
                            manager.initCropper('social_networks');
                        }
                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            if(manager._cropperImageWeb){       
                                manager.destroyCropper('web');
                            }
                            if(manager._cropperImageSocialNetworks){       
                                manager.destroyCropper('social_networks');
                            }

                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;

                    switch(type){
                        case 'web':
                            if(manager._cropperImageWeb){
                                canvas = manager._cropperImageWeb.getCroppedCanvas({ width: 1190, height: 1074, minWidth: 1190, minHeight:1074 });
                            }
                            break;
                        case 'fb_lkd':
                            if(manager._cropperImageSocialNetworks){
                                canvas = manager._cropperImageSocialNetworks.getCroppedCanvas({ width: 1200, height: 627, minWidth: 1200, minHeight:627 });
                            }
                            break;
                        case 'twitter':
                            if(manager._cropperImageSocialNetworks){
                                canvas = manager._cropperImageSocialNetworks.getCroppedCanvas({ width: 1200, height: 600, minWidth: 1200, minHeight:600 });
                            }
                            break;
                    }

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                uploadImage: function(){
                    const vueObj = this;

                    if(vueObj.imageUrl){
                        vueObj.sendingForm = 'yes';

                        vueObj.$els.containerVue.scrollIntoView();

                        vueObj.getBase64dataImages('web');
                        vueObj.getBase64dataImages('fb_lkd');
                        vueObj.getBase64dataImages('twitter');
    
                        vueObj.sendImages();
                    }
                },

                sendImages: function(){
                    const vueObj = this;

                    let checkExist = setInterval(function(){
                        if (vueObj.base64data.web && vueObj.base64data.fb_lkn && vueObj.base64data.twitter) {
                            let urlToSend = "";

                            if(vueObj.eventId){
                                urlToSend = '/json/events/'+vueObj.eventId+'/update_image';
                            }else{
                                urlToSend = '/json/event_preselected_photos/update_image';
                            }

                            let params = {
                                image_pdf: vueObj.base64data.web, 
                                image_fb: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            vueObj.$http({ 
                                    url: urlToSend, method: 'POST', 
                                    contentType: 'multipart/form-data', 
                                    responseType: 'json', 
                                    data: params,
                                    headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                            }).then(function(response){
                                //SUCCESS
                                if(response.data.result == "success"){
                                    
                                    if(vueObj.eventId){
                                        window.location.reload();
                                    }else{
                                        window.location.replace("/admin/event_preselected_photos");
                                    }
                                }
                            }, function(response){
                                //ERROR
                            });
                            
                           clearInterval(checkExist);
                        }
                    }, 3000);
                }
            }
        });
    },

    initCropper: function(type){
        const manager = this;

        if(type){
            if(type && type == 'social_networks'){
                let image = $(".containerAdminEventImageUploaderCropper").find("#eventImageUploadSocialId");

                manager._cropperImageSocialNetworks = new Cropper($(image)[0], {
                    aspectRatio: 16/9,
                    viewMode: 2,
                    zoomable: false,
                    preview:'.previewSocial'
                });
            }else{
                let image = $(".containerAdminEventImageUploaderCropper").find("#eventImageUploadWebId");

                manager._cropperImageWeb = new Cropper($(image)[0], {
                    aspectRatio: 1,
                    viewMode: 2,
                    zoomable: false,
                    preview:'.previewWeb'
                });
            }

        }
    },

    destroyCropper: function(type){
        const manager = this;

        if(type && type == 'social_networks'){
            manager._cropperImageSocialNetworks.destroy();
            manager._cropperImageSocialNetworks = null;
        }else{
            manager._cropperImageWeb.destroy();
            manager._cropperImageWeb = null;
        }
    }
}

$(function(){
    if(eventImageUploadManager._containerVueId && $(eventImageUploadManager._containerVueId).length > 0){
        eventImageUploadManager.initVueEventImageUpload();
    }
});
