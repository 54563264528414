$(function(){
    var masterCourseEditionFormManager = {
        _containerFormId: '#containerFormMasterCourseEditionId',
        _formVueObj: null,

        initFormNewOrEditVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    containerFormDivId: manager._containerFormId,
                    eventType: null,
                    eventId: null,
                    masterCourseEditon: { date_start: null, date_end: null },
                    masterCourseNrTasks: 0,
                    nrDays: 0,
                    tutors: []
                },

                ready: function(){
                    const vueObj = this;

                    const elemContainerForm = vueObj.$els.containerForm;

                    if(elemContainerForm){
                        vueObj.masterCourseNrTasks = $(elemContainerForm).data('nr-tasks');
                        vueObj.eventType = $(elemContainerForm).data('event-type');
                        vueObj.eventId = $(elemContainerForm).data('event-id');
                    }

                    const elemContainerNrDays = vueObj.$els.containerNrDays;
                    if(elemContainerNrDays){
                        vueObj.nrDays = $(elemContainerNrDays).data('value');
                    }
                },

                computed: {
                    nrDaysToAddToStartDate: function(){
                        const vueObj = this;

                        switch(vueObj.eventType){
                            case 'master_course':
                                if(vueObj.masterCourseNrTasks && parseInt(vueObj.masterCourseNrTasks) > 0){
                                    return 7 * parseInt(vueObj.masterCourseNrTasks);
                                }
                                break;

                            case 'workshop':
                                if(vueObj.nrDays && parseInt(vueObj.nrDays) > 0){
                                    return parseInt(vueObj.nrDays);
                                }
                                break;
                        }

                        return 0;
                    }
                },

                events: {
                    'updatedListAuthorsOrModerators': function(msg){
                        this.tutors = msg;
                    }
                },

                // methods
                methods: {
                    calculateDateEnd: function(){
                        const vueObj = this;

                        vueObj.masterCourseEditon.date_end = null;

                        if(vueObj.masterCourseEditon.date_start){
                            let dateStartMoment = moment(vueObj.masterCourseEditon.date_start);

                            if(dateStartMoment.isValid()){
                                let dateEndMoment = dateStartMoment;

                                let nrDaysToAdd = vueObj.nrDaysToAddToStartDate;

                                if(nrDaysToAdd && nrDaysToAdd > 0){
                                    //dateEndMoment = dateStartMoment.add(vueObj.masterCourseNrTasks, 'w');
                                   dateEndMoment = dateStartMoment.add(nrDaysToAdd, 'd');
                                }

                                if(dateEndMoment.isValid()){
                                    vueObj.masterCourseEditon.date_end = dateEndMoment.format('YYYY-MM-DD');
                                }                              
                            }
                        }
                    },

                    sendForm: function(){
                        const vueObj = this;

                        const form = $(vueObj.containerFormDivId).find("form");
                        
                        if(form){
                            let url = form.attr("action");
                            let method = form.attr("method");
                            let params = new FormData(form[0]);

                            params.append('tutors', JSON.stringify(vueObj.tutors));

                            vueObj.$http({ url: url, method: method, data: params }).then(function(response){
                                vueObj.manageRequestResponse(response.data.status);
                            }, function(response){
                                vueObj.manageRequestResponse("error");
                            });
                        }
                    },

                    manageRequestResponse: function(status){
                        const vueObj = this;
                        
                        if(status == 'success'){
                            $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                            switch(vueObj.eventType){
                                case 'master_course':
                                    window.location.href = "/admin/master_course_editions";
                                    break;
    
                                case 'workshop':
                                    window.location.href = "/admin/events/"+ vueObj.eventId +"/editions";
                                    break;
                            }
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                        }
                    }
                }
            });
        },
    };

    if($(masterCourseEditionFormManager._containerFormId) && $(masterCourseEditionFormManager._containerFormId).length > 0){
        masterCourseEditionFormManager.initFormNewOrEditVueObj();
    }

});
