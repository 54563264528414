$(function(){
    var EventsManager = {
        _eventType: null,
        _eventId: 0,
        _eventOnlyRequest: 'no',
        _eventIsFinished: 'no',
        _containerFormId: null,
        _formVueTraining: null,
        _formVueResidence: null,
        _formVueObj: null,
        _formVueInitialized: 'no',

        initToTraining: function(trainingId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "training";
            manager._containerFormId = "#containerFormJoinEventTrainingId";
            manager._formVueInitialized = "yes";
            manager._eventId = trainingId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToResidencies: function(residenceId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "residence";
            manager._containerFormId = "#containerFormJoinEventResidenceId";
            manager._formVueInitialized = "yes";
            manager._eventId = residenceId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToShortCourses: function(shortCourseId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "short_course";
            manager._containerFormId = "#containerFormJoinEventShortCourseId";
            manager._formVueInitialized = "yes";
            manager._eventId = shortCourseId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToWorkshop: function(workshopId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "workshop";
            manager._containerFormId = "#containerFormJoinEventWorkshopId";
            manager._formVueInitialized = "yes";
            manager._eventId = workshopId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToInResidence: function(inResidenceId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "in_residence";
            manager._containerFormId = "#containerFormJoinEventInResidenceId";
            manager._formVueInitialized = "yes";
            manager._eventId = inResidenceId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToLearningCafe: function(learningCafeId, eventOnlyRequest, eventIsFinished){
            var manager = this;
            manager._eventType = "learning_cafe";
            manager._containerFormId = "#containerFormJoinEventLearningCafeId";
            manager._formVueInitialized = "yes";
            manager._eventId = learningCafeId;
            manager._eventOnlyRequest = eventOnlyRequest;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormVueObj();
        },

        initToMasterCourse: function(masterCourseId, eventIsFinished){
            var manager = this;
            manager._eventType = "master_course";
            manager._containerFormId = "#containerFormJoinEventMasterCourseId";
            manager._formVueInitialized = "yes";
            manager._eventId = masterCourseId;
            manager._eventOnlyRequest = false;
            manager._eventIsFinished = eventIsFinished;

            manager.initFormMasterCourseVueObj();
        },

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    formDetails: { obj_id: manager._eventId, only_request: manager._eventOnlyRequest, is_finished: manager._eventIsFinished, name: '', email: '', month_doctoral_study: null, topics_of_interest: null, timezone: null, accept_terms: true, type: manager._eventType, date: null, suggested_date: null, university_affiliation: null, years_work_experience: null, finished_study: false, suggested_locations: [] },
                    emailREGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    showErrorInvalid: { email: 'no' },
                    showInputs: { suggest_date: 'no', years_work_experience: 'no', month_doctoral_study: 'yes' },
                    containerFormDivId: manager._containerFormId,
                    eventDateOptions: []
                },
                ready: function(){
                    var vueObj = this;

                    if(vueObj.formDetails.only_request == 'yes' || vueObj.formDetails.is_finished == 'yes'){
                        vueObj.initDatePickerSuggestedDate();
                    }
                },
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.formDetails.email) }
                    },
                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    }
                },
                // watch values of vars
                watch: {
                    'formDetails.date': function(newVal, oldVal) {
                        var vueObj = this;

                        if(newVal === '0'){
                            vueObj.showInputSuggestedDate();
                        }else{
                            vueObj.hideInputSuggestedDate();
                        }
                    },

                    'formDetails.finished_study': function(newVal, oldVal) {
                        var vueObj = this;

                        if(newVal === true){
                            vueObj.showInputs.month_doctoral_study = "no";
                            vueObj.showInputs.years_work_experience = "yes";
                        }else{
                            vueObj.showInputs.month_doctoral_study = "yes";
                            vueObj.showInputs.years_work_experience = "no";
                        }
                    }
                },
                // methods
                methods: {
                    addParticipant: function () {
                        var vueObj = this;
                        
                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form){
                            if(!form.hasClass("blur")){
                                form.addClass("blur");
                            }

                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);
                            vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                vueObj.manageRequestResponse(response.data.status);
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    manageRequestResponse: function(status){
                        var vueObj = this;

                        window.location.reload();

                        /*var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form && form.hasClass("blur")){
                            form.removeClass("blur");
                        }

                        if(status == 'success'){
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.success");

                            divRequestMessage.removeClass("hide");
                            vueObj.clearForm();
                        }else if(status == 'already exists'){
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.warning");

                            divRequestMessage.removeClass("hide");
                        }else{
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.error");

                            divRequestMessage.removeClass("hide");
                        }

                        var containerToScroll = $(".eventShowDetail");

                        if(containerToScroll && containerToScroll.length > 0){
                            $([document.documentElement, document.body]).animate({
                                scrollTop: containerToScroll.offset().top
                            }, 200);
                        }

                        setTimeout(function(){ divRequestMessage.addClass("hide"); }, 3000);*/
                    },

                    clearForm: function(){
                        var vueObj = this;
                        vueObj.formDetails.name = null;
                        vueObj.formDetails.email = null;
                        vueObj.formDetails.month_doctoral_study = null;
                        vueObj.formDetails.topics_of_interest = null;
                        vueObj.formDetails.timezone = null;
                        vueObj.formDetails.date = null;
                        vueObj.formDetails.suggested_date = null;
                        vueObj.formDetails.years_work_experience = null;
                        vueObj.formDetails.finished_study = false;
                        vueObj.formDetails.university_affiliation = null;
                        vueObj.formDetails.suggested_locations = [];
                    },

                    showInputSuggestedDate: function(){
                        var vueObj = this;

                        vueObj.showInputs.suggest_date = 'yes';
                        vueObj.initDatePickerSuggestedDate();
                    },

                    initDatePickerSuggestedDate: function(){
                        var vueObj = this;

                        setTimeout(function(){
                            var inputSuggestDate = $(vueObj.containerFormDivId).find(".inputSuggestDate");
                            
                            if(inputSuggestDate){
                                var datePickerOptions = { language: 'en', minDate: new Date(),
                                                              dateFormat: 'yyyy-mm-dd', autoClose: true };
    
                                var newDatepicker = inputSuggestDate.datepicker(datePickerOptions).data('datepicker');
                            }
                        }, 100);
                    },

                    hideInputSuggestedDate: function(){
                        var vueObj = this;

                        vueObj.showInputs.suggest_date = 'no';
                    }
                }
            });
        },

        initFormMasterCourseVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    formDetails: { obj_id: manager._eventId, only_request: manager._eventOnlyRequest, is_finished: manager._eventIsFinished, accept_terms: true, type: manager._eventType, suggested_date: null, topics_of_interest: null, master_course_edition_id: null },
                    showInputs: { suggest_date: 'no' },
                    containerFormDivId: manager._containerFormId,
                    masterCourseEditions: null,
                    masterCourseEditionsPricePerParticipant: null,
                    showMessageJoinPending: 'no',
                },
                ready: function(){
                    var vueObj = this;

                    vueObj.getCourseEditions();
                },
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.formDetails.email) }
                    },
                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    }
                },
                // watch values of vars
                watch: {
                    'formDetails.master_course_edition_id': function(newVal, oldVal) {
                        var vueObj = this;

                        if(newVal === '0'){
                            vueObj.showInputSuggestedDate();
                        }else{
                            vueObj.hideInputSuggestedDate();
                        }
                    }
                },

                filters: {
                    formatDate: function(date){
                        return moment(date).format('DD MMMM YYYY');
                    },
                    formatPrice: function(price){
                        return parseFloat(price).toFixed(2);;
                    }
                },
                // methods
                methods: {
                    getCourseEditions: function(){
                        var vueObj = this;

                        vueObj.hideForm();
                        vueObj.showLoadingDiv();

                        var url = $(vueObj.containerFormDivId).data('url-get-editions');

                        if(url){
                            vueObj.$http({ 
                                url: url, 
                                method: 'GET', 
                                responseType: 'json', 
                                data:{} 
                            }).then(function(response){
                                vueObj.hideLoadingDiv();
                                vueObj.showForm();

                                vueObj.masterCourseEditionsPricePerParticipant = response.data.editions_price_per_participant;
                                vueObj.masterCourseEditions = response.data.course_editions;                                
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    showLoadingDiv: function(){
                        var vueObj = this;
                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }
                    },

                    hideLoadingDiv: function(){
                        var vueObj = this;
                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }
                    },

                    showForm: function(){
                        var vueObj = this;
                        var form = $(vueObj.containerFormDivId).find("form");
                        if(form && form.hasClass('hide')){
                            form.removeClass('hide');
                        }
                    },

                    hideForm: function(){
                        var vueObj = this;
                        var form = $(vueObj.containerFormDivId).find("form");
                        if(form && !form.hasClass('hide')){
                            form.addClass('hide');
                        }
                    },

                    addParticipant: function () {
                        var vueObj = this;
                        
                        vueObj.showLoadingDiv();

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form){
                            if(!form.hasClass("blur")){
                                form.addClass("blur");
                            }

                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);
                            vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                vueObj.manageRequestResponse(response.data.status, response.data.url_redirect);
                            }, function(response){
                                //error callback
                            });
                        }
                    },

                    manageRequestResponse: function(status, urlRedirect){
                        var vueObj = this;

                        if(status && status == 'waiting approval'){
                            var containerToScroll = $(vueObj.containerFormDivId);

                            vueObj.showMessageJoinPending = 'yes';
                            vueObj.hideForm();
                            vueObj.hideLoadingDiv();

                            $([document.documentElement, document.body]).animate({
                                scrollTop: containerToScroll.offset().top
                            }, 2000);
                        }else{
                            if(urlRedirect){
                                window.location.href = urlRedirect;
                            }else{
                                window.location.reload();
                            }
                        }
                    },

                    showInputSuggestedDate: function(){
                        var vueObj = this;

                        vueObj.showInputs.suggest_date = 'yes';
                        vueObj.initDatePickerSuggestedDate();
                    },

                    initDatePickerSuggestedDate: function(){
                        var vueObj = this;

                        setTimeout(function(){
                            var inputSuggestDate = $(vueObj.containerFormDivId).find(".inputSuggestDate");
                            
                            if(inputSuggestDate){
                                var datePickerOptions = { language: 'en', minDate: new Date(),
                                                              dateFormat: 'yyyy-mm-dd', autoClose: true };
    
                                var newDatepicker = inputSuggestDate.datepicker(datePickerOptions).data('datepicker');
                            }
                        }, 100);
                    },

                    hideInputSuggestedDate: function(){
                        var vueObj = this;

                        vueObj.showInputs.suggest_date = 'no';
                    }
                }
            });
        },

        moveFormContainerToOpenedAccordionOption: function(containerAccordion){
            var manager = this;

            if(containerAccordion && !containerAccordion.hasClass("finished")){
                var liActive = containerAccordion.find(".accordion-item.is-active");

                if(liActive){
                    var liActiveContent = liActive.find(".accordion-content");
                    if(liActiveContent){
                        var divContainerForm = $(manager._containerFormId);
                        
                        liActiveContent.find(".containerForForm").append(divContainerForm.remove());

                        var divsRequestResultMessages = divContainerForm.find(".containerMessageAfterRequest");
                        if(divsRequestResultMessages && divsRequestResultMessages.length > 0){
                            divsRequestResultMessages.each(function(index, div){
                                var divElem = $(div);

                                if(!divElem.hasClass("hide")){
                                    divElem.addClass("hide");
                                }
                            });
                        }

                        if(divContainerForm.hasClass("hide")){
                            divContainerForm.removeClass("hide");
                        }

                        if(manager._formVueObj){
                            manager._formVueObj.formDetails.obj_id = liActive.data("event-id");
                            manager._formVueObj.formDetails.type = liActive.data("event-type");
                            manager._formVueObj.eventDateOptions = { id: liActive.data("date-id"), date: liActive.data("date") };
                        }
                    }
                }
            }
        },

        checkAndInitFormVue: function(){
            var manager = this;

            if($(".containerPageEventDetail .containerFormJoin").length > 0){
                var tabTitleRegister = $('.containerPageEventDetail .tabs-title.register');
    
                if(tabTitleRegister && tabTitleRegister.hasClass('is-active') && EventsManager._formVueInitialized === 'no'){
                    var eventId = $(".containerPageEventDetail .containerFormJoin .contentFormDiv").data("event-id");
                    var eventType = $(".containerPageEventDetail .containerFormJoin .contentFormDiv").data("event-type");
    
                    var eventOnlyRequestData = $(".containerPageEventDetail .containerFormJoin .contentFormDiv").data("event-only-request");
                    var eventIsFinishedData = $(".containerPageEventDetail .containerFormJoin .contentFormDiv").data("event-is-finished");
    
                    if(eventOnlyRequestData && eventOnlyRequestData == 1){
                        var eventOnlyRequest = 'yes';
                    }else{
                        var eventOnlyRequest = 'no';
                    }
    
                    if(eventIsFinishedData && eventIsFinishedData == 1){
                        var eventIsFinished = 'yes';
                    }else{
                        var eventIsFinished = 'no';
                    }
    
                    switch(eventType) {
                        case 'residence':
                            manager.initToResidencies(eventId, eventOnlyRequest, eventIsFinished);
                            break;
                        case 'short_course':
                            manager.initToShortCourses(eventId, eventOnlyRequest, eventIsFinished);
                          break;
                        case 'workshop':
                            manager.initToWorkshop(eventId, eventOnlyRequest, eventIsFinished);
                          break;
                        case 'in_residence':
                            manager.initToInResidence(eventId, eventOnlyRequest, eventIsFinished);
                          break;
                        case 'learning_cafe':
                            manager.initToLearningCafe(eventId, eventOnlyRequest, eventIsFinished);
                          break;
                        case 'master_course':
                            manager.initToMasterCourse(eventId, eventIsFinished);
                          break;
                        default:
                            manager.initToTraining(eventId, eventOnlyRequest, eventIsFinished);
                    }
                }
            }
        }
    };


    /*if($(".accordionListEvents.training").not( ".finished" ).length > 0){
        EventsManager.initToTraining();
    }else if($(".accordionListEvents.residencies").not( ".finished" ).length > 0){
        EventsManager.initToResidencies();
    }

    $('.accordion.accordionListEvents').not( ".finished" ).on({
        'down.zf.accordion': function(){
           // EventsManager.moveFormContainerToOpenedAccordionOption($(this));
           var liActive = $(this).find(".accordion-item.is-active");
           var eventId = liActive.data("event-id");
           var containerformId = "#containerFormJoinEventTrainingId_" + eventId;
            
           setTimeout(function(){ EventsManager.initToTraining(eventId, containerformId); });
        },
        'up.zf.accordion': function(){
            //console.log('closed');
        }
    });*/


    $(document).on('change.zf.tabs', function(){
        EventsManager.checkAndInitFormVue();
    });

    EventsManager.checkAndInitFormVue();
});
