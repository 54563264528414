$(function(){
    var masterCourseFormManager = {
        _containerFormId: '#containerFormMasterCourseId',
        _formVueObj: null,

        initFormNewOrEditVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    containerFormDivId: manager._containerFormId,

                    masterCourse: { is_online: false },
                    
                    eventId: null,
                    eventType: 'master_course',
                    tokenCsrf: null,
                    currentUser: null,
                    event: { id: 0, name: "", short_description: "", description: "", key_takeaways: "", author_description: "", author_name: "", preselected_photo_id: 0, price_tutored: 0, course_instructions: "", duration_in_days: null, location: null },
                    tasks: [],
                    selectedPhotoPreviewUrl: null,
                    alreadyPreselectedPhoto: null,
                    authorDescriptionFromProfile: true,
                    studySegments: { researchMethodsAndTools: { id: 0, list: [] }, dataCollectionTechiques: { id: 0, list: [] }, doctoralJourney: { id: 0, list: [] } },
                    authors: [],
                    preSelectedAuthors: null,
                    preselectedStudySegments: { researchMethodsAndTools: [], dataCollectionTechiques: [], doctoralJourney: [] },
                    preselectedTasks: [],
                    deletedTasksIds: [],
                    updateWysiwygFields: { short_description: null, description: null, key_takeaways: null, author_description: null },
                    listLearningClubs: null,
                    associatedLearningClubsIds: [],
                },
        
                computed: {
                    isUpdateForm: function(){
                        return (this.eventId && this.eventId > 0) || (this.event && this.event.id && this.event.id > 0);
                    },
                    currentEventId: function(){
                        if(this.event && this.event.id && this.event.id > 0){
                            return this.event.id;
                        }else if(this.eventId && this.eventId > 0){
                            return this.eventId;
                        }

                        return 0;
                    },
                    formType: function(){
                        if(this.isUpdateForm){
                            return 'edit';
                        }

                        return 'new';
                    },

                    showInputAssociatedClubs: function(){
                        return this.listLearningClubs && this.listLearningClubs.length > 0;
                    }
                },
                
                ready: function(){
                    const vueObj = this;

                    const elemContainerForm = vueObj.$els.containerForm;
                    const elemDivEventId = vueObj.$els.divEventId;

                    if(elemContainerForm){
                        vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                    }

                    if(elemContainerForm){
                        vueObj.eventType = $(elemContainerForm).data('event-type');
                    }

                    if(elemDivEventId){
                        vueObj.eventId = $(elemDivEventId).data("event-id");
                    }

                    if(vueObj.isUpdateForm){
                        vueObj.initFormUpdate();
                    }else{
                        vueObj.initFormNew();
                    }
                },

                events: {
                    'updateEventName': function(msg){
                        this.event.name = msg;
                    },
                    'updateEventLocation': function(msg){
                        this.event.location = msg;
                    },
                    'updateEventShortDescription': function(msg){
                        this.event.short_description = msg;
                    },
                    'updateEventDescription': function(msg){
                        this.event.description = msg;
                    },
                    'updateEventKeyTakeAways': function(msg){
                        this.event.key_takeaways = msg;
                    },
                    'updateEventAuthorName': function(msg){
                        this.event.author_name = msg;
                    },
                    'updateEventAuthorDescription': function(msg){
                        this.event.author_description = msg;
                    },
                    'updateEventPreselectedPhotoId': function(msg){
                        this.event.preselected_photo_id = msg.id;
                        this.selectedPhotoPreviewUrl = msg.url;
                    },
                    'updateMasterCoursePrice': function(msg){
                        this.event.price_tutored = msg;
                    },
                    'updateResearchMethodsAndTools': function(msg){
                        this.studySegments.researchMethodsAndTools.id = msg.id;
                        this.studySegments.researchMethodsAndTools.list = msg.list;
                    },
                    'updateDataCollectionTechiques': function(msg){
                        this.studySegments.dataCollectionTechiques.id = msg.id;
                        this.studySegments.dataCollectionTechiques.list = msg.list;
                    },
                    'updateDoctoralJourney': function(msg){
                        this.studySegments.doctoralJourney.id = msg.id;
                        this.studySegments.doctoralJourney.list = msg.list;
                    },
                    'updateEventCourseDescription': function(msg){
                        this.event.course_instructions = msg;
                    },
                    'tasksUpdated': function(msg){
                        this.tasks = msg;
                    },
                    'tasksDeleted': function(msg){
                        this.deletedTasksIds = msg;
                    },

                    'updatedListAuthorsOrModerators': function(msg){
                        this.authors = msg;
                    },

                    'updateAssociatedClubs': function(msg){
                        this.associatedLearningClubsIds = msg;
                    },

                    'saveForm': function(msg){
                        this.sendForm();
                    }
                },
     
                methods: {
                    initFormNew: function(){
                        const vueObj = this;

                        vueObj.getCurrentUserAndSetVars();
                        vueObj.getListLearningClubs();
                        vueObj.getDefaultCourseInstructionsAndSetVar();
                    },

                    initFormUpdate: function(){
                        const vueObj = this;
                        
                        vueObj.getCurrentUserAndSetVars();
                        vueObj.getListLearningClubs();
                        vueObj.getFormDataForEventsAndSetVar();
                    },

                    getListLearningClubs: function(){
                        const vueObj = this;

                        vueObj.$http({
                            url: "/json/learning_clubs",
                            method: 'GET'
                        }).then(function(response){
                            vueObj.listLearningClubs = response.data.learning_clubs;

                            vueObj.$nextTick(() => {
                                if(vueObj.$els.divListAssociatedClubs){
                                    vueObj.initCustomScroll(vueObj.$els.divListAssociatedClubs);
                                }
                            });
                        }, function(response){
                            //ERROR
                        });
                    },

                    getFormDataForEventsAndSetVar: function(){
                        const vueObj = this;

                        let url = '/json/events/'+ vueObj.currentEventId +'/form_data';

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.setEvent(response.data.event);
                                vueObj.setPreselectedPhoto(response.data.preselected_photo);
                                vueObj.setPreselectedSegments(response.data.segments);
                                vueObj.setPreselectedTasks(response.data.tasks);
                                vueObj.setAuthors(response.data.authors);

                                if(response.data.associated_learning_clubs_ids){
                                    vueObj.associatedLearningClubsIds = response.data.associated_learning_clubs_ids;
                                    //console.log(response.data.associated_learning_clubs_ids);
                                }
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    setEvent: function(event){
                        const vueObj = this;

                        if(event){
                            vueObj.event.id = event.id;
                            vueObj.event.name = event.name;
                            vueObj.event.short_description = event.summary;
                            vueObj.event.description = event.description;
                            vueObj.event.key_takeaways = event.key_takeaways;
                            vueObj.event.author_description = event.owner_description;
                            vueObj.event.author_name = event.owner_name;
                            vueObj.event.course_instructions = event.program;
                            vueObj.event.duration_in_days = event.duration_in_days;
                            vueObj.event.location = event.location;

                            if(event.price && event.price > 0){
                                vueObj.event.price_tutored = parseFloat(event.price);
                            }
                        }
                    },

                    setPreselectedPhoto: function(preselectedPhoto){
                        const vueObj = this;

                        if(preselectedPhoto){
                            vueObj.alreadyPreselectedPhoto = preselectedPhoto;
                        }
                    },

                    setPreselectedSegments: function(segments){
                        const vueObj = this;
         
                        if(segments){
                            if('research_methods_and_tools' in segments){
                                $.each(segments.research_methods_and_tools, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedStudySegments.researchMethodsAndTools.push(tempObj);
                                });
                            }

                            if('data_collection_techiques' in segments){
                                $.each(segments.data_collection_techiques, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedStudySegments.dataCollectionTechiques.push(tempObj);
                                });
                            }

                            if('doctoral_journey' in segments){
                                $.each(segments.doctoral_journey, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedStudySegments.doctoralJourney.push(tempObj);
                                });
                            }
                        }
                    },

                    setPreselectedTasks: function(tasks){
                        const vueObj = this;

                        if(tasks && tasks.length > 0){
                            vueObj.preselectedTasks = tasks;
                        }
                    },

                    setAuthors: function(authors){
                        const vueObj = this;

                        if(authors && authors.length > 0){
                            vueObj.preSelectedAuthors = [];
                            vueObj.authors = [];

                            $.each(authors, function(index, author){
                                let tempObj = { id: author.id, user_id: author.user_id, name: author.name, about: author.about, photo: null };

                                if(author.user && author.user.photo){
                                    tempObj.photo = author.user.photo;
                                }

                                vueObj.preSelectedAuthors.push(tempObj);
                                vueObj.authors.push(tempObj);
                            });
                        }
                    },

                    getCurrentUserAndSetVars: function(){
                        const vueObj = this;

                        vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                            vueObj.setCurrentUser(response.data.user);
                            if(!vueObj.isUpdateForm){
                                vueObj.setAuthorDescriptionWithCurrentUserDescription();
                                vueObj.setAuthorNameWithCurrentUserName();
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    setCurrentUser: function(user){
                        const vueObj = this;

                        if(user){
                            vueObj.currentUser = user;
                        }
                    },

                    setAuthorDescriptionWithCurrentUserDescription: function(){
                        const vueObj = this;

                        if(vueObj.currentUser && vueObj.currentUser.about && vueObj.currentUser.about.length > 0){
                            //let strippedString = vueObj.currentUser.about.replace(/(<([^>]+)>)/gi, "");
                            vueObj.event.author_description = vueObj.currentUser.about;
                        }
                    },

                    setAuthorNameWithCurrentUserName: function(){
                        const vueObj = this;

                        if(vueObj.currentUser && vueObj.currentUser.name){
                            let authorName = vueObj.currentUser.name;

                            if(vueObj.currentUser.name_last){
                                authorName = authorName + " " + vueObj.currentUser.name_last;
                            }

                            vueObj.event.author_name = authorName;
                        }
                    },

                    getDefaultCourseInstructionsAndSetVar: function(){
                        const vueObj = this;
                        let url = "";
                        
                        switch(vueObj.eventType){
                            case 'workshop':
                                url = "/json/events/workshop_instructions";
                                break;
                            default:
                                url = "/json/events/course_instructions";
                        }
                        
                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.setDefaultCourseInstructions(response.data.instructions);
                        }, function(response){
                            //ERROR
                        });
                    },

                    setDefaultCourseInstructions: function(defaultInstructions){
                        const vueObj = this;

                        if(defaultInstructions && defaultInstructions.length > 0){
                            vueObj.event.course_instructions = defaultInstructions;
                        }
                    },

                    sendForm: function(args){
                        const vueObj = this;

                        args = args || {}; 
                        args.open_pdf_type = args.open_pdf_type || null; 
                        args.publish = args.publish || 'no'; 
                        

                        let url = "/json/events";
                        let requestType = "POST";

                        let tasksFileLastIndex = 0;
    
                        let params = new FormData();
                        
                        params.append('event_type', vueObj.eventType);

                        params.append('to_publish', args.publish);

                        params.append('event', JSON.stringify(vueObj.event));

                        params.append('segments', JSON.stringify(vueObj.studySegments));

                        params.append('authors', JSON.stringify(vueObj.authors));

                        params.append('tasks_files[]', null);

                        if(vueObj.tasks && vueObj.tasks.length > 0){
                            let tasksToSend = [];

                            $.each(vueObj.tasks, function(indexTask, task){
                                let tskFilesIndexs = [];
                                let taskPrevFilesIdsToRemove = [];

                                if(task.files && task.files.length > 0){
                                    $.each(task.files, function(index, file){
                                        params.append('tasks_files[]', file);
                                        tskFilesIndexs.push(tasksFileLastIndex);
                                        tasksFileLastIndex += 1;
                                    });
                                }

                                if(('prev_files_ids_remove' in task) && task.prev_files_ids_remove.length > 0){
                                    taskPrevFilesIdsToRemove = task.prev_files_ids_remove;
                                }

                                let tsk = JSON.stringify({
                                                            id: task.id,
                                                            title: task.title,
                                                            short_description: task.short_description,
                                                            long_description: task.long_description,
                                                            files_index: tskFilesIndexs,
                                                            files_ids_remove: taskPrevFilesIdsToRemove
                                                        });

                                tasksToSend.push(tsk);

                                params.append('tasks[]', tsk);
                            });
                        }else{
                            params.append('tasks', null);
                        }

                        if(vueObj.deletedTasksIds && vueObj.deletedTasksIds.length > 0){
                            params.append('tasks_deleted_ids', JSON.stringify(vueObj.deletedTasksIds));
                        }else{
                            params.append('tasks_deleted_ids', null);
                        }

                        if(vueObj.associatedLearningClubsIds && vueObj.associatedLearningClubsIds.length > 0){
                            params.append('clubs_ids_string', vueObj.associatedLearningClubsIds.join(','));
                        }

                        if(vueObj.isUpdateForm){
                            url = url + '/' + vueObj.event.id;
    
                            params.append('_method', 'PATCH');
                        }

                        vueObj.$http({ 
                                url: url, method: requestType, 
                                contentType: 'multipart/form-data', 
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                                if(vueObj.formType == 'new'){
                                    vueObj.forceUpdateWysiwyg();
                                }

                                if(response.data.event){
                                    vueObj.event.id = response.data.event.id;

                                    if(response.data.tasks){
                                        vueObj.setPreselectedTasks(response.data.tasks);
                                    }
                                }

                                vueObj.openPdfPreview(args.open_pdf_type);
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    openPdfPreview: function(pdfType){
                        const vueObj = this;

                        if(pdfType && vueObj.event && vueObj.event.id > 0){
                            let urlToPdf = '/events/'+vueObj.event.id+'/pdfs';

                            if(pdfType == 'syllabus'){
                                urlToPdf = urlToPdf + '/syllabus?vtp=show';
                            }else{
                                urlToPdf = urlToPdf + '/brochure?vtp=show';
                            }

                            var win = window.open(urlToPdf, '_previewSyllabus');
                            
                            if (win) {
                                //Browser has allowed it to be opened
                                win.focus();
                            } else {
                                //Browser has blocked it
                                alert('Please allow popups for this website');
                            }
                        }

                        setTimeout(function(){
                            if(vueObj.event.id){
                                window.location.href = "/admin/events/"+ vueObj.event.id +"/edit?notyst=success&notymsg=Success!";
                            }
                        });
                    },

                    forceUpdateWysiwyg: function(){
                        const vueObj = this;

                        const dateNow = moment().format("YYYYMMDD_HHmmSSSS");

                        vueObj.updateWysiwygFields.short_description = dateNow;
                        vueObj.updateWysiwygFields.description = dateNow;
                        vueObj.updateWysiwygFields.key_takeaways = dateNow;
                        vueObj.updateWysiwygFields.author_description = dateNow;

                        vueObj.$nextTick(() => {
                            vueObj.updateWysiwygFields.short_description = null;
                            vueObj.updateWysiwygFields.description = null;
                            vueObj.updateWysiwygFields.key_takeaways = null;
                            vueObj.updateWysiwygFields.author_description = null;
                        });
                    },

                    initCustomScroll: function(elem){        
                        setTimeout(function(){
                            if(elem){
                                $(elem).mCustomScrollbar({
                                    theme:"inset",
                                    axis: "y",
                                    autoDraggerLength: false,
                                    scrollbarPosition: "inside"
                                });
                            }
                        }, 2000);
                    },
                }
            });
        },
    };

    if($(masterCourseFormManager._containerFormId) && $(masterCourseFormManager._containerFormId).length > 0){
        masterCourseFormManager.initFormNewOrEditVueObj();
    } 

    $('.accordionFormMasterCourse').on({
        'down.zf.accordion': function(){
           const liActive = $(this).find(".accordion-item.is-active");

           const menuListTabs = $(".containerFormMasterCourse .containerGenericFormAccordion");

           if(menuListTabs){
               $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                   if(liActive && $(elem).data("tab") == liActive.data("name")){
                       $(elem).addClass("active");
                   }
               });
           }
        },
        'up.zf.accordion': function(){
            const liActive = $(this).find(".accordion-item.is-active");

            const menuListTabs = $(".containerFormMasterCourse .containerGenericFormAccordion");

            if(menuListTabs){
                $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                    if($(elem).hasClass("active")){
                        $(elem).removeClass("active");
                    }
                });
            }
        }
    });
});
