var managerFormProgrammeManageStudents = {
    _containerVueId: '#containerProgrammeManageStudentsId',
    _formVueObj: null,

    initVueForm: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            el: manager._containerVueId,

            data: {
                tokenCsrf: null,
                programmeId: 0,
                isLoading: 'no',
                isLoadingStudents: 'no',
                formData: { user_id: null, mentor_id: null },
                programmeMentors: null,
                programmeStudents: null,
                componentUserForceClear: 'no',
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                showLoadingListStudents: function(){
                    return this.isLoadingStudents && this.isLoadingStudents == 'yes';
                },

                showListProgrammeMentors: function(){
                    return this.programmeMentors && this.programmeMentors.length > 0;
                },

                showListStudents: function(){
                    return !this.showLoadingListStudents && this.programmeStudents && this.programmeStudents.length > 0;
                },

                showNoDataFoundStudents: function(){
                    return  !this.showLoadingListStudents && (!this.programmeStudents || this.programmeStudents.length <= 0);
                }
            },

            events: {
                'updatedListUsersSearchedByName': function(msg){
                    const vueObj = this;

                    if(msg && msg.length > 0){
                        vueObj.formData.user_id = msg[0].id;
                    }else{
                        vueObj.formData.user_id = null;
                    }
                }
            },

            ready: function(){
                const vueObj = this;
                
                const elemContainerForm = vueObj.$els.containerForm;

                if(elemContainerForm){
                    vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                    vueObj.programmeId = $(elemContainerForm).data('programme-id');
                }

                vueObj.getMentorsFromProgramme();
                vueObj.getStudentsFromProgramme();
            },

            methods: {
                getMentorsFromProgramme: function(){
                    const vueObj = this;

                    vueObj.$http({ 
                        url: '/json/programmes/'+ vueObj.programmeId +'/mentors', 
                        method: 'GET', 
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.programmeMentors = response.data.mentors;
                    }, function(response){
                        //error callback
                    });
                },

                setMentor: function(mentorId){
                    const vueObj = this;
                    
                    if(vueObj.formData.mentor_id && vueObj.formData.mentor_id == mentorId){
                        vueObj.formData.mentor_id = null;
                    }else{
                        vueObj.formData.mentor_id = mentorId;
                    }
                },

                clearForm: function(){
                    const vueObj = this;

                    vueObj.componentUserForceClear = 'yes';
                    vueObj.formData.mentor_id = null;

                    vueObj.$nextTick(() => {
                        vueObj.componentUserForceClear = 'no';
                    });
                },

                sendFormAddStudent: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    if(vueObj.formData.user_id && vueObj.formData.mentor_id){
                        let params = new FormData();
                        params.append('user_id', vueObj.formData.user_id);
                        params.append('mentor_id', vueObj.formData.mentor_id);

                        vueObj.$http({ url: '/json/programmes/'+ vueObj.programmeId +'/add_student', 
                                       method: 'POST', 
                                       data: params, 
                                       headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf 
                        }}).then(function(response){
                            
                            $(document).trigger("flashNotyAlert", [response.data.status, response.data.message]);

                            vueObj.clearForm();
                            vueObj.isLoading = 'no';
                            vueObj.getStudentsFromProgramme();
                        }, function(response){
                            //ERROR
                        });
                    }else{
                        vueObj.isLoading = 'no';
                        $(document).trigger("flashNotyAlert", ["error", "Required fields not filled."]);
                    }
                },

                sendFormRemoveStudent: function(userId){
                    const vueObj = this;

                    vueObj.isLoadingStudents = 'yes';

                    let params = new FormData();
                    params.append('user_id', userId);

                    vueObj.$http({ url: '/json/programmes/'+ vueObj.programmeId +'/remove_student', 
                                    method: 'POST', 
                                    data: params, 
                                    headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf 
                    }}).then(function(response){
                        
                        $(document).trigger("flashNotyAlert", [response.data.status, response.data.message]);

                        vueObj.getStudentsFromProgramme();
                    }, function(response){
                        //ERROR
                    });
                },

                getStudentsFromProgramme: function(){
                    const vueObj = this;

                    vueObj.isLoadingStudents = 'yes';

                    vueObj.$http({
                        url: '/json/programmes/' + vueObj.programmeId + '/students',
                        method: 'GET',
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.isLoadingStudents = 'no';

                        vueObj.programmeStudents = response.data.students;
                    }, function(response){
                        //error callback
                    });
                }
            }
        });
    }
};

$(function(){
    if($(managerFormProgrammeManageStudents._containerVueId) && $(managerFormProgrammeManageStudents._containerVueId).length > 0){
        managerFormProgrammeManageStudents.initVueForm();
    }
});

