$(function(){    
    var SessionsManager = {
        _containerFormId: '#containerFormsSigninSignUpId',
        _formVueObj: null,
        _containerVueSignupCompletedId: '#containerSignupCompletedId',
        _vueObjSignupCompleted: null,

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    user: { name: null, name_last: null, email: null, password: null, password_confirmation: null, timezone: null, university_affiliation: null, month_doctoral_study: null, years_work_experience: null, already_finished_study: false, remember_me: true, doctoral_study_start_month: null, doctoral_study_start_year: null, doctoral_study_status: null },
                    userAcceptedTerms: true,
                    formIsVisibleSignIn: 'yes',
                    formIsVisibleSignUp: 'no',
                    formIsVisibleResetPassword: 'no',
                    formIsVisibleSignUpComplete: 'no',
                    emailREGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    showErrorInvalid: { email: 'no' },
                    containerFormDivId: manager._containerFormId,
                    showMessageSignUpSuccessul: 'no',
                    userIdFromSignup: null,
                    isSendingFormVerificationCode: 'no',
                },

				ready: function(){
                    var vueObj = this;

                    vueObj.chooseFormToShow();
                },

                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.user.email) }
                    },
                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    },

                    showDateForDoctoralStatus: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status.length > 0;
                    },

                    showLabelDatesDoctorateForOnGoing: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "on_going";
                    },

                    showLabelDatesDoctorateForFinished: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "finished";
                    },

                    showLabelDatesDoctorateForNotStarted: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "not_started";
                    }
                },
                // methods
                methods: {
                    chooseFormToShow: function(){
                        var vueObj = this;

                        var action = $(vueObj.containerFormDivId).data("action");

                        switch(action){
                            case 'signin':
                                vueObj.showFormSignIn();
                                break;

                            case 'signup':
                                vueObj.showFormSignUp();
                                break;

                            case 'remember_password':
                                vueObj.showFormResetPassword();
                                break;

                            default:
                                vueObj.showFormSignIn();
                        }
                    },

                    showFormSignIn: function(){
                        var vueObj = this;

                        vueObj.formIsVisibleSignUp = 'no';
                        vueObj.formIsVisibleResetPassword = 'no';
                        vueObj.formIsVisibleSignIn = 'yes';
                        vueObj.formIsVisibleSignUpComplete = 'no';
                    },

                    showFormSignUp: function(){
                        var vueObj = this;

                        vueObj.formIsVisibleSignIn = 'no';
                        vueObj.formIsVisibleResetPassword = 'no';
                        vueObj.formIsVisibleSignUp = 'yes';
                        vueObj.formIsVisibleSignUpComplete = 'no';
                    },

                    showFormResetPassword: function(){
                        var vueObj = this;

                        vueObj.formIsVisibleSignIn = 'no';
                        vueObj.formIsVisibleSignUp = 'no';
                        vueObj.formIsVisibleResetPassword = 'yes';
                        vueObj.formIsVisibleSignUpComplete = 'no';
                    },

                    showFormSignUpComplete: function(){
                        var vueObj = this;

                        vueObj.formIsVisibleSignIn = 'no';
                        vueObj.formIsVisibleSignUp = 'no';
                        vueObj.formIsVisibleResetPassword = 'no';
                        vueObj.formIsVisibleSignUpComplete = 'yes';
                    },

                    sendForm: function () {
                        var vueObj = this;

                        if (vueObj.isValid){
                            var form = $(vueObj.containerFormDivId).find(".formSession");
                            
                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);

                            if(vueObj.formIsVisibleSignUp == 'no' || (vueObj.formIsVisibleSignUp == 'yes' && formData.get('g-recaptcha-response'))){
                                var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                                if(containerLoading && containerLoading.hasClass("hide")){
                                    containerLoading.removeClass("hide");
                                }
    
                                if(form && !form.hasClass("blur2px")){
                                    form.addClass("blur2px");
                                }
    
                                vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                    vueObj.manageRequestResponse(response.data);
                                }, function(response){
                                    vueObj.manageRequestResponse({status: "error", message: ''});
                                });
                            }else{
                                //Recaptcha needed
                            }


                        }else{
                        
                        }
                    },

                    manageRequestResponse: function(response){
                        var vueObj = this;

                        if(response.status == 'success' && vueObj.formIsVisibleSignIn === 'yes'){
                            if(response.prev_url){
                                window.location.replace(response.prev_url);
                            }else{
                                location.reload();
                            }
                        }else{
                            var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                            
                            if(containerLoading && !containerLoading.hasClass("hide")){
                                containerLoading.addClass("hide");
                            }

                            var form = $(vueObj.containerFormDivId).find(".formSession");

                            if(form && form.hasClass("blur2px")){
                                form.removeClass("blur2px");
                            }

                            if(response.status == 'success' && vueObj.formIsVisibleSignUp === 'yes'){
                                vueObj.showFormSignUpComplete();

                                if(response.user_id){
                                    vueObj.userIdFromSignup = response.user_id;
                                }
                            }else{
                                if(response.status == 'success'){
                                    var divRequestMessage = $(vueObj.containerFormDivId).find(".containerGenericFormMessage.success");
        
                                    divRequestMessage.removeClass("hide");
                                    vueObj.clearForm();
                                }else if((response.status == 'error' && response.message == 'email already registered') || (response.status == 'warning' && response.message == 'email not confirmed') || (response.status == 'warning' && response.message == 'email dont exist')){
                                    var divRequestMessage = $(vueObj.containerFormDivId).find(".containerGenericFormMessage.warning");
        
                                    divRequestMessage.removeClass("hide");
                                }else{
                                    var divRequestMessage = $(vueObj.containerFormDivId).find(".containerGenericFormMessage.error");
        
                                    divRequestMessage.removeClass("hide");
                                }
        
                                setTimeout(function(){ divRequestMessage.addClass("hide"); }, 5000);
                            }
                        }

                    },

                    clearForm: function(){
                        var vueObj = this;

                        vueObj.user.name = null;
                        vueObj.user.email = null;
                        vueObj.user.password = null;
                        vueObj.user.password_confirmation = null;
                        vueObj.user.timezone = "";
                        vueObj.user.university_affiliation = null;
                        vueObj.user.month_doctoral_study = null;
                        vueObj.user.years_work_experience = null;
                        vueObj.user.already_finished_study = false;
                        vueObj.user.accept_terms = true;
                        vueObj.user.doctoral_study_start_month = "";
                        vueObj.user.doctoral_study_start_year = null;
                        vueObj.user.doctoral_study_status = null;
                    },

                    sendFormActivationCode: function(){
                        const vueObj = this;

                        const containerInputsCode = vueObj.$els.containerInputsCode;
                        const inputsCodeValue = $(containerInputsCode).find('.inputPCode');

                        let code = "";

                        if(inputsCodeValue && inputsCodeValue.length > 0){
                            $.each(inputsCodeValue, function(index, input){
                                code = code + $(input).val();
                            });
                        }

                        if(code && code.length > 0){
                            vueObj.isSendingFormVerificationCode = 'yes';

                            code = parseInt(code);

                            if(!isNaN(code)){
                                const url = "/json/users/"+ vueObj.userIdFromSignup +"/confirm_email_activation_code";
                                let params = { code: code };

                                const tokenCsrf = $(containerInputsCode).data('token-csrf');
                                
                                vueObj.$http({ url: url, method: 'POST', data: params, headers: { 'X-CSRF-TOKEN': tokenCsrf } }).then(function(response){
                                    if(response.data.result && response.data.result == 'success'){
                                        const elemToScroll = vueObj.$els.sectionContainerActivationCode;

                                        if(elemToScroll){
                                            elemToScroll.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                                        }

                                        if(response.data.url_redirect){
                                            setTimeout(function(){ window.location.href = response.data.url_redirect }, 1000);
                                        }
                                    }else{
                                        vueObj.isSendingFormVerificationCode = 'no';

                                        $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                    }
                                }, function(response){
                                    vueObj.isSendingFormVerificationCode = 'no';

                                    $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                });
                            }
                        }
                    }
                }
            });
        },

        initVueObjSignupCompleted: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                el: manager._containerVueSignupCompletedId,

                data: {
                    secondsToRedirect: 11,
                    urlToRedirect: '/'
                },

				ready: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue && $(vueObj.$els.containerVue).data('url-redirect')){
                        let url = $(vueObj.$els.containerVue).data('url-redirect');

                        if(url && url.length > 0){
                            vueObj.urlToRedirect = url;
                        }
                    }

                    vueObj.redirectToHomepage();       
                },

                methods: {
                    redirectToHomepage: function(){
                        const vueObj = this;

                        vueObj.secondsToRedirect--;

                        if(vueObj.secondsToRedirect <= 0){
                            document.location.href= vueObj.urlToRedirect;
                        }else{
                            setTimeout(function(){
                                vueObj.redirectToHomepage();
                            },1000);
                        }
                    }
                }
            });
        },
    };

    if($(SessionsManager._containerFormId) && $(SessionsManager._containerFormId).length > 0){
        SessionsManager.initFormVueObj();
    }

    if($(SessionsManager._containerVueSignupCompletedId) && $(SessionsManager._containerVueSignupCompletedId).length > 0){
        SessionsManager.initVueObjSignupCompleted();
    }
});
