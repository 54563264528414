var managerIndexWebinars = {
    _containerVue: "#containerPageContentIndexWebinars",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                currentUserClubsIds: [],
                learningClubId: 0,
                
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if($(vueObj.$els.currentUserId)){
                        vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                    }
                    
                    if(vueObj.$els.clubId){
                        vueObj.learningClubId = parseInt($(vueObj.$els.clubId).data('id'));
                    }
 
                    if(vueObj.currentUserId && vueObj.currentUserId > 0){
                        vueObj.getCurrentUserLearningClubsIds();
                        vueObj.getCurrentUser();
                    }
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }

};

$(function(){
    if($(managerIndexWebinars._containerVue) && $(managerIndexWebinars._containerVue).length > 0){
        managerIndexWebinars.initVueObj();
    }
});
