$(function(){    
    var FeedbackManager = {
        _containerFormId: '#containerFormFeedbackId',
        _formVueObj: null,

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    feedbackId: 0,
                    feedbackCode: '',
                    feedback: { rating: 0, text_positives: null, text_improvements: null, text_improvements_experience: null, stage: null, user_concerns_about_journey: null, student_stage: null, text_club_user_needs: null },
                    testimony: { text: null, name: null, show_name: true },
                    formAlreadyAnswered: 'no',
                    containerFormDivId: manager._containerFormId,
                    feedbackType: 'event',
                    tokenCsrf: null,
                    formVersion: 'attended',
                    studentStages: [],
                    checkboxesReasonsDidntAttendedClubSessions: [ false, false, false, false, false, false ],
                    otherReasonDidntAttendedClubSessions: null,
                },

				ready: function(){
                    const vueObj = this;
                    
                    const dataIsAlreadyAnswed = $(vueObj.containerFormDivId).data('already-answered');

                    if(dataIsAlreadyAnswed && dataIsAlreadyAnswed === 'yes'){
                        vueObj.formAlreadyAnswered = 'yes';
                    }

                    if(vueObj.$els.containerVue){
                        vueObj.feedbackType = $(vueObj.$els.containerVue).data('feedback-type');
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.formVersion = $(vueObj.$els.containerVue).data('form-version');
                        vueObj.feedbackId = parseInt($(vueObj.$els.containerVue).data('feedback-id'));
                        vueObj.feedbackCode = $(vueObj.$els.containerVue).data('feedback-code');

                        vueObj.initStudentStages($(vueObj.$els.containerVue).data('student-stages'));
                    }
				},
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.contact.email) }
                    },

                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    },

                    isFeedbackFromEvent: function(){
                        return this.feedbackType && this.feedbackType == 'event';
                    },

                    isFeedbackFromClub: function(){
                        return this.feedbackType && this.feedbackType == 'learning_club';
                    },

                    isFormAttended: function(){
                        return !this.formVersion || (this.formVersion && this.formVersion == 'attended');
                    },

                    isFormNotAttended: function(){
                        return this.formVersion && this.formVersion == 'not_attended';
                    },

                    showOtherReasonDidntAttendClubSessions: function(){
                        return this.checkboxesReasonsDidntAttendedClubSessions && this.checkboxesReasonsDidntAttendedClubSessions[5] == true;
                    }
                },

                events: {
                    'updateTextPositives': function(msg){
                        this.feedback.text_positives = msg;
                    },

                    'updateTextImprovements': function(msg){
                        this.feedback.text_improvements = msg;
                    },

                    'updateTextImprovementsExperience': function(msg){
                        this.feedback.text_improvements_experience = msg;
                    },

                    'updateTextUserConcernsAboutJourney': function(msg){
                        this.feedback.user_concerns_about_journey = msg;
                    },
                
                    'updateStudentStage': function(msg){
                        this.feedback.student_stage = msg;
                    },
                
                    'updateTextTestimony': function(msg){
                        this.testimony.text = msg;
                    },
                
                    'updateTextClubUserNeeds': function(msg){
                        this.feedback.text_club_user_needs = msg;
                    },
                
                    'updateTextOtherReasonDidntAttendedClubSessions': function(msg){
                        this.otherReasonDidntAttendedClubSessions = msg;
                    },
                },

                // methods
                methods: {
                    initStudentStages: function(stringStages){
                        const vueObj = this;
                        
                        if(stringStages && stringStages.length > 0){
                            const splitByStages = stringStages.split("/n");

                            if(splitByStages.length > 0){
                                $.each(splitByStages, function(index, stage){
                                    const tempStage = stage.split(";");

                                    if(tempStage.length > 1){
                                        vueObj.studentStages.push({ value: parseInt(tempStage[0]), text: tempStage[1] });
                                    }
                                });
                            }
                        }
                    },

                    toggleCheckboxTestimonyShowName: function(){
                        const vueObj = this;

                        if(vueObj.testimony.show_name){
                            vueObj.testimony.show_name = false;
                        }else{
                            vueObj.testimony.show_name = true;
                        }
                    },

                    manageCheckboxReasonsForNotAttendingClubSessions(option){
                        const vueObj = this;

                        $.each(vueObj.checkboxesReasonsDidntAttendedClubSessions, function(index, value){
                            if(index != option){
                                vueObj.checkboxesReasonsDidntAttendedClubSessions[index] = false;
                            }
                        })
                    },

                    sendForm: function () {
                        var vueObj = this;

                        var form = $(vueObj.containerFormDivId).find("form");
                        
                        var url = form.attr("action");
                        var method = form.attr("method");
                        var formData = new FormData(form[0]);

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }

                        if(form && !form.hasClass("blur")){
                            form.addClass("blur");
                        }

                        vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                            vueObj.manageRequestResponse(response.data.status);
                        }, function(response){
                            vueObj.manageRequestResponse("error");
                        });
                    },

                    sendFormFeedbackClub: function(){
                        const vueObj = this;

                        vueObj.$nextTick(() => {
                            const elemContainerForm = vueObj.$els.containerVue;
    
                            if(elemContainerForm){
                                elemContainerForm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                            }
                        });

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && containerLoading.hasClass("hide")){
                            containerLoading.removeClass("hide");
                        }

                        const divFormInner = $(vueObj.$els.containerFormInner);

                        if(divFormInner){
                            divFormInner.hide();
                        }

                        let url = '/feedbacks/'+ vueObj.feedbackId +'/answer_feedback';

                        let params = new FormData();
                        params.append('_method', 'PATCH');
                        params.append('id', parseInt(vueObj.feedbackId));
                        params.append('code', vueObj.feedbackCode);
                        params.append('text_positives', vueObj.feedback.text_positives);
                        params.append('text_improvements', vueObj.feedback.text_improvements);
                        params.append('text_improvements_experience', vueObj.feedback.text_improvements_experience);
                        params.append('text_user_concerns_about_journey', vueObj.feedback.user_concerns_about_journey);
                        params.append('testimony', vueObj.testimony.text);
                        
                        if(vueObj.isFormAttended){
                            params.append('text_club_user_needs', vueObj.feedback.text_club_user_needs);
                        }
                        
                        if(vueObj.isFormNotAttended){
                            $.each(vueObj.checkboxesReasonsDidntAttendedClubSessions, function(index, value){
                                if(value == true){
                                    switch(index){
                                        case 0:
                                            params.append('text_club_user_needs', "I need sessions at a time more convenient to me.");
                                            break;
                                        case 1:
                                            params.append('text_club_user_needs', "I'd like to see a different session format.");
                                            break;
                                        case 2:
                                            params.append('text_club_user_needs', "I needed an option to connect with peers in written discussions in order to make the most of the limited time I had.");
                                            break;
                                        case 3:
                                            params.append('text_club_user_needs', "I'm no longer in this stage of my doctoral journey.");
                                            break;
                                        case 4:
                                            params.append('text_club_user_needs', "This club no longer interests me.");
                                            break;
                                        case 5:
                                            let tempText = "Other";

                                            if(vueObj.otherReasonDidntAttendedClubSessions && vueObj.otherReasonDidntAttendedClubSessions.length > 0){
                                                tempText = tempText + ": " +  vueObj.otherReasonDidntAttendedClubSessions;
                                            }

                                            params.append('text_club_user_needs', tempText);
                                            break;
                                    }

                                    return false;
                                }
                            })
                        }

                        if(vueObj.feedback.student_stage){
                            params.append('student_stage_id', parseInt(vueObj.feedback.student_stage));
                        }

                        if(vueObj.testimony.show_name){
                            params.append('show_testimony_name', 'yes');
                        }

                        vueObj.$http({ 
                            url: url, method: 'POST',
                            responseType: 'json', 
                            data: params,
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            vueObj.manageRequestResponse(response.data.status);
                        }, function(response){
                            vueObj.manageRequestResponse("error");
                        });
                    },

                    manageRequestResponse: function(status){
                        var vueObj = this;

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form && form.hasClass("blur")){
                            form.removeClass("blur");
                        }

                        if(status && status == 'success'){
                            vueObj.formAlreadyAnswered = 'yes';
                        }else{
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.error");

                            divRequestMessage.removeClass("hide");
                        }
                    }
                }
            });
        },
    };

    if($(FeedbackManager._containerFormId) && $(FeedbackManager._containerFormId).length > 0){
        FeedbackManager.initFormVueObj();
    }
});
