var managerSearchEventsWithUserQuestionnaire = {
    _containerVue: "#containerSearchEventsWithUserQuestionnaireId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                currentUserEventsIdsJoined: [],
                currentUserClubsIdsJoined: [],
                currentUserMembershipsActive: [],
                events: null,
                isLoadingEvents: 'yes',
                tokenCsrf: null,
                containerUserQuestionnaireIsOpen: 'no',
                doctoralJourneyStages: null,
                userQuestionnaire: { doctoralJourneyGroupIds: [], price: 'tier_1', location: 'all' },
                userQuestionnaireFormStepActive: 1,
                componentPage: 'index',
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoadingEvents: function(){
                    return this.isLoadingEvents && this.isLoadingEvents == 'yes';
                },

                hasEvents: function(){
                    return this.events && this.events.length > 0;
                },

                showEvents: function(){
                    return !this.showLoadingEvents && this.hasEvents;
                },

                showContainerUserQuestionnaire: function(){
                    return this.containerUserQuestionnaireIsOpen && this.containerUserQuestionnaireIsOpen == 'yes';
                },

                showLinkStartNow: function(){
                    return !this.showContainerUserQuestionnaire;
                },

                hasDoctoralStages: function(){
                    return this.doctoralJourneyStages && this.doctoralJourneyStages.length > 0;
                },

                isSelectedPriceTier1: function(){
                    return this.userQuestionnaire.price && this.userQuestionnaire.price == 'tier_1';
                },

                isSelectedPriceTier2: function(){
                    return this.userQuestionnaire.price && this.userQuestionnaire.price == 'tier_2';
                },

                isSelectedPriceTier3: function(){
                    return this.userQuestionnaire.price && this.userQuestionnaire.price == 'tier_3';
                },

                isSelectedLocationAll: function(){
                    return this.userQuestionnaire.location && this.userQuestionnaire.location == 'all';
                },

                isSelectedLocationOnline: function(){
                    return this.userQuestionnaire.location && this.userQuestionnaire.location == 'online';
                },

                isSelectedLocationInPerson: function(){
                    return this.userQuestionnaire.location && this.userQuestionnaire.location == 'in_person';
                },

                isActiveUserQuestionnaireStepOne: function(){
                    return this.userQuestionnaireFormStepActive && this.userQuestionnaireFormStepActive == 1;
                },

                isActiveUserQuestionnaireStepTwo: function(){
                    return this.userQuestionnaireFormStepActive && this.userQuestionnaireFormStepActive == 2;
                },
                
                isActiveUserQuestionnaireStepThree: function(){
                    return this.userQuestionnaireFormStepActive && this.userQuestionnaireFormStepActive == 3;
                },

                showBtnNextOnUserQuestionnaire: function(){
                    return this.isActiveUserQuestionnaireStepOne || this.isActiveUserQuestionnaireStepTwo;
                },

                showBtnPreviousOnUserQuestionnaire: function(){
                    return this.isActiveUserQuestionnaireStepTwo || this.isActiveUserQuestionnaireStepThree;
                },

                showBtnSendOnUserQuestionnaire: function(){
                    return this.isActiveUserQuestionnaireStepThree;
                },

                isPageDashboard: function(){
                    return this.componentPage && this.componentPage == 'dashboard';
                },

                showButtonSeeAllEvents: function(){
                    return this.showEvents && this.isPageDashboard;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerVue).data('current-user-id'));
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data("token-csrf");
                        vueObj.componentPage = $(vueObj.$els.containerVue).data("page");
                    }

                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }

                    vueObj.getDoctoralJourneyStages();
                    
                    vueObj.getEvents();
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;
                        vueObj.currentUserMembershipsActive = response.data.memberships_active;
                    }, function(response){
                        //ERROR
                    });
                },

                getEvents: function(){
                    const vueObj = this;

                    vueObj.isLoadingEvents = 'yes';

                    const params = { filter: 'next', is_published: 'yes', with_learning_clubs: 'yes', with_event_participants: 'yes', with_tutors: 'yes', nr_results: 5 };

                    vueObj.$http({ url: '/json/events', method: 'GET', params: params }).then(function(response){
                        vueObj.events = response.data.events;

                        vueObj.isLoadingEvents = 'no';

                        vueObj.manageCarousel();
                    });
                },
                
                getDoctoralJourneyStages: function(){
                    const vueObj = this;

                    vueObj.$http({ 
                        url: "/json/dynamic_lists/doctoral_journey_groups", 
                        method: 'GET', 
                        responseType: 'json',
                    }).then(function(response){
                        vueObj.doctoralJourneyStages = response.data.groups;
                    }, function(response){
                        //error callback
                    });
                },

                manageCarousel: function(){
                    const component = this;
        
                    component.$nextTick(() => {
                        if(component.$els.listEvents){
                            const elem = $(component.$els.listEvents);
        
                            if(component.events){
                                if(!elem.hasClass('owl-carousel')){
                                    elem.addClass("owl-carousel");
        
                                    elem.owlCarousel({
                                        items: 3,
                                        loop:false,
                                        margin:0,
                                        autoplay: false,
                                        dots: true,
                                        mouseDrag: true,
                                        autoHeight: true,
                                        responsive:{
                                            0:{
                                                items:1,
                                                center: true
                                            },
                                            650:{
                                                items: 2
                                            },
                                            1000:{
                                                items:3
                                            }
                                        }
                                    });
                                    
                                    setTimeout(function(){ elem.trigger('refresh.owl.carousel'); }, 1500);
                                }else{
                                    elem.trigger('refresh.owl.carousel');
                                }
                            }else{
                                component.removeCarousel(elem);
                            }
                        }
                    });
                },
        
                removeCarousel: function(divCarousel){
                    if(divCarousel && divCarousel.hasClass('owl-carousel')){
                        divCarousel.trigger('destroy.owl.carousel');
                        divCarousel.removeClass('owl-carousel');
                    }
                },

                toggleContainerUserQuestionnaireVisibility: function(){
                    const vueObj = this;

                    if(vueObj.showContainerUserQuestionnaire){
                        vueObj.containerUserQuestionnaireIsOpen = 'no';
                    }else{
                        vueObj.containerUserQuestionnaireIsOpen = 'yes';
                    }
                },

                doctoralJourneyStageIsSelected: function(stageId){
                    const vueObj = this;

                    if($.inArray(stageId, vueObj.userQuestionnaire.doctoralJourneyGroupIds) > -1){
                        return true;
                    }
        
                    return false;
                },

                addOrRemoveDoctoralStageFromSelected: function(stageId){
                    const vueObj = this;

                    const indexStageIdIdInSelectedOptionsArray = $.inArray(stageId, vueObj.userQuestionnaire.doctoralJourneyGroupIds);

                    if(indexStageIdIdInSelectedOptionsArray > -1){
                        vueObj.userQuestionnaire.doctoralJourneyGroupIds.splice(indexStageIdIdInSelectedOptionsArray, 1);
                    }else{
                        vueObj.userQuestionnaire.doctoralJourneyGroupIds.push(stageId);
                    }
                },

                setPrice: function(priceTier){
                    this.userQuestionnaire.price = priceTier;
                },

                setLocation: function(location){
                    this.userQuestionnaire.location = location;
                },

                moveToNextStepOnForm: function(){
                    const vueObj = this;

                    if(vueObj.userQuestionnaireFormStepActive < 3){
                        vueObj.userQuestionnaireFormStepActive = vueObj.userQuestionnaireFormStepActive + 1;
                    }
                },

                moveToPreviousStepOnForm: function(){
                    const vueObj = this;

                    if(vueObj.userQuestionnaireFormStepActive >= 2){
                        vueObj.userQuestionnaireFormStepActive = vueObj.userQuestionnaireFormStepActive - 1;
                    }
                },

                sendToPageResults: function(){
                    const vueObj = this;

                    let url = "/my_journey_path?";

                    if(vueObj.userQuestionnaire.doctoralJourneyGroupIds && vueObj.userQuestionnaire.doctoralJourneyGroupIds.length > 0){
                        url = url + "djg=" + vueObj.userQuestionnaire.doctoralJourneyGroupIds.join(",") + "&";
                    }

                    if(vueObj.userQuestionnaire.price){
                        url = url + "p=" + vueObj.userQuestionnaire.price + "&";
                    }

                    if(vueObj.userQuestionnaire.location){
                        url = url + "l=" + vueObj.userQuestionnaire.location;
                    }

                    //window.location.href = url;
                    window.open(url, '_blank').focus();
                }
            }
        });
    }
};

$(function(){
    if($(managerSearchEventsWithUserQuestionnaire._containerVue) && $(managerSearchEventsWithUserQuestionnaire._containerVue).length > 0){
        managerSearchEventsWithUserQuestionnaire.initVueObj();
    }
});
