$(function(){    
    var ContactUsManager = {
        _containerFormId: '#containerFormContactUsId',
        _formVueObj: null,
        _containerPageContactCompleteId: "#contactUsFinishedId",
        _vueObjPageContactComplete: null,

        initSubjectSelect: function(){
            var manager = this;

            var subjectToSelectId = $("#containerFormContactUsId").data('subject-id');

            if(subjectToSelectId && subjectToSelectId > 0){
                var subjectToSelect = "subject_" + subjectToSelectId;
                manager._formVueObj.changeSubjectSelected(subjectToSelect);
            }
        },

        checkIfUserIsLoggedIn: function(){
            var manager = this;

            var divUserLoggedIn = $(manager._containerFormId).find(".userIsLoggedIn");

            if(divUserLoggedIn && divUserLoggedIn.length > 0){
                manager._formVueObj.updateStatusUserIsLoggedIn("yes");
            }else{
                manager._formVueObj.updateStatusUserIsLoggedIn("no");
            }
        },

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    contact: { name: null, email: null, subject: null, text: null, accept_terms: true },
                    documentDetails: { name: null, file: null },
                    test : null,
                    emailREGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    showErrorInvalid: { email: 'no' },
                    userIsLoggedIn: 'no',
                    containerFormDivId: manager._containerFormId
                },
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.contact.email) }
                    },
                    isValid: function () {
                        var vueObj = this;

                        if(vueObj.userIsLoggedIn == "no"){
                            var validation = this.validation
                            return Object.keys(validation).every(function (key){
                                return validation[key];
                            })
                        }

                        return true;
                    }
                },
                // methods
                methods: {
                    sendForm: function () {
                        var vueObj = this;

                        if (vueObj.isValid){
                            var form = $(vueObj.containerFormDivId).find("form");
                            
                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);

                            if(formData.get('g-recaptcha-response')){
                                var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                                if(containerLoading && containerLoading.hasClass("hide")){
                                    containerLoading.removeClass("hide");
                                }

                                if(form && !form.hasClass("blur")){
                                    form.addClass("blur");
                                }

                                if(vueObj.documentDetails.file){
                                    formData.delete('document');
                                    formData.append('document', vueObj.documentDetails.file);
                                }

                                vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                    vueObj.manageRequestResponse(response.data.status);
                                }, function(response){
                                    vueObj.manageRequestResponse("error");
                                });
                            }else{
                                //ReCaptcha needed!
                            }

                        }else{
                        
                        }
                    },

                    manageRequestResponse: function(status){
                        var vueObj = this;

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form && form.hasClass("blur")){
                            form.removeClass("blur");
                        }

                        if(status && status == 'success'){
                            /*var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.success");

                            divRequestMessage.removeClass("hide");
                            vueObj.clearForm();*/

                            document.location.href = "/contact_us/complete";
                        }else{
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.error");

                            divRequestMessage.removeClass("hide");
                        }
                    },

                    clearForm: function(){
                        var vueObj = this;
                        var form = $(vueObj.containerFormDivId).find("form");

                        vueObj.contact.name = null;
                        vueObj.contact.email = null;
                        vueObj.contact.subject = null;
                        vueObj.contact.text = null;

                        vueObj.documentDetails.name = null;
                        vueObj.documentDetails.file = null;
                        
                        var inputDoc = $("#inputDocumentId");
                        if(inputDoc){
                            inputDoc.replaceWith(inputDoc.val('').clone(true));
                        }
                    },

                    manageChangeInputDocument: function(inputId){
                        var vueObj = this;                        
                        var inputValue = $(inputId).val();

                        vueObj.documentDetails.name = null;
                        vueObj.documentDetails.file = null;

                        if(inputValue){
                            var arrayInputValue = inputValue.split('\\');

                            if(arrayInputValue && arrayInputValue.length > 0){
                                vueObj.documentDetails.name = arrayInputValue[arrayInputValue.length - 1];
                            }
                        }
                    },

                    changeSubjectSelected: function(subjectToSelect){
                        var vueObj = this;

                        vueObj.contact.subject = subjectToSelect;
                    },

                    updateStatusUserIsLoggedIn: function(isLoggedIn){
                        var vueObj = this;

                        if(isLoggedIn == 'yes'){
                            vueObj.userIsLoggedIn = "yes";
                        }else{
                            vueObj.userIsLoggedIn = "no";
                        }
                    }
                }
            });
        },

        initVuePageContactComplete: function(){
            var manager = this;

            manager._vueObjPageContactComplete = new Vue({
                // element to mount to
                el: manager._containerPageContactCompleteId,
                // initial data
                data: {
                    secondsToRedirect: 10
                },
                // computed property for form validation state
                computed: {
                },
                // methods
                methods: {
                }
            });
        }
    };

    if($(ContactUsManager._containerFormId) && $(ContactUsManager._containerFormId).length > 0){
        ContactUsManager.initFormVueObj();
        ContactUsManager.initSubjectSelect();
        ContactUsManager.checkIfUserIsLoggedIn();
    }

    if($(ContactUsManager._containerPageContactCompleteId) && $(ContactUsManager._containerPageContactCompleteId).length > 0){
        ContactUsManager.initVuePageContactComplete();
    }

    $(".divFormFileUpload .divFileDrop").on('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
    });

    $(".divFormFileUpload .divFileDrop").on('dragenter', function (e) {
        e.stopPropagation();
        e.preventDefault();
    });

    $(".divFormFileUpload .divFileDrop").on('drop', function (e) {
        e.stopPropagation();
        e.preventDefault();

        if(e.originalEvent.dataTransfer.files && e.originalEvent.dataTransfer.files.length > 0){
            var tempFile = e.originalEvent.dataTransfer.files[0];
            var fileExt = tempFile.name.split('.').pop();

            if(tempFile && ((tempFile.type && tempFile.type === 'application/pdf') || ( fileExt && (fileExt === 'doc' || fileExt === 'docx')))){
                ContactUsManager._formVueObj.documentDetails.file = tempFile;
                ContactUsManager._formVueObj.documentDetails.name = tempFile.name;
            }
        }
    });
});
