var svgManager = {
  initInlineSVG: function(divClass){
    $(divClass).each(function(){
      let $img = $(this);
      let imgID = $img.attr('id');
      let imgClass = $img.attr('class');
      let imgURL = $img.attr('src');
      let extension = "";

      if(imgURL){
        extension = imgURL.split(/[#?]/)[0].split('.').pop().trim();
      }

      if(extension && extension == 'svg'){
        $.get(imgURL, function(data) {
          let $svg = $(data).find('svg');
          if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
          }
          if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
          }
          $svg = $svg.removeAttr('xmlns:a');
          $img.replaceWith($svg);
        });
      }
    });
  }
};

function onChangeCheckboxAcceptTermsJoin(checkbox, buttonJoinId){
  const buttonJoin = $("#" + buttonJoinId);

  if(buttonJoin){
    if(checkbox.checked){
      if(buttonJoin.hasClass('disabled')){
        buttonJoin.removeClass('disabled');
      }
    }else{
      if(!buttonJoin.hasClass('disabled')){
        buttonJoin.addClass('disabled');
      }
    }
  }
}

$(function(){
  $(document).foundation();

  $(".custScroll").mCustomScrollbar({
    theme:"inset-2-dark",
    scrollButtons:{ enable: true }
  });

  if($(".containerBarStickyTopAfterScroll") && $(".containerBarStickyTopAfterScroll").length > 0){
    window.onscroll = function() {
      const elemShowAfter = $(".showBarStickyAfter");

      if(elemShowAfter){
        const pointToShow = elemShowAfter.offset().top

        if(pointToShow && $(window).scrollTop() > pointToShow){
          $(".containerBarStickyTopAfterScroll").show();
        }else{
          $(".containerBarStickyTopAfterScroll").hide();
        }
      }
    };
  }

  if($(".pageFixedHeaders") && $(".pageFixedHeaders").length > 0){
    $(window).scroll(function(e) {
      const distanceToTopOfPage = $(window).scrollTop();

      if(distanceToTopOfPage && distanceToTopOfPage >= 100){
        $('html').addClass("showPageVersionAfterScroll");
      }else{
        $('html').removeClass("showPageVersionAfterScroll");
      }
    });
  }

  $(".tabsGroupActivities .tabs-title a").on("click", function(e){
    e.preventDefault();
    e.stopPropagation();

    var clickedLink = $(this);
    var linkJoin = $(".tabsGroupActivities .tabs-title .linkJoin");

    if(clickedLink.attr("href") != "#join"){
      var linkGroup = $(".container-group.s2g").data("url");
      clickedLink.blur();

      window.open(linkGroup, "_blank");
    }
  });

  /* ******* EXPAND DIV ******* */
  if($(".div-expand-container").length > 0){
    divExpandManager.check_size_div_text();
  }

  $(document).on('click', ".open-expand-div", function(e){
    e.stopPropagation();
    e.preventDefault();
    divExpandManager.show_more(this);
  });

  $(document).on('click', ".close-expand-div", function(e){
    e.stopPropagation();
    e.preventDefault();
    divExpandManager.hide_more(this);
  });

  /* ***** SVG ***** */
  if($('.inline-svg') && $('.inline-svg').length > 0){
    svgManager.initInlineSVG('.inline-svg');
  }

  /* ***** FORM ***** */
  $(document).on('click', ".containerMessageAfterRequest .img.x", function(e){
    e.stopPropagation();
    e.preventDefault();
    
    var divParent = $(this).parent();

    if(divParent && !divParent.hasClass("hide")){
      divParent.addClass("hide");
    }

  });

  if($(".hideUntilPageLoads") && $(".hideUntilPageLoads").length > 0){
    $(".hideUntilPageLoads").each(function(){
      var div = $(this);

      div.removeClass("hideUntilPageLoads");
    });
  }

  var globalFunctionalitiesManager = {
    _googleAutocomplete: null,

    _dateTimePickerOptions: { language: 'en', minDate: new Date(),
                              dateFormat: 'yyyy-mm-dd', timepicker: true,
                              timeFormat: 'hh:ii' },

    _datePickerOptions: { language: 'en', minDate: new Date(),
                          dateFormat: 'yyyy-mm-dd', timepicker: false },

    _birthdayPickerOptions: { language: 'en',
                              dateFormat: 'yyyy-mm-dd', timepicker: false },

    initGoogleAutocomplete: function(){
      var manager = this;

      var inputLocation = $('input.googleAutocomplete');

      //console.log(inputLocation);

      if(inputLocation && inputLocation.length > 0){
        var options = {
                        types: ['(cities)'] 
                      };
       // manager._googleAutocomplete = new google.maps.places.Autocomplete(inputLocation, options);
      }
    },

    initTinymce: function(){
      var textareasList = [
                           $('.formResidence textarea#intro'), 
                           $('.formResidence textarea#summary'), 
                           $('.formResidence textarea#program'),
                           $('.formAdminEvent textarea#intro'), 
                           $('.formAdminEvent textarea#summary'), 
                           $('.formAdminEvent textarea#program'),
                           $('.formTutor textarea#description'),
                           $('.formReview textarea#text'),
                           $('.formUserEdit textarea#about'),
                           $('.formUserEdit textarea#about_tutor'),
                           $('.containerEventConfigurationsVue textarea.series_instructions'),
                           $('.containerEventConfigurationsVue textarea.course_instructions'),
                           $('.containerEventConfigurationsVue textarea.workshop_instructions'),
                          ];

      if(textareasList && textareasList.length > 0){
          $.each(textareasList, function(index, textarea){
              if(textarea && textarea.length > 0){
                  tinymce.init({
                  selector: textarea.selector,
                  height: 150,
                  menubar: false,
                  branding: false,
                  resize: false,
                  plugins: [
                      'advlist autolink lists link charmap print preview textcolor',
                      'searchreplace visualblocks code fullscreen image'
                  ],
                  toolbar: 'insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                  });
              }
          });
      }
    },

    initDateTimePickers: function(){
      var manager = this;

      var listInputs = $(".dateTimePicker");

      if(listInputs && listInputs.length > 0){
        $.each(listInputs, function(index, input){
          var newDatepicker = $(input).datepicker(manager._dateTimePickerOptions).data('datepicker');

          if($(input).val()){
            var inputDate = $(input).val();

            var t = inputDate.split(/[- :]/);

            if(t.length < 6){
              t.push("00");
            }
            // Apply each element to the Date function
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);

            var selectedDate = new Date(d);

            newDatepicker.selectDate(selectedDate);
          }
        });
      }
    },

    initDatePickers: function(){
      var manager = this;

      var listInputs = $(".dateOnlyPicker");

      if(listInputs && listInputs.length > 0){
        $.each(listInputs, function(index, input){
          var newDatepicker = $(input).datepicker(manager._datePickerOptions).data('datepicker');

          if($(input).val()){
            var inputDate = $(input).val();

            var t = inputDate.split(/[- :]/);

            // Apply each element to the Date function
            var d = new Date(t[0], t[1]-1, t[2]);

            var selectedDate = new Date(d);

            newDatepicker.selectDate(selectedDate);
          }
        });
      }
    },

    initBirthdayDatePickers: function(){
      var manager = this;

      var listInputs = $(".birthdayPicker");

      if(listInputs && listInputs.length > 0){
        $.each(listInputs, function(index, input){
          var newDatepicker = $(input).datepicker(manager._birthdayPickerOptions).data('datepicker');

          if($(input).val()){
            var inputDate = $(input).val();

            var t = inputDate.split(/[- :]/);

            // Apply each element to the Date function
            var d = new Date(t[0], t[1]-1, t[2]);

            var selectedDate = new Date(d);

            newDatepicker.selectDate(selectedDate);
          }
        });
      }
    },

    addStartDateFormEvent: function(){
      var manager = this;

      var containerDates = $(".containerFormEventDates");
      var containerNewInput = '<div class="small-12 medium-12 large-12 columns noPadding"><div class="small-12 medium-6 large-6 columns noPaddingLeft form-group"><input placeholder="YYYY-MM-DD HH:MM" class="dateTimePicker" autocomplete="off" name="date[]" type="text"></div></div>';
  
      if(containerDates && containerDates.length > 0){
          containerDates.append(containerNewInput);
          manager.initDateTimePickers();
      }
    },

    removeStartDateFormEvent: function(btnClicked){
      var parentDiv = $(btnClicked).parents(".containerFormDate");

      if(parentDiv && parentDiv.length > 0){
        parentDiv.remove();
      }
    },

    manageClickFormGoldV1Label: function(labelClicked){
      var manager = this;

      var containerParent = labelClicked.parent();

      if(containerParent && containerParent.length > 0){
        var input = containerParent.find('.input');

        if(input && input.length == 1){
          if(input.is("select")){
            input.focus();
            setTimeout(function(){ input.trigger( "click" ); }, 1000);
          }else{
            input.focus();
          }
        }
      }
    },

    onWindowResize: function(){
      const manager = this;

      manager.initCarousels();
    },

    initCarousels: function(){
      const manager = this;

      let divListReviewsPage = $(".containerListReviewsPage");
      
      if(divListReviewsPage && divListReviewsPage.length > 0){
        divListReviewsPage.each(function(){
          if($(window).width() <= 700){
            manager.initCarouselOneItemPerPage($(this));
          }else{
            manager.removeCarousel($(this));
          }
        });
      }
    },

    removeCarousel: function(divCarousel){
      if(divCarousel && divCarousel.hasClass('owl-carousel')){
          divCarousel.trigger('destroy.owl.carousel');
          divCarousel.removeClass('owl-carousel');
      }
    },

    initCarouselOneItemPerPage: function(divCarousel){
      if(divCarousel){
        if(!divCarousel.hasClass("owl-carousel")){
          divCarousel.addClass("owl-carousel");

          divCarousel.owlCarousel({
              items:1,
              loop:true,
              margin:0,
              autoplay: false,
              dots: true,
              nav: false,
              mouseDrag: true,
              autoHeight: true
          });
        }
      }
    }
    
  };

  globalFunctionalitiesManager.initGoogleAutocomplete();
  globalFunctionalitiesManager.initTinymce();
  globalFunctionalitiesManager.initDateTimePickers();
  globalFunctionalitiesManager.initDatePickers();
  globalFunctionalitiesManager.initBirthdayDatePickers();
  globalFunctionalitiesManager.initCarousels();

  $(window).resize(function(){
    globalFunctionalitiesManager.onWindowResize();

    divExpandManager.check_size_div_text();
  });

  $(".containerFormEventDates .btnAddDate").on('click', function(e){
    globalFunctionalitiesManager.addStartDateFormEvent();
  });

  $(".containerFormEventDates .btnDeleteDate").on('click', function(e){
    globalFunctionalitiesManager.removeStartDateFormEvent(this);
  });

  $(document).on("change", ".inputChooseImage" , function(e) {
    e.stopPropagation();

    var input = this;
    var element = $(input).parents('.divUploadUserImage').find('.inputChooseImage-preview');

    if (input.files && input.files[0]){
      var reader = new FileReader();
      reader.onload = function (e) { element.attr('src', e.target.result); }
      reader.readAsDataURL(input.files[0]);
  
      if(!element.is(":visible")){
        element.show();
      }
    }
  });

  $(document).on('click', '.linkScrollToElem', function(e){
    e.preventDefault();
    e.stopPropagation();

    var elemIdToScroll = $(this).attr('href');

    if(elemIdToScroll && $(elemIdToScroll).length > 0){
      $('html,body').animate({
        scrollTop: $(elemIdToScroll).offset().top
      }, 2000);
    }
  });

  $(document).on('click', '.containerGenericFormAccordion .listFormMenu li', function(e){
    e.stopPropagation();
    e.preventDefault();

    const tabClassToGo = $(this).data('tab');

    if(tabClassToGo){
      let accordion = null;
      let accordionType = 'master_course';

      if($(".accordionFormMasterCourse") && $(".accordionFormMasterCourse").length > 0){
        accordion = $(".accordionFormMasterCourse");
      }else if($(".accordionFormSeries") && $(".accordionFormSeries").length > 0){
        accordion = $(".accordionFormSeries");
        accordionType = 'events_serie';
      }else if($(".accordionFormSharedV1") && $(".accordionFormSharedV1").length > 0){
        accordion = $(".accordionFormSharedV1");
        accordionType = 'form_shared_v1';
      }

      if(accordion){
        const tabToOpen = accordion.find("." + tabClassToGo);
        const tabContent = tabToOpen.find(".accordion-content");

        if(tabContent){
          if(!tabToOpen.hasClass("is-active")){
            accordion.foundation('toggle', tabContent);
          }

          setTimeout(function(){
            const tabToScroll = accordion.find("." + tabClassToGo);

            if(tabToScroll){
              tabToScroll[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
            
          }, 300);
          
        }
      }
    }
  });

  $(".onCheckChangeSendForm").on('change',function(){
    let form = $(this).parents('form');

    if(form){
      $(form).submit();
    }
  });

  $('.containerBtnJoinHideAndLoading .link').on('click', function(e){
    e.stopPropagation();
    
    const parentDiv = $(this).parents('.containerBtnJoinHideAndLoading');

    if(parentDiv){
      const divLoading = parentDiv.find('.loading');

      if(divLoading && divLoading.hasClass('hide')){
        divLoading.removeClass('hide');
      }

      if(!$(this).hasClass("hide")){
        $(this).addClass("hide");
      }
    }
  });

  $(".containerSharedMenuSide .linkOption").hover(
    function(){
      const divHovered = $(this);
      const divText = divHovered.find(".divText");

      if(divText){
        divText.show();
      }
    }, function() {
      const divHovered = $(this);
      const divText = divHovered.find(".divText");

      if(divText){
        divText.hide();
      }
    }
  );

  $(".containerMenuSideVertical li a").hover(
    function(){
      const divHovered = $(this);
      const divText = divHovered.find(".divText");

      if(divText){
        divText.show();
      }
    }, function() {
      const divHovered = $(this);
      const divText = divHovered.find(".divText");

      if(divText){
        divText.hide();
      }
    }
  );

  const $inp = $(".inputPCode");

  $inp.on({
    paste(ev) { // Handle Pasting
    
      const clip = ev.originalEvent.clipboardData.getData('text').trim();
      // Allow numbers only
      if (!/\d{6}/.test(clip)) return ev.preventDefault(); // Invalid. Exit here
      // Split string to Array or characters
      const s = [...clip];
      // Populate inputs. Focus last input.
      $inp.val(i => s[i]).eq(5).focus(); 
    },
    input(ev) { // Handle typing
      
      const i = $inp.index(this);
      if (this.value) $inp.eq(i + 1).focus();
    },
    keydown(ev) { // Handle Deleting
      
      const i = $inp.index(this);
      if (!this.value && ev.key === "Backspace" && i) $inp.eq(i - 1).focus();
    }
  });

  $(document).on('click', '.linkSendFormOnClick', function(e){
    e.stopPropagation();
    e.preventDefault();

    const elem = $(this);

    if(!elem.hasClass('disabled')){
      const form = elem.parents("form.formAddCurrentUserAsParticipant");
  
      if(form){
        form.submit();
      }
    }
  });

  $('.containerFormWebinarTopicsVote #checkbox_topic_other_id').click(function() {
    $('.containerFormWebinarTopicsVote .divFormInput.topicOther')[this.checked ? "show" : "hide"]();

    if(this.checked){
      $('.containerFormWebinarTopicsVote .inputTopicOther').focus();
    }
  });

  $(document).on('change', ".formUploadImageIntervention input[type='file']", function(e){
    const input = $(this);
    const elemForm = input.parents("form");

    if(elemForm){
      elemForm.submit();
    }
  });
});
