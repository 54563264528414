var learningClubsListEventsComingUpManager = {
    _containerVue: "#containerVueLearningClubShowUpcomingEventsId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                clubId: 0
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    const elemContainerElem = vueObj.$els.containerElem;

                    if(elemContainerElem){
                        let clubId = $(elemContainerElem).data('learning-club-id');
                        
                        if(clubId){
                            vueObj.clubId = parseInt(clubId);
                        }
                    }
                }
            }
        });
    }

};

$(function(){
    if($(learningClubsListEventsComingUpManager._containerVue) && $(learningClubsListEventsComingUpManager._containerVue).length > 0){
        learningClubsListEventsComingUpManager.initVueObj();
    }
});
