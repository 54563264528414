var managerIndexLearningClubs = {
    _containerVue: "#containerPageContentIndexLearningClubs",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUserAccPremium: 'no',
                currentUser: null,
                currentUserClubsIds: []
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if($(vueObj.$els.currentUserId)){
                        vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                    }

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserAccPremium = 'yes';
                        }
                    }
 
                    if(vueObj.currentUserId && vueObj.currentUserId > 0){
                        vueObj.getCurrentUserLearningClubsIds();
                        vueObj.getCurrentUser();
                    }
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }
};

$(function(){
    if($(managerIndexLearningClubs._containerVue) && $(managerIndexLearningClubs._containerVue).length > 0){
        managerIndexLearningClubs.initVueObj();
    }
});
