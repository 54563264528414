var reviewComponentPreviewManager = {
    _containerCarousel: ".containerReviewsComponentPreview .divListSlides .carouselReviewsV1",

    showReviewsLinksReadMore: function(){
        const divsToInit = $(".containerReviewsComponentPreview .containerReviewV1");

        if(divsToInit.length > 0){
            divsToInit.each(function() {
                const divLinksToExpand = $(this).find(".divLinkReadMore");

                if($(this).find(".inner-div-text").height() > $(this).find(".outter-div-text").height()){
                    if(divLinksToExpand && divLinksToExpand.hasClass("hide")){
                        divLinksToExpand.removeClass("hide");
                    }
                }else{
                    if(divLinksToExpand && !divLinksToExpand.hasClass("hide")){
                        divLinksToExpand.addClass("hide");
                    }
                }
            });
        }
    },

    initCarousel: function(){
        const manager = this;

        const divCarousel = $(manager._containerCarousel);

        if(divCarousel){
            if(!divCarousel.hasClass("owl-carousel")){
                divCarousel.addClass("owl-carousel");

                divCarousel.owlCarousel({
                    center: true,
                    items:2,
                    loop:false,
                    margin:30,
                    autoplay: false,
                    dots: false,
                    nav: true,
                    navText: ["<img src='/images/icon_svg_arrow_solid_right.svg'>","<img src='/images/icon_svg_arrow_solid_right.svg'>"],
                    mouseDrag: true,
                    autoHeight: true,
                    onInitialized: manager.initAfterOwlCarouselInit,
                    responsive:{
                        0:{
                            items:1
                        },
                        700:{
                            items:2
                        }
                    }
                });
            }
        }
    },

    initAfterOwlCarouselInit: function(){
        reviewComponentPreviewManager.showReviewsLinksReadMore();
    },

    openModalAndShowReview: function(elem){
        const parentReview = elem.parents(".containerReviewV1");

        if(parentReview){
            const modal = $("#modalReviewV1ReadMoreId");

            if(modal){
                const elemToAddReview = modal.find(".divContainerReview");

                if(elemToAddReview){
                    elemToAddReview.empty();
                    parentReview.clone().appendTo("#modalReviewV1ReadMoreId .divContainerReview");
                }

                modal.foundation('open');
            }
        }
    }
}

$(function(){
    if($(reviewComponentPreviewManager._containerCarousel) && $(reviewComponentPreviewManager._containerCarousel).length > 0){
        reviewComponentPreviewManager.initCarousel();
    }

    $(".containerReviewsComponentPreview .divListSlides .divBtnCarousel.next").on("click", function(e){
        const divCarousel = $(reviewComponentPreviewManager._containerCarousel);
    
        divCarousel.trigger('next.owl.carousel');
    });
    
    $(".containerReviewsComponentPreview .divListSlides .divBtnCarousel.prev").on("click", function(e){
        const divCarousel = $(reviewComponentPreviewManager._containerCarousel);
    
        divCarousel.trigger('prev.owl.carousel');
    });

    $(".containerReviewV1 .linkReadMore").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();

        reviewComponentPreviewManager.openModalAndShowReview($(this));
    });
});
