var managerFormProgrammeModule = {
    _containerVueId: '#containerFormProgrammeModuleId',
    _formVueObj: null,

    initVueForm: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            el: manager._containerVueId,

            data: {
                tokenCsrf: null,
                formData: { id: 0, name: null, components: [] },
                isLoading: 'no',
                preSelectedComponents: null,
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                isUpdateForm: function(){
                    return this.formData.id && this.formData.id > 0;
                }
            },

            events: {
                'updateName': function(msg){
                    this.formData.name = msg;
                },

                'componentAddEventsOrServicesUpdateListSelectedObjs': function(msg){
                    this.formData.components = msg;
                }
            },

            ready: function(){
                const vueObj = this;
                
                const elemContainerForm = vueObj.$els.containerForm;

                if(elemContainerForm){
                    vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                }

                const elemModuleId = vueObj.$els.containerModuleId;

                if(elemModuleId){
                    vueObj.formData.id = $(elemModuleId).data('id');
                }

                if(vueObj.isUpdateForm){
                    vueObj.getModuleDataAndInitForm();
                }
            },

            methods: {
                sendForm: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    vueObj.$nextTick(() => {
                        const elemContainerForm = vueObj.$els.containerForm;

                        if(elemContainerForm){
                            elemContainerForm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    });

                    let method = "POST";

                    let url = "/json/programme_modules";

                    let params = new FormData();
                    params.append('id', vueObj.formData.id);
                    params.append('name', vueObj.formData.name);
                    params.append('components', JSON.stringify(vueObj.formData.components));

                    if(vueObj.isUpdateForm){
                        params.append('_method', 'PATCH');

                        url = "/json/programme_modules/" + vueObj.formData.id;
                    }

                    vueObj.$http({ 
                            url: url, method: method, 
                            contentType: 'multipart/form-data', 
                            responseType: 'json', 
                            data: params,
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        if(response.data.status == 'success'){
                            $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                            if(response.data.url_redirect){
                                setTimeout(function(){ window.location.href = response.data.url_redirect }, 3000);
                            }else{
                                vueObj.isLoading = 'no';
                            }
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            vueObj.isLoading = 'no';
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                getModuleDataAndInitForm: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    vueObj.$http({ 
                        url: "/json/programme_modules/" + vueObj.formData.id, 
                        method: 'GET',
                        responseType: 'json'
                    }).then(function(response){
                        if(response.data.module){
                            vueObj.formData.name = response.data.module.name;
                            
                            if(response.data.module.components && response.data.module.components.length > 0){
                                vueObj.preSelectedComponents = response.data.module.components;
                            }
                        }
        
                        vueObj.isLoading = 'no';
                    }, function(response){
                        //error callback
                    });
                }
            }
        });
    }
};

$(function(){
    if($(managerFormProgrammeModule._containerVueId) && $(managerFormProgrammeModule._containerVueId).length > 0){
        managerFormProgrammeModule.initVueForm();
    }
});
