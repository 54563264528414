var learningClubsIndexEventsManager = {
    _containerVueId: '#pageIndexEventsClubId',
    _vueObjDetailPage: null,
    _cropperEventImage: null,
    _cropperEventImageSocial: null,

    initVue: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                clubId: 0,
                tokenCsrf: null,
                currentUserId: 0,
                currentUser: null,
                currentUserClubsIds: [],
                eventsIdsCurrentUserJoined: [],
                eventsFilter: 'next',
                events: [],
                isLoading: 'no',
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                showListEvents: function(){
                    return this.events && this.events.length > 0;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.clubId = $(vueObj.$els.containerVue).data('club-id');
                        vueObj.currentUserId = $(vueObj.$els.containerVue).data('current-user-id');
                        vueObj.eventsFilter = $(vueObj.$els.containerVue).data('events-filter');
                    }

                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }

                    vueObj.getEvents();
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.eventsIdsCurrentUserJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;

                        vueObj.isLoadingResources = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                getEvents: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/events";
                    let params = { filter: vueObj.eventsFilter };

                    vueObj.$http({ url: url, params: params, method: 'GET' }).then(function(response){
                        vueObj.events = response.data.events;
            
                        vueObj.isLoading = 'no';
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }
};

$(function(){
    if(learningClubsIndexEventsManager._containerVueId && $(learningClubsIndexEventsManager._containerVueId).length > 0){
        learningClubsIndexEventsManager.initVue();
    }
});
