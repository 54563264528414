$(function(){    
    var WebinarTopicFormManager = {
        _containerFormId: '#containerFormWebinarTopicsId',
        _formVueObj: null,

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    webinarTopic: { id: null, suggested_topic: null, email: null },
                    emailREGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    showErrorInvalid: { email: 'no' },
                    containerFormDivId: manager._containerFormId
                },
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.webinarTopic.email) }
                    },
                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    }
                },
                // methods
                methods: {
                    sendForm: function () {
                        var vueObj = this;

                        if (vueObj.isValid){
                            var form = $(vueObj.containerFormDivId).find("form");
                            
                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);

                            if(formData.get('g-recaptcha-response')){
                                var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                                if(containerLoading && containerLoading.hasClass("hide")){
                                    containerLoading.removeClass("hide");
                                }

                                if(form && !form.hasClass("blur")){
                                    form.addClass("blur");
                                }

                                vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                    vueObj.getPercentagesAndManageRequestResponse(response.data.status);
                                }, function(response){
                                    vueObj.manageRequestResponse("error");
                                });
                            }else{
                                //ReCaptcha needed!
                            }

                        }else{
                        
                        }
                    },

                    getPercentagesAndManageRequestResponse: function(status){
                        var vueObj = this;

                        if(status && status == 'success'){
                            var containerTopicsList = $(".containerInputTopics");
                            var url = 'http:' + containerTopicsList.data('url');

                            vueObj.$http({ url: url, method: 'GET', responseType: 'json', data: {} }).then(function(response){
                                var listPercentages = response.data.topic_votes;

                                var listLiInputs = $(".containerSelectWebinarTopics .liTopicInput");

                                if(listLiInputs && listLiInputs.length > 0 && listPercentages){
                                    $.each(listLiInputs, function(index, liInput){
                                        var topicId = $(liInput).data('id');
                                        var divProgressInner = $(liInput).find(".divBarProgress .innerDiv");
                                        var percentageVotes = listPercentages[topicId] + "%";

                                        if(divProgressInner){
                                            $(divProgressInner).css('width', percentageVotes);
                                            $(divProgressInner).text(percentageVotes);
                                        }
                                    });
                                }

                                vueObj.manageRequestResponse(status);
                                
                            }, function(response){
                                //error callback
                            });
                        }else{
                            vueObj.manageRequestResponse(status);
                        }
                    },

                    manageRequestResponse: function(status){
                        var vueObj = this;

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");

                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form && form.hasClass("blur")){
                            form.removeClass("blur");
                        }

                        if(status && status == 'success'){
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.success");

                            divRequestMessage.removeClass("hide");
                            vueObj.clearForm();
                        }else{
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.error");

                            divRequestMessage.removeClass("hide");
                        }

                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    },

                    clearForm: function(){
                        var vueObj = this;

                        vueObj.webinarTopic.id = null;
                        vueObj.webinarTopic.email = null;
                        vueObj.webinarTopic.suggested_topic = null;
                    }
                }
            });
        },
    };

    if($(WebinarTopicFormManager._containerFormId) && $(WebinarTopicFormManager._containerFormId).length > 0){
        WebinarTopicFormManager.initFormVueObj();
    }
});
