var managerServicesPageAssociatedEvents = {
    _containerVue: "#servicesPageEventsAssociatedId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                currentUserClubsIds: [],
                userJoinedEventsIds: [],
                learningClubId: 0,
                learningClub: null,
                courseId: 0,
                course: null,
                workshopsIds: [],
                workshops: null
            },

            ready: function(){
                this.init();
            },

            computed: {
                showListWorkshops: function(){
                    return this.workshops && this.workshops.length > 0;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    
                    if(vueObj.$els.containerVue){
                        vueObj.learningClubId = parseInt($(vueObj.$els.containerVue).data('club-id'));
                        vueObj.courseId = parseInt($(vueObj.$els.containerVue).data('course-id'));
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerVue).data('current-user-id'));

                        if($(vueObj.$els.containerVue).data('workshops-ids')){
                            let tempWorkshopsIdsStr = $(vueObj.$els.containerVue).data('workshops-ids');

                            if(tempWorkshopsIdsStr && tempWorkshopsIdsStr.length > 0){
                                const workshopsIdsArray = tempWorkshopsIdsStr.split(",");
                                if(workshopsIdsArray && workshopsIdsArray.length > 0){
                                    vueObj.workshopsIds = workshopsIdsArray;
                                }
                            }
                        }
                    }
 
                    vueObj.getCurrentUserInfo();
                  
                    vueObj.getAssociatedEvents();
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.userJoinedEventsIds = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getAssociatedEvents: function(){
                    const vueObj = this;

                    if(vueObj.learningClubId && !isNaN(vueObj.learningClubId) && vueObj.learningClubId > 0){
                        const urlClubs  = "/json/learning_clubs";

                        const paramsClubs = { 
                            with_accepted_members: 'yes', 
                            with_upcoming_events: 'yes',
                            learning_clubs_ids: [vueObj.learningClubId]
                        };

                        vueObj.$http({ 
                            url: urlClubs, 
                            method: 'GET', 
                            responseType: 'json', 
                            data: paramsClubs 
                        }).then(function(response){
                            if(response.data.learning_clubs && response.data.learning_clubs.length > 0){
                                vueObj.learningClub = response.data.learning_clubs[0];
                            }
                        }, function(response){
                            //error callback
                        });
                    }

                    if(vueObj.courseId && !isNaN(vueObj.courseId) && vueObj.courseId > 0){
                        const urlCourse = "/json/events";
                        
                        const paramsCourse = { events_ids: [vueObj.courseId] };

                        vueObj.$http({
                            url: urlCourse,
                            method: 'GET',
                            responseType: 'json',
                            data: paramsCourse
                        }).then(function(response){
                            if(response.data.events && response.data.events.length > 0){
                                vueObj.course = response.data.events[0];
                            }
                        });
                    }

                    if(vueObj.workshopsIds && vueObj.workshopsIds.length > 0){
                        vueObj.$http({
                            url: "/json/events",
                            method: 'GET',
                            responseType: 'json',
                            data: { events_ids: vueObj.workshopsIds, with_editions_next: 'yes' }
                        }).then(function(response){
                            if(response.data.events && response.data.events.length > 0){
                                vueObj.workshops = response.data.events;
                            }
                        });
                    }
                }
            }
        });
    }
};

$(function(){
    if($(managerServicesPageAssociatedEvents._containerVue) && $(managerServicesPageAssociatedEvents._containerVue).length > 0){
        managerServicesPageAssociatedEvents.initVueObj();
    }
});
