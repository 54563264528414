var learningClubsListManager = {
    _containerVueListId: "#containerVuePageListViewLearningClubsId",
    _vueObjPageList: null,

    initVueObjPageList: function(){
        var manager = this;

        manager._vueObjPageList = new Vue({
            el: manager._containerVueListId,
            data: {
                searchBy: null,
                optionsForSelect: [],
                clubs: null,
                currentUserClubsIdsJoined: [],
                windowWidth: 0,
                objCreateClubPreview: { id: 0, is_create_preview: 'yes', name: 'This is the name of your own exlusive club', title: "Found your own club here, it's FREE.", description_short: 'Set up your own club and cluster around joint interest and to achieve more together.' }
            },

            ready: function(){
                this.init();
            },

            computed: {
                hasOptionsForSelect(){
                    return this.optionsForSelect && this.optionsForSelect.length > 0;
                }
            },

            watch: {
                searchBy: function(){
                    if(this.searchBy){
                        this.getClubs();
                    }
                },
            },

            methods: {
                onWindowResize: function(){
                    const vueObj = this;

                    vueObj.windowWidth = window.innerWidth;
                },

                init: function(){
                    const vueObj = this;

                    vueObj.windowWidth = window.innerWidth;

                    vueObj.initSelectOptionsAndClubs();
                },

                initSelectOptionsAndClubs: function(){
                    const vueObj = this;

                    let url = "/json/dynamic_lists/options_by_list_name";
                    let params = { name: 'doctoral_journey' };

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.options && response.data.options.length > 0){
                            vueObj.optionsForSelect = response.data.options;
                        }

                        vueObj.getClubs();
                        vueObj.getCurrentUserEventsClubsIdsJoined();
                    }, function(response){
                        //error callback
                    });
                },

                getClubs: function(){
                    const vueObj = this;

                    vueObj.events = null;

                    let url = "/json/learning_clubs";
                    let params = { filter: 'all' };

                    if(vueObj.searchBy == 'latest'){
                        params.filter = 'latest';
                    }

                    if(vueObj.searchBy && $.isNumeric(vueObj.searchBy)){
                        params.dynamic_list_option_id = vueObj.searchBy;
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.learning_clubs){
                            vueObj.clubs = response.data.learning_clubs;
                        }else{
                            vueObj.clubs = [];
                        }

                        vueObj.clubs.unshift(vueObj.objCreateClubPreview);

                    }, function(response){
                        //error callback
                        vueObj.clubs = [];
                        vueObj.clubs.unshift(vueObj.objCreateClubPreview);
                    });
                },

                getCurrentUserEventsClubsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIdsJoined = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }
};

$(function(){
    if($(learningClubsListManager._containerVueListId) && $(learningClubsListManager._containerVueListId).length > 0){
        learningClubsListManager.initVueObjPageList();

        $(window).resize(function() {
            learningClubsListManager._vueObjPageList.windowWidth = window.innerWidth;
        });
    }
});
