$(function(){
    var ratyReadOnlyElems = $('.ratyReadOnly');
    if(ratyReadOnlyElems && ratyReadOnlyElems.length > 0){
        ratyReadOnlyElems.each(function(index, elem){
            var score = $(elem).data('score');

            if(!score){
                score = 0;
            }

            $(elem).raty({
                score: score,
                number: 5,
                halfShow: false,
                starOff: '/images/icon_c13_star_empty.png',
                starOn: '/images/icon_c13_star_full.png',
                hints: ['', '', '', '', ''],
                readOnly: true
            });
        });
    }

    var ratyFormElems = $('.ratyForm');
    if(ratyFormElems && ratyFormElems.length > 0){
        ratyFormElems.each(function(index, elem){
            var defaultScore = $(elem).data('default-score');
            var inputname = $(elem).data('inputname');

            if(!defaultScore){
                defaultScore = 0;
            }

            $(elem).raty({
                score: defaultScore,
                scoreName: inputname,
                halfShow: false,
                starOff: '/images/icon_c13_star_empty.png',
                starOn: '/images/icon_c13_star_full.png',
                numberMax: 5
            });
        });
    }
});
