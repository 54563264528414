var managerShowProgramme = {
    _containerVueId: '#pageProgrammeShowContentId',
    _vueObjDetailPage: null,
    _cropperUserImage: null,

    initVueDetailPage: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                photo: { modal: $("#modalProgrammePhotoUpdateId"), image: $("#eventPhotoUploadId"), imageSocial: $("#eventPhotoUploadSocialId") },
                base64data: { original: null, web: null, fb_lkn: null, twitter: null },
                currentUserId: -1,
                currentUser: null,
                tokenCsrf: null,
                programmeId: 0,
                participantId: 0,
                mentorId: 0,
                currentUserEventsIdsJoined: [],
                currentUserClubsIdsJoined: [],
                sectionActive: 'overview',
                isLoadingResources: 'yes',
                reloadResourcesStorageSize: 'no',
                reloadResources: 'no',
                isLoadingConferences: 'no',
                conferences: { next: null, past: null },
                isLoadingCourses: 'no',
                courses: { next: null, past: null },
            },

            ready: function(){
                this.init();
            },

            computed: {
                isSectionActiveOverview: function(){
                    return !this.sectionActive || (this.sectionActive && this.sectionActive == 'overview');
                },

                isSectionActiveForum: function(){
                    return this.sectionActive && this.sectionActive == 'forum';
                },

                isSectionActiveResources: function(){
                    return this.sectionActive && this.sectionActive == 'resources';
                },

                isSectionActiveModules: function(){
                    return this.sectionActive && this.sectionActive == 'modules';
                },

                isSectionActiveDashboardStudent: function(){
                    return this.sectionActive && this.sectionActive == 'dashboard_student';
                },

                isSectionActiveMyCourses: function(){
                    return this.sectionActive && this.sectionActive == 'my_courses';
                },

                isSectionActiveMyConferences: function(){
                    return this.sectionActive && this.sectionActive == 'my_conferences';
                },

                isSectionActiveDashboardMentor: function(){
                    return this.sectionActive && this.sectionActive == 'dashboard_mentor';
                },

                showSectionOverview: function(){
                    return this.isSectionModeInfo && this.isSectionActiveOverview;
                },

                showSectionForum: function(){
                    return this.isSectionModeInfo && this.isSectionActiveForum;
                },

                showSectionResources: function(){
                    return this.isSectionModeInfo && this.isSectionActiveResources;
                },

                showLoadingResources: function(){
                    return this.isLoadingResources && this.isLoadingResources == 'yes';
                },

                showLoadingConferences: function(){
                    return this.isLoadingConferences && this.isLoadingConferences == 'yes';
                },

                showListConferencesNext: function(){
                    return this.conferences.next && this.conferences.next.length > 0;
                },

                showListConferencesPast: function(){
                    return this.conferences.past && this.conferences.past.length > 0;
                },

                showNoFoundConferences: function(){
                    return !this.showLoadingConferences && !this.showListConferencesNext && !this.showListConferencesPast;
                },

                showLoadingCourses: function(){
                    return this.isLoadingCourses && this.isLoadingCourses == 'yes';
                },

                showListCoursesNext: function(){
                    return this.courses.next && this.courses.next.length > 0;
                },

                showListCoursesPast: function(){
                    return this.courses.past && this.courses.past.length > 0;
                },

                showNoFoundCourses: function(){
                    return !this.showLoadingCourses && !this.showListCoursesNext && !this.showListCoursesPast;
                },
            },

            events: {
                'files_finish_upload': function(msg){
                    const vueObj = this;
                    vueObj.reloadResources = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResources = "no";
                    });
                },

                'resource_deleted': function(msg){
                    const vueObj = this;
                    vueObj.reloadResourcesStorageSize = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResourcesStorageSize = "no";
                    });
                },               
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.sectionActive = $(vueObj.$els.containerVue).data("section-active");
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerVue).data('current-user-id'));
                        vueObj.programmeId = parseInt($(vueObj.$els.containerVue).data('programme-id'));
                        vueObj.participantId = parseInt($(vueObj.$els.containerVue).data('participant-id'));
                        vueObj.mentorId = parseInt($(vueObj.$els.containerVue).data('mentor-id'));
                    }

                    if(vueObj.currentUserId && vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }

                    Vue.nextTick(function () {
                        divExpandManager.check_size_div_text();
                    })

                    if(vueObj.isSectionActiveMyConferences){
                        vueObj.getConferences();
                    }

                    if(vueObj.isSectionActiveMyCourses){
                        vueObj.getCourses();
                    }
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;

                        vueObj.isLoadingResources = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                manageImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventphotouploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.base64data.original = url;

                        if(vueObj.photo.image){
                            vueObj.photo.image.attr("src",url);
                        }

                        if(vueObj.photo.imageSocial){
                            vueObj.photo.imageSocial.attr("src",url);
                        }

                        if(vueObj.photo.modal){
                            vueObj.photo.modal.foundation('open');
                        }

                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;
                    let canvasOptions = null;

                    switch(type){
                        case 'web':
                            canvasOptions = { width: 1190, height: 1074, minWidth: 1190, minHeight:1074 };
                            break;
                        case 'fb_lkd':
                            canvasOptions = { width: 1200, height: 627, minWidth: 1200, minHeight:627 };
                            break;
                        case 'twitter':
                            canvasOptions = { width: 1200, height: 600, minWidth: 1200, minHeight:600 };
                            break;
                    }

            
                    canvas = manager._cropperEventImageSocial.getCroppedCanvas(canvasOptions);
                    

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                updateUserPhoto: function(){
                    const vueObj = this;
                    
                    vueObj.isUploadingImage = 'yes';

                    if(vueObj.photo.modal.find(".divContLoading") && vueObj.photo.modal.find(".divContLoading").hasClass("hide")){
                        vueObj.photo.modal.find(".divContLoading").removeClass("hide");
                    }

                    if(vueObj.photo.modal.find(".btnClose") && !vueObj.photo.modal.find(".btnClose").hasClass("hide")){
                        vueObj.photo.modal.find(".btnClose").addClass("hide");
                    }

                    if(vueObj.photo.modal.find(".divContForm") && !vueObj.photo.modal.find(".divContForm").hasClass("hide")){
                        vueObj.photo.modal.find(".divContForm").addClass("hide");
                    }

                    vueObj.getBase64dataImages('web');
                    vueObj.getBase64dataImages('fb_lkd');
                    vueObj.getBase64dataImages('twitter');

                    vueObj.$nextTick(() => {
                        setTimeout(function(){
                            let params = {
                                image_original: vueObj.base64data.original, 
                                image_web: vueObj.base64data.web, 
                                image_fb_lkn: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            vueObj.$http({ 
                                url: "/json/programmes/"+ vueObj.programmeId +"/update_image", method: 'POST',  
                                contentType: 'multipart/form-data', 
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                            }).then(function(response){
                                //SUCCESS
                                if(response.data.result == "success"){
                                   /* if(vueObj.photo.modal){
                                        vueObj.photo.modal.foundation('close');
                                    }*/
        
                                    window.location.reload();
                                }
                            }, function(response){
                                vueObj.isUploadingImage = 'no';
                                //ERROR
                            });
                        }, 1500);

                    });
                },

                getConferences: function(){
                    const vueObj = this;

                    vueObj.isLoadingConferences = 'yes';

                    let url = "";

                    if(vueObj.participantId && vueObj.participantId > 0){
                        url = "/json/programme_participants/"+ vueObj.participantId +"/conferences";
                    }else if(vueObj.mentorId && vueObj.mentorId > 0){
                        url = "/json/programme_mentors/"+ vueObj.mentorId +"/conferences";
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET',
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.conferences.next = response.data.next;
                        vueObj.conferences.past = response.data.past;
        
                        vueObj.isLoadingConferences = 'no';
                    }, function(response){
                        //error callback
                    });
                },

                getCourses: function(){
                    const vueObj = this;

                    vueObj.isLoadingCourses = 'yes';

                    let url = "";

                    if(vueObj.participantId && vueObj.participantId > 0){
                        url = "/json/programme_participants/"+ vueObj.participantId +"/courses";
                    }else if(vueObj.mentorId && vueObj.mentorId > 0){
                        url = "/json/programme_mentors/"+ vueObj.mentorId +"/courses";
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET',
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.courses.next = response.data.next;
                        vueObj.courses.past = response.data.past;
        
                        vueObj.isLoadingCourses = 'no';
                    }, function(response){
                        //error callback
                    });
                },
            }
        });
    },

    initCropper: function(modal){
        const manager = this;

        if(modal){
            let imageSocial = modal.find("#eventPhotoUploadSocialId");

            manager._cropperEventImageSocial = new Cropper($(imageSocial)[0], {
                aspectRatio: 16/9,
                viewMode: 2,
                zoomable: false,
            });
        }
    },

    destroyCropper: function(){
        const manager = this;

        manager._cropperEventImage.destroy();
        manager._cropperEventImage = null;

        manager._cropperEventImageSocial.destroy();
        manager._cropperEventImageSocial = null;
    }

}

$(function(){
    if(managerShowProgramme._containerVueId && $(managerShowProgramme._containerVueId).length > 0){
        managerShowProgramme.initVueDetailPage();
    }

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalProgrammePhotoUpdateId"){
                managerShowProgramme.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalProgrammePhotoUpdateId"){
                managerShowProgramme.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedProgrammeImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        managerShowProgramme._vueObjDetailPage.updateUserPhoto();
    });
});
