var managerFormProgrammeManageModules = {
    _containerVueId: '#containerFormProgrammeManageModuleId',
    _formVueObj: null,

    initVueForm: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            el: manager._containerVueId,

            data: {
                tokenCsrf: null,
                programmeId: 0,
                isLoading: 'no',
                formData: { modules: [] },
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                }
            },

            events: {
                'componentManageProgrammeModulesUpdated': function(msg){
                    this.formData.modules = msg;
                },
            },

            ready: function(){
                const vueObj = this;
                
                const elemContainerForm = vueObj.$els.containerForm;

                if(elemContainerForm){
                    vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                    vueObj.programmeId = $(elemContainerForm).data('programme-id');
                }
            },

            methods: {
                sendForm: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    vueObj.$nextTick(() => {
                        const elemContainerForm = vueObj.$els.containerForm;

                        if(elemContainerForm){
                            elemContainerForm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    });

                    let params = new FormData();
                    params.append('modules', JSON.stringify(vueObj.formData.modules));

                    vueObj.$http({ 
                            url: "/json/programmes/"+ vueObj.programmeId + "/manage_modules", method: "POST", 
                            contentType: 'multipart/form-data', 
                            responseType: 'json', 
                            data: params,
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        if(response.data.status == 'success'){
                            $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                            if(response.data.url_redirect){
                                setTimeout(function(){ window.location.href = response.data.url_redirect }, 3000);
                            }else{
                                vueObj.isLoading = 'no';
                            }
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                            vueObj.isLoading = 'no';
                        }
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }
};

$(function(){
    if($(managerFormProgrammeManageModules._containerVueId) && $(managerFormProgrammeManageModules._containerVueId).length > 0){
        managerFormProgrammeManageModules.initVueForm();
    }
});
