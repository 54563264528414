var learningClubsShowListEventsManager = {
    _containerVue: "#containerVueLearningClubsShowListEventsId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                clubId: 0,
                eventsAndWorkingSessions: null,
                eventsNext: null,
                eventsPast: null,
                isLoadingEvents: 'yes',
                currentUser: null,
                currentUserClubsIds: [],
                members: null
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoadingEvents: function(){
                    return this.isLoadingEvents && this.isLoadingEvents == 'yes';
                },

                hasEventsNext(){
                    return this.eventsNext && this.eventsNext.length > 0;
                },

                hasEventsPast(){
                    return this.eventsPast && this.eventsPast.length > 0;
                },

                showListEvents: function(){
                    return !this.showLoadingEvents && (this.hasEventsNext || this.hasEventsPast);
                },

                showNoEventsFound: function(){
                    return !this.showLoadingEvents && !this.showListEvents;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    const elemContainerElem = vueObj.$els.containerElem;

                    if(elemContainerElem){
                        let clubId = $(elemContainerElem).data('learning-club-id');
                        
                        if(clubId){
                            vueObj.clubId = parseInt(clubId);
                        }
                    }

                    vueObj.getCurrentUser();
                    vueObj.getCurrentUserLearningClubsIds();
                    vueObj.getEventsAndWorkingSessions();
                },

                getEventsAndWorkingSessions: function(){
                    const vueObj = this;

                    vueObj.isLoadingEvents = 'yes';

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/events_and_working_sessions";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){

                        if((response.data.next && response.data.next.length > 0) && (response.data.past && response.data.past.length > 0)){
                            vueObj.eventsNext = response.data.next;
                            vueObj.eventsPast = response.data.past;
                            /*let tempArray = response.data.next;
                            tempArray.push(...response.data.past);
                            vueObj.eventsAndWorkingSessions = tempArray;*/
                        }else if(response.data.next && response.data.next.length > 0){
                            vueObj.eventsNext = response.data.next;
                        }else if(response.data.past && response.data.past.length > 0){
                            vueObj.eventsPast = response.data.past;
                        }

                        vueObj.isLoadingEvents = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getClubApprovedMembers: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/approved_members";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.members = response.data.members;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }

};

$(function(){
    if($(learningClubsShowListEventsManager._containerVue) && $(learningClubsShowListEventsManager._containerVue).length > 0){
        learningClubsShowListEventsManager.initVueObj();
    }
});
