Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
});

Vue.filter('removeHTML', function (text) {
    let tempText = '<div>'+ text +'</div>';

    return $(tempText).text();
});
  
Vue.filter('userFullName', function(user){
    if(user){
        let name = user.name;

        if(user.name_last && user.name_last.length > 0){
            name = name + " " + user.name_last;
        }

        return name;
    }

    return "";
});
  
Vue.filter('linkToProfile', function(user){
    if(user){
        return "/users/" + user.id;
    }

    return "#";
});

Vue.filter('day', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("DD");
    }

    return "";
});

Vue.filter('month', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("MMM");
    }

    return "";
});

Vue.filter('year', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("YYYY");
    }

    return "";
});

Vue.filter('monthYearV1', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("MMM'YY");
    }

    return "";
});
  
Vue.filter('fomatedDateV1', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("dddd, Do MMMM YYYY, kk:mm") + " GMT";
        //Friday, 9th October 2020, 09:37 GMT
    }

    return "";
});
    
Vue.filter('fomatedDateV2', function(date){
    if(date){
        let dateMoment = moment(date);

        return dateMoment.format("dddd, Do MMMM YYYY");
        //Friday, 9th October 2020
    }

    return "";
});
  
Vue.filter('fomatedDateV3', function(date){
    if(date){
        let dateMoment = moment(date);

        return dateMoment.format("DD.MM.YYYY");
        //09.10.2020
    }

    return "";
});
  
Vue.filter('fomatedDateV4', function(date){
    if(date){
        let dateMoment = moment(date);

        return dateMoment.format("DD/MM/YYYY kk:mm") + " GMT";
        //09.10.2020
    }

    return "";
});
  
Vue.filter('fomatedDateV5', function(date){
    if(date){
        let dateMoment = moment(date);

        return dateMoment.format("DD/MM/YYYY");
        //09.10.2020
    }

    return "";
});

Vue.filter('fomatedDateV6', function(date){
    if(date){
        let dateMoment = moment(date);

        return dateMoment.format("Do MMMM YYYY");
        //Friday, 9th October 2020
    }

    return "";
});

Vue.filter('fomatedDateV5WithBrowserTimezone', function(date){
    if(date){
        const dateMoment = moment.utc(date);
        const dateMomentTimezone = dateMoment.clone().tz(moment.tz.guess());

        return dateMomentTimezone.format('ddd, Do MMM YYYY, kk:mm');
        //Friday, 9th October 2020, 09:37
    }

    return "";
});

Vue.filter('timeBrowserTimezone', function(date){
    if(date){
        const dateMoment = moment.utc(date);
        const dateMomentTimezone = dateMoment.clone().tz(moment.tz.guess());

        return dateMomentTimezone.format('kk:mm') + " " + moment.tz.guess();
        //Friday, 9th October 2020, 09:37
    }

    return "";
});

Vue.filter('timeBrowserTimezoneWithoutStringTimezone', function(date){
    if(date){
        const dateMoment = moment.utc(date);
        const dateMomentTimezone = dateMoment.clone().tz(moment.tz.guess());

        return dateMomentTimezone.format('kk:mm');
        //Friday, 9th October 2020, 09:37
    }

    return "";
});

Vue.filter('timeUk', function(date){
    if(date){
        const dateMoment = moment.utc(date);
        const dateMomentTimezone = dateMoment.clone().tz('Europe/London');

        return dateMomentTimezone.format('kk:mm');
    }

    return "";
});

Vue.filter('timeGmt', function(date){
    if(date){
        const dateMoment = moment.utc(date);

        return dateMoment.format('kk:mm');
    }

    return "";
});

Vue.filter('fomatedDateTimeV1', function(date){
    if(date){
        let dateMoment = moment.utc(date);

        return dateMoment.format("kk:mm [GMT • ] dddd, Do MMMM YYYY");
    }1

    return "";
});
  
Vue.filter('eventUrl', function(event){
    let url = "/";

    if(event){
        if(event.is_create_preview && event.is_create_preview == 'yes'){
            switch(event.type){
                case 'conference':
                    url = "/events/new?type=conference";
                    break;
            }
        }else{
            switch(event.type){
                case 'training':
                    url = '/webinars/' + event.id;
                    break;
                case 'learning_cafe':
                    url = "/learning_cafes/" + event.id;
                    break;
                case 'master_course':
                    url = '/advanced_courses/' + event.id;
                    break;
                case 'workshop':
                    url = "/workshops/" + event.id;
                    break;
                case 'workshop_edition':
                    url = "/workshop_editions/" + event.id;
                    break;
                case 'residence':
                    url = "/residencies/" + event.id;
                    break;
                case 'in_residence':
                    url = "/in_residences/" + event.id;
                    break;
                case 'conference':
                    url = "/conferences/" + event.id;
                    break;
                case 'advanced_training':
                    url = "/conferences/" + event.id;
                    break;
                case 'advanced_training_edition':
                    url = "/conference_editions/" + event.id;
                    break;
                case 'group_session':
                    url = "/group_sessions/" + event.id;
                    break;
                case 'class_offer_edition':
                    url = "/open_programmes/" + event.id;
                    break;
                case 'master_course_edition':
                    url = "/advanced_course_editions/" + event.id;
                    break;
            }
        }
    }

    return url;
});
  
Vue.filter('urlEventJoin', function(event){
    let url = "/";

    if(event){
        switch(event.type){
            case 'training':
                url = '/webinars/' + event.id + '?action=register';
                break;
            case 'learning_cafe':
                url = "/learning_cafes/" + event.id + '?action=register';
                break;
            case 'master_course':
                url = '/advanced_courses/' + event.id + '#selectCourseEdition';
                break;
            case 'workshop':
                url = "/workshops/" + event.id + '?action=register';
                break;
            case 'residence':
                url = "/residencies/" + event.id + '?action=register';
                break;
            case 'in_residence':
                url = "/in_residences/" + event.id + '?action=register';
                break;
            case 'conference':
                url = "/conferences/" + event.id;
                break;
        }
    }

    return url;
});
  
Vue.filter('urlEventWatchReplay', function(event){
    let url = "/";

    if(event){
        switch(event.type){
            case 'training':
                url = '/webinars/' + event.id + '?stb=watch_replay';
                break;
            case 'learning_cafe':
                url = "/learning_cafes/" + event.id + '?stb=watch_replay';
                break;
            case 'master_course':
                url = '/advanced_courses/' + event.id + '?stb=watch_replay';
                break;
            case 'workshop':
                url = "/workshops/" + event.id + '?stb=watch_replay';
                break;
            case 'residence':
                url = "/residencies/" + event.id + '?stb=watch_replay';
                break;
            case 'in_residence':
                url = "/in_residences/" + event.id + '?stb=watch_replay';
                break;
            case 'conference':
                url = "/in_residences/" + event.id + '?stb=watch_replay';
                break;
        }
    }

    return url;
});

Vue.filter('urlRedirectWorkshopShowOrEdition', function(event){
    return "/workshops/redirect_to_show_or_editions?id=" + event.id;
});

Vue.filter('urlRedirectMasterCourseShowOrEdition', function(event){
    return "/advanced_courses/redirect_to_show_or_editions?id=" + event.id;
});

Vue.filter('urlRedirectEdition', function(eventId){
    return "/advanced_courses/"+ eventId +"/redirect_to_edition";
});

Vue.filter('urlFromStorage', function(relativePath){
    return '/storage/' + relativePath;
});

Vue.filter('urlToUserProfile', function(userId){
    return "/users/" + userId;
});

Vue.filter('urlEventsSeries', function(serie){
    let url = "/training_session_series/" + serie.id;

    return url;
});

Vue.filter('urlLearningClubs', function(club){
    let url = "#";

    if(club.is_create_preview && club.is_create_preview == 'yes'){
        url = "/clubs/new";
    }else{
        url = "/clubs/" + club.id;
    }

    return url;
});

Vue.filter('urlGroupSessionsNew', function(clubId){
    let url = "/group_sessions/new";

    if(clubId){
        url = url + '?club_id=' + clubId;
    }

    return url;
});

Vue.filter('urlLearningClubJoin', function(club){
    let url = "#";

    if(club){
        url = "/clubs/"+ club.id +"/join";
    }

    return url;
});

Vue.filter('urlWorkingSession', function(session){
    let url = "#";

    if(session){
        url = "/collaborative_working_sessions/"+ session.id;
    }

    return url;
});

Vue.filter('urlRedirectWorkingSessionMeeting', function(session){
    let url = "#";

    if(session){
        url = "/collaborative_working_sessions/"+ session.id +"/redirect_meeting_url";
    }

    return url;
});

Vue.filter('urlRedirectEventMeeting', function(session){
    let url = "#";

    if(session){
        url = "/events/"+ session.id +"/redirect_meeting_url";
    }

    return url;
});

Vue.filter('urlEventAddCurrentUserAsParticipant', function(event){
    let url = "#";

    if(event){
        url = "/events/"+ event.id +"/add_current_user_as_participant";
    }

    return url;
});

Vue.filter('urlWorkingSessionEdit', function(session){
    let url = "#";

    if(session){
        url = "/collaborative_working_sessions/"+ session.id +"/edit";
    }

    return url;
});

Vue.filter('urlWorkingSessionJoinBBBMeeting', function(session){
    let url = "#";

    if(session){
        url = "/collaborative_working_sessions/"+ session.id +"/join_bbb_meeting";
    }

    return url;
});

Vue.filter('fistLetterString', function(string){
    if(string && string.length > 0){
        return string.substring(1,0);
    }

    return '';
});

Vue.filter('addPointEveryThreeDigits', function(number){
    var parts = number.toString().split(".");

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return parts.join(".");
});

Vue.filter('roundNumberNoDecimals', function(number){
    if(number){
        return Math.round(number); 
    }

    return 0;
});

Vue.filter('urlImageFromFolderPath', function(obj, type){
    let url = "";
    let pathToFolder = null;

    if(obj.photo && obj.photo.length > 0){
        pathToFolder = obj.photo;
    }

    if(obj.image_folder_path && obj.image_folder_path.length > 0){
        pathToFolder = obj.image_folder_path;
    }

    if(pathToFolder){
        switch(type){
            case 'original':
                url = '/storage/' + pathToFolder + '/original.png';
                break;

            case 'web':
                url = '/storage/' + pathToFolder + '/web.png';
                break;

            case 'fb_lkn':
                url = '/storage/' + pathToFolder + '/facebook_linkedin.png';
                break;

            case 'twitter':
                url = '/storage/' + pathToFolder + '/twitter.png';
                break;
        }
    }

    return url;
});

Vue.filter('urlToWorkingSessionsParticipants', function(session){
    if(session){
        return "/collaborative_working_sessions/"+ session.id +"/participants";
    }

    return "#";
});

Vue.filter('urlGroupSession', function(event){
    let url = "#";

    if(event){
        url = "/group_sessions/"+ event.id;
    }

    return url;
});

Vue.filter('urlWebinar', function(event){
    let url = "#";

    if(event){
        url = "/webinars/"+ event.id;
    }

    return url;
});

Vue.filter('urlCourse', function(event){
    let url = "#";

    if(event){
        url = "/advanced_courses/"+ event.id;
    }

    return url;
});

Vue.filter('urlCourseEdition', function(event){
    let url = "#";

    if(event){
        url = "/advanced_course_editions/"+ event.id;
    }

    return url;
});

Vue.filter('urlUserInviteFriends', function(code){
    let url = "#";

    const baseUrl = window.location.origin;

    if(code){
        url = baseUrl + "/share/" + code;
    }

    return url;
});

Vue.filter('urlAdvancedTraining', function(event){
    let url = "#";

    if(event){
        url = "/conferences/"+ event.id;
    }

    return url;
});

Vue.filter('urlAdvancedTrainingEdition', function(event){
    let url = "#";

    if(event){
        url = "/conference_editions/"+ event.id;
    }

    return url;
});

Vue.filter('urlWorkshopEdition', function(event){
    let url = "#";

    if(event){
        url = "/workshop_editions/"+ event.id;
    }

    return url;
});

Vue.filter('urlClassOffer', function(event){
    let url = "#";

    if(event){
        url = "/class_offers/"+ event.id;
    }

    return url;
});

Vue.filter('urlClassOfferEdition', function(event){
    let url = "#";

    if(event){
        url = "/class_offer_editions/"+ event.id;
    }

    return url;
});

Vue.filter('urlRedirectEventType', function(event){
    return "/events/redirect_to_page?id=" + event.id +"&type=" + event.type;
});

Vue.filter('urlBuyProClub', function(club){
    let url = "#";

    if(club){
        url = "/clubs/pro_membership?cid=" + club.id;
    }

    return url;
});

Vue.filter('urlProgramme', function(programme){
    let url = "#";

    if(programme){
        url = "/programmes/"+ programme.id;
    }

    return url;
});
