$(function(){
    var managerShowUser = {
        _containerVueId: '#pageUserShowContentId',
        _vueObjDetailPage: null,
        _cropperUserImage: null,

        initVueDetailPage: function(){
            var manager = this;

            manager._vueObjDetailPage = new Vue({
                el: manager._containerVueId,

                data: {
                    containerId: manager._containerVueId,
                    userId: 0,
                    user: null,
                    currentUserId: -1,
                    currentUser: null,
                    currentUserIsAdmin: 'no',
                    tokenCsrf: null,
                    photo: { modal: $("#modalUserPhotoUpdateId"), image: $("#userPhotoUploadId") },
                    eventsJoined: null,
                    currentUserEventsIdsJoined: [],
                    currentUserClubsIdsJoined: [],
                    sectionActive: 'basic_information',
                    sendingForm: 'no',
                    alreadyInit: 'no',
                    formData: { name: null, name_last: null, email: null, country_id: null, city: null, timezone: null, university: { name: null, url: null }, gender: null, about: null, social_media: { scopus: null, research_gate: null, web_of_science: null, science_direct: null, orcid: null, google_scholar: null, linkedin: null, twitter: null, facebook: null, instagram: null, youtube: null }, my_thesis: { title: null, nature_research: null, current_stage_id: null }, doctoral_study_status: { status: null, month: null, year: null }, research_experience: { research_methods_tools: { id: 0, list: [] }, data_collection_techiques: { id: 0, list: [] }, languages: { id: 0, list: [] }, research_sectors: { id: 0, list: [] }, research_areas: { id: 0, list: [] } }, publications: [], professional_experience: { industries_worked_at: { id: 0, list: [] }, areas_worked_at: { id: 0, list: [] }, areas_of_expertise: { id: 0, list: [] }, years_experience: null, education: [] } },
                    preselectedOptions: { research_methods_tools: [], data_collection_techiques: [], languages: [], research_sectors: [], research_areas: [], industries_worked_at: [], areas_worked_at: [], areas_of_expertise: [] },
                    preExistingPublications: null,
                    preExistingEducation: null,
                    formErrors: { general: 'no', name: 'no', name_last: 'no', email: 'no', university_name: 'no' },
                    inputsInvalid: { email: 'no' },
                    regexs: { url: /^(ftp|http|https):\/\/[^ "]+$/ , email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ },
                    countries: { list: [], list_dropdown: [] },
                    doctoralJourney: { list: [], list_dropdown: [] },
                    listForDropdownTimezone: [],
                    listForDropdownGender: [ { value: 'female', text: 'Female' }, { value: 'male', text: 'Male' }, { value: 'undisclosed', text: 'I rather not say' } ],
                    listForDropdownThesisResearchNature: [ { value: 'philosophy', text: 'Doctor of Philosophy (PhD or DPhil)' }, { value: 'business_administration', text: 'Doctor of Business Administration (DBA)' }, { value: 'education', text: 'Doctor of Education (Ed.Dor EdD)' }, { value: 'science', text: 'Doctor of Science (D.Sc. or Sc.D)' }, { value: 'arts', text: 'Doctor of Arts (D.A.)' }, { value: 'social_sciences', text: 'Doctor of Social Sciences (D.S.Sc. or DsocSci)' }, { value: 'management', text: 'Doctor of Management (D.M. or D.Mgt.)' }, { value: 'engineering', text: 'Doctor of Engineering (EngD or D.Eng)' }, { value: 'medicine', text: 'Doctor of Medicine (MD or MD (Res))' } ],
                    listForDropdownDoctoralStudyStatus: [ { value: 'on_going', text: 'Studying for a doctoral degree' }, { value: 'finished', text: 'Already received a doctoral degree' }, { value: 'not_started', text: 'Preparing to enrol for doctoral studies' } ],
                    listForDropdownMonths: [],
                    listForDropdownYears: [],
                    updateWysiwygFields: { about: null },
                    sectionModeActive: 'info',
                    getData: { publications: 'no', education: 'no' },
                },

                ready: function(){
                    this.init();
                },

                computed: {
                    showEventsJoined: function(){
                        return this.eventsJoined && this.eventsJoined.length > 0;
                    },

                    isSectionModeInfo: function(){
                        return !this.sectionModeActive || (this.sectionModeActive && this.sectionModeActive == 'info');
                    },

                    isSectionModeForm: function(){
                        return this.sectionModeActive && this.sectionModeActive == 'form';
                    },

                    isSectionActiveBasicInformation: function(){
                        return !this.sectionActive || (this.sectionActive && this.sectionActive == 'basic_information');
                    },

                    isSectionActiveResearchExperience: function(){
                        return this.sectionActive && this.sectionActive == 'research_experience';
                    },

                    isSectionActiveProfessionalExperience: function(){
                        return this.sectionActive && this.sectionActive == 'professional_background';
                    },

                    isSectionActivePublications: function(){
                        return this.sectionActive && this.sectionActive == 'publications';
                    },

                    showSectionBasicInformation: function(){
                        return this.isSectionModeInfo && this.isSectionActiveBasicInformation;
                    },

                    showSectionResearchExperience: function(){
                        return this.isSectionModeInfo && this.isSectionActiveResearchExperience;
                    },

                    showSectionProfessionalExperience: function(){
                        return this.isSectionModeInfo && this.isSectionActiveProfessionalExperience;
                    },

                    showSectionPublications: function(){
                        return this.isSectionModeInfo && this.isSectionActivePublications;
                    },

                    showFormBasicInfomation: function(){
                        return this.isSectionModeForm && this.isSectionActiveBasicInformation;
                    },

                    showFormResearchExperience: function(){
                        return this.isSectionModeForm && this.isSectionActiveResearchExperience;
                    },

                    showFormProfessionalExperience: function(){
                        return this.isSectionModeForm && this.isSectionActiveProfessionalExperience;
                    },

                    showFormPublications: function(){
                        return this.isSectionModeForm && this.isSectionActivePublications;
                    },

                    currentUserIsSameAsUser: function(){
                        return this.userId == this.currentUserId;
                    },

                    currentUserIsSystemAdmin: function(){
                        return this.currentUserIsAdmin == 'yes';
                    },

                    userCanEditDetails: function(){
                        return this.currentUserIsSameAsUser || this.currentUserIsSystemAdmin;
                    },

                    userDoctorateIsOnGoing: function(){
                        return !this.user || !this.formData.doctoral_study_status.status || this.formData.doctoral_study_status.status == 'on_going';
                    },

                    userDoctorateIsNotStarted: function(){
                        return this.formData.doctoral_study_status.status && this.formData.doctoral_study_status.status == 'not_started';
                    },

                    userDoctorateIsFinished: function(){
                        return this.formData.doctoral_study_status.status && this.formData.doctoral_study_status.status == 'finished';
                    },

                    showFormSectionUserThesis: function(){
                        return !this.userDoctorateIsFinished;
                    },

                    showLoadingForm: function(){
                        return this.sendingForm == 'yes' || this.alreadyInit == 'no';
                    },

                    showErrorGeneral: function(){
                        return this.formErrors.general == 'yes';
                    },

                    showErrorName: function(){
                        return this.formErrors.name == 'yes';
                    },

                    showErrorNameLast: function(){
                        return this.formErrors.name_last == 'yes';
                    },

                    showErrorEmail: function(){
                        return this.formErrors.email == 'yes';
                    },

                    showErrorUniversityName: function(){
                        return this.formErrors.university_name == 'yes';
                    },

                    showInputInvalidEmail: function(){
                        return this.inputsInvalid.email == 'yes';
                    }
                },

                events: {
                    'updateName': function(msg){
                        this.formData.name = msg;
                    },
                    'updateNameLast': function(msg){
                        this.formData.name_last = msg;
                    },
                    'updateEmail': function(msg){
                        const vueObj = this;

                        vueObj.formData.email = msg;

                        if(msg && msg.length > 0){
                            if(!vueObj.regexs.email.test(msg)){
                                vueObj.inputsInvalid.email = 'yes';
                            }else{
                                vueObj.inputsInvalid.email = 'no';
                            }
                        }
                    },
                    'updateCountry': function(msg){
                        this.formData.country_id = msg;
                    },
                    'updateCity': function(msg){
                        this.formData.city = msg;
                    },
                    'updateTimezone': function(msg){
                        this.formData.timezone = msg;
                    },
                    'updateUniversityName': function(msg){
                        this.formData.university.name = msg;
                    },
                    'updateUniversityUrl': function(msg){
                        this.formData.university.url = msg;
                    },
                    'updateGender': function(msg){
                        this.formData.gender = msg;
                    },
                    'updateAbout': function(msg){
                        this.formData.about = msg;
                    },
                    'updateSocialMediaScopus': function(msg){
                        this.formData.social_media.scopus = msg;
                    },
                    'updateSocialMediaResearchGate': function(msg){
                        this.formData.social_media.research_gate = msg;
                    },
                    'updateSocialMediaWebOfScience': function(msg){
                        this.formData.social_media.web_of_science = msg;
                    },
                    'updateSocialMediaScienceDirect': function(msg){
                        this.formData.social_media.science_direct = msg;
                    },
                    'updateSocialMediaOrcid': function(msg){
                        this.formData.social_media.orcid = msg;
                    },
                    'updateSocialMediaGoogleScholar': function(msg){
                        this.formData.social_media.google_scholar = msg;
                    },
                    'updateSocialMediaLinkedin': function(msg){
                        this.formData.social_media.linkedin = msg;
                    },
                    'updateSocialMediaTwitter': function(msg){
                        this.formData.social_media.twitter = msg;
                    },
                    'updateSocialMediaFacebook': function(msg){
                        this.formData.social_media.facebook = msg;
                    },
                    'updateSocialMediaInstagram': function(msg){
                        this.formData.social_media.instagram = msg;
                    },
                    'updateSocialMediaYoutube': function(msg){
                        this.formData.social_media.youtube = msg;
                    },
                    'updateMyThesisTitle': function(msg){
                        this.formData.my_thesis.title = msg;
                    },
                    'updateMyThesisNatureResearch': function(msg){
                        this.formData.my_thesis.nature_research = msg;
                    },
                    'updateMyThesisCurrentStage': function(msg){
                        this.formData.my_thesis.current_stage_id = msg;
                    },
                    'updateDoctoralStudyStatus': function(msg){
                        this.formData.doctoral_study_status.status = msg;
                    },
                    'updateDoctoralStudyMonth': function(msg){
                        this.formData.doctoral_study_status.month = msg;
                    },
                    'updateDoctoralStudyYear': function(msg){
                        this.formData.doctoral_study_status.year = msg;
                    },

                    'updateResearchMethodsAndTools': function(msg){
                        this.formData.research_experience.research_methods_tools.id = msg.id;
                        this.formData.research_experience.research_methods_tools.list = msg.list;
                    },

                    'updateDataCollectionTechiques': function(msg){
                        this.formData.research_experience.data_collection_techiques.id = msg.id;
                        this.formData.research_experience.data_collection_techiques.list = msg.list;
                    },

                    'updateLanguages': function(msg){
                        this.formData.research_experience.languages.id = msg.id;
                        this.formData.research_experience.languages.list = msg.list;
                    },

                    'updateResearchSectors': function(msg){
                        this.formData.research_experience.research_sectors.id = msg.id;
                        this.formData.research_experience.research_sectors.list = msg.list;
                    },

                    'updateResearchAreas': function(msg){
                        this.formData.research_experience.research_areas.id = msg.id;
                        this.formData.research_experience.research_areas.list = msg.list;
                    },

                    'updateIndustriesWorkedAt': function(msg){
                        this.formData.professional_experience.industries_worked_at.id = msg.id;
                        this.formData.professional_experience.industries_worked_at.list = msg.list;
                    },

                    'updateAreasWorkedAt': function(msg){
                        this.formData.professional_experience.areas_worked_at.id = msg.id;
                        this.formData.professional_experience.areas_worked_at.list = msg.list;
                    },

                    'updateAreasOfExpertise': function(msg){
                        this.formData.professional_experience.areas_of_expertise.id = msg.id;
                        this.formData.professional_experience.areas_of_expertise.list = msg.list;
                    },

                    'updateEducation': function(msg){
                        this.formData.professional_experience.education = msg;
                    },

                    'updatePublications': function(msg){
                        this.formData.publications = msg;
                    },
                },

                methods: {
                    init: function(){
                        const vueObj = this;

                        vueObj.getCurrentUserInfo();

                        if(vueObj.$els.containerVue){
                            vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                            vueObj.userId = parseInt($(vueObj.$els.containerVue).data('user-id'));
                            vueObj.sectionActive = $(vueObj.$els.containerVue).data("section-active");
                            vueObj.currentUserIsAdmin = $(vueObj.$els.containerVue).data("current-user-is-admin");
                        }

                        vueObj.getUserInfo();

                        if($(vueObj.$els.currentUserId)){
                            vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                        }
    
                        vueObj.getEventsJoined();

                        Vue.nextTick(function () {
                            divExpandManager.check_size_div_text();
                        })
                    },

                    initFormData: function(){
                        const vueObj = this;

                        if(vueObj.user){
                            vueObj.formData.name = vueObj.user.name;
                            vueObj.formData.name_last = vueObj.user.name_last;
                            vueObj.formData.email = vueObj.user.email;
                            vueObj.formData.city = vueObj.user.location;
                            vueObj.formData.country_id = vueObj.user.country_id;
                            vueObj.formData.timezone = vueObj.user.timezone;
                            vueObj.formData.university.name = vueObj.user.university_affiliation;
                            vueObj.formData.university.url = vueObj.user.url_university;
                            vueObj.formData.gender = vueObj.user.gender;
                            vueObj.formData.about = vueObj.user.about;

                            vueObj.formData.social_media.scopus = vueObj.user.url_scopus;
                            vueObj.formData.social_media.research_gate = vueObj.user.url_research_gate;
                            vueObj.formData.social_media.web_of_science = vueObj.user.url_web_of_science;
                            vueObj.formData.social_media.science_direct = vueObj.user.url_science_direct;
                            vueObj.formData.social_media.orcid = vueObj.user.url_orcid;
                            vueObj.formData.social_media.google_scholar = vueObj.user.url_google_scholar;
                            vueObj.formData.social_media.linkedin = vueObj.user.linkedin_url;
                            vueObj.formData.social_media.twitter = vueObj.user.url_twitter;
                            vueObj.formData.social_media.facebook = vueObj.user.url_facebook;
                            vueObj.formData.social_media.instagram = vueObj.user.url_instagram;
                            vueObj.formData.social_media.youtube = vueObj.user.url_youTube;

                            vueObj.formData.doctoral_study_status.status = vueObj.user.doctoral_study_status;
                            vueObj.formData.doctoral_study_status.month = vueObj.user.doctoral_study_start_month;
                            vueObj.formData.doctoral_study_status.year = vueObj.user.doctoral_study_start_year;
                        }

                        vueObj.alreadyInit = 'yes';
                    },

                    eventPhoto: function(event){
                        const component = this;
                        let url = '/images/default/group_session.png';
            
                        if(event.preselected_photo && event.preselected_photo.image_pdf_cover){
                            url ="/storage/" + event.preselected_photo.image_pdf_cover;
                        }else{
                            if(event.photo_default){
                                url ="/storage/" + event.photo_default;
                            }
                        }
            
                        if(event.image_folder_path && event.image_folder_path.length > 0){
                            url = component.$options.filters.urlImageFromFolderPath(event, 'web');
                        }
            
                        return url;
                    },

                    hostName: function(event){
                        const component = this;
            
                        let name = "";
            
                        if(event.started_creation_from && event.started_creation_from == 'learning_club' && event.learning_clubs && event.learning_clubs.length > 0){
                            const club = event.learning_clubs[0];
        
                            name = club.name;
                        }else if(event.tutors && event.tutors.length > 0){
                            const host = event.tutors[0];
        
                            if(host.name){
                                name = host.name;
                            }else if(host.user){
                                name = component.$options.filters.userFullName(host.user);
                            }                
                        }else if(event.owner){
                            name = component.$options.filters.userFullName(event.owner);
                        }
            
                        return name;
                    },
            
                    plusHosts: function(event){
                        const component = this;
            
                        let number = 0;
            
                        if(component.isGroupSession){
                            if(event.started_creation_from && event.started_creation_from == 'learning_club'){
                                if(event.learning_clubs && event.learning_clubs.length > 1){
                                    number = event.learning_clubs.length - 1;
                                }
                            }else if(event.tutors && event.tutors.length > 1){
                                number = event.tutors.length - 1;
                            }
                        }
            
                        if(component.isWebinar && event.tutors && event.tutors.length > 1){
                            number = event.tutors.length - 1;
                        }
            
                        return number;
                    },

                    manageUserPhotoUpload: function(){
                        const vueObj = this;

                        const elemInputFile = vueObj.$els.userphotouploader;

                        const files = $(elemInputFile)[0].files;

                        let done = function(url){
                            if(vueObj.photo.image){
                                vueObj.photo.image.attr("src",url);
                            }

                            if(vueObj.photo.modal){
                                vueObj.photo.modal.foundation('open');
                            }

                        };

                        if(files && files.length > 0){
                            let reader = new FileReader();

                            reader.onload = function(){
                                done(reader.result);
                            };

                            reader.readAsDataURL(files[0]);
                        }
                    },

                    updateUserPhoto: function(){
                        const vueObj = this;
                        let canvas = null;

                        if(manager._cropperUserImage){
                            canvas = manager._cropperUserImage.getCroppedCanvas({ width: 279, height: 394 });

                            canvas.toBlob(function(blob){    
                                const reader = new FileReader();
    
                                reader.readAsDataURL(blob);
                                reader.onloadend = function(){
                                    const base64data = reader.result;
    
                                    vueObj.$http({ 
                                            url: '/json/users/update_photo', method: 'POST', 
                                            contentType: 'multipart/form-data', 
                                            responseType: 'json', 
                                            data: { image: base64data },
                                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                                    }).then(function(response){
                                        //SUCCESS
                                        if(response.data.result == "success"){
                                            if(vueObj.photo.modal){
                                                vueObj.photo.modal.foundation('close');
                                            }

                                            //$(document).trigger("flashNotyAlert", ["success", "MEH"]);

                                            window.location.reload();
                                        }
                                    }, function(response){
                                        //ERROR
                                    });
                                }
                            });
                        }
                    },

                    changeToShowForm: function(){
                        this.sectionModeActive = 'form';
                    },

                    changeToShowInfo: function(){
                        this.sectionModeActive = 'info';
                    },

                    getEventsJoined: function(){
                        const vueObj = this;

                        const url = "/json/users/"+ vueObj.userId +"/events_joined";
    
                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.eventsJoined = response.data.events;
                        }, function(response){
                            //ERROR
                        });
                    },

                    getCurrentUserInfo: function(){
                        const vueObj = this;

                        vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                            vueObj.currentUser = response.data.user;
                            vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                            vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;
                        }, function(response){
                            //ERROR
                        });
                    },

                    getUserInfo: function(){
                        const vueObj = this;

                        const url = "/json/users/"+ vueObj.userId +"/info";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.user = response.data.user;

                            vueObj.$nextTick(() => {
                                if(vueObj.userCanEditDetails){
                                    if(response.data.thesis){
                                        vueObj.setThesisInfo(response.data.thesis);
                                    }

                                    if(vueObj.isSectionActiveBasicInformation){
                                        vueObj.initFormData();
                                        vueObj.getListCountries();
                                        vueObj.getListDoctoralStages();
                                        vueObj.initArrayTimezones();
                                        vueObj.initArrayMonths();
                                        vueObj.initArrayYears();
                                    }

                                    if(vueObj.isSectionActiveResearchExperience){
                                        vueObj.setResearchExperienceListsInfo(response.data.research_experience);
                                        vueObj.alreadyInit = 'yes';
                                    }

                                    if(vueObj.isSectionActiveProfessionalExperience){
                                        vueObj.setWorkedAtIndustries(response.data.worked_at_industries);
                                        vueObj.setWorkedAtAreas(response.data.worked_at_areas);
                                        vueObj.setExpertiseAreas(response.data.expertise_areas);
                                        vueObj.setEducation(response.data.education);
                                        vueObj.setYearsExperience(vueObj.user.years_work_experience);
                                        vueObj.alreadyInit = 'yes';
                                    }

                                    if(vueObj.isSectionActivePublications){
                                        vueObj.setPublications(response.data.publications);
                                        vueObj.alreadyInit = 'yes';
                                    }
                                }
                            });
                        }, function(response){
                            //ERROR
                        });
                    },

                    getListCountries: function(){
                        const vueObj = this;

                        vueObj.$http({ url: "/json/countries", method: 'GET' }).then(function(response){
                            vueObj.countries.list = response.data.countries;

                            if(vueObj.countries.list.length > 0){
                                $.each(vueObj.countries.list, function(index, country){
                                    vueObj.countries.list_dropdown.push({ value: country.id, text: country.name });
                                });
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    getListDoctoralStages: function(){
                        const vueObj = this;

                        vueObj.$http({ url: "/json/dynamic_lists/doctoral_journey", method: 'GET' }).then(function(response){
                            vueObj.doctoralJourney.list = response.data.list;

                            if(vueObj.doctoralJourney.list.length > 0){
                                $.each(vueObj.doctoralJourney.list, function(index, stage){
                                    vueObj.doctoralJourney.list_dropdown.push({ value: stage.id, text: stage.name });
                                });
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    initArrayTimezones: function(){
                        const vueObj = this;
    
                        let url = '/json/timezones';
    
                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.timezones && response.data.timezones.length > 0){
                                $.each(response.data.timezones, function(index, timezone){
                                    vueObj.listForDropdownTimezone.push({ value: timezone, text: timezone });
                                });
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    initArrayMonths: function(){
                        const vueObj = this;

                        $.each(moment.months(), function(index, month){
                            vueObj.listForDropdownMonths.push({ value: month.toLowerCase(), text: month });
                        });
                    },

                    initArrayYears: function(){
                        const vueObj = this;

                        vueObj.listForDropdownYears.push({ value: moment().year(), text: moment().year() });

                        for (let i = 1; i <= 70; i++){
                            const tempYearBefore = moment().subtract(i, 'years').year();
                            const tempYearAfter = moment().add(i, 'years').year();

                            vueObj.listForDropdownYears.unshift({ value: tempYearBefore, text: tempYearBefore });
                            vueObj.listForDropdownYears.push({ value: tempYearAfter, text: tempYearAfter });
                        }
                    },

                    setThesisInfo: function(thesis){
                        const vueObj = this;
                        
                        if(thesis){
                            vueObj.formData.my_thesis.title = thesis.title;
                            vueObj.formData.my_thesis.nature_research = thesis.research_nature;
                            vueObj.formData.my_thesis.current_stage_id = thesis.development_stage_id;
                        }
                    },

                    setResearchExperienceListsInfo: function(researchExperienceLists){
                        const vueObj = this;

                        if(researchExperienceLists){    
                            if('languages' in researchExperienceLists){
                                $.each(researchExperienceLists.languages, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedOptions.languages.push(tempObj);
                                });
                            }
    
                            if('research_sectors' in researchExperienceLists){
                                $.each(researchExperienceLists.research_sectors, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedOptions.research_sectors.push(tempObj);
                                });
                            }
    
                            if('research_areas' in researchExperienceLists){
                                $.each(researchExperienceLists.research_areas, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedOptions.research_areas.push(tempObj);
                                });
                            }

                            if('research_methods_and_tools' in researchExperienceLists){
                                $.each(researchExperienceLists.research_methods_and_tools, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedOptions.research_methods_tools.push(tempObj);
                                });
                            }
    
                            if('data_collection_techiques' in researchExperienceLists){
                                $.each(researchExperienceLists.data_collection_techiques, function(index, segment){
                                    let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                    vueObj.preselectedOptions.data_collection_techiques.push(tempObj);
                                });
                            }
                        }
                    },

                    setWorkedAtIndustries: function(workedAtIndustries){
                        const vueObj = this;

                        $.each(workedAtIndustries, function(index, segment){
                            let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                            vueObj.preselectedOptions.industries_worked_at.push(tempObj);
                        });
                    },

                    setWorkedAtAreas: function(workedAtAreas){
                        const vueObj = this;

                        $.each(workedAtAreas, function(index, segment){
                            let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                            vueObj.preselectedOptions.areas_worked_at.push(tempObj);
                        });
                    },

                    setExpertiseAreas: function(expertiseAreas){
                        const vueObj = this;

                        $.each(expertiseAreas, function(index, segment){
                            let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                            vueObj.preselectedOptions.areas_of_expertise.push(tempObj);
                        });
                    },

                    setEducation: function(education){
                        const vueObj = this;
                        
                        if(education && education.length > 0){
                            vueObj.preExistingEducation = education;
                        }
                    },

                    setYearsExperience: function(yearsExperience){
                        const vueObj = this;

                        if(yearsExperience && yearsExperience > 0){
                            vueObj.formData.professional_experience.years_experience = yearsExperience;
                        }
                    },

                    setPublications: function(publications){
                        const vueObj = this;
                        
                        if(publications && publications.length > 0){
                            vueObj.preExistingPublications = publications;
                        }
                    },

                    resetErrors: function(){
                        const vueObj = this;
    
                        vueObj.formErrors.general = 'no';
                        vueObj.formErrors.name = 'no';
                        vueObj.formErrors.name_last = 'no';
                        vueObj.formErrors.email = 'no';
                        vueObj.formErrors.university_name = 'no';
                    },

                    validateForm: function(){
                        const vueObj = this;

                        vueObj.resetErrors();

                        let formIsValid = 'yes';

                        if(vueObj.isSectionActiveBasicInformation){
                            if(vueObj.inputsInvalid.email == 'yes'){
                                formIsValid = "no";
                            }

                            if(formIsValid == 'yes'){
                                if(!vueObj.formData.name || vueObj.formData.name.replace(/\s/g, "").length <= 0){
                                    vueObj.formErrors.name = 'yes';
                                    formIsValid = "no";
                                }

                                if(!vueObj.formData.name_last || vueObj.formData.name_last.replace(/\s/g, "").length <= 0){
                                    vueObj.formErrors.name_last = 'yes';
                                    formIsValid = "no";
                                }

                                if(!vueObj.formData.email || vueObj.formData.email.replace(/\s/g, "").length <= 0){
                                    vueObj.formErrors.email = 'yes';
                                    formIsValid = "no";
                                }

                                if(!vueObj.formData.university.name || vueObj.formData.university.name.replace(/\s/g, "").length <= 0){
                                    vueObj.formErrors.university_name = 'yes';
                                    formIsValid = "no";
                                }
                            }
                        }

                        if(formIsValid == 'no'){
                            vueObj.formErrors.general = 'yes';
                        }

                        return formIsValid;
                    },

                    makeParams: function(){
                        const vueObj = this;

                        let params = new FormData();

                        if(vueObj.isSectionActivePublications){
                            vueObj.getData.publications = 'yes';
                        }

                        if(vueObj.isSectionActiveProfessionalExperience){
                            vueObj.getData.education = 'yes';
                        }

                        vueObj.$nextTick(() => {
                            if(vueObj.isSectionActiveBasicInformation){
                                params.append('name', vueObj.formData.name);
                                params.append('name_last', vueObj.formData.name_last);
                                params.append('email', vueObj.formData.email);
                                params.append('gender', vueObj.formData.gender);
                                params.append('timezone', vueObj.formData.timezone);

                                if(vueObj.formData.city && vueObj.formData.city.replace(/\s/g, "").length > 0){
                                    params.append('location', vueObj.formData.city);
                                }

                                if(vueObj.formData.country_id){
                                    params.append('country_id', vueObj.formData.country_id);
                                }

                                if(vueObj.formData.about && vueObj.formData.about.replace(/\s/g, "").length > 0){
                                    params.append('about', vueObj.formData.about);
                                }
        
                                if(vueObj.formData.doctoral_study_status.status && vueObj.formData.doctoral_study_status.status.replace(/\s/g, "").length > 0){
                                    params.append('doctoral_study_status', vueObj.formData.doctoral_study_status.status);
                                }

                                if(vueObj.formData.doctoral_study_status.month && vueObj.formData.doctoral_study_status.month.replace(/\s/g, "").length > 0){
                                    params.append('doctoral_study_start_month', vueObj.formData.doctoral_study_status.month);
                                }

                                if(vueObj.formData.doctoral_study_status.year){
                                    params.append('doctoral_study_start_year', vueObj.formData.doctoral_study_status.year);
                                }
        
                                if(vueObj.formData.my_thesis.title && vueObj.formData.my_thesis.title.replace(/\s/g, "").length > 0){
                                    params.append('thesis_title', vueObj.formData.my_thesis.title);
                                }

                                if(vueObj.formData.my_thesis.nature_research && vueObj.formData.my_thesis.nature_research.replace(/\s/g, "").length > 0){
                                    params.append('thesis_research_nature', vueObj.formData.my_thesis.nature_research);
                                }

                                if(vueObj.formData.my_thesis.current_stage_id && (!vueObj.formData.doctoral_study_status.status || !vueObj.userDoctorateIsFinished)){
                                    params.append('thesis_development_stage_id', vueObj.formData.my_thesis.current_stage_id);
                                }
        
                                if(vueObj.formData.university.name && vueObj.formData.university.name.replace(/\s/g, "").length > 0){
                                    params.append('university_affiliation', vueObj.formData.university.name);
                                }

                                if(vueObj.formData.university.url && vueObj.formData.university.url.replace(/\s/g, "").length > 0){
                                    params.append('url_university', vueObj.formData.university.url);
                                }
        
                                if(vueObj.formData.social_media.scopus && vueObj.formData.social_media.scopus.replace(/\s/g, "").length > 0){
                                    params.append('url_scopus', vueObj.formData.social_media.scopus);
                                }
                                if(vueObj.formData.social_media.research_gate && vueObj.formData.social_media.research_gate.replace(/\s/g, "").length > 0){
                                    params.append('url_research_gate', vueObj.formData.social_media.research_gate);
                                }
                                if(vueObj.formData.social_media.web_of_science && vueObj.formData.social_media.web_of_science.replace(/\s/g, "").length > 0){
                                    params.append('url_web_of_science', vueObj.formData.social_media.web_of_science);
                                }
                                if(vueObj.formData.social_media.science_direct && vueObj.formData.social_media.science_direct.replace(/\s/g, "").length > 0){
                                    params.append('url_science_direct', vueObj.formData.social_media.science_direct);
                                }
                                if(vueObj.formData.social_media.orcid && vueObj.formData.social_media.orcid.replace(/\s/g, "").length > 0){
                                    params.append('url_orcid', vueObj.formData.social_media.orcid);
                                }
                                if(vueObj.formData.social_media.google_scholar && vueObj.formData.social_media.google_scholar.replace(/\s/g, "").length > 0){
                                    params.append('url_google_scholar', vueObj.formData.social_media.google_scholar);
                                }
                                if(vueObj.formData.social_media.twitter && vueObj.formData.social_media.twitter.replace(/\s/g, "").length > 0){
                                    params.append('url_twitter', vueObj.formData.social_media.twitter);
                                }
                                if(vueObj.formData.social_media.linkedin && vueObj.formData.social_media.linkedin.replace(/\s/g, "").length > 0){
                                    params.append('url_linkedin', vueObj.formData.social_media.linkedin);
                                }
                                if(vueObj.formData.social_media.facebook && vueObj.formData.social_media.facebook.replace(/\s/g, "").length > 0){
                                    params.append('url_facebook', vueObj.formData.social_media.facebook);
                                }
                                if(vueObj.formData.social_media.instagram && vueObj.formData.social_media.instagram.replace(/\s/g, "").length > 0){
                                    params.append('url_instagram', vueObj.formData.social_media.instagram);
                                }
                                if(vueObj.formData.social_media.youtube && vueObj.formData.social_media.youtube.replace(/\s/g, "").length > 0){
                                    params.append('url_youTube', vueObj.formData.social_media.youtube);
                                }
                            }

                            if(vueObj.isSectionActiveResearchExperience){
                                params.append('research_experience', JSON.stringify(vueObj.formData.research_experience));
                            }

                            if(vueObj.isSectionActiveProfessionalExperience){
                                params.append('worked_at_industries', JSON.stringify(vueObj.formData.professional_experience.industries_worked_at));
                                params.append('worked_at_areas', JSON.stringify(vueObj.formData.professional_experience.areas_worked_at));
                                params.append('expertise_areas', JSON.stringify(vueObj.formData.professional_experience.areas_of_expertise));

                                params.append('years_experience', vueObj.formData.professional_experience.years_experience);
                                
                                if(vueObj.formData.professional_experience.education && vueObj.formData.professional_experience.education.length > 0){
                                    params.append('education', JSON.stringify(vueObj.formData.professional_experience.education));
                                }else{
                                    params.append('education', []);
                                }
                            }

                            if(vueObj.isSectionActivePublications){
                                if(vueObj.formData.publications && vueObj.formData.publications.length > 0){
                                    params.append('publications', JSON.stringify(vueObj.formData.publications));
                                }else{
                                    params.append('publications', []);
                                }
                            }
                        });

                        return params;
                    },

                    urlToSendFormUpdate: function(){
                        const vueObj = this;

                        if(vueObj.isSectionActiveBasicInformation){
                            return '/json/users/'+ vueObj.user.id +'/basic_info';
                        }

                        if(vueObj.isSectionActiveResearchExperience){
                            return '/json/users/'+ vueObj.user.id +'/research_experience';
                        }

                        if(vueObj.isSectionActiveProfessionalExperience){
                            return '/json/users/'+ vueObj.user.id +'/professional_experience';
                        }

                        if(vueObj.isSectionActivePublications){
                            return '/json/users/'+ vueObj.user.id +'/publications';
                        }

                        return '/';
                    },

                    sendFormUpdate: function(){
                        const vueObj = this;

                        vueObj.sendingForm = 'yes';

                        vueObj.$nextTick(() => {
                            const containerVue = vueObj.$els.containerVue;
    
                            if(containerVue){
                                containerVue.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                            }
                        });

                        const formIsValid = vueObj.validateForm();

                        if(formIsValid && formIsValid == 'yes'){
                            let params = vueObj.makeParams();
                            params.append('_method', 'PATCH');

                            setTimeout(function(){    
                                vueObj.$http({ 
                                    url: vueObj.urlToSendFormUpdate(), method: 'POST', 
                                    contentType: 'multipart/form-data', 
                                    responseType: 'json', 
                                    data: params,
                                    headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                                }).then(function(response){    
                                    if(response.data.status == 'success'){
                                        $(document).trigger("flashNotyAlert", ["success", response.data.message]);
    
                                        vueObj.$nextTick(() => {
                                            setTimeout(function(){
                                                window.onbeforeunload = function(){ window.scrollTo(0, 0); }
                                                window.location.reload();
                                            }, 2000);
                                        });
                                    }else{
                                        vueObj.sendingForm = 'no';

                                        $(document).trigger("flashNotyAlert", ["error", response.data.message]);
                                    }
                                }, function(response){
                                    //ERROR
                                });
                            }, 1500);
                        }else{
                            vueObj.sendingForm = 'no';
                        }
                    }
                }
            });
        },

        initCropper: function(modal){
            const manager = this;

            if(modal){
                let image = modal.find("#userPhotoUploadId");

                manager._cropperUserImage = new Cropper($(image)[0], {
                    aspectRatio: 2/3,
                    viewMode: 2,
                    preview:'.preview'
                });
            }
        },

        destroyCropper: function(){
            const manager = this;

            manager._cropperUserImage.destroy();
            manager._cropperUserImage = null;
        }
    }

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalUserPhotoUpdateId"){
                managerShowUser.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalUserPhotoUpdateId"){
                managerShowUser.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedUserImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        managerShowUser._vueObjDetailPage.updateUserPhoto();

    });

    if(managerShowUser._containerVueId && $(managerShowUser._containerVueId).length > 0){
        managerShowUser.initVueDetailPage();
    }
});
