var eventsSeriesFormManager = {
    _containerFormId: '#containerFormEventsSerieId',
    _formVueObj: null,

    initFormNewOrEditVueObj: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            // element to mount to
            el: manager._containerFormId,
            // initial data
            data: {
                containerFormDivId: manager._containerFormId,

                masterCourse: { is_online: false },
                
                eventsSerieId: null,
                tokenCsrf: null,
                currentUser: null,
                eventsSerie: { id: 0, title: "", short_description: "", description: "", key_takeaways: "", author_description: "", author_name: "", preselected_photo_id: 0, instructions: "", master_course_id: 0 },
                eventsAssociatedIds: [],
                selectedPhotoPreviewUrl: null,
                alreadyPreselectedPhoto: null,
                authorDescriptionFromProfile: true,
                studySegments: { researchMethodsAndTools: { id: 0, list: [] }, dataCollectionTechiques: { id: 0, list: [] }, thesisDevelopmentStages: { id: 0, list: [] } },
                preselectedStudySegments: { researchMethodsAndTools: [], dataCollectionTechiques: [], thesisDevelopmentStages: [] },
                updateWysiwygFields: { short_description: null, description: null, key_takeaways: null, author_description: null }
            },
    
            computed: {
                isUpdateForm: function(){
                    return (this.eventsSerieId && this.eventsSerieId > 0) || (this.eventsSerie && this.eventsSerie.id && this.eventsSerie.id > 0);
                },
                currentEventsSeriesId: function(){
                    if(this.eventsSerie && this.eventsSerie.id && this.eventsSerie.id > 0){
                        return this.eventsSerie.id;
                    }else if(this.eventsSerieId && this.eventsSerieId > 0){
                        return this.eventsSerieId;
                    }

                    return 0;
                },
                formType: function(){
                    if(this.isUpdateForm){
                        return 'edit';
                    }

                    return 'new';
                }
            },
            
            ready: function(){
                const vueObj = this;

                const elemContainerForm = vueObj.$els.containerForm;
                const elemDivEventSerieId = vueObj.$els.divEventsSerieId;

                if(elemContainerForm){
                    vueObj.tokenCsrf = $(elemContainerForm).data('csrf');
                }

                if(elemDivEventSerieId){
                    vueObj.eventsSerieId = $(elemDivEventSerieId).data("events-serie-id");
                }

                if(vueObj.isUpdateForm){
                    vueObj.initFormUpdate();
                }else{
                    vueObj.initFormNew();
                }
            },

            events: {
                'updateTitle': function(msg){
                    this.eventsSerie.title = msg;
                },
                'updateShortDescription': function(msg){
                    this.eventsSerie.short_description = msg;
                },
                'updateDescription': function(msg){
                    this.eventsSerie.description = msg;
                },
                'updateKeyTakeAways': function(msg){
                    this.eventsSerie.key_takeaways = msg;
                },
                'updateAuthorName': function(msg){
                    this.eventsSerie.author_name = msg;
                },
                'updateAuthorDescription': function(msg){
                    this.eventsSerie.author_description = msg;
                },
                'updatePreselectedPhotoId': function(msg){
                    this.eventsSerie.preselected_photo_id = msg.id;
                    this.selectedPhotoPreviewUrl = msg.url;
                },
                'updateResearchMethodsAndTools': function(msg){
                    this.studySegments.researchMethodsAndTools.id = msg.id;
                    this.studySegments.researchMethodsAndTools.list = msg.list;
                },
                'updateDataCollectionTechiques': function(msg){
                    this.studySegments.dataCollectionTechiques.id = msg.id;
                    this.studySegments.dataCollectionTechiques.list = msg.list;
                },
                'updateThesisDevelopmentStages': function(msg){
                    this.studySegments.thesisDevelopmentStages.id = msg.id;
                    this.studySegments.thesisDevelopmentStages.list = msg.list;
                },
                'updateInstructions': function(msg){
                    this.eventsSerie.instructions = msg;
                },
                'updateAssociatedEvents': function(msg){
                    this.eventsAssociatedIds = msg;
                },
                'updateMasterCourseId': function(msg){
                    this.eventsSerie.master_course_id = msg;
                },

                'saveForm': function(msg){
                    this.sendForm();
                }
            },
 
            methods: {
                initFormNew: function(){
                    const vueObj = this;

                    vueObj.getCurrentUserAndSetVars();
                    vueObj.getDefaultInstructionsAndSetVar();
                },

                initFormUpdate: function(){
                    const vueObj = this;
                    
                    vueObj.getCurrentUserAndSetVars();
                    vueObj.getFormDataForEventsSerieAndSetVar();
                },

                getFormDataForEventsSerieAndSetVar: function(){
                    const vueObj = this;

                    let url = '/json/events_series/'+ vueObj.currentEventsSeriesId +'/form_data';

                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        if(response.data.status == 'success'){
                            vueObj.setEventsSerie(response.data.events_serie);
                            vueObj.setPreselectedPhoto(response.data.preselected_photo);
                            vueObj.setPreselectedSegments(response.data.segments);
                            vueObj.setPreselectedAssociatedEvents(response.data.associated_events);
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                setEventsSerie: function(event){
                    const vueObj = this;

                    if(event){
                        vueObj.eventsSerie.id = event.id;
                        vueObj.eventsSerie.title = event.title;
                        vueObj.eventsSerie.short_description = event.short_description;
                        vueObj.eventsSerie.description = event.long_description;
                        vueObj.eventsSerie.key_takeaways = event.key_takeaways;
                        vueObj.eventsSerie.author_description = event.owner_description;
                        vueObj.eventsSerie.author_name = event.owner_name;
                        vueObj.eventsSerie.instructions = event.instructions;
                        
                        if(event.master_event_id){
                            vueObj.eventsSerie.master_course_id = parseInt(event.master_event_id);
                        }
                    }
                },

                setPreselectedPhoto: function(preselectedPhoto){
                    const vueObj = this;

                    if(preselectedPhoto){
                        vueObj.alreadyPreselectedPhoto = preselectedPhoto;
                    }
                },

                setPreselectedSegments: function(segments){
                    const vueObj = this;
     
                    if(segments){
                        if('research_methods_and_tools' in segments){
                            $.each(segments.research_methods_and_tools, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.researchMethodsAndTools.push(tempObj);
                            });
                        }

                        if('data_collection_techiques' in segments){
                            $.each(segments.data_collection_techiques, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.dataCollectionTechiques.push(tempObj);
                            });
                        }

                        if('thesis_development_stages' in segments){
                            $.each(segments.thesis_development_stages, function(index, segment){
                                let tempObj = { id: segment.id, name: segment.name, featured: segment.is_featured };
                                vueObj.preselectedStudySegments.thesisDevelopmentStages.push(tempObj);
                            });
                        }
                    }
                },

                setPreselectedAssociatedEvents: function(associatedEvents){
                    const vueObj = this;
                    
                    if(associatedEvents && associatedEvents.length > 0){
                        $.each(associatedEvents, function(index, event){
                            vueObj.eventsAssociatedIds.push(event.id);
                        });
                    }
                },

                getCurrentUserAndSetVars: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.setCurrentUser(response.data.user);
                        if(!vueObj.isUpdateForm){
                            vueObj.setAuthorDescriptionWithCurrentUserDescription();
                            vueObj.setAuthorNameWithCurrentUserName();
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                setCurrentUser: function(user){
                    const vueObj = this;

                    if(user){
                        vueObj.currentUser = user;
                    }
                },

                setAuthorDescriptionWithCurrentUserDescription: function(){
                    const vueObj = this;

                    if(vueObj.currentUser && vueObj.currentUser.about && vueObj.currentUser.about.length > 0){
                        //let strippedString = vueObj.currentUser.about.replace(/(<([^>]+)>)/gi, "");
                        vueObj.eventsSerie.author_description = vueObj.currentUser.about;
                    }
                },

                setAuthorNameWithCurrentUserName: function(){
                    const vueObj = this;

                    if(vueObj.currentUser && vueObj.currentUser.name){
                        let authorName = vueObj.currentUser.name;

                        if(vueObj.currentUser.name_last){
                            authorName = authorName + " " + vueObj.currentUser.name_last;
                        }

                        vueObj.eventsSerie.author_name = authorName;
                    }
                },

                getDefaultInstructionsAndSetVar: function(){
                    const vueObj = this;
                    
                    vueObj.$http({ url: '/json/event_configurations/series_instructions', method: 'GET' }).then(function(response){
                        vueObj.setDefaultInstructions(response.data.instructions);
                    }, function(response){
                        //ERROR
                    });
                },

                setDefaultInstructions: function(defaultInstructions){
                    const vueObj = this;

                    if(defaultInstructions && defaultInstructions.length > 0){
                        vueObj.eventsSerie.instructions = defaultInstructions;
                    }
                },

                sendForm: function(args){
                    const vueObj = this;

                    args = args || {}; 
                    args.open_pdf_type = args.open_pdf_type || null; 
                    args.publish = args.publish || 'no'; 
                    

                    let url = "/json/events_series";
                    let requestType = "POST";

                    let params = new FormData();

                    params.append('events_serie', JSON.stringify(vueObj.eventsSerie));

                    params.append('segments', JSON.stringify(vueObj.studySegments));

                    params.append('associated_events_ids', vueObj.eventsAssociatedIds);

                    if(vueObj.isUpdateForm){
                        url = url + '/' + vueObj.eventsSerie.id;

                        params.append('_method', 'PATCH');
                    }

                    vueObj.$http({ 
                            url: url, method: requestType, 
                            contentType: 'multipart/form-data', 
                            responseType: 'json', 
                            data: params,
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        if(response.data.status == 'success'){
                            $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                            if(vueObj.formType == 'new'){
                                vueObj.forceUpdateWysiwyg();
                            }

                            if(response.data.events_serie){
                                vueObj.eventsSerie.id = response.data.events_serie.id;
                            }
                        }else{
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                forceUpdateWysiwyg: function(){
                    const vueObj = this;

                    const dateNow = moment().format("YYYYMMDD_HHmmSSSS");

                    vueObj.updateWysiwygFields.short_description = dateNow;
                    vueObj.updateWysiwygFields.description = dateNow;
                    vueObj.updateWysiwygFields.key_takeaways = dateNow;
                    vueObj.updateWysiwygFields.author_description = dateNow;

                    vueObj.$nextTick(() => {
                        vueObj.updateWysiwygFields.short_description = null;
                        vueObj.updateWysiwygFields.description = null;
                        vueObj.updateWysiwygFields.key_takeaways = null;
                        vueObj.updateWysiwygFields.author_description = null;
                    });
                }
            }
        });
    },
};

$(function(){
    if($(eventsSeriesFormManager._containerFormId) && $(eventsSeriesFormManager._containerFormId).length > 0){
        eventsSeriesFormManager.initFormNewOrEditVueObj();
    } 

    $('.accordionFormSeries').on({
        'down.zf.accordion': function(){
           const liActive = $(this).find(".accordion-item.is-active");

           const menuListTabs = $(".containerFormEventsSerie .containerGenericFormAccordion");

           if(menuListTabs){
               $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                   if(liActive && $(elem).data("tab") == liActive.data("name")){
                       $(elem).addClass("active");
                   }
               });
           }
        },
        'up.zf.accordion': function(){
            const liActive = $(this).find(".accordion-item.is-active");

            const menuListTabs = $(".containerFormEventsSerie .containerGenericFormAccordion");

            if(menuListTabs){
                $.each(menuListTabs.find(".listFormMenu li"), function(index, elem){
                    if($(elem).hasClass("active")){
                        $(elem).removeClass("active");
                    }
                });
            }
        }
    });
});
