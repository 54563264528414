$(function(){
    var indexEventsManager = {
        _vueManager: null,

        initVueIndexEvents: function(){
            var manager = this;

            manager._vueManager = new Vue({
				el: '#containerHomeEventsId',

				data: {
                  selectedMonth: null,
                  selectedYear: null,
                  isWaitingForResponse: true,
                  events: null
                },

                filters: {
                    showDay: function (date) {
                        return moment(date).format('DD');
                    },
                    showMonth: function (date) {
                        return moment(date).format('MMMM');
                    },
                    showYear: function (date) {
                        return moment(date).format('YYYY');
                    },
                    formatSummary: function(value){
                        var div = document.createElement("div");
                        div.innerHTML = value;
                        var text = div.textContent || div.innerText || "";
                        return text.substring(0,125)+"..";
                    },
                    formatName: function(name){
                        if(name.length <= 30){
                            return name;
                        }else{
                            return name.substring(0,30)+"..";
                        }
                    },
                    formatDate: function(date){
                        return moment(date).format('DD MMMM YYYY');
                    },
                    formatDateWithTime: function(date){
                        return moment(date).format('DD MMMM YYYY HH:mm');
                    }
                },

				ready: function(){
                    var vueObj = this;

                    vueObj.init();
				},

				methods: {
                    init: function(){
                        var vueObj = this;

                        vueObj.initSelectedMonthAndYear();
                        setTimeout(function(){ vueObj.getEvents(); });
                    },

					initSelectedMonthAndYear: function(){
                        var vueObj = this;
                        
                        var month = new Date().getMonth();
                        var year = new Date().getFullYear();

                        vueObj.selectedMonth = month + 1;
                        vueObj.selectedYear = year;
                    },
                    
                    clickNextMonth: function(){
                        var vueObj = this;

                        if(!vueObj.isWaitingForResponse){
                            if(vueObj.selectedMonth && vueObj.selectedMonth){
                                if(vueObj.selectedMonth + 1 < 13){
                                    vueObj.selectedMonth += 1;
                                }else{
                                    vueObj.selectedMonth = 1;
                                    vueObj.selectedYear += 1;
                                }
                            }

                            vueObj.getEvents();
                        }
                    },

                    clickPreviousMonth: function(){
                        var vueObj = this;

                        if(!vueObj.isWaitingForResponse){
                            if(vueObj.selectedMonth && vueObj.selectedMonth){
                                if(vueObj.selectedMonth - 1 > 0){
                                    vueObj.selectedMonth -= 1;
                                }else{
                                    vueObj.selectedMonth = 12;
                                    vueObj.selectedYear -= 1;
                                }
                            }

                            vueObj.getEvents();
                        }
                    },

                    getEvents: function(){
                        var vueObj = this;

                        vueObj.isWaitingForResponse = true;

                        var url = $("#containerHomeEventsId").data('route');
                        //var url = 'https:' + $("#containerHomeEventsId").data('route');

                        vueObj.$http({ 
                            url: url, 
                            method: 'GET', 
                            responseType: 'json', 
                            data:{ month: vueObj.selectedMonth, year: vueObj.selectedYear } 
                        }).then(function(response){
                            vueObj.events = response.data.events;
                            vueObj.isWaitingForResponse = false;
                            
                        }, function(response){
                            //error callback
                            vueObj.isWaitingForResponse = false;
                        });
                    },

                    getEventImagePath: function(imagePath){
                        return "/storage/"+imagePath;
                    },

                    goToEvent: function(event){
                        var url = $("#containerHomeEventsId").data('event-url') + '?id=' + event.id + '&type=' + event.type;
                        window.location.href = url;
                    }
				}
			});
        }
    }

    if($("#containerHomeEventsId") && $("#containerHomeEventsId").length > 0){
        indexEventsManager.initVueIndexEvents();
    }
});
