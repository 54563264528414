var tablePricingManager = {
    _classTablePrices: ".containerGeneralTablePrices",
    _classForVueObj: ".vueTablePrices",
    _vueObj: null,
    _containerTableServices: "#containerTableServicesId",
    _vueTableServices: null,

    init: function(){
        const manager = this;

        manager.initVueObj();
        manager.initVueObjTableServices();
    },

    initVueObj: function(){
        var manager = this;

        if($(manager._classForVueObj) && $(manager._classForVueObj).length > 0){
            manager._vueObj = new Vue({
                el: manager._classForVueObj,
    
                data: {
                    tableOnDemandIsVisible: "no"
                },
    
                ready: function(){
                    manager.initOrRemoveCarousel();
                },
    
                computed: {
                    isVisibleTablePrices(){
                        return this.tableOnDemandIsVisible == 'no';
                    },
                    isVisibleTableOnDemand(){
                        return this.tableOnDemandIsVisible == 'yes';
                    }
                },
    
                methods: {
                    showTableOnDemand: function(){
                        const vueObj = this;
                        //vueObj.$els.containerForm
    
                        vueObj.tableOnDemandIsVisible = "yes";
    
                        vueObj.$nextTick(() => {
                            manager.updateCarousel();
                            vueObj.slideToTopTable();
                        });
    
                    },
    
                    hideTableOnDemand: function(){
                        const vueObj = this;
    
                        vueObj.tableOnDemandIsVisible = "no";
    
                        vueObj.$nextTick(() => {
                            manager.updateCarousel();
                            vueObj.slideToTopTable();
                        });
                    },
    
                    slideToTopTable: function(){
                        const vueObj = this;
    
                        if(vueObj.$els.containerVue){
                            vueObj.$els.containerVue.scrollIntoView();
                        }
                    }
                }
            });
        }
    },

    initVueObjTableServices: function(){
        const manager = this;

        if($(manager._containerTableServices) && $(manager._containerTableServices).length > 0){
            manager._vueTableServices = new Vue({
                el: manager._containerTableServices,
    
                data: {
                    tableVisible: 'formats'
                },

                ready: function(){
                    manager.initOrRemoveCarousel();
                },
    
                computed: {
                    tableIsVisibleFormats: function(){
                        return this.tableVisible && this.tableVisible == 'formats';
                    },
    
                    tableIsVisibleMonthlySupportPackage: function(){
                        return this.tableVisible && this.tableVisible == 'monthly_support_package';
                    },
    
                    tableIsVisibleOnDemand: function(){
                        return this.tableVisible && this.tableVisible == 'on_demand';
                    },

                    buttonIsVisibleBack: function(){
                        return this.tableIsVisibleMonthlySupportPackage || this.tableIsVisibleOnDemand;
                    },

                    textIsVisibleBuyBrainCoins: function(){
                        return this.tableIsVisibleOnDemand;
                    }
                },
    
                methods: {
                    showTable: function(type){
                        const vueObj = this;

                        vueObj.setTableType(type);
    
                        vueObj.$nextTick(() => {
                            manager.updateCarousel();
                            vueObj.slideToTopContainer();
                        });
                    },

                    setTableType: function(type){
                        const vueObj = this;

                        vueObj.tableVisible = type;
                    },
    
                    slideToTopContainer: function(){
                        const vueObj = this;
    
                        if(vueObj.$els.containerVue){
                            vueObj.$els.containerVue.scrollIntoView();
                        }
                    }
                }
            });
        }
    },

    onWindowResize: function(){
        const manager = this;

        manager.initOrRemoveCarousel();
    },

    updateCarousel: function(){
        const manager = this;

        const divsTablePrices = $(manager._classTablePrices);

        if(divsTablePrices && divsTablePrices.length > 0){
            $.each(divsTablePrices, function(index, div){
                const divTable = $(div);

                if(divTable.hasClass("owl-carousel")){
                    divTable.trigger('refresh.owl.carousel');
                }else{
                    manager.initOrRemoveCarousel();
                }
            });
        }
    },
  
    initOrRemoveCarousel: function(){
        const manager = this;

        const divsTablePrices = $(manager._classTablePrices);

        if(divsTablePrices && divsTablePrices.length > 0){
            $.each(divsTablePrices, function(index, div){
                const divTable = $(div);
                const widthToAddCarousel = divTable.data("carousel");

                if(widthToAddCarousel && $(window).width() <= widthToAddCarousel){
                    if(!divTable.hasClass("owl-carousel")){
                        divTable.addClass("owl-carousel");

                        divTable.owlCarousel({
                            items:1,
                            loop:true,
                            margin:0,
                            autoplay: false,
                            dots: true,
                            nav: true,
                            navText: ['<img src="/images/icon_select_arrow.svg" alt="img">','<img src="/images/icon_select_arrow.svg" alt="img">'],
                            mouseDrag: true,
                            autoHeight: true
                        });
                    }
                }else{
                    manager.removeCarousel(divTable);
                }
            });
        }
    },
  
    removeCarousel: function(divCarousel){
        if(divCarousel && divCarousel.hasClass('owl-carousel')){
            divCarousel.trigger('destroy.owl.carousel');
            divCarousel.removeClass('owl-carousel');
        }
    }
};

$(window).resize(function(){
    tablePricingManager.onWindowResize();
});

$(document).on('change.zf.tabs', function(){
    if($(".tabsWithTableServices") && $(".tabsWithTableServices").length > 0){
        tablePricingManager._vueTableServices.setTableType("formats");
    }
});

$(function(){
    tablePricingManager.init();
});
