var cookieConsentManager = {
    _containerBannerId: '#containerCookieConsentId',

    showBanner: function(){
        var manager = this;

        var divBanner = $(manager._containerBannerId);

        if(divBanner && divBanner.length > 0){
            divBanner.show();
            divBanner.addClass("animated fadeInUp");
        }
    },

    hideBanner: function(){
        var manager = this;

        var divBanner = $(manager._containerBannerId);

        if(divBanner && divBanner.length > 0){
            divBanner.removeClass("fadeInUp");
            divBanner.addClass("fadeOutDown");
        }
    },

    showOrHideBanner: function(){
        var manager = this;

        if(!Cookies.get('dh_accepted_cookies')){
            manager.showBanner();
        }
    },

    clickBtnClose: function(){
        var manager = this;

        manager.hideBanner();

        Cookies.set('dh_accepted_cookies', 'yes', { expires: 365 });
    }
}

$(function(){
    cookieConsentManager.showOrHideBanner();
    
    $("#containerCookieConsentId .divClose").on('click', function(){
        cookieConsentManager.clickBtnClose();
    });
});
