$(function(){
    var userEditManager = {
        _containerFormId: '#containerFormUserEditId',
        _formVueObj: null,

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                el: manager._containerFormId,
                data: {
                    user: { already_finished_study: false, doctoral_study_status: null }
                },

                computed: {
                    showLabelDatesDoctorateStatus: function(){
                        return this.user.doctoral_study_status;
                    },

                    showLabelDatesDoctorateForOnGoing: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "on_going";
                    },

                    showLabelDatesDoctorateForFinished: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "finished";
                    },

                    showLabelDatesDoctorateForNotStarted: function(){
                        return this.user.doctoral_study_status && this.user.doctoral_study_status == "not_started";
                    }
                },

                methods: {

                }
            });
        }
    }

    if($(userEditManager._containerFormId) && $(userEditManager._containerFormId).length > 0){
        userEditManager.initFormVueObj();
    }
});
