var menuHorizontalScrollManager = {
    // duration of scroll animation
    _scrollDuration: 800,
    // paddles
    _leftPaddle: '.menuHorizontalScroll .left-paddle',
    _rightPaddle: '.menuHorizontalScroll .right-paddle',
    // get items dimensions
    _itemsLength: 0,
    _itemSize: 0,
    // get some relevant size for the paddle triggering point
    _paddleMargin: 20,
    _menuWrapperSize: 0,
    _menuInvisibleSize: 0,

    init: function(){
        const manager = this;

        manager.setMenuWrapperSize();

        manager._itemsLength = $('.menuHorizontalScroll .menu .item').length;
        manager._itemSize = $('.menuHorizontalScroll .menu .item').outerWidth(true);
        manager._menuInvisibleSize = this.getMenuSize() - this._menuWrapperSize;

        manager.checkShowOrHidePaddlesOnInitAndResize();

        // print important values
        /*$('#print-wrapper-size span').text(manager._menuWrapperSize);
        $('#print-menu-size span').text(manager.getMenuSize());
        $('#print-menu-invisible-size span').text(manager._menuInvisibleSize);
        $('#print-menu-position span').text(manager.getMenuPosition());*/
    },

    setMenuWrapperSize: function(){
        this._menuWrapperSize = $('.menuHorizontalScroll').outerWidth();
    },

    onMenuScroll: function(){
        const manager = this;

        // get how much of menu is invisible
        manager._menuInvisibleSize = manager.getMenuSize() - manager._menuWrapperSize;
        // get how much have we scrolled so far
        const menuPosition = manager.getMenuPosition();
    
        const menuEndOffset = manager._menuInvisibleSize - manager._paddleMargin;
    
        // show & hide the paddles 
        // depending on scroll position
        if (menuPosition <= manager._paddleMargin) {
            $(manager._leftPaddle).addClass('hidden');
            $(manager._rightPaddle).removeClass('hidden');
        } else if (menuPosition < menuEndOffset) {
            // show both paddles in the middle
            $(manager._leftPaddle).removeClass('hidden');
            $(manager._rightPaddle).removeClass('hidden');
        } else if (menuPosition >= menuEndOffset) {
            $(manager._leftPaddle).removeClass('hidden');
            $(manager._rightPaddle).addClass('hidden');
        }
    },

    checkShowOrHidePaddlesOnInitAndResize: function(){
        const manager = this;

        if(manager.getMenuSize() > manager._menuWrapperSize){
            $(manager._rightPaddle).removeClass('hidden');
        }else{
            if(!$(manager._rightPaddle).hasClass('hidden')){
                $(manager._rightPaddle).addClass('hidden');
            }
        }
    },

    getMenuSize: function(){
        const manager = this;

        return manager._itemsLength * manager._itemSize;
    },

    // get how much have we scrolled to the left
    getMenuPosition: function() {
        return $('.menuHorizontalScroll .menu').scrollLeft();
    }   
};

$(function(){
    menuHorizontalScrollManager.init();

    $(window).on('resize', function() {
        menuHorizontalScrollManager.setMenuWrapperSize();

        menuHorizontalScrollManager.checkShowOrHidePaddlesOnInitAndResize();
    });

    // size of the visible part of the menu is equal as the wrapper size 
    //menuVisibleSize = menuWrapperSize;

    // finally, what happens when we are actually scrolling the menu
    $('.menuHorizontalScroll .menu').on('scroll', function() {
        menuHorizontalScrollManager.onMenuScroll();
    });
    
    // scroll to left
    $(menuHorizontalScrollManager._rightPaddle).on('click', function() {
        $('.menuHorizontalScroll .menu').animate( { scrollLeft: menuHorizontalScrollManager.getMenuPosition() + 200 }, menuHorizontalScrollManager._scrollDuration);
    });
    
    // scroll to right
    $(menuHorizontalScrollManager._leftPaddle).on('click', function() {
        $('.menuHorizontalScroll .menu').animate( { scrollLeft: menuHorizontalScrollManager.getMenuPosition() - 200 }, menuHorizontalScrollManager._scrollDuration);
    });
});
