var managerUserSettings = {
    _containerVue: "#containerPageUserSettings",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                sectionActive: 'forum',
                tokenCsrf: null,                
                forumFrequency: null,
                emailFrqequencyDigest: null,
            },

            ready: function(){
                this.init();
            },

            computed: {
                isSectionActiveForum: function(){
                    return !this.sectionActive || this.sectionActive == 'forum';
                },

                isSectionActiveEmailSubscriptions: function(){
                    return this.sectionActive && this.sectionActive == 'email_subscriptions';
                },

                isSectionActiveChangePassword: function(){
                    return this.sectionActive && this.sectionActive == 'change_password';
                },

                isSectionActiveCancelAccount: function(){
                    return this.sectionActive && this.sectionActive == 'cancel_account';
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.sectionActive = $(vueObj.$els.containerVue).data("section-active");
                    }
                },

                onChangeForumFrequency: function(){
                    const vueObj = this;

                    vueObj.$http({ 
                        url: '/json/users/update_forum_frequency', method: 'POST', 
                        responseType: 'json', 
                        data: { frequency: vueObj.forumFrequency },
                        headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        //SUCCESS
                        if(response.data.result == "success"){
                            $(document).trigger("flashNotyAlert", ["success", "Updated!"]);
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                onChangeEmailFrequencyDigest: function(){
                    const vueObj = this;

                    vueObj.$http({ 
                        url: '/json/users/update_email_frequency_digest_current_user', method: 'POST', 
                        responseType: 'json', 
                        data: { frequency: vueObj.emailFrqequencyDigest },
                        headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                    }).then(function(response){
                        //SUCCESS
                        if(response.data.result == "success"){
                            $(document).trigger("flashNotyAlert", ["success", "Updated!"]);
                        }
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    },

    submitFormUserPrivacyUpdate: function(clickedCheckbox){
        var manager = this;

        var divParent = clickedCheckbox.parents('.containerCheckboxUserPrivacy');
        var form = divParent.find('form');

        if(form){
            var divContainerListCheckboxes = $(manager._divClassContainerListCheckboxesPrivacy);
            
            if(divContainerListCheckboxes){
                var divContainerListCheckboxesInner = divContainerListCheckboxes.find(".containerListCheckboxesUserPrivacyInner");

                if(divContainerListCheckboxesInner && !divContainerListCheckboxesInner.hasClass('blur2px')){
                    divContainerListCheckboxesInner.addClass('blur2px');
                }

                var divContainerLoading = divContainerListCheckboxes.find(".containerWaitingForRequest");

                if(divContainerLoading && divContainerLoading.hasClass('hide')){
                    divContainerLoading.removeClass('hide');
                }
            }
            form.submit();
        }
    },

};

$(document).on('click', '.checkboxUserPrivacyV1', function(){
    managerUserSettings.submitFormUserPrivacyUpdate($(this));
});

$(function(){
    if($(managerUserSettings._containerVue) && $(managerUserSettings._containerVue).length > 0){
        managerUserSettings.initVueObj();
    }
});
