var managerSectionInviteFriend = {
    _containerVueId: "#sectionContainerInviteFriendId",
    _vueManager: null,

    initVue: function(){
        const manager = this;

        manager._vueManager = new Vue({
            el: manager._containerVueId,

            data: {
              sectionIsOpen: false,
              urlToInviteFriends: "",
              inputSelectedClassVisible: false,
              taggleEmails: null,
              isSendingEmail: 'no',
              tokenCsrf: '',
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    const code = $(vueObj.$els.containerVue).data("code");
                    vueObj.urlToInviteFriends = vueObj.$options.filters.urlUserInviteFriends(code);

                    vueObj.tokenCsrf = $(vueObj.$els.containerVue).data("token-csrf");
                }

                if(vueObj.$els.containerTaggleListEmails){
                    vueObj.taggleEmails = new Taggle('taggleListEmailsId', {
                        placeholder: 'Add up to 100 email addresses here',
                        allowDuplicates: false,
                        submitKeys: [13, 32],
                        maxTags: 100,
                        onTagAdd: function(event, tag){
                            const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                            if(!pattern.test(tag)){
                                vueObj.taggleEmails.remove(tag, true);
                            }
                        }
                    });
                }

                if(vueObj.$els.openAtStart && $(vueObj.$els.openAtStart).data("status") && $(vueObj.$els.openAtStart).data("status") == 'yes'){
                    vueObj.$nextTick(() => {
                        vueObj.openSection();
                    });
                }
            },

            computed: {
                showBtnSendEmails: function(){
                    return this.isSendingEmail && this.isSendingEmail == 'no';
                }
            },

            methods: {
                toggleSectionVisibility: function(){
                    const vueObj = this;

                    if(vueObj.sectionIsOpen){
                        vueObj.closeSection();
                    }else{
                        vueObj.openSection();
                    }
                },

                openSection: function(){
                    const vueObj = this;

                    vueObj.sectionIsOpen = true;				
                },

                closeSection: function(){
                    const vueObj = this;

                    vueObj.sectionIsOpen = false;			
                },

                copyToClipboardLink: function(origin){
                    const vueObj = this;

                    if(origin && origin == 'input'){
                        vueObj.inputSelectedClassVisible = true;
                    }

                    navigator.clipboard.writeText(vueObj.urlToInviteFriends);

                    $(document).trigger("flashNotyAlert", ["success", "Your invite link has been copied."]);
                },

                removeSelectedFromInputLink: function(){
                    this.inputSelectedClassVisible = false;
                },

                sendEmails: function(){
                    const vueObj = this;

                    vueObj.isSendingEmail = 'yes';

                    const emailsToSendInvite = vueObj.taggleEmails.getTagValues();

                    if(emailsToSendInvite && emailsToSendInvite.length > 0){
                        const url = "/json/user_invites/send_emails";

                        let params = new FormData();
                        params.append('emails', emailsToSendInvite);

                        vueObj.$http({ url: url, method: 'POST', data: params, headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }}).then(function(response){
                            if(response.data.status && response.data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", "Your invitations will be sent soon."]);
                                vueObj.isSendingEmail = 'no';

                                vueObj.taggleEmails.removeAll();
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                                vueObj.isSendingEmail = 'no';
                            }
                        }, function(response){
                            //ERROR
                        });
                    }else{
                        vueObj.isSendingEmail = 'no';
                    }
                }
            }
        });
    }
};

$(function(){
    if($(managerSectionInviteFriend._containerVueId) && $(managerSectionInviteFriend._containerVueId).length > 0){
        managerSectionInviteFriend.initVue();
    }

    $(document).on('click', function(e){
        if(!$(e.target).closest(".sectionContainerInviteFriend .containerInviteFriend").length && !$(e.target).closest(".taggle .close").length && $(".sectionContainerInviteFriend").hasClass("active")){
            managerSectionInviteFriend._vueManager.closeSection();
        }

        if(!$(e.target).closest(".sectionContainerInviteFriend .containerInviteFriend .divContent .containerInputLink input[type='text']").length && $(".sectionContainerInviteFriend .containerInviteFriend .divContent .containerInputLink input[type='text']").hasClass("selected")){
            managerSectionInviteFriend._vueManager.removeSelectedFromInputLink();
        }
    });

    $(".linkInviteFriends").on("click", function(e){
        e.stopPropagation();
        e.preventDefault();

        managerSectionInviteFriend._vueManager.toggleSectionVisibility();
    });
});
