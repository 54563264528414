var doctoralJourneyModuleManager = {
    _containerVue: "#containerModuleDoctoralJourneysId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                activeSlideNumber: 1
            },

            ready: function(){
                this.initCustomScroll();
            },

            computed: {
                showSlideOne: function(){
                    return (this.activeSlideNumber && this.activeSlideNumber == 1) || !this.activeSlideNumber;
                },

                showSlideTwo: function(){
                    return this.activeSlideNumber && this.activeSlideNumber == 2;
                },

                showSlideThree: function(){
                    return this.activeSlideNumber && this.activeSlideNumber == 3;
                },

                showSlideFour: function(){
                    return this.activeSlideNumber && this.activeSlideNumber == 4;
                },

                showSlideFive: function(){
                    return this.activeSlideNumber && this.activeSlideNumber == 5;
                },

                showSlideSix: function(){
                    return this.activeSlideNumber && this.activeSlideNumber == 6;
                }
            },

            methods: {
                changeActiveSlide: function(numberSlide){
                    this.activeSlideNumber = numberSlide;
                },

                initCustomScroll: function(){
                    const component = this;
        
                    setTimeout(function(){
                        if(component.$els.divList){
                            $(component.$els.divList).mCustomScrollbar({
                                theme:"inset",
                                axis: "y",
                                autoDraggerLength: false,
                                scrollbarPosition: "inside"
                            });
                        }
                    }, 2000);
                },
            }
        });
    }

};

$(function(){
    if($(doctoralJourneyModuleManager._containerVue) && $(doctoralJourneyModuleManager._containerVue).length > 0){
        doctoralJourneyModuleManager.initVueObj();
    }
});
