var eventDetailPageCarouselManager = {
    _carouselTasksClass: ".containerProgramListTasks",

    initCarousels: function(){
        const manager = this;

        if(false && $(manager._carouselTasksClass) && $(manager._carouselTasksClass).length > 0){
            manager.initCarouselTasks();
        }
    },

    initCarouselTasks: function(){
        const manager = this;

        $(manager._carouselTasksClass).owlCarousel({
            items: 1,
            loop:true,
            margin:0,
            autoplay: false,
            dots: true,
            mouseDrag: true,
            autoHeight: true,
        });
    }
};

$(function(){
    var sliderOwlCarouselPageShowResidence = $(".owl-carousel.pageShowEvent");

    if(sliderOwlCarouselPageShowResidence.length > 0){
        sliderOwlCarouselPageShowResidence.owlCarousel({
            items:1,
            loop:true,
            margin:0,
            autoplay: true,
            autoplayTimeout:3500,
            dots: true,
            mouseDrag: false,
            animateOut: 'fadeOut'
        });
    }

    var sliderOwlCarouselSharedEventTypes = $(".containerSharedCarouselEventTypes .containerOwlCarousel");

    if(sliderOwlCarouselSharedEventTypes && sliderOwlCarouselSharedEventTypes.length > 0){
        sliderOwlCarouselSharedEventTypes.owlCarousel({
            items: 1,
            loop:true,
            margin:0,
            autoplay: false,
            autoplayTimeout:5000,
            dots: true,
            mouseDrag: true,
            autoHeight: true,
        });

        setTimeout(function(){ sliderOwlCarouselSharedEventTypes.trigger('refresh.owl.carousel'); }, 1500);
    }

    $(document).on('click', '.linkSharedEventTypesGoToSlide', function(e){
        e.preventDefault();
        e.stopPropagation();

        if(sliderOwlCarouselSharedEventTypes && sliderOwlCarouselSharedEventTypes.length > 0){
            var clickedElem = $(this);

            if(!clickedElem.hasClass("active")){
                var slideToGo = clickedElem.data("slide-nr");
    
                sliderOwlCarouselSharedEventTypes.trigger('to.owl.carousel', slideToGo);
            }
        }
    });

    var eventDetailPageManager = {
        _containerNavButtonsId: '#containerPageNavButtonsId',
        _navButtonsVueObj: null,

        initAllVueObjs: function(){
            var manager = this;

            if($(".containerPageEventDetail #containerPageNavButtonsId") && $(".containerPageEventDetail #containerPageNavButtonsId").length > 0){
                manager.initVueObjNavButtons();
            }
        },

        initVueObjNavButtons: function(){
            var manager = this;

            manager._navButtonsVueObj = new Vue({
                // element to mount to
                el: manager._containerNavButtonsId,
                // initial data
                data: {
                    containerFormDivId: manager._containerNavButtonsId,
                    changeLabels: 'yes',
                },

				ready: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        if($(vueObj.$els.containerVue).data('change-labels') && $(vueObj.$els.containerVue).data('change-labels') == 'no'){
                            vueObj.changeLabels = 'no';
                        }
                    }
                    
                    vueObj.checkUrlParamsForAction();
                },
                
                // methods
                methods: {
                    checkUrlParamsForAction: function(){
                        var vueObj = this;

                        var action = manager.getUrlParameter('action');

                        if(action){
                            switch(action){
                                case 'schedule':
                                    manager.goToAndOpenTabRegister('schedule', vueObj.changeLabels);
                                    break;
                                case 'register':
                                    manager.goToAndOpenTabRegister('register', vueObj.changeLabels);
                                    break;
                                case 'reschedule':
                                    manager.goToAndOpenTabRegister('reschedule', vueObj.changeLabels);
                                    break;
                                case 'slides':
                                    vueObj.goToSlides();
                                    break;
                            }
                        }
                    },

                    goToTabRegister: function(type){
                        manager.goToAndOpenTabRegister(type, this.changeLabels);
                    },

                    goToTabWatchReplay: function(){
                        manager.goToAndOpenTabWatchReplay();
                    },

                    goToSlides: function(){
                        var vueObj = this;

                        var titleSlides = $('#containerEventSlidesTitleId');
            
                        if(titleSlides && titleSlides.length > 0){            
                            $('html, body').animate({
                                scrollTop: titleSlides.offset().top - 10
                            }, 1500);
                        }
                    },
                }
            });
        },

        getUrlParameter: function(sParam){
            var manager = this;

            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
        
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
        
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        },

        goToAndOpenTabRegister: function(type, changeLabels){
            var manager = this;

            var linkTabRegister = $(".eventShowDetail.tabs .tabs-title.register a");

            if(linkTabRegister.hasClass("linkAccesssMasterCourseEdition")){
                manager.goToCourseEdition(linkTabRegister);
            }else{
                if(linkTabRegister && linkTabRegister.length > 0){
                    if(!changeLabels || (changeLabels && changeLabels == 'yes')){
                        manager.changeLabelsRegisterFormAndTab(type);
                    }

                    linkTabRegister.trigger("click");
    
                    $('html, body').animate({
                        scrollTop: linkTabRegister.offset().top - 100
                    }, 1500);
                }
            }

        },

        goToAndOpenTabWatchReplay: function(){
            var manager = this;

            var linkTabWatchReplay = $('.eventShowDetail.tabs .tabs-title.watchReplay a');

            if(linkTabWatchReplay && linkTabWatchReplay.length > 0){
                linkTabWatchReplay.trigger("click");

                $('html, body').animate({
                    scrollTop: linkTabWatchReplay.offset().top
                }, 1500);
            }
        },

        goToAndOpenTabMessageBoard: function(){
            var manager = this;

            var linkTabMessageBoard = $('.eventShowDetail.tabs .tabs-title.messageBoard a');

            if(linkTabMessageBoard && linkTabMessageBoard.length > 0){
                linkTabMessageBoard.trigger("click");

                $('html, body').animate({
                    scrollTop: linkTabMessageBoard.offset().top
                }, 1500);
            }
        },

        changeLabelsRegisterFormAndTab: function(type){
            var manager = this;

            var labelAllSpans = $(".eventShowDetail.tabs .tabs-title.register a span");

            var labelRegister = $(".eventShowDetail.tabs .tabs-title.register a span.register");
            var labelSchedule = $(".eventShowDetail.tabs .tabs-title.register a span.schedule");
            var labelReschedule= $(".eventShowDetail.tabs .tabs-title.register a span.reschedule");

            if(labelAllSpans && labelAllSpans.length > 0){
                $.each(labelAllSpans, function(index, value){
                    if(!$(value).hasClass("hide")){
                        $(value).addClass("hide");
                    }
                });
    
                switch(type){
                    case 'schedule':
                        if(labelSchedule){
                            labelSchedule.removeClass("hide");
                        }
                        break;
                    case 'register':
                        if(labelRegister){
                            labelRegister.removeClass("hide");
                        }
                        break;
                    case 'reschedule':
                        if(labelReschedule){
                            labelReschedule.removeClass("hide");
                        }
                        break;
                    default:
                        if(labelSchedule){
                            labelSchedule.removeClass("hide");
                        }
                }
            }
        },

        goToCourseEdition: function(linkToMasterCourseEdition){
            if(linkToMasterCourseEdition.length > 0){
                var url = linkToMasterCourseEdition.data('url');

                if(url){
                    window.location.href = url;
                }
            }
        }
    };

    eventDetailPageManager.initAllVueObjs();

    $(document).on('click', '.containerPageEventDetail #containerPlaceholderSlideId a', function(e){
        e.preventDefault();
        e.stopPropagation();
        
        eventDetailPageManager.goToAndOpenTabWatchReplay();
    });

    $(document).on('click', '.messageBoardLinkRegisterToEvent', function(e){
        e.preventDefault();
        e.stopPropagation();
        
        eventDetailPageManager.goToAndOpenTabRegister('register', 'yes');
    });

    $(document).on('click', '.eventDetailLinkGoToMessageBoard', function(e){
        e.preventDefault();
        e.stopPropagation();
        
        eventDetailPageManager.goToAndOpenTabMessageBoard();
    });

    $(document).on('change.zf.tabs', function(){
        if($(".tabs-title.register").hasClass("is-active")){
            var linkToMasterCourseEdition = $(".tabs-title.register").find(".linkAccesssMasterCourseEdition");

            eventDetailPageManager.goToCourseEdition(linkToMasterCourseEdition);
        }
    });


    eventDetailPageCarouselManager.initCarousels();
});
