$(function(){
  if($("#s2gApiContainer").length > 0){

    var s2gApiManager = {
      _s2gVue: new Vue({
        el: '#s2gApiContainer',

        data: {
          group: {},
          activities: [],
          apiEmail: 'andreas@scale2go.com',
          apiToken: 'ywUY2jXKSxqaU_z7P9zE',
          apiGroupId: 452
        },

        ready:function(){
          if($(".container-group.s2g").length > 0){
            var apiGetGroupUrl = 'http://www.scale2go.com/api/list_groups';
            var params = { api_user_email: this.apiEmail,
                           api_user_token: this.apiToken,
                           groups_ids: this.apiGroupId
                         };


            this.$http.get(apiGetGroupUrl, params).then(function (response) {
              // success callback
              if(response.data.request_success && response.data.request_success === true && response.data.groups.length > 0){
                this.group = response.data.groups[0];
              }
            }, function (response) {
              //error
            });
          }

          // DO AJAX REQUEST
          if($(".container-activities.s2g").length > 0){
            var apiGetActivitiesUrl = 'http://www.scale2go.com/api/groups/' + this.apiGroupId + '/activities';
            var params = { api_user_email: this.apiEmail,
                           api_user_token: this.apiToken
                         };

            this.$http.get(apiGetActivitiesUrl, params).then(function (response) {
              // success callback
              if(response.data.request_success && response.data.request_success === true && response.data.total_activities > 0){
                this.activities = response.data.activities;
              }
            }, function (response) {
              //error
            });
          }
        }
      }),
    };
  }
});
