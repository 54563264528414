$(function(){
    /******** Owl carousel ********/
    var sliderOwlCarouselOpeningPage = $(".owl-carousel.openingPage");

    if(sliderOwlCarouselOpeningPage.length > 0){
        sliderOwlCarouselOpeningPage.owlCarousel({
            items:1,
            loop:true,
            margin:0,
            autoplay: true,
            autoplayTimeout:5000,
            dots: true,
            mouseDrag: false,
            animateOut: 'fadeOut'
        });
    }

    $(".owl-carousel.openingPage .divBtnSlideNav").on("click", function(e){
        e.stopPropagation();

        var clickedDiv = $(this);

        if(sliderOwlCarouselOpeningPage){
            if(clickedDiv.hasClass("next")){
                sliderOwlCarouselOpeningPage.trigger('next.owl.carousel');
            }else{
                sliderOwlCarouselOpeningPage.trigger('prev.owl.carousel');
            }
        }
    });

    var sliderOwlCarouselSectionIndexFeatured = $(".sectionIndexFeatured .owlCarouselFeatured");

    if(sliderOwlCarouselSectionIndexFeatured.length > 0){
        sliderOwlCarouselSectionIndexFeatured.owlCarousel({
            margin:10,
            responsiveClass:true,
            navText: [
                '<i class="fa faChevronLeft" aria-hidden="true"></i>',
                '<i class="fa faChevronRight" aria-hidden="true"></i>'
            ],
            navContainer: '.sectionIndexFeatured .custom-nav',
            responsive:{
                0:{
                    items:1,
                    nav:true,
                    loop:true
                },
                600:{
                    items:2,
                    nav:false,
                    loop:true
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:true
                }
            }
        })
    }

    var sliderOwlCarouselSectionIndexServices = $(".containerHomeServices .owlCarouselServices");

    if(sliderOwlCarouselSectionIndexServices.length > 0){
        sliderOwlCarouselSectionIndexServices.owlCarousel({
            margin:10,
            responsiveClass:true,
            autoWidth: true,
            navText: [
                '<i class="fa faChevronLeft" aria-hidden="true"></i>',
                '<i class="fa faChevronRight" aria-hidden="true"></i>'
            ],
            navContainer: '.containerHomeServices .custom-nav',
            responsive:{
                0:{
                    items:1,
                    nav:true,
                    dots: false
                },
                600:{
                    items:2,
                    nav:true,
                    dots: false
                },
                960:{
                    items:3,
                    nav:true,
                    dots: false
                },
                1400:{
                    items:4,
                    nav:true,
                    dots: false
                },
                1435:{
                    items:5,
                    nav:true,
                    dots: false
                },
                1670:{
                    items:6,
                    nav:true,
                    dots: false
                }
            }
        })
    }

    var sliderOwnlCarouselSectionIndexTestimonials = $(".containerHomeTestimonials .owl-carousel");
    if(sliderOwnlCarouselSectionIndexTestimonials.length > 0){
        sliderOwnlCarouselSectionIndexTestimonials.owlCarousel({
            autoplay: true,
            items: 1,
            nav: true,
            loop: true,
            autoplayHoverPause: true,
            animateOut: 'slideOutUp',
            animateIn: 'slideInUp',
            autoplayTimeout: 3000,
          });
    }

    $(document).on('click', ".linkFreeFeedbackOpenThread", function(e){
        e.preventDefault();
        e.stopPropagation();

        $(".containerPageGeneralMessageBoard.pageFreeFedback .containerFormNewThread .containerActionLinks ul li .link").click();

        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $(".containerPageGeneralMessageBoard.pageFreeFedback .formMbThreadNew").offset().top - 100
            }, 1500);
        });
    });

    /* ******* Owl carousel - Index Page | Testimonials ******* */
    var sliderOwlCarouselIndexTestimonials = $(".owl-carousel.containerListTestimonials");

    if(sliderOwlCarouselIndexTestimonials.length > 0){
        sliderOwlCarouselIndexTestimonials.owlCarousel({
            items:1,
            loop:true,
            margin:0,
            autoplay: false,
            autoplayTimeout:5000,
            dots: false,
            mouseDrag: true,
            onInitialized: runAfterInitOwlCarouselIndexTestimonials
        });
    }

    function runAfterInitOwlCarouselIndexTestimonials(event){
        divExpandManager.check_size_div_text();
    };

    $(".containerIndexBlockTestimonials .btnCarousel.next").on("click", function(e){
        sliderOwlCarouselIndexTestimonials.trigger('next.owl.carousel');
    });

    $(".containerIndexBlockTestimonials .btnCarousel.prev").on("click", function(e){
        sliderOwlCarouselIndexTestimonials.trigger('prev.owl.carousel');
    });

    /* *************************************************************** */

    /* ******* Owl carousel - Index Page | Mobile only ******* */
    var indexMobileOnlyCarouselManager = {
        _classDivListServicesThesis: ".containerIndexBlockListServices .containerService.thesisDev .listBulletSubServices",
        _classDivListServicesConnect: ".containerIndexBlockListServices .containerService.connect .listBulletSubServices",
        _classDivListServicesTraining: ".containerIndexBlockListServices .containerService.training .listBulletSubServices",
        _classDivListServicesProfServices: ".containerIndexBlockListServices .containerService.profServices .listBulletSubServices",
        _classDivBlogPosts: ".containerIndexBlockBlogPosts .divListBlogPosts",

        checkStatusCarousel: function(typeCheck){
            if($(".containerPageHome").length > 0 && $(window).width() < 500){
                indexMobileOnlyCarouselManager.startCarousels(typeCheck);
            }else if($(".containerPageHome").length > 0){
                indexMobileOnlyCarouselManager.stopCarousels();
            }
        },

        startCarousels: function(typeCheck){
            var manager = this;
            manager.initCarouselListServices();

            if(typeCheck && typeCheck === 'window_resize'){
                //manager.initCarouselBlogPosts();
            }
        },

        stopCarousels: function(){
            var manager = this;
            manager.stopCarouselListServices();
            //manager.stopCarouselBlogPosts();
        },

        initCarouselListServices(){
            var manager = this;

            var divListServicesThesis = $(manager._classDivListServicesThesis);
            var divListServicesConnect = $(manager._classDivListServicesConnect);
            var divListServicesTraining = $(manager._classDivListServicesTraining);
            var divListServicesProfServices = $(manager._classDivListServicesProfServices);

            if(divListServicesThesis.length > 0 && !divListServicesThesis.hasClass('owl-carousel')){
                divListServicesThesis.addClass('owl-carousel');

                divListServicesThesis.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                });
            }

            if(divListServicesConnect.length > 0 && !divListServicesConnect.hasClass('owl-carousel')){
                divListServicesConnect.addClass('owl-carousel');

                divListServicesConnect.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                });
            }

            if(divListServicesTraining.length > 0 && !divListServicesTraining.hasClass('owl-carousel')){
                divListServicesTraining.addClass('owl-carousel');

                divListServicesTraining.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                });
            }

            if(divListServicesProfServices.length > 0 && !divListServicesProfServices.hasClass('owl-carousel')){
                divListServicesProfServices.addClass('owl-carousel');

                divListServicesProfServices.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                });
            }
        },

        stopCarouselListServices: function(){
            var manager = this;

            var divListServicesThesis = $(manager._classDivListServicesThesis);
            var divListServicesConnect = $(manager._classDivListServicesConnect);
            var divListServicesTraining = $(manager._classDivListServicesTraining);
            var divListServicesProfServices = $(manager._classDivListServicesProfServices);

            if(divListServicesThesis.length > 0 && divListServicesThesis.hasClass('owl-carousel')){
                divListServicesThesis.trigger('destroy.owl.carousel');
                divListServicesThesis.removeClass('owl-carousel');
            }

            if(divListServicesConnect.length > 0 && divListServicesConnect.hasClass('owl-carousel')){
                divListServicesConnect.trigger('destroy.owl.carousel');
                divListServicesConnect.removeClass('owl-carousel');
            }

            if(divListServicesTraining.length > 0 && divListServicesTraining.hasClass('owl-carousel')){
                divListServicesTraining.trigger('destroy.owl.carousel');
                divListServicesTraining.removeClass('owl-carousel');
            }

            if(divListServicesProfServices.length > 0 && divListServicesProfServices.hasClass('owl-carousel')){
                divListServicesProfServices.trigger('destroy.owl.carousel');
                divListServicesProfServices.removeClass('owl-carousel');
            }
        },

        initCarouselBlogPosts: function(){
            var manager = this;
            
            var divListBlogPosts = $(manager._classDivBlogPosts);

            if(divListBlogPosts.length > 0 && !divListBlogPosts.hasClass('owl-carousel')){
                divListBlogPosts.addClass('owl-carousel');

                var windowWidth = $(window).width();

                divListBlogPosts.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                    autoHeight: true,
                    responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                            nav:true,
                            dots:true
                        },
                        675:{
                            items:2,
                            nav:true,
                            dots:true
                        },
                        1100:{
                            items:3,
                            nav:false,
                            dots:false
                        }
                    }
                });
            }
        },

        stopCarouselBlogPosts: function(){
            var manager = this;

            var divListBlogPosts = $(manager._classDivBlogPosts);

            if(divListBlogPosts.length > 0 && divListBlogPosts.hasClass('owl-carousel')){
                divListBlogPosts.trigger('destroy.owl.carousel');
                divListBlogPosts.removeClass('owl-carousel');
            }
        }
    };

    indexMobileOnlyCarouselManager.checkStatusCarousel('init_page');

    $(window).resize(function(){
        indexMobileOnlyCarouselManager.checkStatusCarousel('window_resize');
    });
    /* *************************************************************** */

    /* ******* Owl carousel - Services ******* */
    var servicesCarouselManager = {
        _classDivListServicesIcons: '.owlCarouselSharedServicesListIcon',
        _classDivServicesThesisDev: ".owlCarouselServicesThesisDevelopment",
        _classDivServicesProfessionalServices: ".owlCarouselServicesProfessionalServices",
        _classDivWhitepapers: ".owlCarouselSharedWhitePapersListPreview",
        _classDivMentors: ".owlCarouselHomeMentors",
        _classHomePromotionSlides: ".owlCarouselHomePromotionSlides",
        _classBannerPromotionSlides: ".owlCarouselBannerPromotionSlides",
        _classTableJourneysComparison: ".owlCarouselTableJorneysComparison",
        _classTablePackagesMentored: ".owlCarouselTablePackagesMentored",
        _classTableInResidencesOptions: ".owlCarouselTableInResidencesOptions",

        _classDivCarouselIt3: ".owlServCarouselIt3",

        initAllCarousels: function(){
            var manager = this;

            var carouselsIt3 = $(manager._classDivCarouselIt3);

            if(carouselsIt3 && carouselsIt3.length > 0){
                carouselsIt3.each(function(index, elem) {
                    manager.initCarouselV1($(elem));
                });
            }

            if($(manager._classDivWhitepapers) && $(manager._classDivWhitepapers).length > 0){
                manager.initCarouselV2($(manager._classDivWhitepapers));
            }
            
            if($(manager._classDivMentors) && $(manager._classDivMentors).length > 0){
                manager.initCarouselMentors($(manager._classDivMentors));
            }
            
            if($(manager._classHomePromotionSlides) && $(manager._classHomePromotionSlides).length > 0){
                manager.initCarouselHomePromotionSlides($(manager._classHomePromotionSlides));
            }
            
            if($(manager._classBannerPromotionSlides) && $(manager._classBannerPromotionSlides).length > 0){
                manager.initCarouselBannerPromotionSlides($(manager._classBannerPromotionSlides));
            }
            
            if($(manager._classTableJourneysComparison) && $(manager._classTableJourneysComparison).length > 0){
                manager.initCarouselTableJourneysComparison($(manager._classTableJourneysComparison));
            }

            if($(manager._classTablePackagesMentored) && $(manager._classTablePackagesMentored).length > 0){
                manager.initCarouselTablePackagesMentored($(manager._classTablePackagesMentored));
            }

            if($(manager._classTableInResidencesOptions) && $(manager._classTableInResidencesOptions).length > 0){
                manager.initCarouselTableInResidencesOptions($(manager._classTableInResidencesOptions));
            }

            if($(window).width() > 700){
                if($(manager._classDivListServicesIcons) && $(manager._classDivListServicesIcons).length > 0){
                    manager.initCarouseListServicesIcons($(manager._classDivListServicesIcons));
                }
            }else{
                if($(manager._classDivServicesThesisDev) && $(manager._classDivServicesThesisDev).length > 0){
                    manager.initCarouselMobileForServicesAfter700($(manager._classDivServicesThesisDev));
                }
                if($(manager._classDivServicesProfessionalServices) && $(manager._classDivServicesProfessionalServices).length > 0){
                    manager.initCarouselMobileForServicesAfter700($(manager._classDivServicesProfessionalServices));
                }
            }
        },

        initCarouselV1: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:1,
                loop:true,
                margin:0,
                autoplay: false,
                autoplayTimeout:5000,
                dots: true,
                mouseDrag: true,
                autoHeight: true,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                        loop:true,
                        nav:false,
                        dots:true
                    },
                    700:{
                        items:2,
                        loop:true,
                        nav:false,
                        dots:true
                    },
                    1300:{
                        items:3,
                        loop:false,
                        nav:false,
                        dots:true
                    }
                }
            });
        },

        initCarouseListServicesIcons: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
                
                divCarousel.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                    autoHeight: true,
                    responsiveClass:true,
                    responsive:{
                        0:{
                            items:3,
                            loop:false,
                            nav:false,
                            dots:false
                        },
                        700:{
                            items:2,
                            loop:true,
                            nav:false,
                            dots:true
                        },
                        920:{
                            items:2,
                            loop:true,
                            nav:false,
                            dots:true
                        },
                        1300:{
                            items:3,
                            loop:false,
                            nav:false,
                            dots:true
                        }
                    }
                });
            }

        },

        initCarouselV2: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:1,
                loop:true,
                margin:0,
                autoplay: false,
                autoplayTimeout:5000,
                dots: true,
                mouseDrag: true,
                autoHeight: true,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                        loop:true,
                        nav:false,
                        dots:true
                    },
                    675:{
                        items:2,
                        loop:true,
                        nav:false,
                        dots:true
                    },
                    1020:{
                        items:3,
                        loop:false,
                        nav:false,
                        dots:true
                    }
                }
            });
        },

        initCarouselMobileForServicesAfter700: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');

                divCarousel.owlCarousel({
                    items:1,
                    loop:true,
                    margin:0,
                    autoplay: false,
                    autoplayTimeout:5000,
                    dots: true,
                    mouseDrag: true,
                    autoHeight: true
                });

                setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
            }

        },

        initCarouselMentors: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:1,
                loop:true,
                margin:0,
                autoplay: false,
                autoplayTimeout:5000,
                dots: true,
                mouseDrag: true,
                autoHeight: true,
                autoWidth:true,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                        loop:true,
                        nav:false,
                        dots:true,
                        autoWidth:false
                    },
                    810:{
                        items:2,
                        loop:false,
                        nav:false,
                        dots:false
                    }
                }
            });
        },

        initCarouselHomePromotionSlides: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:1,
                loop:true,
                margin:0,
                autoplay: true,
                autoplayTimeout: 4000,
                autoplayHoverPause: true,
                dots: true,
                mouseDrag: true,
                autoHeight: true
            });

            setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
        },

        initCarouselBannerPromotionSlides: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:1,
                loop:true,
                margin:0,
                autoplay: true,
                autoplayTimeout: 4000,
                autoplayHoverPause: true,
                dots: false,
                mouseDrag: true,
                autoHeight: true
            });

            setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
        },

        initCarouselTableJourneysComparison: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items:3,
                loop:false,
                margin:0,
                autoplay: false,
                dots: false,
                mouseDrag: true,
                autoHeight: true,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                        loop:true,
                        nav:false,
                        dots:true,
                    },
                    700:{
                        items:2,
                        loop:false,
                        nav:false,
                        dots:true
                    },
                    1100:{
                        items:3,
                        loop:false,
                        nav:false,
                        dots:false
                    }
                }
            });

            setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
        },

        initCarouselTablePackagesMentored: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items: 3,
                loop: false,
                margin: 0,
                autoplay: false,
                dots: false,
                mouseDrag: true, 
                autoHeight: true,
                responsiveClass: true,
                responsive:{
                    0:{
                        items: 1,
                        loop: true, 
                        nav: false,
                        dots: true
                    },
                    700:{
                        items: 2, 
                        loop: false,
                        nav: false, 
                        dots: true
                    },
                    1100:{
                        items: 3, 
                        loop: false, 
                        nav: false, 
                        dots: false
                    }
                }
            });

            setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
        },

        initCarouselTableInResidencesOptions: function(divCarousel){
            if(!divCarousel.hasClass('owl-carousel')){
                divCarousel.addClass('owl-carousel');
            }

            divCarousel.owlCarousel({
                items: 3,
                loop: false,
                margin: 0,
                autoplay: false,
                dots: false,
                mouseDrag: true, 
                autoHeight: true,
                responsiveClass: true,
                responsive:{
                    0:{
                        items: 1,
                        loop: true, 
                        nav: false,
                        dots: true
                    },
                    700:{
                        items: 2, 
                        loop: false,
                        nav: false, 
                        dots: true
                    },
                    1100:{
                        items: 3, 
                        loop: false, 
                        nav: false, 
                        dots: false
                    }
                }
            });

            setTimeout(function(){ divCarousel.trigger('refresh.owl.carousel'); }, 1500);
        },

        removeCarousel: function(divCarousel){
            if(divCarousel && divCarousel.hasClass('owl-carousel')){
                divCarousel.trigger('destroy.owl.carousel');
                divCarousel.removeClass('owl-carousel');
            }
        },

        checkCarouselsAfterResizeWindow: function(){
            var manager = this;
            if($(window).width() <= 700){
                manager.removeCarousel($(manager._classDivListServicesIcons));
                manager.initCarouselMobileForServicesAfter700($(manager._classDivServicesThesisDev));
                manager.initCarouselMobileForServicesAfter700($(manager._classDivServicesProfessionalServices));
            }else{
                manager.initCarouseListServicesIcons($(manager._classDivListServicesIcons));
                manager.removeCarousel($(manager._classDivServicesThesisDev));
                manager.removeCarousel($(manager._classDivServicesProfessionalServices));
            }
        }
    };

    servicesCarouselManager.initAllCarousels();

    $(window).resize(function(){
        servicesCarouselManager.checkCarouselsAfterResizeWindow();
    });

    /* *************************************************************** */

    /* ******* Owl carousel - Proogramme Page | Faculty users ******* */
    const sliderOwlCarouselProgrammePageFacultyUsers = $(".owl-carousel.page-programme__faculty__users-list");

    if(sliderOwlCarouselProgrammePageFacultyUsers.length > 0){
        sliderOwlCarouselProgrammePageFacultyUsers.owlCarousel({
            items:3,
            loop:true,
            margin:0,
            autoplay: true,
            autoplayTimeout:8000,
            dots: true,
            mouseDrag: true
        });
    }
    /* *************************************************************** */

    /* ******* Owl carousel - Proogramme Page | Culture experience ******* */
    const sliderOwlCarouselProgrammePageCultureExp = $(".owl-carousel.page-programme__faculty__cultural-experience-list");

    if(sliderOwlCarouselProgrammePageCultureExp.length > 0){
        sliderOwlCarouselProgrammePageCultureExp.owlCarousel({
            items:1,
            loop:true,
            margin:0,
            autoplay: true,
            autoplayTimeout:2500,
            smartSpeed: 1000,
            slideTransition: 'linear',
            dots: false,
            mouseDrag: true,
            autoWidth: true,
            autoHeight: true,
            autoplayHoverPause: true,
            //center: true,
        });
    }
    /* *************************************************************** */
});
