var managerIndexConversations = {
    _containerVueId: '#containerPageIndexConversationsId',
    _vueObj: null,
    _cropperEventImage: null,
    _cropperEventImageSocial: null,

    initVue: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                tokenCsrf: null,
                userId: 0,
                activeConversationId: 0,
                userIdToSendNewMessageId: null
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                    vueObj.userId = $(vueObj.$els.containerVue).data('user-id');
                }


                if(vueObj.$els.containerDataActiveConversationId){
                    vueObj.activeConversationId = parseInt($(vueObj.$els.containerDataActiveConversationId).data('id'));
                }

                if(vueObj.$els.containerDataUserToSendNewMessage){
                    vueObj.userIdToSendNewMessageId = parseInt($(vueObj.$els.containerDataUserToSendNewMessage).data("id"));
                }
            },

            events: {
            },

            computed: {
                showLoadingConversations: function(){
                    return this.isLoadingConversations && this.isLoadingConversations == 'yes';
                }
            },

            methods: {
            }
        });
    },
};

$(function(){
    if(managerIndexConversations._containerVueId && $(managerIndexConversations._containerVueId).length > 0){
        managerIndexConversations.initVue();
    }
});
