const managerConversationsNrUnviewed = {
    _divNrConversationsUnviewed_menuTop: '.userMessages .divUpdateNrConversationsUnviewed',
    _divNrConversationsUnviewed_menuSide: '.containerSideMenuPageContent .liItem .linkSideMenuUserMessages .divNrUnviewed',
    _timeOutGetNrUnviewedMessages: null,

    existsDivOnMenuTop: function(){
        return $(this._divNrConversationsUnviewed_menuTop) && $(this._divNrConversationsUnviewed_menuTop).length > 0;
    },

    existsDivOnMenuSide: function(){
        return $(this._divNrConversationsUnviewed_menuSide) && $(this._divNrConversationsUnviewed_menuSide).length > 0;
    },

    initOrUpdateCounts: function(){
        const manager = this;

        if(manager.existsDivOnMenuSide() || manager.existsDivOnMenuTop()){
            manager.getNrConversationsUnviewed();
        }else{
            if(manager._timeOutGetNrUnviewedMessages){
                clearTimeout(manager._timeOutGetNrUnviewedMessages);
            }
        }
    },

    getNrConversationsUnviewed: function(){
        const manager = this;

        $.ajax({
            url: "/json/conversation_message_receipts/get_unviewed_nr_from_current_user",
            type: 'GET',
            dataType: 'json',
            success: function(data){
                manager.renderNrUnviewedMessages(data.nr_unviewed_messages);

                manager._timeOutGetNrUnviewedMessages = setTimeout(function(){
                                                            manager.getNrConversationsUnviewed(); 
                                                        }, 3000);
            }
        });
    },

    renderNrUnviewedMessages: function(nrMessages){
        const manager = this;

        if(nrMessages > 0){
            manager.addNrUnviewedMessagesToDiv(nrMessages);
        }else{
            manager.removeNrUnviewedMessagesToDiv();
        }
    },

    addNrUnviewedMessagesToDiv: function(nrMessages){
        const manager = this;

        let nrMessagesToShow = nrMessages;

        if(nrMessagesToShow > 99){
            nrMessagesToShow = "99+";
        }

        if(manager.existsDivOnMenuTop()){
            $(manager._divNrConversationsUnviewed_menuTop).html(nrMessagesToShow);
        }

        if(manager.existsDivOnMenuSide()){
            $(manager._divNrConversationsUnviewed_menuSide).html(nrMessagesToShow).addClass('active');
        }
    },

    removeNrUnviewedMessagesToDiv: function(){
        const manager = this;

        if(manager.existsDivOnMenuTop()){
            $(manager._divNrConversationsUnviewed_menuTop).html("");
        }

        if(manager.existsDivOnMenuSide()){
            $(manager._divNrConversationsUnviewed_menuSide).html("").removeClass("active");
        }
    }
};

$(function(){
    managerConversationsNrUnviewed.initOrUpdateCounts();
});
