$(function(){
	if($("#sectionBlogPostsId").length > 0){
		var blogDataManager = {
			_blodDataVue: new Vue({
				el: '#sectionBlogPostsId',

				data: {
				  latestPosts: [],
				  categoriesHTML: null,
				  tags: []
				},

				ready: function(){
					var blogVue = this;

					blogVue.getLatestPosts();
					//blogVue.getCategories();
					//blogVue.getTags();
				},

                filters: {
                    formatDate: function(date){
                        return moment(date, 'MMMM DD, YYYY').format('YYYY');
                    },
                },

				methods: {
					getLatestPosts: function(){
						var blogVue = this;

						var latestPostsUrl = "https://www.blog.doctoratehub.com/xml-feed.php";
						var latestPostsParams = { numberposts: 5 };

						blogVue.$http.get(latestPostsUrl, latestPostsParams).then(function(response){
							//success
						}, function(response){
							var xml = response.data,
								xmlDoc = $.parseXML( xml ),
								$xml = $( xmlDoc ),
								$items = $xml.find("items");

							$items.find("item").each(function(index){
								var post = $(this);

								blogVue.latestPosts.push({title: post.find("title").text(), 
														  url: post.find("link").text(),
														  url_thumbnail: post.find("thumbnail").text(),
														  author: { name: post.find("author").text() },
														  date: post.find("pubDate").text(),
														  text: post.find("teaser").text()
														});
							});

							Vue.nextTick(function () {
								blogVue.addCarousel();
							});
							
							//error
						});
					},

					addCarousel: function(){
						var divListCarousel = $(".containerIndexBlockBlogPosts .divListBlogPosts");

						if(divListCarousel.length > 0 && !divListCarousel.hasClass('owl-carousel')){
							divListCarousel.addClass('owl-carousel');

							divListCarousel.owlCarousel({
								items:1,
								loop:true,
								margin:0,
								autoplay: false,
								autoplayTimeout:5000,
								dots: true,
								mouseDrag: true,
								autoHeight: true,
								responsiveClass:true,
								responsive:{
									0:{
										items:1,
										loop:true,
										nav:false,
										dots:true
									},
									675:{
										items:2,
										loop:true,
										nav:false,
										dots:true
									},
									1100:{
										items:3,
										loop:false,
										nav:false,
										dots:true
									}
								}
							});
						}
					
					},

					getCategories: function(){
						var blogVue = this;

						var categoriesUrl = "http://www.blog.doctoratehub.com/xml-categories.php";
						var categoriesParams = {}

						blogVue.$http.get(categoriesUrl, categoriesParams).then(function(response){
							//success
						}, function(response){
							blogVue.categoriesHTML = response.data;
						});
					},

					getTags: function(){
						var blogVue = this;

						var tagsUrl = "http://www.blog.doctoratehub.com/xml-tags.php";
						var tagsParams = {};

						blogVue.$http.get(tagsUrl, tagsParams).then(function(response){
							//success
						}, function(response){
							var xml = response.data,
								xmlDoc = $.parseXML( xml ),
								$xml = $( xmlDoc ),
								$tags = $xml.find("tags");

							$tags.find("tag").each(function(index){
								var tag = $(this);

								blogVue.tags.push({id: tag.find("id").text(), name: tag.find("title").text(), url: tag.find("url").text()});
							});
						});
					}
				}
			}),
		};
	}
});
