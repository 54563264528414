var eventsShowContainerTopManager = {
    _containerVueId: '#containerShowEventsSectionTopId',
    _vueObjDetailPage: null,
    _cropperEventImage: null,

    initVue: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                eventId: 0,
                tokenCsrf: null,
                photo: { modal: $("#modalEventPhotoUpdateId"), image: $("#eventPhotoUploadId") },
                base64data: { original: null, web: null, fb_lkn: null, twitter: null },
                isUploadingImage: 'no',
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoading: function(){
                    return this.isUploadingImage && this.isUploadingImage == 'yes'; 
                },

                showFormUploadImage: function(){
                    return this.isUploadingImage && this.isUploadingImage == 'no'; 
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.eventId = $(vueObj.$els.containerVue).data('event-id');
                    }
                },

                manageEvenImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventphotouploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.base64data.original = url;

                        if(vueObj.photo.image){
                            vueObj.photo.image.attr("src",url);
                        }

                        if(vueObj.photo.modal){
                            vueObj.photo.modal.foundation('open');
                        }

                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;
                    let canvasOptions = null;

                    switch(type){
                        case 'web':
                            canvasOptions = { width: 1190, height: 1074, minWidth: 1190, minHeight:1074 };
                            break;
                        case 'fb_lkd':
                            canvasOptions = { width: 1200, height: 627, minWidth: 1200, minHeight:627 };
                            break;
                        case 'twitter':
                            canvasOptions = { width: 1200, height: 600, minWidth: 1200, minHeight:600 };
                            break;
                    }

                    canvas = manager._cropperEventImage.getCroppedCanvas(canvasOptions);

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                updateUserPhoto: function(){
                    const vueObj = this;
                    
                    vueObj.isUploadingImage = 'yes';

                    if(vueObj.photo.modal.find(".divContLoading") && vueObj.photo.modal.find(".divContLoading").hasClass("hide")){
                        vueObj.photo.modal.find(".divContLoading").removeClass("hide");
                    }

                    if(vueObj.photo.modal.find(".btnClose") && !vueObj.photo.modal.find(".btnClose").hasClass("hide")){
                        vueObj.photo.modal.find(".btnClose").addClass("hide");
                    }

                    if(vueObj.photo.modal.find(".divContForm") && !vueObj.photo.modal.find(".divContForm").hasClass("hide")){
                        vueObj.photo.modal.find(".divContForm").addClass("hide");
                    }

                    vueObj.getBase64dataImages('web');
                    vueObj.getBase64dataImages('fb_lkd');
                    vueObj.getBase64dataImages('twitter');

                    vueObj.$nextTick(() => {
                        setTimeout(function(){
                            let params = {
                                image_original: vueObj.base64data.original, 
                                image_web: vueObj.base64data.web, 
                                image_fb_lkn: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            /*let params = new FormData();

                            params.append('image_web', vueObj.base64data.web);
                            params.append('image_fb_lkn', vueObj.base64data.fb_lkn);
                            params.append('image_twitter', vueObj.base64data.twitter);
                            params.append('image_original', vueObj.base64data.original);*/

                            setTimeout(function(){
                                vueObj.$http({ 
                                    url: "/json/events/"+ vueObj.eventId +"/update_image/v1", method: 'POST', 
                                    contentType: 'multipart/form-data', 
                                    responseType: 'json', 
                                    data: params,
                                    headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                                }).then(function(response){
                                    //SUCCESS
                                    if(response.data.result == "success"){
                                    /* if(vueObj.photo.modal){
                                            vueObj.photo.modal.foundation('close');
                                        }*/
            
                                        window.location.reload();
                                    }
                                }, function(response){
                                    vueObj.isUploadingImage = 'no';
                                    //ERROR
                                });
                            }, 5000);
                        }, 3000);

                    });
                },

            }
        });
    },

    initCropper: function(modal){
        const manager = this;

        if(modal){
            let image = modal.find("#eventPhotoUploadId");

            manager._cropperEventImage = new Cropper($(image)[0], {
                aspectRatio: 16/9,
                viewMode: 2,
                zoomable: false,
            });
        }
    },

    destroyCropper: function(){
        const manager = this;

        manager._cropperEventImage.destroy();
        manager._cropperEventImage = null;
    }
};

$(function(){
    if(eventsShowContainerTopManager._containerVueId && $(eventsShowContainerTopManager._containerVueId).length > 0){
        eventsShowContainerTopManager.initVue();
    }

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalEventPhotoUpdateId"){
                eventsShowContainerTopManager.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalEventPhotoUpdateId"){
                eventsShowContainerTopManager.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedEventImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        eventsShowContainerTopManager._vueObjDetailPage.updateUserPhoto();

    });
});
