var eventsSeriesShowManager = {
    _containerVueId: "#containerVuePageShowEventsSeriesId",
    _vueObjListEvents: null,

    initVueObjPage: function(){
        var manager = this;

        manager._vueObjPageList = new Vue({
            el: manager._containerVueId,
            data: {
                seriesId: 0,
                associatedEventsNext: [],
                associatedEventsPast: [],
            },

            ready: function(){
                this.init();
            },

            computed: {
            },

            watch: {
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.seriesId = $(vueObj.$els.containerVue).data("id");
                    }

                    vueObj.getEvents();
                },

                getEvents: function(){
                    const vueObj = this;

                    vueObj.events = null;

                    let url = "/json/events_series/"+ vueObj.seriesId +"/events_next_past";

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: {} 
                    }).then(function(response){
                        if(response.data.next){
                            vueObj.associatedEventsNext = response.data.next;
                        }

                        if(response.data.past){
                            vueObj.associatedEventsPast = response.data.past;
                        }
                    }, function(response){
                        //error callback
                    });
                }
            }
        });
    }
};

$(function(){
    if($(eventsSeriesShowManager._containerVueId) && $(eventsSeriesShowManager._containerVueId).length > 0){
        eventsSeriesShowManager.initVueObjPage();
    }
});
