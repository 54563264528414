var eventsListManager = {
    _containerVueListEventsId: "#containerVueListEventsId",
    _vueObjListEvents: null,
    _containerVuePageList: "#containerVuePageListViewId",
    _vueObjPageList: null,

    initVueObjListEvents: function(){
        var manager = this;

        manager._vueObjListEvents = new Vue({
            el: manager._containerVueListEventsId,
            data: {
                eventType: null,
                searchBy: null,
                searchByLocation: null,
                optionsForSelect: [],
                events: null,
                currentUserEventsIdsJoined: [],
                itemsPerPage: 0,
                pagination: { page: 1, pages: [] },
                windowWidth: 0
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    vueObj.eventType = $(vueObj.$els.containerVue).data("type");

                    if($(vueObj.$els.containerVue).data("pre-selected-location") && $(vueObj.$els.containerVue).data("pre-selected-location").length > 0){
                        vueObj.searchByLocation = $(vueObj.$els.containerVue).data("pre-selected-location");
                    }
                }

                vueObj.initSelectOptionsAndEvents();

                vueObj.windowWidth = window.innerWidth;
            },

            computed: {
                hasOptionsForSelect(){
                    return this.optionsForSelect && this.optionsForSelect.length > 0;
                },
                showLoadingEvents(){
                    return !this.events;
                },
                showNoEvents(){
                    return this.events && this.events.length <= 0;
                },
                showListEvents(){
                    return this.events && this.events.length > 0;
                },

                showPagination(){
                    return this.pagination.pages && this.pagination.pages.length > 1;
                },

                displayedEvents() {
                    return this.paginate(this.events);
                }
            },

            watch: {
                searchBy: function(){
                    if(this.searchBy){
                        this.getEvents();
                    }
                },

                events: function(){
                    this.setPage(1);
                    this.setPages();
                },

                windowWidth: function(){
                    const vueObj = this;

                    if(vueObj.windowWidth && vueObj.windowWidth > 0){
                        if(vueObj.windowWidth <= 700){
                            vueObj.itemsPerPage = 1;
                        }else if(vueObj.windowWidth <= 923){
                            vueObj.itemsPerPage = 4;
                        }else{
                            vueObj.itemsPerPage = 6;
                        }
                    }
                },

                itemsPerPage: function(){
                    this.setPages();
                }
            },

            methods: {
                onWindowResize: function(){
                    const vueObj = this;

                    vueObj.windowWidth = window.innerWidth;
                },

                initSelectOptionsAndEvents: function(){
                    const vueObj = this;
                    let url = "";
                    let params = null;

                    switch(vueObj.eventType){
                        case 'master_course':
                            url = "/json/dynamic_lists/options_by_list_name";
                            params = { name: 'doctoral_journey' };
                            break;
                        default:
                            url = "/json/dynamic_lists/options_by_list_name";
                            params = { name: 'doctoral_journey' };
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.options && response.data.options.length > 0){
                            vueObj.optionsForSelect = response.data.options;
                        }

                        vueObj.getEvents();
                        vueObj.getCurrentUserEventsIdsJoined();
                    }, function(response){
                        //error callback
                    });
                },

                getEvents: function(){
                    const vueObj = this;

                    vueObj.events = null;

                    let params = { type: vueObj.eventType, filter: null };
                    let url = '/json/events/get_all_by_type';

                    if(vueObj.searchBy == 'latest'){
                        params.filter = 'latest';
                    }
      
                    if(vueObj.searchByLocation){
                        params.location = vueObj.searchByLocation;
                    }

                    //TO DO: Change all tyo the same method on controller
                    if(vueObj.searchBy && $.isNumeric(vueObj.searchBy)){
                        params.option_id = vueObj.searchBy;
                        params.filter = 'latest';
                        url = '/json/events/get_all_by_type_and_dyn_list_option_id'
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.events){
                            vueObj.events = response.data.events;
                        }else{
                            vueObj.events = [];
                        }
                    }, function(response){
                        //error callback
                        vueObj.events = [];
                    });
                },

                getCurrentUserEventsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserEventsIdsJoined = response.data.events_ids;

                    }, function(response){
                        //ERROR
                    });
                },

                setPages: function() {
                    const vueObj = this;

                    vueObj.pagination.pages = [];

                    if(vueObj.events && vueObj.events.length > 0 && vueObj.itemsPerPage && vueObj.itemsPerPage > 0){
                        let numberOfPages = Math.ceil(vueObj.events.length / vueObj.itemsPerPage);
    
                        for (let index = 1; index <= numberOfPages; index++) {
                            vueObj.pagination.pages.push(index);
                        }
                    }
                },

                paginate: function(events){
                    const vueObj = this;

                    if(events && events.length > 0){
                        let page = vueObj.pagination.page;
                        let perPage = vueObj.itemsPerPage;
    
                        let from = (page * perPage) - perPage;
                        let to = (page * perPage);
    
                        return  events.slice(from, to);
                    }

                    return [];
                },

                setPage: function(pageNr, isFromButton = null){
                    const vueObj = this;

                    if(pageNr && vueObj.pagination.pages.length >= pageNr){
                        vueObj.pagination.page = pageNr;
                    }else{
                        vueObj.pagination.page = 1;
                    }

                    if(isFromButton && isFromButton == 'yes'){
                        vueObj.$nextTick(() => {
                            if(vueObj.$els.containerVue){
                                vueObj.$els.containerVue.scrollIntoView();
                            }
                        });
                    }
                },

                getEventImage: function(event){
                    let url = '';

                    if(event.preselected_photo && event.preselected_photo.image_pdf_cover){
                        url ="/storage/" + event.preselected_photo.image_pdf_cover;
                    }

                    return url;
                },

                getEventCreatedYear: function(event){
                    return moment(event.created_at).format('YYYY');
                },

                getEventSummary: function(event){
                    const textMaxLength = 179;
                    let textToShow = '';
                    let txtWIthoutHtml = '';

                    if(event.summary && event.summary.length > 0){
                        if(/<\/?[a-z][\s\S]*>/i.test(event.summary)){
                            txtWIthoutHtml = $(event.summary).text();
                        }else{
                            txtWIthoutHtml = event.summary;
                        }

                        if(txtWIthoutHtml && txtWIthoutHtml.length > 0){
                            textToShow = txtWIthoutHtml.substring(0, textMaxLength);
                            if(txtWIthoutHtml.length >= textMaxLength){
                                textToShow = textToShow + '...';
                            }
                        }
                    }

                    return textToShow;
                },

                getEventLocation: function(event){
                    let location = "Online";

                    if((!event.is_online || event.is_online == 0) && event.location && event.location.length > 0){
                        location = event.location;
                    }

                    return location;
                },

                getEventUrlOrEdition: function(event){
                    const vueObj = this;

                    if(vueObj.currentUserIsParticipant(event)){
                        return '/advanced_courses/'+ event.id +'/redirect_to_edition';
                    }

                    return '/advanced_courses/' + event.id;
                },

                getEventUrl: function(event){
                    return '/advanced_courses/' + event.id;
                },

                eventHasAssociatedSeries: function(event){
                    if(event.events_series && event.events_series.length > 0){
                        return true;
                    }
        
                    return false;
                },

                currentUserIsParticipant: function(event){
                    const vueObj = this;

                    if(vueObj.currentUserEventsIdsJoined && vueObj.currentUserEventsIdsJoined.length > 0){
                        if($.inArray(event.id, vueObj.currentUserEventsIdsJoined) >= 0){
                            return true;
                        }
                    }

                    return false;
                },

                movePage: function(direction){
                    const vueObj = this;

                    let tempPage = vueObj.pagination.page;
                    
                    if(direction && direction == 'prev'){
                        tempPage = tempPage - 1;
                    }else{
                        tempPage = tempPage + 1;
                    }

                    if(tempPage <= 0){
                        vueObj.pagination.page = vueObj.pagination.pages.length;
                    }else if(tempPage > vueObj.pagination.pages.length){
                        vueObj.pagination.page = 1;
                    }else{
                        vueObj.pagination.page = tempPage;
                    }

                    vueObj.$nextTick(() => {
                        if(vueObj.$els.containerVue){
                            vueObj.$els.containerVue.scrollIntoView();
                        }
                    });
                }
            }
        });
    },

    initVueObjPageList: function(){
        var manager = this;

        manager._vueObjPageList = new Vue({
            el: manager._containerVuePageList,
            data: {
                eventType: null,
                events: null,
                eventsFinished: null,
                currentUserEventsIdsJoined: [],
                windowWidth: 0,
                filter: null,
                searchByLocation: null,
            },

            ready: function(){
                this.init();
            },

            computed: {
                filterAllIsActive(){
                    return (this.filter && this.filter == "all") || !this.filter;
                },

                objPreviewCreate(){
                    const vueObj = this;

                    let objCreate = { created_at: moment().format('YYYY-MM-DD HH:mm'), type: vueObj.eventType, name: 'This is the name of your own event', summary: 'Set up your own event.', is_create_preview: 'yes' };

                    switch(vueObj.eventType){
                        case 'conference':
                            objCreate.type = vueObj.eventType;
                            objCreate.name = "This is the name of your own training camp"
                            objCreate.summary = "Set up your own training camp.";
                            break;
                    }

                    return objCreate;
                },

                showCreatePreview: function(){
                    return this.eventType && this.eventType == 'conference';
                }
            },

            watch: {
            },

            methods: {
                onWindowResize: function(){
                    const vueObj = this;

                    vueObj.windowWidth = window.innerWidth;
                },

                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.eventType = $(vueObj.$els.containerVue).data("type");

                        if($(vueObj.$els.containerVue).data("pre-selected-location") && $(vueObj.$els.containerVue).data("pre-selected-location").length > 0){
                            vueObj.searchByLocation = $(vueObj.$els.containerVue).data("pre-selected-location");
                        }
                    }

                    vueObj.windowWidth = window.innerWidth;

                    vueObj.getEvents('next');
                    vueObj.getEvents('finished');
                    vueObj.getCurrentUserEventsIdsJoined();
                },

                setFilterAndGetEvents: function(filter){
                    const vueObj = this;

                    vueObj.setFilter(filter);
                    vueObj.getEvents();
                },

                setFilter: function(filter){
                    const vueObj = this;

                    vueObj.filter = filter;
                },

                getEvents: function(forcedFilter){
                    const vueObj = this;

                    vueObj.events = null;

                    let filterToSend = null;

                    if(forcedFilter){
                        filterToSend = forcedFilter;
                    }else{
                        filterToSend = vueObj.filter;
                    }

                    let url = "/json/events";
                    let params = { type: vueObj.eventType, with_crowdfunding_active_or_not_started: 'yes', filter: null, location: null };

                    if(vueObj.searchByLocation){
                        params.location = vueObj.searchByLocation;
                    }

                    switch(filterToSend){
                        case 'next':
                            params.filter = "next_with_date_nulls";
                            break;
                        case 'finished':
                            params.filter = "past";
                            break;
                        default:
                            params.filter = filterToSend;
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.events){
                            switch(filterToSend){
                                case 'next':
                                    vueObj.events = response.data.events;

                                    if(vueObj.showCreatePreview){
                                        vueObj.events.unshift(vueObj.objPreviewCreate);
                                    }
                                    break;
                                case 'finished':
                                    vueObj.eventsFinished = response.data.events;
                                    break;
                                default:
                                    vueObj.events = response.data.events;

                                    if(vueObj.showCreatePreview){
                                        vueObj.events.unshift(vueObj.objPreviewCreate);
                                    }
                            }
                        }else{
                            vueObj.events = [];

                            if(vueObj.showCreatePreview){
                                vueObj.events.unshift(vueObj.objPreviewCreate);
                            }
                        }

                    }, function(response){
                        //error callback
                        vueObj.events = [];

                        if(vueObj.showCreatePreview){
                            vueObj.events.unshift(vueObj.objPreviewCreate);
                        }
                    });
                },

                getCurrentUserEventsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserEventsIdsJoined = response.data.events_ids;

                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }
};

$(function(){
    if($(eventsListManager._containerVueListEventsId) && $(eventsListManager._containerVueListEventsId).length > 0){
        eventsListManager.initVueObjListEvents();

        $(window).resize(function() {
            eventsListManager._vueObjListEvents.windowWidth = window.innerWidth;
        });
    }

    if($(eventsListManager._containerVuePageList) && $(eventsListManager._containerVuePageList).length > 0){
        eventsListManager.initVueObjPageList();

        $(window).resize(function() {
            eventsListManager._vueObjPageList.windowWidth = window.innerWidth;
        });
    }
});
