Vue.component('list-attachments-v1', {
    template: `
            <div class="customScrollBarStyleV1 containerGenericListAttachments scrollBar" 
                 v-if="files && files.length > 0"
                 v-el:list-files>

                <div class="divAttachment" v-for="file in files">
                    <div class="divUploadedImg" 
                            v-if="getAttachmentsFileExtension(file) == 'jpeg' || getAttachmentsFileExtension(file) == 'jpg' || getAttachmentsFileExtension(file) == 'png'">
                        <img :src="getAttachmentsFileURL(file)" class="">
                    </div>

                    <div class="divUploadedFile" v-else>
                        <div class="divImgFileType">
                            <img src="/images/icon_uploaded_file.png" class="icon" alt="file">
                            <span class="spanFileType">{{ getAttachmentsFileExtension(file) }}</span>
                        </div>

                        <div class="divName">{{ file.name }}</div>
                    </div>

                    <a :href="getAttachmentsFileURL(file)" class="linkDownload" title="Download" target="_blank">
                        <img src="/images/icon_c54_download.png">
                    </a>
                </div>
            </div>
        `,
    props: {
        files: {
            type: Array,
            required: true
        }
    },

    ready: function(){
        this.initOrUpdateScroll();
    },

    computed: {
    },

    methods: {
        getAttachmentsFileExtension: function(file){
            let ext = "";

            if(file.name){
                let nameArray = file.name.split(".");

                if(nameArray.length > 0){
                    ext = nameArray[nameArray.length -1];
                }
            }

            return ext;
        },

        getAttachmentsFileURL: function(file){
            let url = "";

            if(file.file){
                url = '/storage/' + file.file;
            }

            return url;
        },

        initOrUpdateScroll: function(){
            const component = this;

            setTimeout(function(){
                if(component.$els.listFiles){
                    const elem = $(component.$els.listFiles);

                    if(elem.hasClass("mCustomScrollbar")){
                        elem.mCustomScrollbar("update");
                        elem.mCustomScrollbar("scrollTo", "left");
                    }else{
                        elem.mCustomScrollbar({
                            theme:"inset",
                            axis: "x",
                            autoDraggerLength: false,
                            scrollbarPosition: "outside"
                        });
                    }
                }
            });
        }
    }
});

Vue.component('list-attachments-v2', {
    template: `
            <div class="containerGenericListAttachmentsV2" 
                 v-if="files && files.length > 0"
                 v-el:list-files>

                <div class="divAttachment" v-for="file in files" title="{{ file.name }}">
                    <div class="divUploadedImg" 
                            v-if="getAttachmentsFileExtension(file) == 'jpeg' || getAttachmentsFileExtension(file) == 'jpg' || getAttachmentsFileExtension(file) == 'png'">
                        <img :src="getAttachmentsFileURL(file)" class="">
                    </div>

                    <a :href="getAttachmentsFileURL(file)" class="linkDownload" title="{{ file.name }}" target="_blank">
                        <img src="/images/icon_svg_expand_1.svg">
                    </a>
                </div>
            </div>
        `,
    props: {
        files: {
            type: Array,
            required: true
        }
    },

    ready: function(){

    },

    computed: {
    },

    methods: {
        getAttachmentsFileExtension: function(file){
            let ext = "";

            if(file.name){
                let nameArray = file.name.split(".");

                if(nameArray.length > 0){
                    ext = nameArray[nameArray.length -1];
                }
            }

            return ext;
        },

        getAttachmentsFileURL: function(file){
            let url = "";

            if(file.file){
                url = '/storage/' + file.file;
            }

            return url;
        }
    }
});

Vue.component('list-attachments-v3', {
    template: `
            <div class="containerGenericListAttachmentsV3" 
                 v-if="files && files.length > 0"
                 v-el:list-files>
                <ul class="containerListAttachedFilesV1">
                    <li class="divAttachment" v-for="attachment in files">
                        <div class="divUploadedImg" 
                                v-if="getAttachmentsFileExtension(attachment) == 'image/jpeg' || getAttachmentsFileExtension(attachment) == 'image/jpg' || getAttachmentsFileExtension(attachment) == 'image/png'">
                            <img src="#" v-bind:src="getAttachmentsFileURL(attachment)" class="">
                        </div>

                        <div class="divUploadedFile" v-else>
                            <img src="/images/icon_uploaded_file.png" class="icon" alt="file">
                        </div>

                        <div class="divName">
                            <a :href="getAttachmentsFileURL(attachment)" class="linkDownload" title="{{ attachment.name }}" target="_blank">
                                {{ attachment.name }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        `,
    props: {
        files: {
            type: Array,
            required: true
        }
    },

    ready: function(){

    },

    computed: {
    },

    methods: {
        getAttachmentsFileExtension: function(file){
            let ext = "";

            if(file.name){
                let nameArray = file.name.split(".");

                if(nameArray.length > 0){
                    ext = nameArray[nameArray.length -1];
                }
            }

            return ext;
        },

        getAttachmentsFileURL: function(file){
            let url = "";

            if(file.file){
                url = '/storage/' + file.file;
            }

            return url;
        }
    }
});

Vue.component('title-section', {
    template: `
        <div class="divSectionV1Title">
            <div class="divImage">
                <img class="img" src="/images/logo_v2_image.png">
            </div>

            <p class="title" v-if="title">{{ title }}</p>

            <p class="text" v-if="text">{{ text }}</p>

            <div class="divListParticipants" v-if="showListParticipants">
                <preview-event-participant v-for="participant in listParticipants"
                                           :user="participant.user">
                </preview-event-participant>
            </div>

            <a :href="linkUrl" class="link" v-if="linkUrl">{{ linkText }}</a>
        </div>
        `,
    props: {
        title: {
            type: String,
            required: true
        },
        
        text: {
            type: String
        },

        linkUrl: {
            type: String
        },

        linkText: {
            type: String
        },

        listParticipants: {
            type: Array
        }
    },

    ready: function(){
    },

    computed: {
        showListParticipants: function(){
            return this.listParticipants && this.listParticipants.length > 0;
        }
    },

    methods: {
    }
});

Vue.component('redirect-to', {
    template: `
        <div class="divRedirectTo">
            <p>You will be redirected within the next few seconds… ({{secondsToRedirect}})</p>
        </div>
        `,
    props: {
        url: {
            type: String,
            required: true
        },

        secondsToRedirect: {
            type: Number,
            redirect: true
        }
    },

    data(){
        return{
        };
    },

    ready: function(){
        this.redirectTo();
    },

    computed: {
    },

    methods: {
        redirectTo: function(){
            const component = this;

            component.secondsToRedirect--;

            if(component.secondsToRedirect <= 0){
                document.location.href= component.url;
            }else{
                setTimeout(function(){
                    component.redirectTo();
                },1000);
            }
        }
    }
});

Vue.component('timeline-event', {
    template: `
        <div class="containerTimelineEvent" v-if="user">
            <div class="divUserImage">
                <div class="userGenRoundPhoto" :style="{ 'background-image': 'url(' + userPhoto + ')' }" v-if="user.photo"></div>
                <div class="userGenRoundLetter" v-else>{{ user.name | fistLetterString }}</div>
            </div>

            <div class="divInfo">
                <div class="divInfoInner">
                    <p class="text"><span v-html="text"></span></p>
                    <div class="divUserAndDate">
                        <template v-if="user">
                            <a :href="user | linkToProfile" class="linkC13HoverUnderline">{{ user | userFullName }}</a>
                            <span class="separator">|</span>
                        </template>
                        <span class="date">{{ createdDate }} GMT</span>
                    </div>
                </div>
            </div>
        </div>
        `,
    props: {
        eventName: {
            type: String,
            required: true
        },
        
        user: {
            type: Object
        },

        date: {
            type: String,
            required: true
        },

        eventArrayObjects: {
            type: String
        },

        learningClubId: {
            type: Number
        },

        eventId: {
            type: Number
        },

        eventObj: {
            type: Object
        }
    },

    computed: {
        userPhoto: function(){            
            return this.$options.filters.urlFromStorage(this.user.photo);
        },

        isFromLearningClub: function(){
            return this.learningClubId && parseInt(this.learningClubId) > 0;
        },

        isFromEvent: function(){
            return this.eventId && parseInt(this.eventId) > 0;
        },

        createdDate: function(){
            if(this.date){
                return moment(this.date).format('dddd, DD MMMM YYYY HH:mm');
            }

            return "";
        },

        linkToUser: function(){
            const component = this;

            let url = "";

            if(component.user){
                url = "<a href='"+ component.$options.filters.linkToProfile(component.user) +"' class='linkC13HoverUnderline'>"+ component.$options.filters.userFullName(component.user) +"</a>";
            }

            return url;
        },

        userName: function(){
            const component = this;

            let name = "";

            if(component.user){
                name = component.$options.filters.userFullName(component.user);
            }

            return name;
        },

        text: function(){
            const component = this;
            let text = "";

            switch(component.eventName){
                case 'created':
                    if(component.isFromLearningClub){
                        text = component.userName + " created the club.";
                    }

                    if(component.isFromEvent){
                        if(component.eventObj){
                            switch(component.eventObj.type){
                                case 'advanced_training':
                                    text = component.userName + " created the advanced training.";
                                    break;

                                case 'advanced_training_edition':
                                    text = component.userName + " created the edition.";
                                    break;

                                case 'class_offer_edition':
                                    text = component.userName + " created the edition.";
                                    break;
                            }
                        }
                    }
                    break;

                case 'events_added':
                    if(component.isFromLearningClub){
                        text = component.listObjects;

                        const nrObjects = component.nrFromListObjects;

                        if(nrObjects && nrObjects == 1){
                            text = text + " was added.";
                        }else{
                            text = text + " were added.";
                        }
                    }
                    break;

                case 'cws_added':
                    if(component.isFromLearningClub){
                        text = component.listObjects + " session was added.";
                    }
                    break;

                case 'group_session_added':
                    if(component.isFromLearningClub){
                        text = component.listObjects + " group session was added.";
                    }
                    break;

                case 'updated':
                    if(component.isFromLearningClub){
                        text = component.userName + " updated the club details.";
                    }

                    if(component.isFromEvent){
                        if(component.eventObj){
                            switch(component.eventObj.type){
                                case 'advanced_training':
                                    text = component.userName + " updated the advanced training.";
                                    break;

                                case 'advanced_training_edition':
                                    text = component.userName + " updated the edition.";
                                    break;

                                case 'class_offer_edition':
                                    text = component.userName + " updated the edition.";
                                    break;
                            }
                        }
                    }
                    break;

                case 'joined':
                    if(component.isFromLearningClub){
                        text = component.userName + " joined the club.";
                    }
                    break;

                case 'join_request':
                    if(component.isFromLearningClub){
                        text = component.userName + " asked to join the club.";
                    }

                    if(component.isFromEvent){
                        if(component.eventObj){
                            switch(component.eventObj.type){
                                case 'advanced_training_edition':
                                    text = component.userName + " participation is pending.";
                                    break;

                                case 'class_offer_edition':
                                    text = component.userName + " participation is pending.";
                                    break;
                            }
                        }
                    }
                    break;

                case 'resources_added':
                    if(component.isFromLearningClub || component.isFromEvent){
                        text = component.userName + " added new resources.";
                    }
                    break;
            }

            return text;
        },

        listObjects: function(){
            const component = this;

            if(component.eventArrayObjects && component.eventArrayObjects.length > 0){
                return component.eventArrayObjects;
            }

            return "";
        },

        nrFromListObjects: function(){
            const component = this;

            if(component.eventArrayObjects && component.eventArrayObjects.length > 0){
                return component.eventArrayObjects.split(",").length;
            }
        }
    },

    methods: {
    }
});

Vue.component('filters-doctoral-journey', {
    template: `
        <div class="containerFiltersDoctoralJourney">
            <div class="containerSection list doctoralJourney">
                <p class="titleSection">Doctoral Journey</p>
                
                <div class="containerListTopics" v-if="showListTopics">
                    <div @click="showTopicOptions('thesisResearchProposal')">Thesis research proposal development</div>
                    <div @click="showTopicOptions('researchPlanning')">Research planning: Getting ready for data collection</div>
                    <div @click="showTopicOptions('researchExecution')">Research execution: Data collection and analysis</div>
                    <div @click="showTopicOptions('researchWriteUp')">Research Write up:  Writing your thesis</div>
                    <div @click="showTopicOptions('vivaAndGraduation')">Viva & Graduation</div>
                    <div @click="showTopicOptions('postDoctoralPublishing')">Post Doctoral & Publishing</div>
                </div>

                <div class="containerTopicOptions" v-if="showListTopicsOptions">
                    <div @click="addOrRemoveOptionFromSelected(option.id)"
                        v-for="option in listOptionsToShow">
                        {{ option.name }}

                        <img class="check" src="/images/icon_c54_right.png" v-show="showCheckIconOnOption(option.id)">
                    </div>
                </div>

                <div class="buttonV7 fill cMenuSide btn goBack" @click="manageClickBtnGoBack()" v-if="showBtnGoBack">
                    <img class="icon" src="/images/c_white/arrow_right.png">
                    Back
                </div>
            </div>
        </div>
        `,
    props: {
        preSelectedOptionsIds: {
            type: Array
        }
    },

    ready: function(){
        this.initListDoctoralJourney();
    },

    data() {
        return {
            thesisResearchProposal: [],
            researchPlanning: [],
            researchExecution: [],
            researchWriteUp: [],
            vivaAndGraduation: [],
            postDoctoralPublishing: [],
            selectedTopicJourney: null,
            selectedOptionsIds: [],
        };
    },

    computed: {
        showListTopics: function(){
            return !this.selectedTopicJourney;
        },

        showListTopicsOptions: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney.length > 0;
        },

        showBtnGoBack: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney.length > 0;
        },

        showTopicsThesisResearchProposal: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'thesisResearchProposal';
        },

        showTopicsResearchPlanning: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'researchPlanning';
        },

        showTopicsResearchExecution: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'researchExecution';
        },

        showTopicsResearchWriteUp: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'researchWriteUp';
        },

        showTopicsVivaAndGraduation: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'vivaAndGraduation';
        },

        showTopicsPostDoctoralPublishing: function(){
            return this.selectedTopicJourney && this.selectedTopicJourney == 'postDoctoralPublishing';
        },

        listOptionsToShow: function(){
            if(this.showTopicsThesisResearchProposal){
                return this.thesisResearchProposal;
            }

            if(this.showTopicsResearchPlanning){
                return this.researchPlanning;
            }

            if(this.showTopicsResearchExecution){
                return this.researchExecution;
            }

            if(this.showTopicsResearchWriteUp){
                return this.researchWriteUp;
            }

            if(this.showTopicsVivaAndGraduation){
                return this.vivaAndGraduation;
            }

            if(this.showTopicsPostDoctoralPublishing){
                return this.postDoctoralPublishing;
            }

            return [];
        }
    },

    methods: {
        initListDoctoralJourney: function(){
            const component = this;
            let url = "";
            let params = null;

            url = "/json/dynamic_lists/options_by_list_name";
            params = { name: 'doctoral_journey' };

            component.$http({ 
                url: url, 
                method: 'GET', 
                responseType: 'json', 
                data: params 
            }).then(function(response){
                if(response.data.options && response.data.options.length > 0){
                    $.each(response.data.options, function(index, option){
                        if(index < 4){
                            component.thesisResearchProposal.push(option);
                        }

                        if(index > 3 && index < 11){
                            component.researchPlanning.push(option);
                        }
                        if(index > 10 && index < 15){
                            component.researchExecution.push(option);
                        }
                        if(index > 14 && index < 19){
                            component.researchWriteUp.push(option);
                        }
                        if(index > 18 && index < 23){
                            component.vivaAndGraduation.push(option);
                        }
                        if(index > 22){
                            component.postDoctoralPublishing.push(option);
                        }
                    });

                    component.initPreSelectedOptions();
                }
            }, function(response){
                //error callback
            });
        },

        initPreSelectedOptions: function(){
            const component = this;

            if(component.preSelectedOptionsIds && component.preSelectedOptionsIds.length > 0){
                component.selectedOptionsIds = component.preSelectedOptionsIds;
                component.dispathEventSelectedOptionsIdsUpdated();
            }
        },

        showTopicOptions: function(topic){
            const component = this;

            component.selectedTopicJourney = topic;
        },

        manageClickBtnGoBack: function(){
            const component = this;

            component.selectedTopicJourney = null;
        },

        showCheckIconOnOption: function(optionId){
            const component = this;

            if($.inArray(optionId, component.selectedOptionsIds) > -1){
                return true;
            }

            return false;
        },

        addOrRemoveOptionFromSelected: function(optionId){
            const component = this;

            const indexOptionIdInSelectedOptionsArray = $.inArray(optionId, component.selectedOptionsIds);

            if(indexOptionIdInSelectedOptionsArray > -1){
                component.selectedOptionsIds.splice(indexOptionIdInSelectedOptionsArray, 1);
            }else{
                component.selectedOptionsIds.push(optionId);
            }

            component.dispathEventSelectedOptionsIdsUpdated();
        },

        dispathEventSelectedOptionsIdsUpdated: function(){
            this.$dispatch('filterDoctoralJourneysOptionsSelectedUpdated', this.selectedOptionsIds);
        }
    }
});

Vue.component('filters-doctoral-journey-stages', {
    template: `
        <div class="containerFiltersDoctoralJourney stages">
            <div class="containerSection list doctoralJourney">
                <p class="titleSection">Doctoral Journey</p>
                
                <div class="containerListTopics" v-if="showListStages">
                    <div class="containerTopicOptions">
                        <div v-for="stage in doctoralJourneyStages"
                            @click="addOrRemoveStageIdFromSelected(stage.id)">

                            {{ stage.name }}
                        
                            <img class="check" src="/images/icon_c54_right.png" v-show="showCheckIconOnStage(stage.id)">
                        </div>
                    </div>
                </div>

                <div class="buttonV7 fill cMenuSide btn goBack" @click="manageClickBtnGoBack()" v-if="showBtnGoBack">
                    <img class="icon" src="/images/c_white/arrow_right.png">
                    Back
                </div>
            </div>
        </div>
        `,
    props: {
        preSelectedStagesIds: {
            type: Array
        }
    },

    ready: function(){
        this.getDoctoralJourneyStages();
    },

    data() {
        return {
            doctoralJourneyStages: [],
            selectedStagesIds: [],
        };
    },

    computed: {
        showListStages: function(){
            return this.doctoralJourneyStages && this.doctoralJourneyStages.length > 0;
        },
    },

    methods: {
        getDoctoralJourneyStages: function(){
            const component = this;

            component.$http({ 
                url: "/json/dynamic_lists/doctoral_journey_groups", 
                method: 'GET', 
                responseType: 'json',
            }).then(function(response){
                component.doctoralJourneyStages = response.data.groups;

                component.initPreSelectedStages();
            }, function(response){
                //error callback
            });
        },

        initPreSelectedStages: function(){
            const component = this;

            if(component.preSelectedStagesIds && component.preSelectedStagesIds.length > 0){
                component.selectedStagesIds = component.preSelectedStagesIds;
                component.dispathEventSelectedStagesIdsUpdated();
            }
        },

        showCheckIconOnStage: function(stageId){
            const component = this;

            if($.inArray(stageId, component.selectedStagesIds) > -1){
                return true;
            }

            return false;
        },

        addOrRemoveStageIdFromSelected: function(stageId){
            const component = this;

            const indexOptionIdInSelectedStagesArray = $.inArray(stageId, component.selectedStagesIds);

            if(indexOptionIdInSelectedStagesArray > -1){
                component.selectedStagesIds.splice(indexOptionIdInSelectedStagesArray, 1);
            }else{
                component.selectedStagesIds.push(stageId);
            }

            component.dispathEventSelectedStagesIdsUpdated();
        },

        dispathEventSelectedStagesIdsUpdated: function(){
            this.$dispatch('filterDoctoralJourneysStagesSelectedUpdated', this.selectedStagesIds);
        },
    }
});

Vue.component('preview-attachment-v1', {
    template: `
        <div class="containerPreviewAttachmentV1">
            <a :href="attachmentUrl" target="_blank">
                <div class="divInner image" 
                    :style="{ 'background-image': 'url(' + attachmentUrl + ')' }"
                    v-if="isAttachmentImage"></div>

                <div class="divInner file" v-if="!isAttachmentImage">
                    <img src="/images/icon_uploaded_file.png" />
                    <p class="name" v-if="attachment.name">{{ attachment.name }}</p>
                    <p class="extension">{{ attachmentExtension }}</p>
                </div>
            </a>
        </div>
        `,
    props: {
        attachment: {
            type: Object,
            required: true
        }
    },

    data() {
        return {          
        };
    },

    ready: function(){
    },

    computed: {
        isAttachmentImage: function(){
            const attachmentExt = this.attachmentExtension;

            return attachmentExt == 'jpeg' || attachmentExt == 'jpg' || attachmentExt == 'png' || attachmentExt == 'gif';
        },

        attachmentExtension: function(){
            const component = this;
            let extension = "";

            if(component.attachment.name && component.attachment.name.length > 0){
                const arrayNameSplitByDot = component.attachment.name.split(".");

                if(arrayNameSplitByDot && arrayNameSplitByDot.length > 0){
                    extension = arrayNameSplitByDot[arrayNameSplitByDot.length - 1];
                }
            }
            
            return extension;
        },

        attachmentUrl: function(){
            return this.$options.filters.urlFromStorage(this.attachment.path);
        }
    },

    watch: {
    },

    events: {
    },

    methods: {
    }
});
