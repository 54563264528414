var managerPromotionsBanner = {
    _containerBannerId: "#containerPromotionInvFriendId",

    showBanner: function(){
        const manager = this;

        var divBanner = $(manager._containerBannerId);

        if(divBanner && divBanner.length > 0){
            divBanner.show();
            divBanner.addClass("animated fadeInDown");
        }
    },

    hideBanner: function(){
        const manager = this;

        var divBanner = $(manager._containerBannerId);

        if(divBanner && divBanner.length > 0){
            divBanner.removeClass("fadeInDown");
            divBanner.addClass("fadeOutUp");
        }
    },

    showOrHideBanner: function(){
        const manager = this;

        if(!Cookies.get('dh_promotion_inv_two_friends')){
            manager.showBanner();
        }
    },

    clickBtnClose: function(){
        const manager = this;

        manager.hideBanner();

        Cookies.set('dh_promotion_inv_two_friends', 'yes', { expires: 365 });
    },

    closeAndOpenInviteFriends: function(){
        const manager = this;

        manager.clickBtnClose();

        window.location.href = "/invite";
    }
};

$(function(){
    if($(managerPromotionsBanner._containerBannerId) && $(managerPromotionsBanner._containerBannerId).length > 0){
        managerPromotionsBanner.showOrHideBanner();
    }

    $("#containerPromotionInvFriendId .divClose").on('click', function(){
        managerPromotionsBanner.closeAndOpenInviteFriends();
    });

    $("#containerPromotionInvFriendId .divInviteLater").on('click', function(){
        managerPromotionsBanner.clickBtnClose();
    });
});
