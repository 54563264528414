var managerShowWebinar = {
    _containerVue: "#containerPageContentShowWebinar",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;
                }
            }
        });
    }

};

$(function(){
    if($(managerShowWebinar._containerVue) && $(managerShowWebinar._containerVue).length > 0){
        managerShowWebinar.initVueObj();
    }
});
