$(function(){    
    var ResidenceLocationsFormManager = {
        _containerFormId: '#containerFormCallResidencesId',
        _formVueObj: null,

        initFormVueObj: function(){
            var manager = this;

            manager._formVueObj = new Vue({
                // element to mount to
                el: manager._containerFormId,
                // initial data
                data: {
                    formData: { listLocations: new Array(), other: null, email: null },
                    emailREGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    showErrorInvalid: { email: 'no' },
                    containerFormDivId: manager._containerFormId
                },

				ready: function(){
					var vueObj = this;

					vueObj.initLocations(manager._containerFormId);
				},
                // computed property for form validation state
                computed: {
                    validation: function () {
                        return { email: this.emailREGEX.test(this.formData.email) }
                    },
                    isValid: function () {
                        var validation = this.validation
                        return Object.keys(validation).every(function (key){
                            return validation[key];
                        })
                    }
                },
                // methods
                methods: {
                    initLocations: function(containerFormId){
                        var vueObj = this;
                        var url = $(containerFormId).data('url-locations');

                        vueObj.$http({ url: url, method: 'GET', responseType: 'json', data: {} }).then(function(response){                            
                            if(response.data.locations && response.data.locations.length > 0){
                                $.each(response.data.locations, function(index, location){
                                    vueObj.formData.listLocations.push({ id: location.id, name: location.name, months: [] });
                                })

                                ResidenceLocationsFormManager.reloadFoundation();
                            }
                        }, function(response){
                            //error callback
                        });
                    },

                    sendForm: function () {
                        var vueObj = this;

                        if (vueObj.isValid){
                            var form = $(vueObj.containerFormDivId).find("form");
                            
                            var url = form.attr("action");
                            var method = form.attr("method");
                            var formData = new FormData(form[0]);

                            if(formData.get('g-recaptcha-response')){
                                var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");
                                if(containerLoading && containerLoading.hasClass("hide")){
                                    containerLoading.removeClass("hide");
                                }

                                if(form && !form.hasClass("blur")){
                                    form.addClass("blur");
                                }

                                formData.append('list_locations', JSON.stringify(vueObj.formData.listLocations));

                                vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                                    vueObj.manageRequestResponse(response.data.status);
                                }, function(response){
                                    vueObj.manageRequestResponse("error");
                                });
                            }else{
                                //ReCaptcha needed!
                            }

                        }else{
                        
                        }
                    },

                    manageRequestResponse: function(status){
                        var vueObj = this;

                        var containerLoading = $(vueObj.containerFormDivId).find(".containerWaitingForRequest");

                        if(containerLoading && !containerLoading.hasClass("hide")){
                            containerLoading.addClass("hide");
                        }

                        var form = $(vueObj.containerFormDivId).find("form");

                        if(form && form.hasClass("blur")){
                            form.removeClass("blur");
                        }

                        if(status && status == 'success'){
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.success");

                            divRequestMessage.removeClass("hide");
                            vueObj.clearForm();
                        }else{
                            var divRequestMessage = $(vueObj.containerFormDivId).find(".containerMessageAfterRequest.error");

                            divRequestMessage.removeClass("hide");
                        }

                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    },

                    clearForm: function(){
                        var vueObj = this;

                        vueObj.formData.other = null;
                        vueObj.formData.email = null;

                        $.each(vueObj.formData.listLocations, function(index, location){
                            location.months = null;
                        });
                    }
                }
            });
        },

        reloadFoundation: function(){
            setTimeout(function(){ $(document).foundation(); });
        },
    };

    if($(ResidenceLocationsFormManager._containerFormId) && $(ResidenceLocationsFormManager._containerFormId).length > 0){
        ResidenceLocationsFormManager.initFormVueObj();
    }
});
