$(function(){
    var sideMenuManager = {
        _vueManager: null,

        initSideMenu: function(){
            var manager = this;

            manager._vueManager = new Vue({
				el: '#containterSideMenuAndContentId',

				data: {
                  menuIsOpen: false
				},

				methods: {
                    manageBtnMenuClick: function(){
                        var vueObj = this;

                        if(vueObj.menuIsOpen){
                            vueObj.closeMenu();
                        }else{
                            vueObj.openMenu();
                        }
                    },
					openMenu: function(){
                        var vueObj = this;
                        vueObj.menuIsOpen = true;				
					},
                    closeMenu: function(){
                        var vueObj = this;
                        vueObj.menuIsOpen = false;			
					}
				}
			});
        }
    };

    $(".containerSideMenuPageContent ul").mCustomScrollbar({
        theme:"minimal-dark",
        axis: "y",
        autoDraggerLength: false,
        mouseWheel:{ scrollAmount: 200 }
    });


    if($("#containterSideMenuAndContentId") && $("#containterSideMenuAndContentId").length > 0){
        sideMenuManager.initSideMenu();
    }

    $(document).on('click', function(e){
        if(!$(e.target).closest(".containerSideMenuPageContent ul").length && $(".containerSideMenuPageContent").hasClass("active") && !$(e.target).closest(".containerSideMenuPageContent ul li").length && !$(e.target).closest(".containerSideMenuPage").length){
            sideMenuManager._vueManager.manageBtnMenuClick();
        }
    });
});
