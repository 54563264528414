var managerShowGroupSessions = {
    _containerVue: "#containerPageContentShowGroupSessions",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUser: null,
                currentUserId: 0,
                currentUserEventsIdsJoined: [],
                currentUserClubsIdsJoined: [],
                
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    vueObj.getCurrentUserInfo();
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;

                        if(vueObj.currentUser){
                            vueObj.currentUserId = vueObj.currentUser.id;
                        }
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }

};

$(function(){
    if($(managerShowGroupSessions._containerVue) && $(managerShowGroupSessions._containerVue).length > 0){
        managerShowGroupSessions.initVueObj();
    }
});
