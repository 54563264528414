$(function(){
  if($("#formSubscribeId").length > 0){
    var subscribeManager = {

      _animationEnd: 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
      _animatedInDiv: "zoomIn",
      _animatedOutDiv: "zoomOut",
      _form: $(".form-subscribe"),
      _timeoutDivFeedback: null,

      _emailRE: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      _formVue: new Vue({

        // element to mount to
        el: '#formSubscribeId',

        // initial data
        data: {
          newSubscription: { email: '', name: '' },
          showDivError: "no"
        },

        // computed property for form validation state
        computed: {
          validation: function () {
            return { email: subscribeManager._emailRE.test(this.newSubscription.email) }
          },
          isValid: function () {
            var validation = this.validation
            return Object.keys(validation).every(function (key) {
              return validation[key]
            })
          }
        },

        // methods
        methods: {
          addSubscription: function () {
            var manager = subscribeManager;
            if (this.isValid){
              var url = manager._form.attr("action");
              var method = manager._form.attr("method");
              var formData = new FormData(manager._form[0]);

              // GET request
              this.$http({ url: url, method: method, data: formData }).then(function (response) {
                this.addSubscriptionResponseHandler(response.data);
              }, function (response) {
                  // error callback
              });
            }else{
              this.showDivValidations($(".container-form-validations"));
            }
          },

          addSubscriptionResponseHandler: function(responseData){
            var vue = this;
            var divFeedback = $(".container-form-feedback");
            divFeedback.empty();

            if(divFeedback.hasClass("error")){
              divFeedback.removeClass("error");
            }

            if(divFeedback.hasClass("success")){
              divFeedback.removeClass("success");
            }

            if(responseData.status){
              divFeedback.addClass(responseData.status);
              if(responseData.status === "success"){
                subscribeManager._form.find("input.email").val("");
              }
            }

            if(responseData.errors){
              $.each(responseData.errors, function(key, error){
                divFeedback.append("<p>"+ error[0] +"</p>");
              });
            }

            if(responseData.message){
              divFeedback.append("<p>"+ responseData.message +"</p>");
            }


            vue.showDivValidations(divFeedback);
          },

          showDivValidations: function(divToShow){
            if(divToShow.is(":visible")){
              divToShow.addClass("hide");
              clearTimeout(subscribeManager._timeoutDivFeedback);
            }

            if(divToShow.hasClass("hide")){
              divToShow.removeClass("hide");
            }

            divToShow.addClass(subscribeManager._animatedInDiv).one(subscribeManager._animationEnd, function() {
              $(this).removeClass(subscribeManager._animatedInDiv);
            });

            subscribeManager._timeoutDivFeedback = setTimeout(function(){
              divToShow.addClass(subscribeManager._animatedOutDiv).one(subscribeManager._animationEnd, function() {
                $(this).removeClass(subscribeManager._animatedOutDiv);
                $(this).addClass("hide");
              });
            }, 3500);
          }
        }
      })
    }
  }
});
