var eventsIndexManager = {
    _containerVueId: "#containerVuePageIndexEvents",
    _vueObj: null,

    initVue: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVueId,
            data: {
                events: { next: null, past: null },
                loading: { next: 'yes', past: 'yes' },
                selectedType: { next: 'all', past: 'all' },
                currentUser: null,
                currentUserAccPremium: 'no',
                currentUserClubsIds: [],
                eventsIdsCurrentUserJoined: [],
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoadingEventsNext(){
                    return this.loading.next && this.loading.next == 'yes';
                },

                showLoadingEventsPast(){
                    return this.loading.past && this.loading.past == 'yes';
                },

                showListEventsNext(){
                    return !this.showLoadingEventsNext && this.events.next && this.events.next.length > 0;
                },

                showListEventsPast(){
                    return !this.showLoadingEventsPast && this.events.past && this.events.past.length > 0;
                },

                showListEmptyEventsNext(){
                    return !this.showLoadingEventsNext && (!this.events.next || this.events.next.length <= 0);
                },

                showListEmptyEventsPast(){
                    return !this.showLoadingEventsPast && (!this.events.past || this.events.past.length <= 0);
                }
            },

            watch: {
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserAccPremium = 'yes';
                        }
                    }

                    vueObj.getCurrentUserEventsIdsJoined();
                    vueObj.getCurrentUserLearningClubsIds();

                    vueObj.getEvents('next');
                    vueObj.getEvents('finished');

                    vueObj.getCurrentUser();
                },

                getCurrentUserEventsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_ids', method: 'GET' }).then(function(response){
                        vueObj.eventsIdsCurrentUserJoined = response.data.events_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                    }, function(response){
                        //ERROR
                    });
                },

                getEvents: function(filter){
                    const vueObj = this;

                    const url = "/json/events/index";
                    
                    let params = { filter: filter };

                    if(filter == 'next'){
                        vueObj.loading.next = "yes";
                        params.type = vueObj.selectedType.next;
                    }else{
                        vueObj.loading.past = "yes";
                        params.type = vueObj.selectedType.past;
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){                    
                        if(filter == 'next'){
                            vueObj.events.next = response.data.events;
                            vueObj.loading.next = "no";
                        }else{
                            vueObj.events.past = response.data.events;
                            vueObj.loading.past = "no";
                        }

                    }, function(response){
                        //error callback
                    });
                },

                manageChangeType: function(type, $eventsSection){
                    const vueObj = this;
                    let previousSelectedType = "";

                    switch($eventsSection){
                        case 'finished':
                            previousSelectedType = vueObj.selectedType.past;
                            vueObj.selectedType.past = type;
                            break;

                        default:
                            previousSelectedType = vueObj.selectedType.next;
                            vueObj.selectedType.next = type;
                    }

                    if(previousSelectedType != type){
                        vueObj.getEvents($eventsSection);
                    }
                }
            }
        });
    }
};

$(function(){
    if($(eventsIndexManager._containerVueId) && $(eventsIndexManager._containerVueId).length > 0){
        eventsIndexManager.initVue();
    }
});
