var uploadedResourcesManager = {
    _containerVue: "#containerVueLearningClubsManageResourcesId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                clubId: 0,
                currentUser: null,
                members: null,
                reloadResources: 'no',
                reloadResourcesStorageSize: 'no',
                isLoading: 'yes',
            },

            ready: function(){
                this.init();
            },

            events: {
                'files_finish_upload': function(msg){
                    const vueObj = this;
                    vueObj.reloadResources = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResources = "no";
                    });
                },

                'resource_deleted': function(msg){
                    const vueObj = this;
                    vueObj.reloadResourcesStorageSize = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResourcesStorageSize = "no";
                    });
                }
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    const elemContainerElem = vueObj.$els.containerElem;

                    if(elemContainerElem){
                        let clubId = $(elemContainerElem).data('learning-club-id');
                        
                        if(clubId){
                            vueObj.clubId = parseInt(clubId);
                        }
                    }

                    vueObj.getCurrentUser();
                    vueObj.getClubApprovedMembers();
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;

                        vueObj.isLoading = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                getClubApprovedMembers: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/approved_members";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.members = response.data.members;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }

};

$(function(){
    if($(uploadedResourcesManager._containerVue) && $(uploadedResourcesManager._containerVue).length > 0){
        uploadedResourcesManager.initVueObj();
    }
});
