$(function(){    
    $('body').on('click', '.wysiwygToolbar .btnTool', function(e){
        e.preventDefault();
        e.stopPropagation();

        var command = $(this).data('command');

        if(command == 'h1' || command == 'h2' || command == 'p') {
            document.execCommand('formatBlock', false, command);
        }

        if(command == 'forecolor' || command == 'backcolor') {
            document.execCommand($(this).data('command'), false, $(this).data('value'));
        }

        if(command == 'createlink' || command == 'insertimage') {
            var url = prompt("Enter the URL");
            document.execCommand($(this).data('command'), false, url);
        }else{
            document.execCommand($(this).data('command'), false, null);
        }
    });

    $('body').on('paste', '.divEditor.wysiwyg', function(e){
        e.preventDefault();

        const clipboardData = event.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');

        //pastedData = striptags(pastedData, ['p', 'br', 'ul', 'ol', 'li']); // remove all html except the listed tags

        var arrayParagraphs = pastedData.split('\n');

        if(arrayParagraphs && arrayParagraphs.length > 0){
            var tempData = '';

            $.each(arrayParagraphs, function(index, value){
                if(value.length > 1){
                    var tempElem = "<p>"+ value +"</p>";
                    tempData = tempData + tempElem;
                }
            });

            if(tempData.length > 1){
                pastedData = tempData;
            }
        }

        pastedData = pastedData.replace(/(class=["|'].*["|'])/ig, ''); // Remove classes
        pastedData = pastedData.replace(/(id=["|'].*["|'])/ig, ''); // Remove ids
        pastedData = pastedData.replace(/(style=["|'].*["|'])/ig, ''); // Remove inline styles
        pastedData = pastedData.replace(/\s\B/ig, ''); // Remove non linebreaking whitespace

        document.execCommand('insertHTML', false, pastedData);
    });
});
