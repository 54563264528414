$(function(){
    $(document).on('keyup', '.containerPageAdminConfigurations .tableConfigs input:text', function(e){
        if(e.keyCode === 13){
            adminConfigurationsManager.updateKey($(this));
        }
    });
});

var adminConfigurationsManager = {
    _url: '/admin/configurations/{id}/update',
    _vueEventConfigurationsId: ".containerEventConfigurationsVue",

    updateKey: function(input){
        if(input){            
            var url = '/admin/configurations/'+ input.data("id") +'/update';
            var params = { key: input.attr("name"), value: input.val() };
            
            var tokenInput = $("input[name='_token']");
            if(tokenInput){
                params['_token'] = tokenInput.val();
            }

            $.ajax({ 
                url: url, 
                type: 'POST', 
                data: params 
            }).done(function(data){
                if(data.saved && data.saved === 'yes'){
                    var divRequestMessage = $(".containerMessageAfterRequest.success");
                    divRequestMessage.removeClass("hide");
                }else{
                    var divRequestMessage = $(".containerMessageAfterRequest.error");
                    divRequestMessage.removeClass("hide");
                }
            });
        }
    },

    initVueEventConfigurations: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            el: manager._vueEventConfigurationsId,
            data: {
            },

            ready: function(){
            },

            methods: {
            }
        });
    }
};

