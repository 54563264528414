var eventFormManager = {
    _containerFormId: '#containerFormEventNewOrEditId',
    _containerFormGeneralEvent: "#container",
    _formVueObj: null,

    initFormNewOrEditVueObj: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            // element to mount to
            el: manager._containerFormId,
            // initial data
            data: {
                containerFormDivId: manager._containerFormId,
                event: { is_online: false },
            },
            // computed property for form validation state
            computed: {},
            // methods
            methods: {

            }
        });
    },
};

$(function(){
    if($(eventFormManager._containerFormId) && $(eventFormManager._containerFormId).length > 0){
        eventFormManager.initFormNewOrEditVueObj();
    }
});
