$(function(){
    var showUserManager = {
        _divClassContainerListCheckboxesPrivacy: '.containerListCheckboxesUserPrivacy',
        _containerVueId: '#containerContentPageUserProfileId',
        _vueObjDetailPage: null,
        _cropperUserImage: null,

        submitFormUserPrivacyUpdate: function(clickedCheckbox){
            var manager = this;

            var divParent = clickedCheckbox.parents('.containerCheckboxUserPrivacy');
            var form = divParent.find('form');

            if(form){
                var divContainerListCheckboxes = $(manager._divClassContainerListCheckboxesPrivacy);
                
                if(divContainerListCheckboxes){
                    var divContainerListCheckboxesInner = divContainerListCheckboxes.find(".containerListCheckboxesUserPrivacyInner");

                    if(divContainerListCheckboxesInner && !divContainerListCheckboxesInner.hasClass('blur2px')){
                        divContainerListCheckboxesInner.addClass('blur2px');
                    }

                    var divContainerLoading = divContainerListCheckboxes.find(".containerWaitingForRequest");

                    if(divContainerLoading && divContainerLoading.hasClass('hide')){
                        divContainerLoading.removeClass('hide');
                    }
                }
                form.submit();
            }
        },

        initVueDetailPage: function(){
            var manager = this;

            manager._vueObjDetailPage = new Vue({
                el: manager._containerVueId,

                data: {
                    containerId: manager._containerVueId,
                    userId: 0,
                    currentUserId: 0,
                    currentUserAccPremium: 'no',
                    tokenCsrf: null,
                    listTabsActive: { about: 'no', settings: 'no', eventsParticipant: 'no', eventsTutor: 'no', 
                                      webinars: 'no', courses: 'no', residencies: 'no', workshops: 'no' },
                    listTabsSettingsActive: { privacy: 'no', tutor: 'no', team: 'no', deleteAcc: 'no', changePassword: 'no', forum: 'no', emailSubscriptions: 'no' },
                    deleteAccountForm: { reason: null, show_reason_other: 'no' },
                    forumFrequency: null,
                    emailFrqequencyDigest: null,
                    becomeTutor: { show_form: 'no' },
                    photo: { modal: $("#modalUserPhotoUpdateId"), image: $("#userPhotoUploadId") },
                    eventsJoined: null,
                    currentUserEventsIdsJoined: [],
                    currentUserClubsIdsJoined: [],
                },

                ready: function(){
                    this.init();
                },

                computed: {
                    showEventsJoined: function(){
                        return this.eventsJoined && this.eventsJoined.length > 0;
                    }
                },

                methods: {
                    init: function(){
                        const vueObj = this;

                        vueObj.getCurrentUserInfo();

                        vueObj.initActiveTab();
                        vueObj.initActiveTabSettings();

                        if(vueObj.$els.containerVue){
                            vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                            vueObj.userId = parseInt($(vueObj.$els.containerVue).data('user-id'));
                        }

                        if($(vueObj.$els.currentUserId)){
                            vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                        }
    
                        if($(vueObj.$els.currentUserAccPremium)){
                            if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                                vueObj.currentUserAccPremium = 'yes';
                            }
                        }

                        vueObj.getEventsJoined();
                    },

                    initActiveTab: function(){
                        var vueObj = this;
                        var div = $(vueObj.containerId).find(".containerTopSelectorSections");

                        if(div && div.length > 0){
                            var activeTab = div.data('selected-tab');

                            if(activeTab){
                                vueObj.makeTabActive(activeTab);
                            }else{
                                vueObj.makeTabActive('about');
                            }
                        }
                    },

                    initActiveTabSettings: function(){
                        var vueObj = this;
                        var div = $(vueObj.containerId).find(".containerUserSettings");

                        if(div && div.length > 0){
                            if(div && div.length > 0){
                                var activeTab = div.data('selected');
    
                                if(activeTab){
                                    vueObj.makeTabSettingActive(activeTab);
                                }else{
                                    vueObj.makeTabSettingActive('privacy');
                                }
                            }
                        }
                    },

                    makeTabActive: function(tabName){
                        var vueObj = this;

                        if(vueObj.listTabsActive[tabName] != 'yes'){
                            $.each(vueObj.listTabsActive, function(index, item){
                                vueObj.listTabsActive[index] = 'no';
                            });
    
                            vueObj.listTabsActive[tabName] = 'yes';
                        }
                    },

                    makeTabSettingActive: function(tabName){
                        var vueObj = this;

                        if(vueObj.listTabsSettingsActive[tabName] != 'yes'){
                            $.each(vueObj.listTabsSettingsActive, function(index, item){
                                vueObj.listTabsSettingsActive[index] = 'no';
                            });
    
                            vueObj.listTabsSettingsActive[tabName] = 'yes';
                        }
                    },
                    
                    deleteAccount_changeReasonRadioBtn: function(){
                        var vueObj = this;

                        if(vueObj.deleteAccountForm.reason && vueObj.deleteAccountForm.reason == 'other'){
                            vueObj.deleteAccountForm.show_reason_other = 'yes';
                        }else{
                            vueObj.deleteAccountForm.show_reason_other = 'no';
                        }
                    },

                    clickLinkBecomeTutor: function(){
                        var vueObj = this;

                        vueObj.becomeTutor.show_form = 'yes';
                    },

                    manageUserPhotoUpload: function(){
                        const vueObj = this;

                        const elemInputFile = vueObj.$els.userphotouploader;

                        const files = $(elemInputFile)[0].files;

                        let done = function(url){
                            if(vueObj.photo.image){
                                vueObj.photo.image.attr("src",url);
                            }

                            if(vueObj.photo.modal){
                                vueObj.photo.modal.foundation('open');
                            }

                        };

                        if(files && files.length > 0){
                            let reader = new FileReader();

                            reader.onload = function(){
                                done(reader.result);
                            };

                            reader.readAsDataURL(files[0]);
                        }
                    },

                    updateUserPhoto: function(){
                        const vueObj = this;
                        let canvas = null;

                        if(manager._cropperUserImage){
                            canvas = manager._cropperUserImage.getCroppedCanvas({ width: 279, height: 394 });

                            canvas.toBlob(function(blob){    
                                const reader = new FileReader();
    
                                reader.readAsDataURL(blob);
                                reader.onloadend = function(){
                                    const base64data = reader.result;
    
                                    vueObj.$http({ 
                                            url: '/json/users/update_photo', method: 'POST', 
                                            contentType: 'multipart/form-data', 
                                            responseType: 'json', 
                                            data: { image: base64data },
                                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                                    }).then(function(response){
                                        //SUCCESS
                                        if(response.data.result == "success"){
                                            if(vueObj.photo.modal){
                                                vueObj.photo.modal.foundation('close');
                                            }

                                            //$(document).trigger("flashNotyAlert", ["success", "MEH"]);

                                            window.location.reload();
                                        }
                                    }, function(response){
                                        //ERROR
                                    });
                                }
                            });
                        }
                    },

                    onChangeForumFrequency: function(){
                        const vueObj = this;

                        vueObj.$http({ 
                            url: '/json/users/update_forum_frequency', method: 'POST', 
                            responseType: 'json', 
                            data: { frequency: vueObj.forumFrequency },
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            //SUCCESS
                            if(response.data.result == "success"){
                                $(document).trigger("flashNotyAlert", ["success", "Updated!"]);
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    onChangeEmailFrequencyDigest: function(){
                        const vueObj = this;

                        vueObj.$http({ 
                            url: '/json/users/update_email_frequency_digest_current_user', method: 'POST', 
                            responseType: 'json', 
                            data: { frequency: vueObj.emailFrqequencyDigest },
                            headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            //SUCCESS
                            if(response.data.result == "success"){
                                $(document).trigger("flashNotyAlert", ["success", "Updated!"]);
                            }
                        }, function(response){
                            //ERROR
                        });
                    },

                    getEventsJoined: function(){
                        const vueObj = this;

                        const url = "/json/users/"+ vueObj.userId +"/events_joined";
    
                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.eventsJoined = response.data.events;
                        }, function(response){
                            //ERROR
                        });
                    },

                    getCurrentUserInfo: function(){
                        const vueObj = this;

                        vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                            vueObj.currentUser = response.data.user;
                            vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                            vueObj.currentUserClubsIdsJoined = response.data.joined_learning_clubs_ids;
                        }, function(response){
                            //ERROR
                        });
                    },
                }
            });
        },

        initCropper: function(modal){
            const manager = this;

            if(modal){
                let image = modal.find("#userPhotoUploadId");

                manager._cropperUserImage = new Cropper($(image)[0], {
                    aspectRatio: 2/3,
                    viewMode: 2,
                    preview:'.preview'
                });
            }
        },

        destroyCropper: function(){
            const manager = this;

            manager._cropperUserImage.destroy();
            manager._cropperUserImage = null;
        }
    }

    $('.checkboxUserPrivacy').on('click', function(){
        showUserManager.submitFormUserPrivacyUpdate($(this));
    });

    if(showUserManager._containerVueId && $(showUserManager._containerVueId).length > 0){
        showUserManager.initVueDetailPage();
    }
});
