var managerShowMasterCourseEdition = {
    _containerVueId: '#containerShowMasterCourseEditionId',
    _vueObjDetailPage: null,
    _cropperEventImage: null,
    _cropperEventImageSocial: null,

    initVue: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                eventId: 0,
                tokenCsrf: null,
                currentUserId: 0,
                currentUser: null,
                currentUserIsPremiumAcc: 'no',
                currentUserClubsIds: [],
                eventsIdsCurrentUserJoined: [],
                photo: { modal: $("#modalMasterCourseEditionPhotoUpdateId"), image: $("#eventPhotoUploadId"), imageSocial: $("#eventPhotoUploadSocialId") },
                base64data: { original: null, web: null, fb_lkn: null, twitter: null },
                isUploadingImage: 'no',
                currentUserCanSeeDetails: 'no',
                activeIndexTask: 0,
                forceUpdateThreads: 'no',
                tasks: null,
                sectionActive: 'overview',
                messageBoardThreadsLoadedFirstTime: 'yes',
            },

            ready: function(){
                this.init();
            },

            events: {
                'forceUpdateMessageBoard': function(){
                    this.forceUpdateThreads = 'yes';

                    this.$nextTick(() => {
                        this.forceUpdateThreads = 'no';
                    });
                },

                'updatedIndexActiveOnTaskList': function(msg){
                    this.activeIndexTask = msg;
                },

                'updatedIndexActiveOnMessageBoard': function(msg){
                    this.activeIndexTask = msg;
                },

                'messageBoardByTask_threadsLoaded': function(msg){
                    const vueObj = this;

                    Vue.nextTick(function () {
                        if(vueObj.isSectionActiveForum && vueObj.messageBoardThreadsLoadedFirstTime == 'yes'){
                            const elemForum = document.getElementById("containerGenericMessageBoardByTaskAndThreadDetailId");
    
                            if(elemForum){
                                elemForum.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                            }

                            vueObj.messageBoardThreadsLoadedFirstTime = 'no';
                        }
                    });
                }
            },

            computed: {
                isSectionActiveOverview: function(){
                    return !this.sectionActive || (this.sectionActive && this.sectionActive == 'overview');
                },

                isSectionActiveTasks: function(){
                    return this.sectionActive && this.sectionActive == 'tasks';
                },

                isSectionActiveForum: function(){
                    return this.sectionActive && this.sectionActive == 'forum';
                },

                isSectionActiveParticipants: function(){
                    return this.sectionActive && this.sectionActive == 'participants';
                },

                isSectionActiveMyTasks: function(){
                    return this.sectionActive && this.sectionActive == 'my_tasks';
                },

                isSectionActiveConversationMyCoach: function(){
                    return this.sectionActive && this.sectionActive == 'conversation_my_coach';
                },

                isSectionActiveMyStudents: function(){
                    return this.sectionActive && this.sectionActive == 'my_students';
                },
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.eventId = $(vueObj.$els.containerVue).data('event-id');
                        vueObj.sectionActive = $(vueObj.$els.containerVue).data("section-active");
                    }

                    if(vueObj.$els.containerUserId){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerUserId).data('user-id'));
                    }

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserIsPremiumAcc = 'yes';
                        }
                    }

                    if($(vueObj.$els.currentUserCanSeeDetails)){
                        vueObj.currentUserCanSeeDetails = $(vueObj.$els.currentUserCanSeeDetails).data('status');
                    }
           
                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }

                    Vue.nextTick(function () {
                        divExpandManager.check_size_div_text();
                    });

                    vueObj.getTasks();
                },
                
                getTasks: function(){
                    const vueObj = this;

                    if(vueObj.eventId && vueObj.eventId > 0){
                        vueObj.tasks = null;

                        let url = "/json/events/"+ vueObj.eventId +"/tasks";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.tasks = response.data.tasks;
                            }else{
                                vueObj.tasks = [];
                            }
                        }, function(response){
                            //ERROR
                            vueObj.tasks = [];
                        });
                    }
                },

                manageImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventphotouploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.base64data.original = url;

                        if(vueObj.photo.image){
                            vueObj.photo.image.attr("src",url);
                        }

                        if(vueObj.photo.imageSocial){
                            vueObj.photo.imageSocial.attr("src",url);
                        }

                        if(vueObj.photo.modal){
                            vueObj.photo.modal.foundation('open');
                        }

                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;
                    let canvasOptions = null;

                    switch(type){
                        case 'web':
                            canvasOptions = { width: 1190, height: 1074, minWidth: 1190, minHeight:1074 };
                            break;
                        case 'fb_lkd':
                            canvasOptions = { width: 1200, height: 627, minWidth: 1200, minHeight:627 };
                            break;
                        case 'twitter':
                            canvasOptions = { width: 1200, height: 600, minWidth: 1200, minHeight:600 };
                            break;
                    }

            
                    canvas = manager._cropperEventImageSocial.getCroppedCanvas(canvasOptions);
                    

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                updateUserPhoto: function(){
                    const vueObj = this;
                    
                    vueObj.isUploadingImage = 'yes';

                    if(vueObj.photo.modal.find(".divContLoading") && vueObj.photo.modal.find(".divContLoading").hasClass("hide")){
                        vueObj.photo.modal.find(".divContLoading").removeClass("hide");
                    }

                    if(vueObj.photo.modal.find(".btnClose") && !vueObj.photo.modal.find(".btnClose").hasClass("hide")){
                        vueObj.photo.modal.find(".btnClose").addClass("hide");
                    }

                    if(vueObj.photo.modal.find(".divContForm") && !vueObj.photo.modal.find(".divContForm").hasClass("hide")){
                        vueObj.photo.modal.find(".divContForm").addClass("hide");
                    }

                    vueObj.getBase64dataImages('web');
                    vueObj.getBase64dataImages('fb_lkd');
                    vueObj.getBase64dataImages('twitter');

                    vueObj.$nextTick(() => {
                        setTimeout(function(){
                            let params = {
                                image_original: vueObj.base64data.original, 
                                image_web: vueObj.base64data.web, 
                                image_fb_lkn: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            vueObj.$http({ 
                                url: "/json/events/"+ vueObj.eventId +"/update_image/v1", method: 'POST',  
                                contentType: 'multipart/form-data', 
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                            }).then(function(response){
                                //SUCCESS
                                if(response.data.result == "success"){
                                   /* if(vueObj.photo.modal){
                                        vueObj.photo.modal.foundation('close');
                                    }*/
        
                                    window.location.reload();
                                }
                            }, function(response){
                                vueObj.isUploadingImage = 'no';
                                //ERROR
                            });
                        }, 1500);

                    });
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.eventsIdsCurrentUserJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;

                        vueObj.isLoadingResources = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                forceReloadTimeline: function(){
                    const vueObj = this;

                    vueObj.reloadTimeline = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadTimeline = "no";
                    });
                },

                manageActiveIndexChange: function(newIndex){
                    const vueObj = this;

                    vueObj.activeIndexTask = newIndex;
                }

            }
        });
    },

    initCropper: function(modal){
        const manager = this;

        if(modal){
            let imageSocial = modal.find("#eventPhotoUploadSocialId");

            manager._cropperEventImageSocial = new Cropper($(imageSocial)[0], {
                aspectRatio: 16/9,
                viewMode: 2,
                zoomable: false,
            });
        }
    },

    destroyCropper: function(){
        const manager = this;

        manager._cropperEventImage.destroy();
        manager._cropperEventImage = null;

        manager._cropperEventImageSocial.destroy();
        manager._cropperEventImageSocial = null;
    }
};

$(function(){
    if(managerShowMasterCourseEdition._containerVueId && $(managerShowMasterCourseEdition._containerVueId).length > 0){
        managerShowMasterCourseEdition.initVue();
    }

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalMasterCourseEditionPhotoUpdateId"){
                managerShowMasterCourseEdition.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalMasterCourseEditionPhotoUpdateId"){
                managerShowMasterCourseEdition.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedMasterCourseEditionImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        managerShowMasterCourseEdition._vueObjDetailPage.updateUserPhoto();
    });
});
