var eventsSeriesListManager = {
    _containerVueListId: "#containerVuePageListViewEventsSeriesId",
    _vueObjPageList: null,

    initVueObjPageList: function(){
        var manager = this;

        manager._vueObjPageList = new Vue({
            el: manager._containerVueListId,
            data: {
                searchBy: null,
                optionsForSelect: [],
                series: null,
                currentUserSeriesIdsJoined: [],
                windowWidth: 0
            },

            ready: function(){
                this.init();
            },

            computed: {
                hasOptionsForSelect(){
                    return this.optionsForSelect && this.optionsForSelect.length > 0;
                }
            },

            watch: {
                searchBy: function(){
                    if(this.searchBy){
                        this.getSeries();
                    }
                },
            },

            methods: {
                onWindowResize: function(){
                    const vueObj = this;

                    vueObj.windowWidth = window.innerWidth;
                },

                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.eventType = $(vueObj.$els.containerVue).data("type");
                    }

                    vueObj.windowWidth = window.innerWidth;

                    vueObj.initSelectOptionsAndSeries();
                },

                initSelectOptionsAndSeries: function(){
                    const vueObj = this;

                    let url = "/json/dynamic_lists/options_by_list_name";
                    let params = { name: 'thesis_development_stages' };

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.options && response.data.options.length > 0){
                            vueObj.optionsForSelect = response.data.options;
                        }

                        vueObj.getSeries();
                        vueObj.getCurrentUserEventsSeriesIdsJoined();
                    }, function(response){
                        //error callback
                    });
                },

                getSeries: function(){
                    const vueObj = this;

                    vueObj.events = null;

                    let url = "/json/events_series";
                    let params = { type: vueObj.eventType, filter: null };

                    if(vueObj.searchBy == 'latest'){
                        params.filter = 'latest';
                    }

                    if(vueObj.searchBy && $.isNumeric(vueObj.searchBy)){
                        params.dynamic_list_option_id = vueObj.searchBy;
                        params.filter = 'latest';
                    }

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: params 
                    }).then(function(response){
                        if(response.data.events_series){
                            vueObj.series = response.data.events_series;
                        }else{
                            vueObj.series = [];
                        }

                    }, function(response){
                        //error callback
                        vueObj.series = [];
                    });
                },

                getCurrentUserEventsSeriesIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_series_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserSeriesIdsJoined = response.data.events_series_ids;
                    }, function(response){
                        //ERROR
                    });
                }
            }
        });
    }
};

$(function(){
    if($(eventsSeriesListManager._containerVueListId) && $(eventsSeriesListManager._containerVueListId).length > 0){
        eventsSeriesListManager.initVueObjPageList();

        $(window).resize(function() {
            eventsSeriesListManager._vueObjPageList.windowWidth = window.innerWidth;
        });
    }
});
