var sortableManager = {
    startSortable: function(link){
        var containerDivLinks = link.parent();

        if(containerDivLinks){
            var linkSave = containerDivLinks.find(".linkToSortable.save");
            var containerListToSortableId = containerDivLinks.data('list-id');
            var containerListToSortable = $("#"+containerListToSortableId);

            if(linkSave && linkSave.hasClass('hide')){
                link.addClass('hide');
                linkSave.removeClass('hide');
            }

            if(containerListToSortable){
                containerListToSortable.sortable({
                    appendTo: document.body,
                    axis: "y",
                    containment: "window",
                    cursor: "move",
                    start: function(e, ui){
                        ui.placeholder.height(ui.item.height());
                    },
                    update: function(event, ui){
                        //console.log("MOVE");
                    }
                });
            }
        }
    },

    saveSortable: function(link){
        var containerDivLinks = link.parent();

        if(containerDivLinks){
            var linkStart = containerDivLinks.find(".linkToSortable.start");
            var containerListToSortableId = containerDivLinks.data('list-id');
            var containerListToSortable = $("#"+containerListToSortableId);

            if(containerListToSortable){
                var listIds = containerListToSortable.sortable("toArray");

                if(listIds && listIds.length > 0){
                    var stringIdsOrder = listIds.toString();

                    var url = containerDivLinks.data('route');
                    var csrfToken = containerDivLinks.data('csrf');

                    var params = {};
                    params['_token'] = csrfToken;
                    params['list_ordered_events_ids'] = stringIdsOrder;

                    $.ajax({
                        url: url,
                        type: "POST",
                        dataType: "json",
                        data: params,
                        success: function(data){
                            if(data.status == 'success'){
                                $(document).trigger("flashNotyAlert", ["success", data.message]);
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", data.message]);
                            }
                        }
                    });
                }

                containerListToSortable.sortable("destroy");
            }

            if(linkStart && linkStart.hasClass('hide')){
                link.addClass('hide');
                linkStart.removeClass('hide');
            }
        }
    }
};

$(function(){
    $(document).on('click', '.linkToSortable', function(e){
        e.preventDefault();
        e.stopPropagation();

        var clickedLink = $(this);

        if(clickedLink.hasClass('start')){
            sortableManager.startSortable(clickedLink);
        }else if(clickedLink.hasClass('save')){
            sortableManager.saveSortable(clickedLink);
        }
    });
});
