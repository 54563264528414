var newEventEditionManager = {
    _containerId: '#containerFormNewEditionId',
    _formVueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._formVueObj = new Vue({
            // element to mount to
            el: manager._containerId,
            // initial data
            data: {
                containerFormDivId: manager._containerId,
                crowdfundingId: 0,
                edition: { date_start: null, date_end: null },
                durationInDays: null,
                tutors: [],
                participantsCrowdfunding: [],
                usersIdsToJoinEdition: []
            },

            ready: function(){
                const vueObj = this;

                const elemContainerForm = vueObj.$els.containerForm;

                if(elemContainerForm){
                    vueObj.crowdfundingId = $(elemContainerForm).data('crowdfunding-id');
                }

                vueObj.getParticipantsCrowdfunding();
            },

            computed: {
                showParticipantsCrowdfunding(){
                    return this.participantsCrowdfunding && this.participantsCrowdfunding.length > 0;
                }
            },

            events: {
                'updatedListAuthorsOrModerators': function(msg){
                    this.tutors = msg;
                },
                'updatedUsersIdsSelected': function(msg){
                    this.usersIdsToJoinEdition = msg;
                }
            },

            // methods
            methods: {
                calculateDateEnd: function(){
                    const vueObj = this;

                    vueObj.edition.date_end = null;

                    if(vueObj.edition.date_start && vueObj.durationInDays){
                        let dateStartMoment = moment(vueObj.edition.date_start);

                        if(dateStartMoment.isValid()){
                            let dateEndMoment = dateStartMoment;

                            let nrDaysToAdd = parseInt(vueObj.durationInDays);

                            if(nrDaysToAdd && nrDaysToAdd >= 0){
                                //dateEndMoment = dateStartMoment.add(vueObj.masterCourseNrTasks, 'w');
                               dateEndMoment = dateStartMoment.add(nrDaysToAdd, 'd');
                            }

                            if(dateEndMoment.isValid()){
                                vueObj.edition.date_end = dateEndMoment.format('YYYY-MM-DD');
                            }                              
                        }
                    }
                },

                sendForm: function(){
                    const vueObj = this;

                    const form = $(vueObj.containerFormDivId).find("form");
                    
                    if(form){
                        let url = form.attr("action");
                        let method = form.attr("method");
                        let params = new FormData(form[0]);

                        params.append('tutors', JSON.stringify(vueObj.tutors));
                        params.append('users_ids_to_join_edition', JSON.stringify(vueObj.usersIdsToJoinEdition));

                        vueObj.$http({ url: url, method: method, data: params }).then(function(response){
                            vueObj.manageRequestResponse(response.data.status);
                        }, function(response){
                            vueObj.manageRequestResponse("error");
                        });
                    }
                },

                manageRequestResponse: function(status){
                    if(status == 'success'){
                        $(document).trigger("flashNotyAlert", ["success", "Success!"]);

                        window.location.href = "/admin/workshops";
                    }else{
                        $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                    }
                },

                getParticipantsCrowdfunding: function(){
                    const vueObj = this;

                    const url = "/json/crowdfundings/"+ vueObj.crowdfundingId +"/participants_users"

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json', 
                        data: [] 
                    }).then(function(response){
                        if(response.data.participants && response.data.participants.length > 0){
                            $.each(response.data.participants, function(index, participant){
                                if(participant.user){
                                    vueObj.participantsCrowdfunding.push(participant.user);
                                }
                            });
                        }else{
                            vueObj.participantsCrowdfunding = [];
                        }

                    }, function(response){
                        //error callback
                        vueObj.participantsCrowdfunding = [];
                    });
                }
            }
        });
    },
};

$(function(){
    if($(newEventEditionManager._containerId) && $(newEventEditionManager._containerId).length > 0){
        newEventEditionManager.initVueObj();
    }
});
