var workingSessionsJoinBBBManager = {
    _containerVue: "#containerVueWorkingSessionJoinBBB",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                sessionId: 0,
                tokenCsrf: null,
                isLoading: 'yes',
                isRunning: null,
                isFinished: null
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                showSessionFinished: function(){
                    return !this.showLoading && this.isFinished && this.isFinished == 'yes';
                },

                showSessionRunning: function(){
                    return !this.showLoading && this.isRunning && this.isRunning == 'yes';
                },

                showSessionNotStarted: function(){
                    return !this.showLoading && !this.showSessionFinished && !this.showSessionRunning;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;
                    const elemContainerElem = vueObj.$els.containerVue;

                    if(elemContainerElem){
                        const sessionId = $(elemContainerElem).data('working-session-id');
                        
                        if(sessionId){
                            vueObj.sessionId = parseInt(sessionId);
                        }

                        const tokenCsrf = $(elemContainerElem).data('token-csrf');
                        
                        if(tokenCsrf){
                            vueObj.tokenCsrf = tokenCsrf;
                        }
                    }

                    vueObj.getInfo();
                },

                getInfo: function(){
                    const vueObj = this;

                    const url = "/json/collaborative_working_sessions/"+ vueObj.sessionId +"/info_running_and_finished";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        if(response.data.status == 'success'){
                            vueObj.isRunning = response.data.is_running;
                            vueObj.isFinished = response.data.is_finished;

                            vueObj.isLoading = 'no';

                            if(vueObj.showSessionRunning){
                                vueObj.addCurrentUserAsParticipantAndGetUrlToRedirect();
                            }
                        }
                    }, function(response){
                        //ERROR
                    });
                },

                addCurrentUserAsParticipantAndGetUrlToRedirect: function(){
                    const vueObj = this;

                    const url = "/json/collaborative_working_sessions/"+ vueObj.sessionId +"/add_participant_current_user_and_get_url_redirect";

                    vueObj.$http({ 
                                url: url, method: 'POST',
                                responseType: 'json',
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                        }).then(function(response){
                            if(response.data.status == 'success'){
                                if(response.data.url_redirect){
                                    setTimeout(function(){ window.location.href = response.data.url_redirect }, 1000);
                                }
                            }
                        }, function(response){
                            //ERROR
                        });
                }
            }
        });
    }

};

$(function(){
    if($(workingSessionsJoinBBBManager._containerVue) && $(workingSessionsJoinBBBManager._containerVue).length > 0){
        workingSessionsJoinBBBManager.initVueObj();
    }
});
