var pageNavigationManager = {
    expandOrCollapseNavigation: function(clickedBtn){
        const manager = this;

        const containerNavigation = clickedBtn.parents(".containerPageNavigationSide");

        if(containerNavigation){
            if(containerNavigation.hasClass("expanded")){
                manager.collapseNavigation(containerNavigation)
            }else{
                manager.expandNavigation(containerNavigation);
            }
        }
    },

    expandNavigation: function(containerNavigation){
        if(!containerNavigation.hasClass("expanded")){
            containerNavigation.addClass("expanded");
    
            let navigationWidth = containerNavigation.data("width");
    
            if(!navigationWidth){
                navigationWidth = "15rem";
            }
    
            containerNavigation.width(navigationWidth);
    
            const containerPage = $("#divContainerPageId");
            if(containerPage){
                containerPage.addClass("navigationOpen");
            }
        }
    },

    collapseNavigation: function(containerNavigation){
        if(containerNavigation.hasClass("expanded")){
            containerNavigation.removeClass("expanded");
            containerNavigation.width("1rem");
    
            const containerPage = $("#divContainerPageId");
            if(containerPage){
                containerPage.removeClass("navigationOpen");
            }
        }
    },

    onWindowResize: function(){
        const manager = this;
        
        const containerNavigation = $(".containerPageNavigationSide");

        if(containerNavigation){
            if($(window).width() <= 800){
                if(!containerNavigation.hasClass("menuHorizontalScroll")){
                    containerNavigation.addClass("menuHorizontalScroll");
                }

                manager.expandNavigation(containerNavigation);
            }else{
                if(containerNavigation.hasClass("menuHorizontalScroll")){
                    containerNavigation.removeClass("menuHorizontalScroll");
                }
            }

            
        }
    }
};

$(function(){
    pageNavigationManager.onWindowResize();

    $("#btnPageNavigationExpandOrCollapse").click(function(e){
        e.stopPropagation();
        e.preventDefault();

        pageNavigationManager.expandOrCollapseNavigation($(this));
    });

    $(window).resize(function(){
        pageNavigationManager.onWindowResize();
    });
});
