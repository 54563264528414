var tablesManager = {
    changePricesBetweenMonthlyAndQuarterly: function(checkbox){
        const parent = $(checkbox).parents(".liTable");
        let containerPrices = null;
        let containerBuyPackage = null;
    
        if(parent){
            containerPrices = parent.find(".price");
            containerBuyPackage = parent.find(".divBuyPackage");
        }
    
        if(containerPrices){
            if(checkbox.checked){
                //Show quarterly
                containerPrices.find('.monthly').hide();
                containerPrices.find('.quarterly').show();
            }else{
                //Show monthly
                containerPrices.find('.quarterly').hide();
                containerPrices.find('.monthly').show();
            }
        }

        if(containerBuyPackage){
            if(checkbox.checked){
                containerBuyPackage.find("div.monthly").hide();
                containerBuyPackage.find("div.quarterly").show();
            }else{
                containerBuyPackage.find("div.monthly").show();
                containerBuyPackage.find("div.quarterly").hide();
            }
        }
    },

    checkAndApplyChangesForMobileLayout: function(){
        const manager = this;

        if($(window).width() <= 955){
            //MOBILE
            const divTables = $(".tableManJS");

            $(".tableManJS").each(function(index, divTable){
                if(!$(divTable).hasClass('mobile')){
                    $(divTable).addClass('mobile');

                    const divsColumns = $(divTable).find(".columnPackage");
    
                    if(divsColumns && divsColumns.length > 0){
                        $(divsColumns).each(function(index, divColumn){
                            manager.showOrHideBenefitsElems($(divColumn), 'hide');

                            $(divColumn).find(".liShowMoreOrLess .linkShowMore").show();
                            $(divColumn).find(".liShowMoreOrLess .linkShowLess").hide();
                        });
                    }
                }

            });
        }else{
            //DESKTOP
            const divTables = $(".tableManJS");

            $(".tableManJS").each(function(index, divTable){
                if($(divTable).hasClass('mobile')){
                    $(divTable).removeClass('mobile');

                    const divsColumns = $(divTable).find(".columnPackage");
    
                    if(divsColumns && divsColumns.length > 0){
                        $(divsColumns).each(function(index, divColumn){
                            manager.showOrHideBenefitsElems($(divColumn), 'show');

                            $(divColumn).find(".liShowMoreOrLess .linkShowMore").hide();
                            $(divColumn).find(".liShowMoreOrLess .linkShowLess").hide();
                        });
                    }
                }

            });
        }
    },

    showAllBenefits: function(clickedLink){
        const manager = this;

        const parentDivColumn = clickedLink.parents(".columnPackage");

        if(parentDivColumn){
            manager.showOrHideBenefitsElems(parentDivColumn, 'show');

            const linkShowLess = parentDivColumn.find(".liShowMoreOrLess .linkShowLess").show();

            if(linkShowLess && linkShowLess.length > 0){
                linkShowLess.show();
            }

            clickedLink.hide();
        }
    },

    hideAllBenefits: function(clickedLink){
        const manager = this;

        const parentDivColumn = clickedLink.parents(".columnPackage");

        if(parentDivColumn){
            manager.showOrHideBenefitsElems(parentDivColumn, 'hide');

            const linkShowMore = parentDivColumn.find(".liShowMoreOrLess .linkShowMore").show();

            if(linkShowMore && linkShowMore.length > 0){
                linkShowMore.show();
            }

            clickedLink.hide();
        }
    },

    showOrHideBenefitsElems: function(parentDiv, showOrHide){
        if(parentDiv){
            const listLiBenefits = parentDiv.find(".liBenefit");
            
            if(showOrHide && showOrHide == 'show'){
                if(listLiBenefits && listLiBenefits.length > 0){
                    listLiBenefits.each(function(index, liBenefit){
                        $(liBenefit).show();
                    });
                }
            }else{
                if(listLiBenefits && listLiBenefits.length > 0){
                    listLiBenefits.each(function(index, liBenefit){
                        $(liBenefit).hide();
                    });
                }
            }
        }
    }
};

function priceTableChangeBetweenMonthlyAndQuarterly(checkbox){
    tablesManager.changePricesBetweenMonthlyAndQuarterly(checkbox);
};

$(function(){
    if($(".tableManJS").length > 0){
        tablesManager.checkAndApplyChangesForMobileLayout();
    };

    $(window).resize(function(){
        if($(".tableManJS").length > 0){
            tablesManager.checkAndApplyChangesForMobileLayout();
        }
    });

    $(".containerTablePricesV1 .ulTable .liTable ul li.liShowMoreOrLess a").on('click', function(e){
        e.stopPropagation();
        e.preventDefault();

        const clickedLink = $(this);

        if(clickedLink.hasClass('linkShowMore')){
            tablesManager.showAllBenefits(clickedLink);
        }else{
            tablesManager.hideAllBenefits(clickedLink);
        }
    });
});
