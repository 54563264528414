var eventRegistrationCompleteManager = {
    _containerVue: "#containerEventRegistrationCompleteId",
    _vueObjList: null,

    initVueObj: function(){
        const manager = this;

        manager._vueObjList = new Vue({
            el: manager._containerVue,
            data: {
                userId: null,
                eventId: null,
                event: null,
                hosts: [],
                associatedLearningClubs: [],
                loading: { event: 'no', suggested: 'no' },
                suggested: { webinar: null, club: null, course: null, advancedTraining: null, workshop: null },
                userJoinedEventsIds: null,
                userJoinedClubsIds: null
            },

            ready: function(){
                const vueObj = this;

                if(vueObj.$els.containerVue){
                    vueObj.eventId = parseInt($(vueObj.$els.containerVue).data('event-id'));
                    vueObj.userId = parseInt($(vueObj.$els.containerVue).data('user-id'));
                    
                    vueObj.getEvent();
                    vueObj.getSuggested();
                }
            },

            computed: {
                isWebinar(){
                    return this.event && this.event.type == 'training';
                },

                isGroupSession(){
                    return this.event && this.event.type == 'group_session';
                },

                isAdvancedTrainingEdition(){
                    return this.event && this.event.type == 'advanced_training_edition';
                },

                isWorkshopEdition(){
                    return this.event && this.event.type == 'workshop_edition';
                },

                isMasterCourseEdition(){
                    return this.event && this.event.type == 'master_course_edition';
                },

                isClassOfferEdition(){
                    return this.event && this.event.type == 'class_offer_edition';
                },
                
                isLoadingEvent(){
                    return this.loading.event && this.loading.event == 'yes';
                },

                isLoadingSuggested(){
                    return this.loading.suggested && this.loading.suggested == 'yes';
                },

                showEvent: function(){
                    return this.event && !this.isLoadingEvent;
                },

                showSuggested: function(){
                    return (this.suggested.webinar || this.suggested.club || this.suggested.course ) && !this.isLoadingSuggested;
                }
            },

            methods: {
                getEvent: function(){
                    const vueObj = this;

                    if(vueObj.eventId){
                        vueObj.loading.event = 'yes';

                        const url = "/json/events/"+ vueObj.eventId +"/details_v1";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            if(response.data.status == 'success'){
                                vueObj.event = response.data.obj_details;
                                vueObj.hosts = response.data.hosts;
                                vueObj.associatedLearningClubs = response.data.learning_clubs_associated;

                                vueObj.loading.event = 'no';
                            }
                        }, function(response){
                            //ERROR
                        });
                    }
                },

                getSuggested: function(){
                    const vueObj = this;

                    if(vueObj.eventId){
                        vueObj.loading.suggested = 'yes';

                        const url = "/json/events/"+ vueObj.eventId +"/suggested_by_doctoral_journey";

                        vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                            vueObj.suggested.webinar = response.data.webinar;
                            vueObj.suggested.club = response.data.club;
                            vueObj.suggested.course = response.data.course;
                            vueObj.suggested.advancedTraining = response.data.advanced_training;
                            vueObj.suggested.workshop = response.data.workshop;

                            vueObj.userJoinedEventsIds = response.data.events_ids_user_joined;
                            vueObj.userJoinedClubsIds = response.data.clubs_ids_user_joined;

                            vueObj.loading.suggested = 'no';
                        }, function(response){
                            //ERROR
                        });
                    }
                },
            }
        });
    }
};

$(function(){
    if($(eventRegistrationCompleteManager._containerVue) && $(eventRegistrationCompleteManager._containerVue).length > 0){
        eventRegistrationCompleteManager.initVueObj();
    }
});
