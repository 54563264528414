var managerComponentDashboardProgrammeUser = {
    _containerVue: "#containerSectionDashboardUserProgrammesId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                programmes: null,
                isLoading: 'yes'
            },

            ready: function(){
                this.init();
            },

            computed: {
                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                hasProgrammes: function(){
                    return this.programmes && this.programmes.length > 0;
                },

                showSectionProgrammes: function(){
                    return !this.showLoading && this.hasProgrammes;
                },
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerVue).data('current-user-id'));
                    }
                    
                    vueObj.getProgrammes();
                },

                getProgrammes: function(){
                    const vueObj = this;

                    vueObj.isLoading = 'yes';

                    const params = { };

                    vueObj.$http({ url: '/json/users/'+ vueObj.currentUserId +'/programmes', method: 'GET', params: params }).then(function(response){
                        vueObj.programmes = response.data.programmes;

                        vueObj.isLoading = 'no';

                        vueObj.manageCarousel();
                    });
                },

                manageCarousel: function(){
                    const component = this;
        
                    component.$nextTick(() => {
                        if(component.$els.listProgrammes){
                            const elem = $(component.$els.listProgrammes);
        
                            if(component.hasProgrammes){
                                if(!elem.hasClass('owl-carousel')){
                                    elem.addClass("owl-carousel");
        
                                    elem.owlCarousel({
                                        items: 3,
                                        loop:false,
                                        margin:0,
                                        autoplay: false,
                                        dots: true,
                                        mouseDrag: true,
                                        autoHeight: true,
                                        responsive:{
                                            0:{
                                                items:1,
                                                center: true
                                            },
                                            650:{
                                                items: 2
                                            },
                                            1000:{
                                                items:3
                                            }
                                        }
                                    });
                                    
                                    setTimeout(function(){ elem.trigger('refresh.owl.carousel'); }, 1500);
                                }else{
                                    elem.trigger('refresh.owl.carousel');
                                }
                            }else{
                                component.removeCarousel(elem);
                            }
                        }
                    });
                },
        
                removeCarousel: function(divCarousel){
                    if(divCarousel && divCarousel.hasClass('owl-carousel')){
                        divCarousel.trigger('destroy.owl.carousel');
                        divCarousel.removeClass('owl-carousel');
                    }
                },
            }
        });
    }
};

$(function(){
    if($(managerComponentDashboardProgrammeUser._containerVue) && $(managerComponentDashboardProgrammeUser._containerVue).length > 0){
        managerComponentDashboardProgrammeUser.initVueObj();
    }
});
