var managerIndexGroupSessions = {
    _containerVue: "#containerPageContentIndexGroupSessions",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUserAccPremium: 'no',
                currentUser: null,
                currentUser: null,
                currentUserClubsIds: [],
                currentUserEventsIdsJoined: [],
                currentUserMembershipsActive: [],
            },

            ready: function(){
                this.init();
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if($(vueObj.$els.currentUserId)){
                        vueObj.currentUserId = parseInt($(vueObj.$els.currentUserId).data('id'));
                    }

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserAccPremium = 'yes';
                        }
                    }
 
                    if(vueObj.currentUserId && vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.currentUserEventsIdsJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;
                        vueObj.currentUserMembershipsActive = response.data.memberships_active;
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }

};

$(function(){
    if($(managerIndexGroupSessions._containerVue) && $(managerIndexGroupSessions._containerVue).length > 0){
        managerIndexGroupSessions.initVueObj();
    }
});
