var divExpandManager = {
  show_more: function(link){
    var comment = $(link).closest(".div-expand-container");
    var comment_text_outter_div = comment.find(".outter-div-text");
    var comment_text_inner_div = comment.find(".inner-div-text");

    $(link).addClass("hide");
    comment.find(".close-expand-div").removeClass("hide");
    comment_text_outter_div.height(comment_text_inner_div.height() + 10);
    comment_text_outter_div.css("max-height", "initial");
  },

  hide_more: function(link){
    var comment = $(link).closest(".div-expand-container");
    var comment_text_outter_div = comment.find(".outter-div-text");
    var comment_text_inner_div = comment.find(".inner-div-text");

    $(link).addClass("hide");
    comment.find(".open-expand-div").removeClass("hide");

    comment_text_outter_div.removeAttr('style');

    if(comment_text_outter_div.height() > '35rem'){
      comment_text_outter_div.height('2.2rem');
    }
  },

  check_size_div_text: function(){
    const divsToInit = $(".div-expand-container");

    if(divsToInit.length > 0){
      divsToInit.each(function() {
        const divLinksToExpand = $(this).find(".open-expand-div");

        if($(this).find(".inner-div-text").height() > $(this).find(".outter-div-text").height()){
          if(divLinksToExpand && divLinksToExpand.hasClass("hide")){
            divLinksToExpand.removeClass("hide");
          }
        }else{
          if(divLinksToExpand && !divLinksToExpand.hasClass("hide")){
            divLinksToExpand.addClass("hide");
          }
        }

      });
    }
  }

};
