var managerServicesPageInResidencesFeatured = {
    _containerVue: "#servicesPageInResidencesFeaturedId",
    _vueObj: null,

    initVueObj: function(){
        var manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,

            data: {
                currentUserId: 0,
                currentUser: null,
                currentUserClubsIds: [],
                userJoinedEventsIds: [],
                events: null,
                isLoading: 'no',
            },

            ready: function(){
                this.init();
            },

            computed: {
                showListEvents: function(){
                    return this.events && this.events.length > 0;
                },

                showLoading: function(){
                    return this.isLoading && this.isLoading == 'yes';
                },

                showNoDataFound: function(){
                    return this.events && this.events.length <= 0;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;
                     
                    vueObj.getCurrentUserInfo();

                    vueObj.getInResidences();
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        if(response.data.user){
                            vueObj.currentUserId = response.data.user.id;
                        }

                        vueObj.currentUser = response.data.user;
                        vueObj.userJoinedEventsIds = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getInResidences: function(location){
                    const vueObj = this;

                    vueObj.events = null;
                    vueObj.isLoading = 'yes';

                    const url = "/json/events";
                    let params = { type: 'in_residence', location: null, is_finished: false };

                    switch(location){
                        case 'in_person':
                            params.location = 'in_residence_in_person';
                            break;
                        case 'online':
                            params.location = 'in_residence_online';
                            break;
                    }

                    vueObj.$http({
                        url: url,
                        method: 'GET',
                        responseType: 'json',
                        data: params
                    }).then(function(response){
                        vueObj.events = response.data.events;

                        vueObj.isLoading = 'no';
                    });
                }
            }
        });
    }
};

$(function(){
    if($(managerServicesPageInResidencesFeatured._containerVue) && $(managerServicesPageInResidencesFeatured._containerVue).length > 0){
        managerServicesPageInResidencesFeatured.initVueObj();
    }
});
