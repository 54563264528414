var myDashboardManager = {
    _containerVue: "#pageContentVueMyDashboard",
    _vueObj: null,

    initVue: function(){
        const manager = this;

        manager._vueObj = new Vue({
            el: manager._containerVue,
            data: {
                currentUser: null,
                eventsIdsJoined: [],
                currentUserClubsIds: []
            },

            ready: function(){
                this.init();                
            },

            events: {
            },

            computed: {
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    vueObj.getCurrentUser();
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;

                        vueObj.getEventsIdsJoined();
                        vueObj.getCurrentUserLearningClubsIds();
                    }, function(response){
                        //ERROR
                    });
                },

                getEventsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_ids', method: 'GET' }).then(function(response){
                        vueObj.eventsIdsJoined = response.data.events_ids;

                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },
            }
        });
    }
};

$(function(){
    if($(myDashboardManager._containerVue) && $(myDashboardManager._containerVue).length > 0){
        myDashboardManager.initVue();
    }
});
