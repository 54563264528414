var learningClubsShowManager = {
    _containerVueId: '#containerShowLearningClubsId',
    _vueObjDetailPage: null,
    _cropperEventImage: null,
    _cropperEventImageSocial: null,

    initVue: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                clubId: 0,
                tokenCsrf: null,
                currentUserId: 0,
                currentUser: null,
                currentUserIsPremiumAcc: 'no',
                currentUserClubsIds: [],
                eventsIdsCurrentUserJoined: [],
                photo: { modal: $("#modalClubPhotoUpdateId"), image: $("#eventPhotoUploadId"), imageSocial: $("#eventPhotoUploadSocialId") },
                base64data: { original: null, web: null, fb_lkn: null, twitter: null },
                isUploadingImage: 'no',
                events: { next: null, next_with_date: [], past: null },
                members: null,
                nrShowEventsUpcomingWithDate: 2,
                tabActive: 'overview',
                reloadResources: 'no',
                reloadResourcesStorageSize: 'no',
                isLoadingResources: 'yes',
                reloadTimeline: 'no',
                isLoadingTimeline: 'yes',
                latestResources: [],
                latestTimelineEvents: [],
                eventsHandpickedByMods: [],
                eventsHandpickedByDH: { webinar: null, course: null },
            },

            ready: function(){
                this.init();
            },

            events: {
                'files_finish_upload': function(msg){
                    const vueObj = this;
                    vueObj.reloadResources = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResources = "no";
                    });
                },

                'resource_deleted': function(msg){
                    const vueObj = this;
                    vueObj.reloadResourcesStorageSize = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadResourcesStorageSize = "no";
                    });
                },

                'resources_latest_n': function(resources){
                    const vueObj = this;

                    vueObj.latestResources = resources;
                },

                'timeline_latest_n': function(timeline){
                    const vueObj = this;

                    vueObj.latestTimelineEvents = timeline;
                },

                'changeTab': function(tabToChange){
                    this.changeTabAndScrollToIt(tabToChange, 'yes');
                }
            },

            computed: {
                showTabOverview: function(){
                    return this.tabActive && this.tabActive == 'overview';
                },

                showTabEvents: function(){
                    return this.tabActive && this.tabActive == 'events';
                },

                showTabForum: function(){
                    return this.tabActive && this.tabActive == 'forum';
                },

                showTabMembers: function(){
                    return this.tabActive && this.tabActive == 'members';
                },

                showTabResources: function(){
                    return this.tabActive && this.tabActive == 'resources';
                },

                showTabTimeline: function(){
                    return this.tabActive && this.tabActive == 'timeline';
                },

                showTabUpgradeToPro: function(){
                    return this.tabActive && this.tabActive == 'upgrade_pro';
                },

                showLoading: function(){
                    return this.isUploadingImage && this.isUploadingImage == 'yes'; 
                },

                showFormUploadImage: function(){
                    return this.isUploadingImage && this.isUploadingImage == 'no'; 
                },

                showEventsUpcomingWithDate: function(){
                    return this.events.next_with_date && this.events.next_with_date.length > 0;
                },

                showEventsUpcoming: function(){
                    return this.events.next && this.events.next.length > 0;
                },

                showEventsPast: function(){
                    return this.events.past && this.events.past.length > 0;
                },

                showBtnViewAllUpcomingEventsForEventsUpcomingWithoutDate: function(){
                    if(this.showEventsUpcomingWithDate && this.events.next_with_date.length > this.nrShowEventsUpcomingWithDate){
                        return true;
                    }

                    return false;
                },

                showLoadingResources: function(){
                    return this.isLoadingResources && this.isLoadingResources == 'yes';
                },

                showLoadingTimeline: function(){
                    return this.isLoadingTimeline && this.isLoadingTimeline == 'yes';
                },

                showEventsHandpickedByMods: function(){
                    return this.eventsHandpickedByMods && this.eventsHandpickedByMods.length > 0;
                },

                showEventsHandpickedByDH: function(){
                    return this.eventsHandpickedByDH.webinar || this.eventsHandpickedByDH.course;
                },

                showBtnEventsNextSeeAll: function(){
                    return this.events.next && this.events.next.length > 5;
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.clubId = $(vueObj.$els.containerVue).data('club-id');

                        if($(vueObj.$els.containerVue).data('tab-active')){
                            vueObj.tabActive = $(vueObj.$els.containerVue).data('tab-active');
                        }
                    }

                    if(vueObj.$els.containerUserId){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerUserId).data('user-id'));
                    }

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserIsPremiumAcc = 'yes';
                        }
                    }

                    vueObj.checkAndInitQrCodeWhatsapp();

                    vueObj.getEvents();
                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserEventsIdsJoined();
                        vueObj.getCurrentUserLearningClubsIds();
                        vueObj.getCurrentUser();
                    }
                    vueObj.getClubApprovedMembers();
                    vueObj.getEventsHandpickedByDH();
                },

                manageImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventphotouploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.base64data.original = url;

                        if(vueObj.photo.image){
                            vueObj.photo.image.attr("src",url);
                        }

                        if(vueObj.photo.imageSocial){
                            vueObj.photo.imageSocial.attr("src",url);
                        }

                        if(vueObj.photo.modal){
                            vueObj.photo.modal.foundation('open');
                        }

                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;
                    let canvasOptions = null;

                    switch(type){
                        case 'web':
                            canvasOptions = { width: 1190, height: 1074, minWidth: 1190, minHeight:1074 };
                            break;
                        case 'fb_lkd':
                            canvasOptions = { width: 1200, height: 627, minWidth: 1200, minHeight:627 };
                            break;
                        case 'twitter':
                            canvasOptions = { width: 1200, height: 600, minWidth: 1200, minHeight:600 };
                            break;
                    }

            
                    canvas = manager._cropperEventImageSocial.getCroppedCanvas(canvasOptions);
                    

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                updateUserPhoto: function(){
                    const vueObj = this;
                    
                    vueObj.isUploadingImage = 'yes';

                    if(vueObj.photo.modal.find(".divContLoading") && vueObj.photo.modal.find(".divContLoading").hasClass("hide")){
                        vueObj.photo.modal.find(".divContLoading").removeClass("hide");
                    }

                    if(vueObj.photo.modal.find(".btnClose") && !vueObj.photo.modal.find(".btnClose").hasClass("hide")){
                        vueObj.photo.modal.find(".btnClose").addClass("hide");
                    }

                    if(vueObj.photo.modal.find(".divContForm") && !vueObj.photo.modal.find(".divContForm").hasClass("hide")){
                        vueObj.photo.modal.find(".divContForm").addClass("hide");
                    }

                    vueObj.getBase64dataImages('web');
                    vueObj.getBase64dataImages('fb_lkd');
                    vueObj.getBase64dataImages('twitter');

                    vueObj.$nextTick(() => {
                        setTimeout(function(){
                            let params = {
                                image_original: vueObj.base64data.original, 
                                image_web: vueObj.base64data.web, 
                                image_fb_lkn: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            vueObj.$http({ 
                                url: "/json/learning_clubs/"+ vueObj.clubId +"/update_image", method: 'POST', 
                                contentType: 'multipart/form-data', 
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                            }).then(function(response){
                                //SUCCESS
                                if(response.data.result == "success"){
                                   /* if(vueObj.photo.modal){
                                        vueObj.photo.modal.foundation('close');
                                    }*/
        
                                    window.location.reload();
                                }
                            }, function(response){
                                vueObj.isUploadingImage = 'no';
                                //ERROR
                            });
                        }, 1500);

                    });
                },

                getEvents: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/events_and_working_sessions";

                    let tempEventsNext = [];
                    let tempEventsPast = [];
                    let tempEventsHandpickedByMods = [];
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        if(response.data.next && response.data.next.length > 0){
                            $.each(response.data.next, function(index, event){
                                if(event.date && (event.type && event.type == 'group_session')){
                                    vueObj.events.next_with_date.push(event);
                                }

                                if(event.type && event.type == 'group_session'){
                                    tempEventsNext.push(event);
                                }else{
                                    tempEventsHandpickedByMods.push(event);
                                }
                            });

                            if(tempEventsNext && tempEventsNext.length > 0){
                                vueObj.events.next = tempEventsNext;
                            }
                        }

                        if(response.data.past && response.data.past.length > 0){
                            $.each(response.data.past, function(index, event){
                                if(event.type && event.type == 'group_session'){
                                    tempEventsPast.push(event);
                                }else{
                                    tempEventsHandpickedByMods.push(event);
                                }
                            });

                            if(tempEventsPast && tempEventsPast.length > 0){
                                vueObj.events.past = tempEventsPast;
                            }
                        }

                        if(tempEventsHandpickedByMods && tempEventsHandpickedByMods.length > 0){
                            vueObj.eventsHandpickedByMods = tempEventsHandpickedByMods;
                            vueObj.initCarousel('handpicked_mods');
                        }

                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserLearningClubsIds: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current_user_joined_learning_clubs_ids', method: 'GET' }).then(function(response){
                        vueObj.currentUserClubsIds = response.data.learning_clubs_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUserEventsIdsJoined: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_joined_events_ids', method: 'GET' }).then(function(response){
                        vueObj.eventsIdsCurrentUserJoined = response.data.events_ids;
                    }, function(response){
                        //ERROR
                    });
                },

                getCurrentUser: function(){
                    const vueObj = this;
        
                    vueObj.$http({ url: '/json/users/current', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;

                        vueObj.isLoadingResources = 'no';
                        vueObj.isLoadingTimeline = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                getClubApprovedMembers: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/approved_members";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        vueObj.members = response.data.members;
                    }, function(response){
                        //ERROR
                    });
                },

                getEventsHandpickedByDH: function(){
                    const vueObj = this;

                    const url = "/json/learning_clubs/"+ vueObj.clubId +"/suggested_events_by_doctoral_journey";
        
                    vueObj.$http({ url: url, method: 'GET' }).then(function(response){
                        if(response.data.webinar){
                            vueObj.eventsHandpickedByDH.webinar = response.data.webinar;
                        }

                        if(response.data.course){
                            vueObj.eventsHandpickedByDH.course = response.data.course;
                        }

                        if(vueObj.eventsHandpickedByDH.webinar && vueObj.eventsHandpickedByDH.course){
                            vueObj.initCarousel('handpicked_dh');
                        }

                    }, function(response){
                        //ERROR
                    });
                },

                changeTabAndScrollToIt: function(newTab, scrollToMenu){
                    const vueObj = this;

                    if(vueObj.tabActive != newTab){
                        vueObj.tabActive = newTab;

                        if(scrollToMenu && scrollToMenu == 'yes' && $(vueObj.$els.containerMenuTabs)){
                            vueObj.$nextTick(() => {
                                vueObj.$els.containerMenuTabs.scrollIntoView();
                            });
                        }
                    }
                },

                forceReloadTimeline: function(){
                    const vueObj = this;

                    vueObj.reloadTimeline = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadTimeline = "no";
                    });
                },

                checkAndInitQrCodeWhatsapp: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerWhastapp){
                        const url = $(vueObj.$els.containerWhastapp).find("a").attr('href');

                        const qrCode = new QRCodeStyling({
                            width: 100,
                            height: 100,
                            type: "svg",
                            data: url,
                            image: "/images/qrcode/icon.png",
                            dotsOptions: {
                                color: "#888482",
                                type: "rounded"
                            },
                            backgroundOptions: {
                                color: "transparent",
                            },
                            imageOptions: {
                                crossOrigin: "anonymous",
                                margin: 3,
                                imageSize: 0.4,
                                hideBackgroundDots: true
                            }
                        });

                        qrCode.append(vueObj.$els.containerWhastappQrCode);
                    }
                },

                initCarousel: function(carouselName){
                    const component = this;
        
                    component.$nextTick(() => {
                        let divCarousel = null;
                        let carouselOptions = { items: 2, loop: true, margin:0, autoplay: false, dots: false, nav: true, mouseDrag: true, autoHeight: true,
                                                responsive:{ 0:{ items:1, center: true }, 660:{ items: 2 } }
                                              };

                        switch(carouselName){
                            case 'handpicked_dh':
                                divCarousel = component.$els.carouselEventsHandpickedDh;
                                break;

                            default:
                                divCarousel = component.$els.carouselEventsHandpickedMods;
                        }

                        if(divCarousel){
                            const elem = $(divCarousel);
                            
                            if(!elem.hasClass('owl-carousel')){
                                elem.addClass("owl-carousel");
    
                                elem.owlCarousel(carouselOptions);
                                
                                setTimeout(function(){ elem.trigger('refresh.owl.carousel'); }, 1500);
                            }else{
                                elem.trigger('refresh.owl.carousel');
                            }
                        }
                    });
                },
        
                removeCarousel: function(divCarousel){
                    if(divCarousel && divCarousel.hasClass('owl-carousel')){
                        divCarousel.trigger('destroy.owl.carousel');
                        divCarousel.removeClass('owl-carousel');
                    }
                },
            }
        });
    },

    initCropper: function(modal){
        const manager = this;

        if(modal){
            let imageSocial = modal.find("#eventPhotoUploadSocialId");

            manager._cropperEventImageSocial = new Cropper($(imageSocial)[0], {
                aspectRatio: 16/9,
                viewMode: 2,
                zoomable: false,
            });
        }
    },

    destroyCropper: function(){
        const manager = this;

        manager._cropperEventImage.destroy();
        manager._cropperEventImage = null;

        manager._cropperEventImageSocial.destroy();
        manager._cropperEventImageSocial = null;
    }
};

$(function(){
    if(learningClubsShowManager._containerVueId && $(learningClubsShowManager._containerVueId).length > 0){
        learningClubsShowManager.initVue();
    }

    $(document).on("learningClubsReloadAssets", {}, function() {
        learningClubsShowManager._vueObjDetailPage.forceReloadTimeline();
    });

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalClubPhotoUpdateId"){
                learningClubsShowManager.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalClubPhotoUpdateId"){
                learningClubsShowManager.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedClubImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        learningClubsShowManager._vueObjDetailPage.updateUserPhoto();
    });


});
