var notyAlertManager = (function(){
    var output = {};

    output.show = function(type, message){
        new Noty({
            type: type,
            layout: 'topRight',
            theme: 'relax',
            text: message,
            timeout: '3000',
            progressBar: false,
            closeWith: ['click'],
            killer: true
        }).show();
    };

    output.watch = function(messageContainerSelector){
        setInterval(function(){
            var alertBoxes = $(messageContainerSelector);
            
            if(alertBoxes && alertBoxes.length > 0){
                alertBoxes.each(function(){
                    var elem = $(this);

                    var type = elem.data("type");
                    var message = elem.data("message");

                    output.show(type, message);

                    elem.remove();
                });
            }
        }, 300);
    };

    return output;
}());

$(function(){
    if(notyAlertManager){
        notyAlertManager.watch('.notyAlert');
    }

    $(document).on("flashNotyAlert", {
        foo: "bar"
    }, function(event, type, message) {
        //event.data.foo;

        

        if(message){
            var elem = "<div class='divNotyAlertFromJS' data-type="+type+" data-message="+message+"></div>";

            $('body').append(elem);
            $(".divNotyAlertFromJS").data("message", message);
            $(".divNotyAlertFromJS").addClass("notyAlert");
        }
    });
});
