var callForSupervisorsManager = {
    _containerVueId: "#containerPageCallForDoctoralThesisSupervisorId",
    _vueObjPage: null,
    _containerPageSubmitCompleteId: "#callForSupervisorsPageSumbitCompletedId",
    _vueObjPageSubmitComplete: null,

    initVueObjPage: function(){
        var manager = this;

        manager._vueObjPage = new Vue({
            el: manager._containerVueId,
            data: {
                tokenCsrf: null,
                showForm: 'no',
                formIsBeingSent: 'no',
                contact: { email: null, linkedin_profile_link: null, receive_news: true, cv: null }
            },

            ready: function(){
                this.init();
            },

            computed: {
                formIsVisible(){
                    return this.showForm && this.showForm == 'yes';
                },
                formIsHidden(){
                    return this.showForm && this.showForm == 'no';
                },
                sendingForm: function(){
                    return this.formIsBeingSent && this.formIsBeingSent == 'yes';
                }
            },

            watch: {
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                    }
                },

                toggleFormVisible: function(){
                    const vueObj = this;

                    if(!vueObj.showForm || vueObj.showForm == 'yes'){
                        vueObj.showForm = 'no';
                    }else{
                        vueObj.showForm = 'yes';
                    }
                },

                goToFormAndOpen: function(){
                    const vueObj = this;

                    vueObj.showForm = 'yes';

                    vueObj.$nextTick(() => {
                        if(vueObj.$els.containerVue){
                            vueObj.$els.containerForm.scrollIntoView();
                        }
                    });
                },

                manageFileUploads: function(){
                    const vueObj = this;

                    let elem = vueObj.$els.containerCv;
                    let files = $(elem)[0].files;

                    vueObj.contact.cv = files[0];
                },

                sendForm: function(){
                    const vueObj = this;

                    const formElem = $(vueObj.$els.containerVue).find("form");

                    const url = formElem.attr("action");
                    const method = formElem.attr("method");
                    const formData = new FormData(formElem[0]);

                    if(formData.get('g-recaptcha-response')){
                        vueObj.formIsBeingSent = "yes";

                        vueObj.$http({ url: url, method: method, data: formData }).then(function(response){
                            if(response.data.status == 'success'){
                                /*$(document).trigger("flashNotyAlert", ["success", response.data.message]);
                                vueObj.toggleFormVisible();
                                vueObj.cleanForm();*/

                                document.location.href = "/call_for_supervisor/complete";
                            }else{
                                $(document).trigger("flashNotyAlert", ["error", response.data.message]);
                            }

                            vueObj.formIsBeingSent = 'no';

                        }, function(response){
                            $(document).trigger("flashNotyAlert", ["error", "Something went wrong."]);
                        });
                    }else{
                        //ReCaptcha needed!
                    }
                },

                cleanForm: function(){
                    const vueObj = this;

                    vueObj.contact.email = null;
                    vueObj.contact.linkedin_profile_link = null;
                    vueObj.contact.receive_news = true;
                    vueObj.contact.cv = null;
                }
            }
        });
    },

    initVuePageSubmitComplete: function(){
        var manager = this;

        manager._vueObjPageSubmitComplete = new Vue({
            // element to mount to
            el: manager._containerPageSubmitCompleteId,
            // initial data
            data: {
                secondsToRedirect: 10
            },
            // computed property for form validation state
            computed: {
            },
            // methods
            methods: {
            }
        });
    }
};

$(function(){
    if($(callForSupervisorsManager._containerVueId) && $(callForSupervisorsManager._containerVueId).length > 0){
        callForSupervisorsManager.initVueObjPage();
    }

    if($(callForSupervisorsManager._containerPageSubmitCompleteId) && $(callForSupervisorsManager._containerPageSubmitCompleteId).length > 0){
        callForSupervisorsManager.initVuePageSubmitComplete();
    }
});
