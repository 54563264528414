var managerShowMasterCourseDetail = {
    _containerVueId: '#containerShowMasterCourseId',
    _vueObjDetailPage: null,
    _cropperEventImage: null,
    _cropperEventImageSocial: null,

    initVue: function(){
        var manager = this;

        manager._vueObjDetailPage = new Vue({
            el: manager._containerVueId,

            data: {
                containerId: manager._containerVueId,
                eventId: 0,
                tokenCsrf: null,
                currentUserId: 0,
                currentUser: null,
                currentUserIsPremiumAcc: 'no',
                currentUserClubsIds: [],
                eventsIdsCurrentUserJoined: [],
                currentUserJoinedIdsProgrammes: [],
                photo: { modal: $("#modalMasterCoursePhotoUpdateId"), image: $("#eventPhotoUploadId"), imageSocial: $("#eventPhotoUploadSocialId") },
                base64data: { original: null, web: null, fb_lkn: null, twitter: null },
                isUploadingImage: 'no',
                isLoadingEditions: 'no',
                editions: { next: [], past: [], free_edition: null },
                sectionActive: 'overview',
            },

            ready: function(){
                this.init();
            },

            events: {
            },

            computed: {
                showLoadingEditions: function(){
                    return this.isLoadingEditions && this.isLoadingEditions == 'yes';
                },

                hasEditionsNext: function(){
                    return this.editions.next && this.editions.next.length > 0;
                },

                hasEditionsPast: function(){
                    return this.editions.past && this.editions.past.length > 0;
                },

                hasEditionFree: function(){
                    return this.editions.free_edition && this.editions.free_edition != null;
                },

                showListEditions: function(){
                    return !this.showLoadingEditions && (this.hasEditionsNext || this.hasEditionsPast || this.hasEditionFree);
                },

                showListEditionsNoResults: function(){
                    return !this.showLoadingEditions && !this.hasEditionsNext && !this.hasEditionsPast && !this.hasEditionFree;
                },

                isSectionActiveOverview: function(){
                    return !this.sectionActive || (this.sectionActive && this.sectionActive == 'overview');
                },

                isSectionActiveProgram: function(){
                    return this.sectionActive && this.sectionActive == 'program';
                },

                isSectionActiveEditions: function(){
                    return this.sectionActive && this.sectionActive == 'editions';
                }
            },

            methods: {
                init: function(){
                    const vueObj = this;

                    if(vueObj.$els.containerVue){
                        vueObj.tokenCsrf = $(vueObj.$els.containerVue).data('token-csrf');
                        vueObj.eventId = $(vueObj.$els.containerVue).data('event-id');
                        vueObj.sectionActive = $(vueObj.$els.containerVue).data("section-active");
                    }

                    if(vueObj.$els.containerUserId){
                        vueObj.currentUserId = parseInt($(vueObj.$els.containerUserId).data('user-id'));
                    }

                    if($(vueObj.$els.currentUserAccPremium)){
                        if(parseInt($(vueObj.$els.currentUserAccPremium).data('premium')) == 1){
                            vueObj.currentUserIsPremiumAcc = 'yes';
                        }
                    }

           
                    if(vueObj.currentUserId > 0){
                        vueObj.getCurrentUserInfo();
                    }

                    Vue.nextTick(function () {
                        divExpandManager.check_size_div_text();
                    });

                    vueObj.getEditions();
                },

                manageImageUpload: function(){
                    const vueObj = this;

                    const elemInputFile = vueObj.$els.eventphotouploader;

                    const files = $(elemInputFile)[0].files;

                    let done = function(url){
                        vueObj.base64data.original = url;

                        if(vueObj.photo.image){
                            vueObj.photo.image.attr("src",url);
                        }

                        if(vueObj.photo.imageSocial){
                            vueObj.photo.imageSocial.attr("src",url);
                        }

                        if(vueObj.photo.modal){
                            vueObj.photo.modal.foundation('open');
                        }

                    };

                    if(files && files.length > 0){
                        let reader = new FileReader();

                        reader.onload = function(){
                            done(reader.result);
                        };

                        reader.readAsDataURL(files[0]);
                    }
                },

                getBase64dataImages: function(type){
                    const vueObj = this;
                    let canvas = null;
                    let canvasOptions = null;

                    switch(type){
                        case 'web':
                            canvasOptions = { width: 1190, height: 1074, minWidth: 1190, minHeight:1074 };
                            break;
                        case 'fb_lkd':
                            canvasOptions = { width: 1200, height: 627, minWidth: 1200, minHeight:627 };
                            break;
                        case 'twitter':
                            canvasOptions = { width: 1200, height: 600, minWidth: 1200, minHeight:600 };
                            break;
                    }

            
                    canvas = manager._cropperEventImageSocial.getCroppedCanvas(canvasOptions);
                    

                    if(canvas){
                        canvas.toBlob(function(blob){ 
                            const reader = new FileReader();

                            reader.readAsDataURL(blob);

                            reader.onloadend = function(){
                                switch(type){
                                    case 'web':
                                        vueObj.base64data.web = reader.result;
                                        break;
                                    case 'fb_lkd':
                                        vueObj.base64data.fb_lkn = reader.result;
                                        break;
                                    case 'twitter':
                                        vueObj.base64data.twitter = reader.result;
                                        break;
                                }
                            };
                        });
                    }
                },

                updateUserPhoto: function(){
                    const vueObj = this;

                    vueObj.isUploadingImage = 'yes';

                    if(vueObj.photo.modal.find(".divContLoading") && vueObj.photo.modal.find(".divContLoading").hasClass("hide")){
                        vueObj.photo.modal.find(".divContLoading").removeClass("hide");
                    }

                    if(vueObj.photo.modal.find(".btnClose") && !vueObj.photo.modal.find(".btnClose").hasClass("hide")){
                        vueObj.photo.modal.find(".btnClose").addClass("hide");
                    }

                    if(vueObj.photo.modal.find(".divContForm") && !vueObj.photo.modal.find(".divContForm").hasClass("hide")){
                        vueObj.photo.modal.find(".divContForm").addClass("hide");
                    }

                    vueObj.getBase64dataImages('web');
                    vueObj.getBase64dataImages('fb_lkd');
                    vueObj.getBase64dataImages('twitter');

                    vueObj.$nextTick(() => {
                        setTimeout(function(){
                            let params = {
                                image_original: vueObj.base64data.original, 
                                image_web: vueObj.base64data.web, 
                                image_fb_lkn: vueObj.base64data.fb_lkn, 
                                image_twitter: vueObj.base64data.twitter
                            };

                            vueObj.$http({ 
                                url: "/json/events/"+ vueObj.eventId +"/update_image/v1", method: 'POST',  
                                contentType: 'multipart/form-data', 
                                responseType: 'json', 
                                data: params,
                                headers: { 'X-CSRF-TOKEN': vueObj.tokenCsrf }
                            }).then(function(response){
                                //SUCCESS
                                if(response.data.result == "success"){
                                   /* if(vueObj.photo.modal){
                                        vueObj.photo.modal.foundation('close');
                                    }*/
        
                                    window.location.reload();
                                }
                            }, function(response){
                                vueObj.isUploadingImage = 'no';
                                //ERROR
                            });
                        }, 1500);

                    });
                },

                getCurrentUserInfo: function(){
                    const vueObj = this;

                    vueObj.$http({ url: '/json/users/current_user_info', method: 'GET' }).then(function(response){
                        vueObj.currentUser = response.data.user;
                        vueObj.eventsIdsCurrentUserJoined = response.data.joined_events_ids;
                        vueObj.currentUserClubsIds = response.data.joined_learning_clubs_ids;
                        vueObj.currentUserJoinedIdsProgrammes = response.data.joined_programmes_ids;

                        vueObj.isLoadingResources = 'no';
                    }, function(response){
                        //ERROR
                    });
                },

                forceReloadTimeline: function(){
                    const vueObj = this;

                    vueObj.reloadTimeline = "yes";

                    Vue.nextTick(function () {
                        vueObj.reloadTimeline = "no";
                    });
                },

                getEditions: function(){
                    const vueObj = this;

                    vueObj.isLoadingEditions = 'yes';

                    const url = "/json/advanced_courses/"+ vueObj.eventId +"/editions";

                    vueObj.$http({ 
                        url: url, 
                        method: 'GET', 
                        responseType: 'json'
                    }).then(function(response){
                        vueObj.editions.free_edition = response.data.free_edition;
                        vueObj.editions.next = response.data.next;        
                        vueObj.editions.past = response.data.past;        
                        vueObj.isLoadingEditions = "no";
                    }, function(response){
                        //error callback
                    });
                }
            }
        });
    },

    initCropper: function(modal){
        const manager = this;

        if(modal){
            let imageSocial = modal.find("#eventPhotoUploadSocialId");

            manager._cropperEventImageSocial = new Cropper($(imageSocial)[0], {
                aspectRatio: 16/9,
                viewMode: 2,
                zoomable: false,
            });
        }
    },

    destroyCropper: function(){
        const manager = this;

        manager._cropperEventImage.destroy();
        manager._cropperEventImage = null;

        manager._cropperEventImageSocial.destroy();
        manager._cropperEventImageSocial = null;
    }
};

$(function(){
    if(managerShowMasterCourseDetail._containerVueId && $(managerShowMasterCourseDetail._containerVueId).length > 0){
        managerShowMasterCourseDetail.initVue();
    }

    $(document).on("eventReloadAssets", {}, function() {
        managerShowMasterCourseDetail._vueObjDetailPage.forceReloadTimeline();
    });

    $(document).on(
        'open.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalMasterCoursePhotoUpdateId"){
                managerShowMasterCourseDetail.initCropper($(this));
            }
        }
    );

    $(document).on(
        'closed.zf.reveal', '[data-reveal]', function (){
            if(this.id == "modalMasterCoursePhotoUpdateId"){
                managerShowMasterCourseDetail.destroyCropper();
            }
        }
    );

    $(document).on('click', '.btnSendUpdatedMasterCourseImage', function(e){
        e.stopPropagation();
        e.preventDefault();

        managerShowMasterCourseDetail._vueObjDetailPage.updateUserPhoto();
    });


});
