var notificationManager = {
    _linkClassSideMenuNotifications: '.linkSideMenuUserNotifications',
    _linkClassHeaderOptionsNotifications: '.linkHeaderOptionsUserNotifications',
    _linkClassHeaderUserPhoto: '.containerHeaderUlOptions .linkUserProfile',
    _timeoutGetNrUnviewedNotifications: null,

    renderNrNotificationsUnviewed: function(nrNotifications){
        var manager = this;
        
        var linkSideMenu = $(manager._linkClassSideMenuNotifications);
        var linkHeaderOptions = $(manager._linkClassHeaderOptionsNotifications);
        var linkHeaderUserPhoto = $(manager._linkClassHeaderUserPhoto);

        if(linkSideMenu && linkSideMenu.length > 0){
            manager.addNrNotificationsToDiv(linkSideMenu.find('.divNrNotifications'), nrNotifications);
        }

        if(linkHeaderOptions && linkHeaderOptions.length > 0){
            manager.addNrNotificationsToDiv(linkHeaderOptions.find('.divNrNotifications'), nrNotifications);
        }

        if(linkHeaderUserPhoto && linkHeaderUserPhoto.length > 0){
            manager.addNrNotificationsToDiv(linkHeaderUserPhoto.find('.divNrNotifications'), nrNotifications);
        }
    },

    addNrNotificationsToDiv: function(divNrNotifications, nrNotifications){
        if(nrNotifications && nrNotifications > 0){
            var nrNotificationsToShow = nrNotifications;

            if(nrNotifications > 99){
                nrNotificationsToShow = '99+';
            }

            if(divNrNotifications){
                if(!divNrNotifications.hasClass("active")){
                    divNrNotifications.addClass("active");
                }

                divNrNotifications.html(nrNotificationsToShow);
            }
        }else{
            if(divNrNotifications.hasClass("active")){
                divNrNotifications.removeClass("active");
            }
        }
    },

    getNrNotificationsUnviewed: function(){
        var manager = this;

        var url = null;

        var linkSideMenu = $(manager._linkClassSideMenuNotifications);

        if(linkSideMenu && linkSideMenu.length > 0){
            url = linkSideMenu.data('route');
        }else{
            var linkHeaderOptions = $(manager._linkClassHeaderOptionsNotifications);
            url = linkHeaderOptions.data('route');
        }

        if(url){
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                success: function(data){
                    manager.renderNrNotificationsUnviewed(data.nr_unviewed);
                }
            });

            manager._timeoutGetNrUnviewedNotifications = setTimeout(function(){
                                                                        manager.getNrNotificationsUnviewed(); 
                                                                    }, 10000);
        }else{
            if(manager._timeoutGetNrUnviewedNotifications){
                manager.clearTime(manager._timeoutGetNrUnviewedNotifications);
            }
        }
    },

    clearTime: function(timeout){
        clearTimeout(timeout);
    }
};

$(function(){
    notificationManager.getNrNotificationsUnviewed();
});
